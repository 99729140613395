var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showInsertMultipleImagesDialog,
        "max-width": "400px",
        persistent: "",
      },
    },
    [
      _c("validation-observer", {
        ref: "imageMultiImageForm",
        attrs: { slim: "" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ valid: isFormValid }) {
              return [
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c(
                          "v-toolbar",
                          {
                            staticClass: "elevation-0",
                            attrs: { dark: "", color: "primary" },
                          },
                          [
                            _c("v-toolbar-title", [
                              _vm._v(" Insert Multiple Images "),
                            ]),
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "", dark: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit(
                                      "close-insert-images-dialog"
                                    )
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v("mdi-close")])],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-card-text",
                          { staticClass: "py-3" },
                          [
                            _c("div", { staticClass: "py-1 my-0 body-1" }, [
                              _vm._v("Insert image as"),
                            ]),
                            _c("validation-provider", {
                              attrs: { name: "Input URL", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c(
                                          "v-radio-group",
                                          {
                                            staticClass: "py-1 my-0",
                                            attrs: {
                                              "error-messages": errors,
                                              success: valid,
                                            },
                                            model: {
                                              value: _vm.insertImageAs,
                                              callback: function ($$v) {
                                                _vm.insertImageAs = $$v
                                              },
                                              expression: "insertImageAs",
                                            },
                                          },
                                          [
                                            _c("v-radio", {
                                              attrs: {
                                                label: "Photo Group",
                                                value:
                                                  _vm.INSERT_IMAGE.PHOTO_GROUP,
                                              },
                                            }),
                                            _vm.insertImageAs ===
                                            _vm.INSERT_IMAGE.PHOTO_GROUP
                                              ? _c("ImageSizeFields", {
                                                  staticClass: "my-2",
                                                  model: {
                                                    value: _vm.imageStyles,
                                                    callback: function ($$v) {
                                                      _vm.imageStyles = $$v
                                                    },
                                                    expression: "imageStyles",
                                                  },
                                                })
                                              : _vm._e(),
                                            _c("v-radio", {
                                              attrs: {
                                                label: "Photo Page - 1 per row",
                                                value:
                                                  _vm.INSERT_IMAGE
                                                    .PHOTO_PAGE_1_PER_ROW,
                                              },
                                            }),
                                            _c("v-radio", {
                                              attrs: {
                                                label: "Photo Page - 2 per row",
                                                value:
                                                  _vm.INSERT_IMAGE
                                                    .PHOTO_PAGE_2_PER_ROW,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          { staticClass: "d-flex justify-end" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  type: "submit",
                                  color: "primary",
                                  disabled: !isFormValid,
                                  dark: isFormValid,
                                },
                              },
                              [_vm._v(" Insert ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }