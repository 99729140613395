<template>
  <v-dialog
    :value="showPreviewTemplateDialog"
    max-width="800px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card class="pa-0 ma-0">
      <v-toolbar dark color="primary" ref="toolbar">
        <v-toolbar-title>Sample PDF</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('preview-template-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        :style="{
          'background-color': '#f1f2f1',
          height: $vuetify.breakpoint.xsOnly ? `${contentHeight}px` : '65vh',
          'overflow-y': 'auto',
          'max-height': $vuetify.breakpoint.xsOnly ? undefined : '60vh',
        }"
        class="pa-0 ma-0 flex-grow-1 d-flex flex-column"
      >
        <div v-if="!templateNotFound" class="d-flex flex-grow-1">
          <iframe
            :key="generatedPdfUrl"
            :src="generatedPdfUrl"
            type="application/pdf"
            class="flex-grow-1"
          ></iframe>
        </div>
        <div v-else class="d-flex flex-grow-1 justify-center align-center">
          Template not found
        </div>

        <EditFormDataDialog
          v-if="showEditFormDataDialog"
          :showEditFormDataDialog="showEditFormDataDialog"
          :formDefinition="computedFormDefinition"
          @edit-form-dialog-close="showEditFormDataDialog = false"
          @input="computedFormDefinition = $event"
          @form-data-submitted="
            params = $event;
            showEditFormDataDialog = false;
            generatePdfPreview();
          "
        />
      </v-card-text>

      <v-card-actions class="d-flex justify-end py-4" ref="cardAction">
        <v-btn color="primary" dark @click="showEditFormDataDialog = true">
          Edit Form Data
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import EditFormDataDialog from "@/components/forms/form-builder-edit-form/report-template-form/preview-template-dialog/EditFormDataDialog.vue";
import { cloneDeep } from "lodash";
import contentHeightMixin from "@/mixins/contentHeightMixin";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "PreviewTemplateDialog",
  mixins: [contentHeightMixin],
  props: {
    showPreviewTemplateDialog: Boolean,
    formDefinition: Object,
  },
  components: {
    EditFormDataDialog,
  },
  data() {
    return {
      generatedPdfUrl: "",
      showEditFormDataDialog: false,
      params: {},
      computedFormDefinition: {},
      templateNotFound: false,
    };
  },
  methods: {
    async generatePdfPreview() {
      const items = cloneDeep(
        this.formDefinition.form.sections
          .map((c) => c.items)
          .filter(
            (item) =>
              item?.question?.type !== "FILE" ||
              (item?.question?.type === "FILE" && item?.question?.isImage)
          )
          .flat()
      );
      const defaultParams = items.reduce((acc, item) => {
        if (item?.question?.type === "NUMBER") {
          return {
            ...acc,
            [item.id]: 0,
          };
        } else {
          return {
            ...acc,
            [item.id]: "",
          };
        }
      }, {});
      this.params = { ...defaultParams, ...this.params };
      const { params } = this;
      const {
        html_merge_definition: htmlMergeDefinition,
        html_merge_definition_header: htmlMergeDefinitionHeader,
        html_merge_definition_footer: htmlMergeDefinitionFooter,
        html_merge_definition_version: htmlMergeDefinitionVersion,
        form,
      } = this.formDefinition;
      if (!htmlMergeDefinition) {
        this.templateNotFound = true;
        return;
      }
      const { data } = await axios.post(
        `${APIURL}/form_definitions/pdf_preview`,
        {
          html_merge_definition: htmlMergeDefinition,
          html_merge_definition_header: htmlMergeDefinitionHeader,
          html_merge_definition_footer: htmlMergeDefinitionFooter,
          html_merge_definition_version: htmlMergeDefinitionVersion,
          form,
          params,
        },
        {
          responseType: "blob",
        }
      );
      this.generatedPdfUrl = window.URL.createObjectURL(data);
    },
  },
  beforeMount() {
    this.generatePdfPreview();
    this.computedFormDefinition = cloneDeep(this.formDefinition);
  },
};
</script>
