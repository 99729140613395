var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-toolbar",
        { attrs: { dark: "", color: "primary" } },
        [
          _c("v-toolbar-title", [
            _vm._v(
              " " +
                _vm._s(typeof this.index !== "number" ? "Add" : "Edit") +
                " Choice "
            ),
          ]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { icon: "", dark: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("choice-form-close")
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _c("validation-observer", { ref: "form" }, [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.saveChoice.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _vm.isImage
                          ? [
                              _c("file-pond", {
                                ref: "files",
                                attrs: {
                                  name: "files",
                                  "label-idle": "Tap or drop photos here...",
                                  "allow-multiple": false,
                                  "accepted-file-types":
                                    "image/jpeg, image/png",
                                  files: _vm.selectedFile,
                                  allowFileSizeValidation: "",
                                  maxFileSize: "2MB",
                                },
                                on: { addfile: _vm.onFileChange },
                              }),
                            ]
                          : [
                              _c("validation-provider", {
                                attrs: { name: "Value", rules: "required" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Value",
                                            "hide-details": "auto",
                                            "error-messages": errors,
                                            success: valid,
                                            color: "primary",
                                            name: "value",
                                            disabled: !_vm.canManageForms,
                                          },
                                          model: {
                                            value: _vm.choice.value,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.choice, "value", $$v)
                                            },
                                            expression: "choice.value",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                      ],
                      2
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("validation-provider", {
                          attrs: { name: "Label" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors, valid }) {
                                return [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Label (Optional)",
                                      "hide-details": "auto",
                                      "error-messages": errors,
                                      success: valid,
                                      color: "primary",
                                      name: "name",
                                      disabled: !_vm.canManageForms,
                                    },
                                    model: {
                                      value: _vm.choice.label,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.choice, "label", $$v)
                                      },
                                      expression: "choice.label",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("validation-provider", {
                          attrs: { name: "Visible" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors, valid }) {
                                return [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.visibilityOptions,
                                      label: "Visible",
                                      "error-messages": errors,
                                      success: valid,
                                      "item-text": "label",
                                      "item-value": "value",
                                      "hide-details": "",
                                      disabled: !_vm.canManageForms,
                                    },
                                    model: {
                                      value: _vm.visibleCondition,
                                      callback: function ($$v) {
                                        _vm.visibleCondition = $$v
                                      },
                                      expression: "visibleCondition",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        ["conditional"].includes(_vm.visibleCondition)
                          ? _c("ComparisonConditionForm", {
                              attrs: {
                                formDefinition: _vm.formDefinition,
                                selectedItem: _vm.selectedItem,
                              },
                              model: {
                                value: _vm.choice.visible,
                                callback: function ($$v) {
                                  _vm.$set(_vm.choice, "visible", $$v)
                                },
                                expression: "choice.visible",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "d-flex justify-end",
                        attrs: { cols: "12" },
                      },
                      [
                        _c("v-btn", { attrs: { text: "", type: "submit" } }, [
                          _vm._v("Save Choice"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }