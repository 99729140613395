<template>
  <v-dialog
    :value="showEditPositiveResponseChoicesDialog"
    max-width="1000px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title> Edit Positive Response Choices </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$emit('close-dialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text style="background-color: #f1f2f1" class="pa-4">
          <v-card>
            <v-card-text class="pa-0">
              <v-simple-table height="40vh" fixed-header>
                <thead>
                  <tr>
                    <th></th>
                    <th>Value</th>
                    <th>Label</th>
                    <th>Visibility</th>
                  </tr>
                </thead>

                <draggable
                  :list="selectedItemOptions.question.options"
                  tag="tbody"
                  v-if="selectedItemOptions.question.options.length > 0"
                  @end="onSubmit()"
                >
                  <tr
                    v-for="option of selectedItemOptions.question.options"
                    :key="option.id"
                  >
                    <td class="pl-1 pr-0 mx-0 cursor-grab">
                      <v-icon>{{ mdiDragVertical }}</v-icon>
                    </td>
                    <td>{{ option.value }}</td>
                    <td>{{ option.label }}</td>
                    <td class="d-flex align-center primary--text">
                      <VisibleConditionDialog
                        :formDefinition="formDefinition"
                        v-model="option.visible"
                        @input="onSubmit()"
                      >
                        <b>
                          {{ getVisibleCondition(option) }}
                        </b>
                      </VisibleConditionDialog>
                    </td>
                  </tr>
                </draggable>
                <tbody v-else>
                  <tr>
                    <td colspan="4">
                      <div class="d-flex justify-center caption">
                        No Positive Response choices added yet.
                      </div>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pa-5">
          <v-btn
            text
            type="saveChoices"
            @click="
              onSubmit();
              $emit('close-dialog');
            "
          >
            Save Choices
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import draggable from "vuedraggable";
import VisibleConditionDialog from "@/components/forms/form-builder-edit-form/form-control-edit-form/positive-response-form/VisibleConditionDialog.vue";
import { cloneDeep } from "lodash";
import axios from "axios";
import { mdiDragVertical } from "@mdi/js";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "EditPositiveResponseChoicesDialog",
  components: {
    draggable,
    VisibleConditionDialog,
  },
  props: {
    value: Object,
    formDefinition: Object,
    showEditPositiveResponseChoicesDialog: Boolean,
    locateRequestProviderId: String,
  },
  beforeMount() {
    this.selectedItemOptions = cloneDeep(this.value);
  },
  data() {
    return { mdiDragVertical, selectedItemOptions: {} };
  },
  methods: {
    onSubmit() {
      this.$emit("input", this.selectedItemOptions);
    },
    getVisibleCondition(option) {
      if (
        option?.visible?.condition !== "ALWAYS" &&
        option?.visible?.condition !== "NEVER"
      ) {
        return "CONDITIONAL";
      } else {
        return option?.visible?.condition;
      }
    },
    async getPositiveResponses() {
      if (!this.locateRequestProviderId) {
        return;
      }
      const {
        data: { results },
      } = await axios.get(
        `${APIURL}/positive_response_codes/${this.locateRequestProviderId}`
      );
      const existingOptions = cloneDeep(
        this.selectedItemOptions.question.options
      );
      this.selectedItemOptions.question.options = [...results].map((r) => {
        const { description, positive_response_code: value } = r;
        const existingOption = existingOptions.find((o) => o.value === value);
        const visible = existingOption?.visible ?? { condition: "ALWAYS" };
        return { label: `${value} ${description}`, value, visible };
      });
      this.onSubmit();
    },
  },
  watch: {
    locateRequestProviderId: {
      immediate: true,
      handler() {
        this.getPositiveResponses();
      },
    },
  },
};
</script>
