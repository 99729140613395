<template>
  <v-card
    class="elevation-0"
    width="100%"
    :style="selected ? 'background-color: #fff8e1' : ''"
  >
    <v-card-text class="pa-0 ma-0 mb-2">
      <v-row>
        <template v-if="item.question.isImage">
          <v-col cols="12" class="py-0" :class="{ selected }">
            <p class="caption mb-0">
              {{ `${item.number} ${item.question.label}` }}
            </p>
            <p class="caption mb-0">{{ item.question.placeholder }}</p>
            <v-radio-group v-model="selectedValue" :disabled="readOnly">
              <v-radio
                v-for="option in options"
                :key="option.id"
                :value="option.value"
                :readonly="readOnly"
              >
                <template v-slot:label>
                  <img
                    :src="option.value"
                    :style="{ width: 'auto', height: '64px' }"
                  />
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </template>
        <template v-else>
          <template v-if="item.question.showAllOptions">
            <v-col cols="12" class="py-0" :class="{ selected }">
              <p class="caption mb-0">
                {{ `${item.number} ${item.question.label}` }}
              </p>
              <p class="caption mb-0">{{ item.question.placeholder }}</p>

              <v-radio-group
                v-model="selectedValue"
                :disabled="readOnly"
                @change="onInputChange"
              >
                <v-radio
                  v-for="option in options"
                  :key="option.id"
                  :value="option.value"
                  :label="option.label"
                >
                </v-radio>
              </v-radio-group>
            </v-col>
          </template>
          <template v-else>
            <v-col cols="12">
              <validation-provider
                slim
                v-slot="{ errors, valid }"
                :name="item.question.label"
                :rules="{
                  required: isRequired,
                }"
              >
                <v-select
                  autocomplete="off"
                  :items="options"
                  item-text="label"
                  item-value="value"
                  :label="`${item.number} ${item.question.label}`"
                  v-model="selectedValue"
                  @change="onInputChange"
                  :error-messages="errors"
                  :success="valid"
                  :disabled="readOnly"
                  :hint="item.question.placeholder"
                  persistent-hint
                >
                </v-select>
              </validation-provider>
            </v-col>
          </template>
        </template>

        <v-col cols="12" v-if="showTextInput">
          <validation-provider
            v-slot="{ errors, valid }"
            :name="item.question.label"
            rules="required"
          >
            <v-text-field
              v-model="otherValue"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              :name="item.id"
              @input="onInputChange"
              :disabled="readOnly"
              :label="`${item.number} ${item.question.label}`"
            >
            </v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import dependantValueMixin from "@/mixins/dependantValueMixin";

export default {
  name: "SingleSelectInput",
  props: {
    item: Object,
    formDefinition: Object,
    value: [Array, String],
    selected: Boolean,
    readOnly: Boolean,
  },
  mixins: [dependantValueMixin],
  computed: {
    options() {
      const filteredOptions =
        this.item?.question?.options?.filter((o) => {
          if (typeof o?.visible !== "object") {
            return true;
          }
          const { sections } = { ...this.formDefinition.form };
          const items = sections.map((s) => s.items).flat();
          const value = items?.find(
            (it) => it.id === o?.visible?.dependantId
          )?.value;
          if (o?.visible?.condition === "EQUAL") {
            return value === o?.visible?.value;
          } else if (o?.visible?.condition === "NOT_EQUAL") {
            return value !== o?.visible?.value;
          } else if (o?.visible?.condition === "GREATER_THAN") {
            return +value > +o?.visible?.value;
          } else if (o?.visible?.condition === "GREATER_THAN_OR_EQUAL") {
            return +value >= +o?.visible?.value;
          } else if (o?.visible?.condition === "LESS_THAN") {
            return +value < +o?.visible?.value;
          } else if (o?.visible?.condition === "LESS_THAN_OR_EQUAL") {
            return +value <= +o?.visible?.value;
          }
        }) ?? [];
      if (this.item.question.isImage) {
        return [...filteredOptions];
      } else {
        if (this.item.question.hasOther) {
          return [
            ...filteredOptions,
            {
              value: "other",
              label: "Other",
            },
          ];
        } else {
          return [...filteredOptions];
        }
      }
    },
    showTextInput() {
      return this.selectedValue === "other";
    },
    isRequired() {
      const { sections } = { ...this.formDefinition.form };
      for (const section of sections) {
        for (const dependantItem of section.items) {
          if (+dependantItem.id === +this.item.question.required.dependantId) {
            if (dependantItem?.question?.type === "GROUP") {
              for (const groupDependantItem of dependantItem?.question
                ?.groupedItems ?? []) {
                if (
                  +groupDependantItem.id ===
                  +this.item.question.required.dependantGroupItemId
                ) {
                  const groupDependantItemValue = groupDependantItem.value;
                  if (Array.isArray(groupDependantItemValue)) {
                    if (groupDependantItemValue.length > 1) {
                      return false;
                    } else {
                      const [dependantItemValue] = groupDependantItemValue;
                      return this.checkDependantValue(dependantItemValue);
                    }
                  } else {
                    return this.checkDependantValue(groupDependantItemValue);
                  }
                }
              }
            } else {
              const dependantItemValue = dependantItem.value;
              if (Array.isArray(dependantItemValue)) {
                if (dependantItemValue.length > 1) {
                  return false;
                } else {
                  const [dependantValue] = dependantItemValue;
                  return this.checkDependantValue(dependantValue);
                }
              } else {
                return this.checkDependantValue(dependantItemValue);
              }
            }
          }
        }
      }
      return this.item.question.required.condition === "ALWAYS";
    },
  },
  data() {
    return {
      otherValue: undefined,
      selectedValue: "",
    };
  },
  methods: {
    onInputChange() {
      if (this.selectedValue === "other") {
        this.$emit("input", this.otherValue);
      } else {
        this.$emit("input", this.selectedValue);
      }
    },
  },
  watch: {
    value: {
      handler(val) {
        const values = this.options.map((o) => o.value);
        if (val && !values.includes(val)) {
          this.otherValue = val;
          this.selectedValue = "other";
        } else {
          this.selectedValue = val;
        }
      },
    },
  },
};
</script>

<style scoped></style>
