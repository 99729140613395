const BORDER_STYLE = {
  LEFT_TOP_BOTTOM: "left-top-bottom",
  TOP_BOTTOM: "top-bottom",
  TOP_RIGHT_BOTTOM: "top-right-bottom",
  ALL: "all",
  LEFT_TOP: "left-top",
  TOP: "top",
  TOP_RIGHT: "top-right",
  LEFT_TOP_RIGHT: "left-top-right",
  LEFT: "left",
  NONE: "none",
  RIGHT: "right",
  LEFT_RIGHT: "left-right",
  LEFT_BOTTOM: "left-bottom",
  BOTTOM: "bottom",
  RIGHT_BOTTOM: "right-bottom",
  LEFT_BOTTOM_RIGHT: "left-bottom-right",
};

export default BORDER_STYLE;
