import Link from "@tiptap/extension-link";

const CustomLink = Link.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      href: {
        default: null,
        parseHTML: (element) => element.getAttribute("href"),
        renderHTML: (attributes) => ({
          href: attributes.href,
        }),
      },
      class: {
        default: null,
      },
      style: {
        default: null,
      },
    };
  },
});

export default CustomLink;
