import Paragraph from "@tiptap/extension-paragraph";

const CustomParagraph = Paragraph.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      display: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.display) {
            return {};
          }
          return {
            style: `display: ${attributes.display}`,
          };
        },
        parseHTML: (element) => {
          return element.style.display.replace(/['"]+/g, "");
        },
      },
      width: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.width) {
            return {};
          }

          return {
            style: `width: ${attributes.width}`,
          };
        },
        parseHTML: (element) => {
          return element.style.width.replace(/['"]+/g, "");
        },
      },
      padding: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.padding) {
            return {};
          }

          return {
            style: `padding: ${attributes.padding}`,
          };
        },
        parseHTML: (element) => {
          return element.style.padding.replace(/['"]+/g, "");
        },
      },
      marginTop: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.marginTop) {
            return {};
          }

          return {
            style: `margin-top: ${attributes.marginTop}`,
          };
        },
        parseHTML: (element) => {
          return element.style.marginTop.replace(/['"]+/g, "");
        },
      },
      marginBottom: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.marginBottom) {
            return {};
          }

          return {
            style: `margin-bottom: ${attributes.marginBottom}`,
          };
        },
        parseHTML: (element) => {
          return element.style.marginBottom.replace(/['"]+/g, "");
        },
      },
      marginLeft: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.marginLeft) {
            return {};
          }

          return {
            style: `margin-left: ${attributes.marginLeft}`,
          };
        },
        parseHTML: (element) => {
          return element.style.marginLeft.replace(/['"]+/g, "");
        },
      },
      marginRight: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.marginRight) {
            return {};
          }

          return {
            style: `margin-top: ${attributes.marginRight}`,
          };
        },
        parseHTML: (element) => {
          return element.style.marginRight.replace(/['"]+/g, "");
        },
      },
      fontSize: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.fontSize) {
            return {};
          }

          return {
            style: `font-size: ${attributes.fontSize}`,
          };
        },
        parseHTML: (element) => {
          return element.style.fontSize.replace(/['"]+/g, "");
        },
      },
      class: {
        default: null,
      },
    };
  },

  renderHTML({ HTMLAttributes, node }) {
    // Check if any of the content has span-like marks or styling
    const hasSpanStyling =
      node.content.size > 0 &&
      node.content.content.some((child) => {
        // Check if the node has any style marks
        return child.marks?.some(
          (mark) =>
            mark.type.name === "textStyle" || // Check for textStyle marks
            mark.attrs?.style?.includes("font-family") || // Or direct style attributes
            mark.attrs?.style?.includes("font-size") ||
            mark.attrs?.style?.includes("color")
        );
      });

    const isPlainText =
      node.content.size > 0 &&
      node.content.childCount === 1 &&
      node.content.firstChild.type.name === "text";

    // If it's a completely empty paragraph with no content
    if (!node.content.size) {
      return [
        "p",
        { ...HTMLAttributes, class: "lineBreak" },
        [
          "span",
          { style: "font-family: Open Sans; font-size: 16px; color: #000;" },
          0,
        ],
      ];
    }

    // If it's plain text with no styling, wrap with default styled span
    if (isPlainText && !hasSpanStyling) {
      // Remove lineBreak class if it exists since we now have content
      const attrs = { ...HTMLAttributes };
      if (attrs.class?.includes("lineBreak")) {
        attrs.class = attrs.class.replace("lineBreak", "").trim();
        if (!attrs.class) delete attrs.class;
      }

      return [
        "p",
        attrs,
        [
          "span",
          { style: "font-family: Open Sans; font-size: 16px; color: #000;" },
          0,
        ],
      ];
    }

    // If there's existing formatted content or styling, remove lineBreak class
    const attrs = { ...HTMLAttributes };
    if (attrs.class?.includes("lineBreak")) {
      attrs.class = attrs.class.replace("lineBreak", "").trim();
      if (!attrs.class) delete attrs.class;
    }

    // Return the paragraph with existing content
    return ["p", attrs, 0];
  },
});

export default CustomParagraph;
