var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "elevation-0 mx-auto",
      attrs: { "max-width": "600", color: "#e5e5e5" },
    },
    [
      _vm.isFormDefinitionOnLocateRequestLayer
        ? _c(
            "v-card-text",
            { staticClass: "px-0 py-3 card-background" },
            [
              _c(
                "v-tabs",
                {
                  attrs: { "background-color": "#f1f2f1" },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c("v-tab", { attrs: { href: "#labor" } }, [
                    _vm._v(" Labor "),
                  ]),
                  _c("v-tab", { attrs: { href: "#comments" } }, [
                    _vm._v(" Comments "),
                  ]),
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c(
                    "v-tab-item",
                    {
                      staticClass: "card-background",
                      attrs: { value: "labor" },
                    },
                    [
                      _c("CityworksLaborForm", {
                        on: {
                          input: function ($event) {
                            return _vm.$emit("input", _vm.formDefinition)
                          },
                        },
                        model: {
                          value: _vm.formDefinition,
                          callback: function ($$v) {
                            _vm.formDefinition = $$v
                          },
                          expression: "formDefinition",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { attrs: { value: "comments" } },
                    [
                      _c("CityworksServiceRequestCommentsForm", {
                        on: {
                          input: function ($event) {
                            return _vm.$emit("input", _vm.formDefinition)
                          },
                        },
                        model: {
                          value: _vm.formDefinition,
                          callback: function ($$v) {
                            _vm.formDefinition = $$v
                          },
                          expression: "formDefinition",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-card-text",
            { staticClass: "pa-0 card-background" },
            [
              _c(
                "v-tabs",
                {
                  attrs: { "background-color": "#f1f2f1" },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c("v-tab", { attrs: { href: "#to-cityworks" } }, [
                    _vm._v(" To Cityworks "),
                  ]),
                  _c("v-tab", { attrs: { href: "#from-cityworks" } }, [
                    _vm._v(" From Cityworks "),
                  ]),
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c(
                    "v-tab-item",
                    {
                      staticClass: "card-background",
                      attrs: { value: "to-cityworks" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "ma-2 ml-0 mr-0 card-background" },
                        [
                          _c("v-switch", {
                            attrs: {
                              label: "Enable To Cityworks UtiliBot",
                              disabled: !_vm.canManageForms,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$emit("input", _vm.formDefinition)
                              },
                            },
                            model: {
                              value:
                                _vm.formDefinition.form
                                  .city_works_webhook_config.enabled,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formDefinition.form
                                    .city_works_webhook_config,
                                  "enabled",
                                  $$v
                                )
                              },
                              expression:
                                "formDefinition.form.city_works_webhook_config.enabled",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.formDefinition.form.city_works_webhook_config.enabled
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("p", { staticClass: "caption" }, [
                                        _c("b", [_vm._v("Template")]),
                                      ]),
                                      _c("TemplateForm", {
                                        key: _vm.hasValidCityworksToken,
                                        on: {
                                          input: function ($event) {
                                            return _vm.$emit(
                                              "input",
                                              _vm.formDefinition
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.formDefinition,
                                          callback: function ($$v) {
                                            _vm.formDefinition = $$v
                                          },
                                          expression: "formDefinition",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("p", { staticClass: "caption" }, [
                                        _c("b", [_vm._v("Cityworks Activity")]),
                                      ]),
                                      _c("CityworksActivityForm", {
                                        attrs: {
                                          activityType: _vm.activityType,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.$emit(
                                              "input",
                                              _vm.formDefinition
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.formDefinition,
                                          callback: function ($$v) {
                                            _vm.formDefinition = $$v
                                          },
                                          expression: "formDefinition",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.activityType
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _vm.activityType === "Inspection"
                                            ? _c(
                                                "p",
                                                { staticClass: "caption" },
                                                [_c("b", [_vm._v("Comments")])]
                                              )
                                            : _c(
                                                "p",
                                                { staticClass: "caption" },
                                                [
                                                  _c("b", [
                                                    _vm._v(
                                                      "Comments and Instructions"
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                          _c("CityworksCommentsForm", {
                                            attrs: {
                                              activityType: _vm.activityType,
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.$emit(
                                                  "input",
                                                  _vm.formDefinition
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.formDefinition,
                                              callback: function ($$v) {
                                                _vm.formDefinition = $$v
                                              },
                                              expression: "formDefinition",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.activityType === "Inspection"
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("p", { staticClass: "caption" }, [
                                            _c("b", [_vm._v("Location")]),
                                          ]),
                                          _c("CityworksLocationForm", {
                                            on: {
                                              input: function ($event) {
                                                return _vm.$emit(
                                                  "input",
                                                  _vm.formDefinition
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.formDefinition,
                                              callback: function ($$v) {
                                                _vm.formDefinition = $$v
                                              },
                                              expression: "formDefinition",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.activityType === "Inspection"
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("p", { staticClass: "caption" }, [
                                            _c("b", [
                                              _vm._v("Observation Mappings"),
                                            ]),
                                          ]),
                                          _c("CityworksObservationMappings", {
                                            on: {
                                              input: function ($event) {
                                                return _vm.$emit(
                                                  "input",
                                                  _vm.formDefinition
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.formDefinition,
                                              callback: function ($$v) {
                                                _vm.formDefinition = $$v
                                              },
                                              expression: "formDefinition",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("p", { staticClass: "caption" }, [
                                        _c("b", [
                                          _vm._v(
                                            "Create Cityworks Activities On Form Submission"
                                          ),
                                        ]),
                                      ]),
                                      _c("CityworksActivities", {
                                        on: {
                                          input: function ($event) {
                                            return _vm.$emit(
                                              "input",
                                              _vm.formDefinition
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.formDefinition,
                                          callback: function ($$v) {
                                            _vm.formDefinition = $$v
                                          },
                                          expression: "formDefinition",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "v-tab-item",
                    {
                      staticClass: "card-background",
                      attrs: { value: "from-cityworks" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "ma-2 ml-0 mr-0 card-background" },
                        [
                          _c("v-switch", {
                            attrs: {
                              label: "Enable From Cityworks UtiliBot",
                              disabled: !_vm.canManageForms,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$emit("input", _vm.formDefinition)
                              },
                            },
                            model: {
                              value:
                                _vm.formDefinition.form
                                  .city_works_webhook_config.create_task
                                  .enabled,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formDefinition.form
                                    .city_works_webhook_config.create_task,
                                  "enabled",
                                  $$v
                                )
                              },
                              expression:
                                "\n              formDefinition.form.city_works_webhook_config.create_task\n                .enabled\n            ",
                            },
                          }),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("p", { staticClass: "caption" }, [
                                    _c("b", [_vm._v("Configuration")]),
                                  ]),
                                  _c("FromCityworksConfigurationForm", {
                                    on: {
                                      input: function ($event) {
                                        return _vm.$emit(
                                          "input",
                                          _vm.formDefinition
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.formDefinition,
                                      callback: function ($$v) {
                                        _vm.formDefinition = $$v
                                      },
                                      expression: "formDefinition",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm.showUserCityworksSignInDialog
                ? _c("UserCityworksSignInDialog", {
                    attrs: {
                      cityworksIntegrationInfo: _vm.cityworksIntegrationInfo,
                      showUserCityworksSignInDialog:
                        _vm.showUserCityworksSignInDialog,
                    },
                    on: {
                      "cityworks-sign-in-dialog-close": function ($event) {
                        _vm.showUserCityworksSignInDialog = false
                      },
                      "cityworks-sign-in-dialog-succeed": function ($event) {
                        _vm.showUserCityworksSignInDialog = false
                        _vm.validateCityworksToken()
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }