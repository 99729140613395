var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "d-flex flex-column my-0 py-3" },
    [
      _c("v-switch", {
        staticClass: "py-0 my-0",
        attrs: {
          label: "Enable PDF",
          disabled: _vm.disabled,
          "hide-details": "",
        },
        on: {
          change: function ($event) {
            return _vm.$emit("input", _vm.formDefinition)
          },
        },
        model: {
          value: _vm.formDefinition.form.formDescription.isDocumentEnabled,
          callback: function ($$v) {
            _vm.$set(
              _vm.formDefinition.form.formDescription,
              "isDocumentEnabled",
              $$v
            )
          },
          expression: "formDefinition.form.formDescription.isDocumentEnabled",
        },
      }),
      _c(
        "v-btn",
        {
          attrs: { text: "", color: "primary" },
          on: {
            click: function ($event) {
              _vm.showPreviewTemplateDialog = true
            },
          },
        },
        [_vm._v(" View Sample PDF ")]
      ),
      _c("validation-provider", {
        attrs: {
          name: "File Name",
          rules: {
            required: _vm.formDefinition.form.formDescription.isDocumentEnabled,
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ errors, valid }) {
              return [
                _c("v-text-field", {
                  attrs: {
                    placeholder: "File Name",
                    label: _vm.formNameLabel,
                    "hide-details": "auto",
                    "error-messages": errors,
                    success: valid,
                    color: "primary",
                    name: "value",
                    disabled: _vm.disabled,
                    "persistent-hint": "",
                    hint: _vm.NAME_HINT,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$emit("input", _vm.formDefinition)
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      _vm.canManageForms
                        ? {
                            key: "append",
                            fn: function () {
                              return [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: { "offset-y": "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    { attrs: { icon: "" } },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      _vm._s(_vm.mdiPlus) + " "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _vm.textFormFields.length > 0
                                      ? _c(
                                          "v-list",
                                          _vm._l(
                                            _vm.textFormFields,
                                            function (f) {
                                              return _c(
                                                "v-list-item",
                                                {
                                                  key: f.id,
                                                  staticClass:
                                                    "body-1 px-4 my-0 list-background",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.updateFileName(
                                                        f.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " ID:" +
                                                      _vm._s(f.id) +
                                                      " - " +
                                                      _vm._s(f.question.label) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass: "pa-3 list-background",
                                          },
                                          [_vm._v("No Applicable Fields")]
                                        ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.formDefinition.form.formDescription.pdfFileName,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formDefinition.form.formDescription,
                        "pdfFileName",
                        $$v
                      )
                    },
                    expression:
                      "formDefinition.form.formDescription.pdfFileName",
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c("validation-provider", {
        attrs: { name: "Folder" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ errors, valid }) {
              return [
                _c("v-text-field", {
                  attrs: {
                    placeholder: "Folder",
                    label: "Folder",
                    "hide-details": "auto",
                    "error-messages": errors,
                    success: valid,
                    color: "primary",
                    name: "value",
                    disabled: _vm.disabled,
                    "persistent-hint": "",
                    hint: _vm.FOLDER_HINT,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$emit("input", _vm.formDefinition)
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      _vm.canManageForms
                        ? {
                            key: "append",
                            fn: function () {
                              return [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: { "offset-y": "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    { attrs: { icon: "" } },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      _vm._s(_vm.mdiPlus) + " "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _vm.textFormFields.length > 0
                                      ? _c(
                                          "v-list",
                                          { attrs: { "max-height": "95vh" } },
                                          _vm._l(
                                            _vm.textFormFields,
                                            function (f) {
                                              return _c(
                                                "v-list-item",
                                                {
                                                  key: f.id,
                                                  staticClass:
                                                    "body-1 px-4 my-0 list-background",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.updateFolderName(
                                                        f.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " ID:" +
                                                      _vm._s(f.id) +
                                                      " - " +
                                                      _vm._s(f.question.label) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass: "pa-3 list-background",
                                          },
                                          [_vm._v("No Applicable Fields")]
                                        ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.formDefinition.folder,
                    callback: function ($$v) {
                      _vm.$set(_vm.formDefinition, "folder", $$v)
                    },
                    expression: "formDefinition.folder",
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _vm.showPageSettings
        ? _c(
            "v-btn",
            {
              attrs: { text: "", color: "primary", disabled: _vm.disabled },
              on: {
                click: function ($event) {
                  _vm.showPageSettingsDialog = true
                },
              },
            },
            [_vm._v(" Page Settings ")]
          )
        : _vm._e(),
      _vm.showPreviewTemplateDialog
        ? _c("PreviewTemplateDialog", {
            attrs: {
              showPreviewTemplateDialog: _vm.showPreviewTemplateDialog,
              formDefinition: _vm.value,
            },
            on: {
              "preview-template-dialog-close": function ($event) {
                _vm.showPreviewTemplateDialog = false
              },
            },
          })
        : _vm._e(),
      _vm.showPageSettingsDialog
        ? _c("PageSettingsDialog", {
            attrs: {
              showPageSettingsDialog: _vm.showPageSettingsDialog,
              value: _vm.value,
              disabled: _vm.disabled,
            },
            on: {
              "close-doc-settings-dialog": function ($event) {
                _vm.showPageSettingsDialog = false
              },
              input: _vm.onInputDocSettings,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }