var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "overflow-auto dark d-flex justify-center",
      style: {
        width: `${_vm.contentWidth}px`,
        height: `${_vm.contentHeight}px`,
      },
    },
    [
      _c(
        "v-card",
        {
          staticClass: "ma-6",
          attrs: { width: "804px", height: "fit-content" },
        },
        [
          _c(
            "v-card-text",
            { staticClass: "pa-8" },
            [
              _vm.htmlCode
                ? _c("div", { domProps: { innerHTML: _vm._s(_vm.htmlCode) } })
                : [_vm._v("   ")],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }