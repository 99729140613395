var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "node-view-wrapper",
    {
      staticClass: "statement",
      attrs: { as: "div", "data-placeholder": _vm.node.attrs.statement },
    },
    [_vm._v(" " + _vm._s(_vm.displayPlaceholder) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }