var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _vm.value === _vm.LOGIC_COMPARISON.EQUALS
      ? _c("span", { staticClass: "symbol" }, [_vm._v(" = ")])
      : _vm.value === _vm.LOGIC_COMPARISON.CONTAINS
      ? _c("span", { staticClass: "symbol" }, [_vm._v(" ⊂ ")])
      : _vm.value === _vm.LOGIC_COMPARISON.GREATER_THAN
      ? _c("span", { staticClass: "symbol" }, [_vm._v(" > ")])
      : _vm.value === _vm.LOGIC_COMPARISON.GREATER_THAN_OR_EQUAL
      ? _c("span", { staticClass: "symbol" }, [_vm._v(" ≥ ")])
      : _vm.value === _vm.LOGIC_COMPARISON.LESS_THAN
      ? _c("span", { staticClass: "symbol" }, [_vm._v(" < ")])
      : _vm.value === _vm.LOGIC_COMPARISON.LESS_THAN_OR_EQUAL
      ? _c("span", { staticClass: "symbol" }, [_vm._v(" ≤ ")])
      : _vm.value === _vm.LOGIC_COMPARISON.DOES_NOT_EQUAL
      ? _c("span", { staticClass: "symbol" }, [_vm._v(" ≠ ")])
      : _vm.value === _vm.LOGIC_COMPARISON.NOT_EMPTY
      ? _c(
          "span",
          { staticClass: "symbol" },
          [
            _c(
              "v-icon",
              {
                attrs: {
                  small: "",
                  color: _vm.selected ? "#4caf50" : undefined,
                },
              },
              [_vm._v(" " + _vm._s(_vm.mdiFormTextboxPassword) + " ")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }