<template>
  <validation-observer ref="photoFileForm">
    <v-toolbar color="primary" height="56px" class="elevation-0" ref="toolbar">
      <v-list-item two-line class="px-0">
        <v-list-item-content>
          <div class="d-flex justify-space-between">
            <v-list-item-title class="white--text text-h6">
              {{ selectedControlType | itemTitle(selectedItem) }}
            </v-list-item-title>
            <v-btn
              icon
              color="white"
              @click="$emit('form-control-form-submitted')"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-toolbar>
    <form
      @submit.prevent="
        onSubmit();
        $emit('form-control-form-submitted');
      "
    >
      <v-list
        dense
        style="max-height: calc(100vh - 56px)"
        class="overflow-y-auto"
      >
        <v-list-item class="my-0 py-0">
          <v-list-item-content>
            <validation-provider
              v-slot="{ errors, valid }"
              name="Label"
              rules="required"
            >
              <v-text-field
                v-model="selectedItemOptions.question.label"
                label="Label"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="name"
                @change="onSubmit()"
                ref="labelInput"
                :disabled="!canManageForms"
              />
            </validation-provider>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="my-0 py-0">
          <v-list-item-content>
            <validation-provider v-slot="{ errors, valid }" name="Visible">
              <v-select
                v-model="createCondition"
                :items="createOptions"
                label="Create"
                :error-messages="errors"
                :success="valid"
                item-text="label"
                item-value="value"
                @change="onSubmit()"
                hide-details
                :disabled="!canManageForms"
              >
              </v-select>
            </validation-provider>

            <CreateComparisonConditionForm
              v-if="['conditional'].includes(createCondition)"
              v-model="selectedItemOptions.question.create"
              :formDefinition="formDefinition"
              :selectedItem="selectedItem"
              @input="onSubmit()"
            />
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="my-0 py-0">
          <v-list-item-content>
            <validation-provider
              v-slot="{ errors, valid }"
              name="Required"
              rules="required"
            >
              <v-select
                v-model="requiredCondition"
                :items="requiredOptions"
                label="Required"
                :error-messages="errors"
                :success="valid"
                item-text="label"
                item-value="value"
                @change="onSubmit()"
                hide-details
                :disabled="!canManageForms"
              ></v-select>
            </validation-provider>

            <ComparisonConditionForm
              v-if="['conditional'].includes(requiredCondition)"
              v-model="selectedItemOptions.question.required"
              :formDefinition="formDefinition"
              :selectedItem="selectedItem"
              @input="onSubmit()"
            />
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="selectedItemOptions.question.isImage"
          class="my-0 py-0"
        >
          <v-list-item-content>
            <validation-provider v-slot="{ errors, valid }" name="Visible">
              <v-select
                v-model="selectedItemOptions.question.addPhotoMetadata"
                :items="[
                  { label: 'Never', value: 'NEVER' },
                  { label: 'Always', value: 'ALWAYS' },
                  { label: 'User Choice', value: 'CHOICE' },
                ]"
                label="Add Photo Metadata"
                :error-messages="errors"
                :success="valid"
                item-text="label"
                item-value="value"
                @change="onSubmit()"
                hide-details
                :disabled="!canManageForms"
              >
              </v-select>
            </validation-provider>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="selectedItemOptions.question.isImage"
          class="my-0 py-0"
        >
          <v-list-item-content>
            <validation-provider
              v-slot="{ errors, valid }"
              name="Maximum Width/Height of Photo"
              rules="min_value:0"
            >
              <v-text-field
                v-model="selectedItemOptions.question.maxWidthHeight"
                label="Maximum Width/Height of Photo"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="name"
                @change="onSubmit()"
                :disabled="!canManageForms"
              />
            </validation-provider>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="my-0 py-0">
          <v-list-item-content class="my-0 py-0">
            <v-switch
              v-model="selectedItemOptions.question.showDescription"
              label="Show Description"
              @change="onSubmit()"
              class="my-0 py-0"
              :disabled="!canManageForms"
            ></v-switch>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="my-0 py-0">
          <v-list-item-content class="my-0 py-0">
            <v-switch
              v-model="selectedItemOptions.allowMultiple"
              label="Allow Multiple"
              @change="onSubmit()"
              class="my-0 py-0"
              :disabled="!canManageForms"
            ></v-switch>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="my-0 py-0">
          <v-list-item-content class="my-0 py-0">
            <v-switch
              v-model="selectedItemOptions.showInReport"
              label="Show on Form Submissions Page"
              @change="onSubmit()"
              class="my-0 py-0"
              :disabled="!canManageForms"
            ></v-switch>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <AdvancedSettingsForm
              v-model="selectedItemOptions"
              :formDefinition="formDefinition"
              :selectedItem="selectedItem"
              :layer="layer"
              @input="onSubmit()"
            />
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <div class="d-flex flex-wrap gap" v-if="hasAdvancedSettings">
              <section
                class="text-uppercase d-flex primary--text gap align-center"
                v-if="hasVisibleSettings"
                @click="
                  if (canManageForms) {
                    showAdvancedVisibilitySettingsDialog = true;
                  }
                "
              >
                <div>Visible {{ visibleSetting }}</div>

                <v-btn icon :disabled="!canManageForms">
                  <v-icon color="primary">
                    {{ mdiPencil }}
                  </v-icon>
                </v-btn>
              </section>
            </div>
            <div v-else class="d-flex align-center justify-center">
              No advanced settings
            </div>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="my-0 py-0">
            <v-btn
              color="primary"
              type="submit"
              width="100%"
              :disabled="!canManageForms"
            >
              Update Item
            </v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <AdvancedVisibilitySettingsDialog
        v-if="showAdvancedVisibilitySettingsDialog"
        :showAdvancedVisibilitySettingsDialog="
          showAdvancedVisibilitySettingsDialog
        "
        :formDefinition="formDefinition"
        :layer="layer"
        :selectedItem="selectedItem"
        @advanced-settings-dialog-close="
          showAdvancedVisibilitySettingsDialog = false
        "
        v-model="selectedItemOptions"
        @input="onSubmit()"
      />
    </form>
  </validation-observer>
</template>

<script>
import {
  comparisonTypes,
  requiredOptions,
  visibilityOptions,
  createOptions,
} from "@/constants/choices";
import { cloneDeep } from "lodash";
import sleep from "@/mixins/sleep";
import ComparisonConditionForm from "./shared/ComparisonConditionForm";
import CreateComparisonConditionForm from "./shared/CreateComparisonConditionForm";
import AdvancedSettingsForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/AdvancedSettingsForm.vue";
import AdvancedVisibilitySettingsDialog from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/advanced-settings-form/AdvancedVisibilitySettingsDialog.vue";
import { mdiPencil } from "@mdi/js";
import { CONDITION_CHOICES } from "@/constants/advancedSettings";
import axios from "axios";
import permissionsMixin from "@/mixins/permissionsMixin";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "PhotoFileForm",
  props: {
    selectedItem: Object,
    formDefinition: Object,
    selectedControlType: String,
  },
  components: {
    ComparisonConditionForm,
    CreateComparisonConditionForm,
    AdvancedSettingsForm,
    AdvancedVisibilitySettingsDialog,
  },
  computed: {
    dependantOptions() {
      const formDefinition = { ...this.formDefinition };
      const sectionItems = formDefinition.form.sections.map((s) => s.items);
      return sectionItems
        .flat()
        .filter(({ id, type: itemType }) => {
          return id !== this.selectedItem.id && itemType !== "INFORMATION";
        })
        .map(({ id, number, question: { label } = {} }) => ({
          value: id,
          label: `${number} ${label}`,
        }));
    },
    requiredComparisonOptions() {
      const formDefinition = { ...this.formDefinition };
      const items = formDefinition.form.sections.map((s) => s.items).flat();
      const dependantItem = items.find(
        ({ id }) =>
          id === this.selectedItemOptions.question.required.dependantId
      );
      if (
        dependantItem &&
        dependantItem.question &&
        dependantItem.question.type === "NUMBER"
      ) {
        return comparisonTypes;
      }
      return [
        {
          value: "NOT_EQUAL",
          label: "Not Equal",
        },
        {
          value: "EQUAL",
          label: "Equal",
        },
      ];
    },
    visibleComparisonOptions() {
      const formDefinition = { ...this.formDefinition };
      const items = formDefinition.form.sections.map((s) => s.items).flat();
      const dependantItem = items.find(
        ({ id }) => id === this.selectedItemOptions.question.visible.dependantId
      );
      if (
        dependantItem &&
        dependantItem.question &&
        dependantItem.question.type === "NUMBER"
      ) {
        return comparisonTypes;
      }
      return [
        {
          value: "NOT_EQUAL",
          label: "Not Equal",
        },
        {
          value: "EQUAL",
          label: "Equal",
        },
      ];
    },
    visibleSetting() {
      if (
        this.selectedItemOptions?.question?.visible?.applyVisible === "ALWAYS"
      ) {
        return "";
      }
      return this.selectedItemOptions?.question?.visible?.applyVisible;
    },
    hasAdvancedSettings() {
      const { hasVisibleSettings } = this;
      return hasVisibleSettings;
    },
    hasVisibleSettings() {
      return (
        this.selectedItemOptions.question.visible?.applyVisible !==
        CONDITION_CHOICES.ALWAYS
      );
    },
  },
  mixins: [permissionsMixin],
  data() {
    return {
      selectedItemOptions: {
        question: {},
      },
      requiredOptions,
      visibilityOptions,
      comparisonTypes,
      requiredCondition: "ALWAYS",
      createCondition: "ALWAYS",
      createOptions,
      showAdvancedVisibilitySettingsDialog: false,
      mdiPencil,
      layer: {},
    };
  },
  watch: {
    requiredCondition(val) {
      if (["NEVER", "ALWAYS"].includes(val)) {
        this.selectedItemOptions.question.required.condition = val;
      }
    },
    createCondition(val) {
      if (["ALWAYS"].includes(val)) {
        this.selectedItemOptions.question.create.condition = val;
        this.selectedItemOptions.question.create.dependent = undefined;
        this.selectedItemOptions.question.create.dependentFieldId = undefined;
        this.selectedItemOptions.question.create.value = undefined;
      }
    },
  },
  methods: {
    async getLayer() {
      const { map_service_id: mapServiceId } = this.formDefinition;
      const {
        data: { results },
      } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
      this.layer = results;
    },
    async onSubmit() {
      const success = await this.$refs.photoFileForm.validate();
      if (!success) {
        return;
      }
      this.$emit("input", this.selectedItemOptions);
    },
    getSelectedItemOptions() {
      this.selectedItemOptions = cloneDeep(this.selectedItem);
      if (
        this.selectedItemOptions?.question?.required &&
        !["NEVER", "ALWAYS"].includes(
          this.selectedItemOptions?.question?.required?.condition
        )
      ) {
        this.requiredCondition = "conditional";
      } else {
        this.requiredCondition =
          this.selectedItemOptions?.question?.required?.condition;
      }

      if (
        this.selectedItemOptions?.question?.create &&
        !["ALWAYS"].includes(
          this.selectedItemOptions?.question?.create?.condition
        )
      ) {
        this.createCondition = "conditional";
      } else {
        this.createCondition =
          this.selectedItemOptions.question.visible.condition;
      }

      if (!this.selectedItemOptions.question.default) {
        this.selectedItemOptions.question.default = {};
      }
    },
  },
  beforeMount() {
    this.getSelectedItemOptions();
    this.getLayer();
  },
  async mounted() {
    if (!this.selectedItemOptions.question.label) {
      await this.$nextTick();
      await sleep(500);
      this.$refs.labelInput.$refs.input.focus();
    }
  },
};
</script>
