<template>
  <v-dialog
    :value="showInsertMultipleImagesDialog"
    max-width="400px"
    persistent
  >
    <validation-observer
      slim
      ref="imageMultiImageForm"
      v-slot="{ valid: isFormValid }"
    >
      <form @submit.prevent="submit">
        <v-card>
          <v-toolbar dark color="primary" class="elevation-0">
            <v-toolbar-title> Insert Multiple Images </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="$emit('close-insert-images-dialog')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text class="py-3">
            <div class="py-1 my-0 body-1">Insert image as</div>
            <validation-provider
              v-slot="{ errors, valid }"
              name="Input URL"
              rules="required"
            >
              <v-radio-group
                v-model="insertImageAs"
                class="py-1 my-0"
                :error-messages="errors"
                :success="valid"
              >
                <v-radio
                  label="Photo Group"
                  :value="INSERT_IMAGE.PHOTO_GROUP"
                ></v-radio>

                <ImageSizeFields
                  class="my-2"
                  v-if="insertImageAs === INSERT_IMAGE.PHOTO_GROUP"
                  v-model="imageStyles"
                />

                <v-radio
                  label="Photo Page - 1 per row"
                  :value="INSERT_IMAGE.PHOTO_PAGE_1_PER_ROW"
                ></v-radio>

                <v-radio
                  label="Photo Page - 2 per row"
                  :value="INSERT_IMAGE.PHOTO_PAGE_2_PER_ROW"
                ></v-radio>
              </v-radio-group>
            </validation-provider>
          </v-card-text>

          <v-card-actions class="d-flex justify-end">
            <v-btn
              type="submit"
              color="primary"
              :disabled="!isFormValid"
              :dark="isFormValid"
            >
              Insert
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </validation-observer>
  </v-dialog>
</template>

<script>
import INSERT_IMAGE from "@/constants/images";
import ImageSizeFields from "@/components/shared/template-editor/insert-multiple-images-dialog/ImageSizeFields";

export default {
  name: "InsertMultipleImagesDialog",
  components: { ImageSizeFields },
  data() {
    return {
      INSERT_IMAGE,
      insertImageAs: undefined,
      imageStyles: {
        imageWidth: 0,
        imageHeight: 0,
        imagePadding: 0,
      },
    };
  },
  props: {
    showInsertMultipleImagesDialog: Boolean,
  },
  methods: {
    async submit() {
      const success = await this.$refs.imageMultiImageForm.validate();
      if (!success) {
        return;
      }
      const { insertImageAs } = this;
      const { imageWidth, imageHeight, imagePadding } = this.imageStyles;
      this.$emit("insert-multiple-image", {
        insertImageAs,
        imageWidth,
        imageHeight,
        imagePadding,
      });
    },
  },
};
</script>
