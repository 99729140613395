<template>
  <validation-observer ref="tableForm" slim v-slot="{ valid: isFormValid }">
    <form @submit.prevent="insertTable">
      <v-card>
        <v-card-text class="py-3 px-4">
          <validation-provider
            v-slot="{ errors, valid }"
            name="Number of Rows"
            :rules="{ min_value: 0, max_value: 100, required: true }"
          >
            <v-text-field
              v-model="numRow"
              label="Number of Rows"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="name"
              type="number"
              class="pt-1"
              hint="Max value 100"
              min="0"
              max="100"
              persistent-hint
              @keydown="onNumberKeyDown"
              @change="checkRowVal()"
              @blur.stop
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors, valid }"
            name="Number of Columns"
            :rules="{ min_value: 0, max_value: 20, required: true }"
          >
            <v-text-field
              v-model="numCol"
              label="Number of Columns"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="name"
              type="number"
              hint="Max value 20"
              min="0"
              max="20"
              persistent-hint
              @keydown="onNumberKeyDown"
              @change="checkColVal()"
              @blur.stop
            />
          </validation-provider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="$emit('cancel')"> Cancel </v-btn>
          <v-btn
            color="primary"
            type="submit"
            :disabled="!isFormValid"
            :dark="isFormValid"
          >
            Insert
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </validation-observer>
</template>

<script>
export default {
  name: "TableMenu",
  data() {
    return {
      numRow: 0,
      numCol: 0,
    };
  },
  methods: {
    checkRowVal() {
      if (this.numRow > 100) {
        this.numRow = 100;
      } else if (this.numRow < 0) {
        this.numRow = 0;
      }
    },
    checkColVal() {
      if (this.numCol > 20) {
        this.numCol = 20;
      } else if (this.numCol < 0) {
        this.numCol = 0;
      }
    },
    onNumberKeyDown(e) {
      if (["e", "+", "-", "*", "/"].includes(e.key.toLowerCase())) {
        e.preventDefault();
      }
    },
    async insertTable() {
      const success = await this.$refs.tableForm.validate();
      if (!success) {
        return;
      }
      const { numRow, numCol } = this;
      this.$emit("insert-table", { numRow, numCol });
    },
  },
};
</script>
