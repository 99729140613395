var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return Array.isArray(_vm.formDefinition.form.relatedTable.fields)
    ? _c("v-simple-table", {
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function () {
                return [
                  _c("thead", [
                    _c("tr", [
                      _c("th", { staticClass: "text-left" }, [
                        _vm._v("ArcGIS Feature Service Field"),
                      ]),
                      _c("th", { staticClass: "text-left" }, [
                        _vm._v("UtiliSync Form Field"),
                      ]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.fields, function (field) {
                      return _c("tr", { key: field.name }, [
                        _c("td", [_vm._v(_vm._s(field.alias))]),
                        !_vm.noTokenAndNotNoneTokenType
                          ? _c(
                              "td",
                              [
                                _c("validation-provider", {
                                  attrs: { name: "UtiliSync Form Field" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors, valid }) {
                                          return [
                                            _c("v-select", {
                                              attrs: {
                                                label: "UtiliSync Form Field",
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                success: valid,
                                                color: "primary",
                                                name: "utiliSyncFormField",
                                                items:
                                                  _vm.filterQuestionChoices(
                                                    field
                                                  ),
                                                "item-text": "label",
                                                "item-value": "value",
                                                disabled: !_vm.canManageForms,
                                              },
                                              on: { change: _vm.onChange },
                                              model: {
                                                value: field.selectedQuestionId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    field,
                                                    "selectedQuestionId",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "field.selectedQuestionId",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm.utiliSyncFieldName(field.selectedQuestionId)
                          ? _c("td", { staticClass: "font-weight-bold" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.utiliSyncFieldName(
                                      field.selectedQuestionId
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          : _c("td", { staticClass: "font-weight-bold" }, [
                              _c("i", [_vm._v("None")]),
                            ]),
                      ])
                    }),
                    0
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          636782690
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }