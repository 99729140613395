<template>
  <div class="d-flex justify-space-between">
    <validation-observer ref="informationForm">
      <form>
        <v-list class="px-0 py-0 my-0" dense>
          <v-list-item
            class="px-0 pt-0"
            v-if="
              ['HEADER'].includes(getType(selectedRepeatingGroupItemOptions))
            "
          >
            <v-list-item-content class="mt-0 pt-0">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Label"
                rules="required"
              >
                <v-text-field
                  v-model="selectedRepeatingGroupItemOptions.information.label"
                  label="Label"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="Label"
                  @input="onSubmit()"
                  :disabled="!canManageForms"
                />
              </validation-provider>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="px-0 pt-0 mt-0"
            v-if="['TEXT'].includes(getType(selectedRepeatingGroupItemOptions))"
          >
            <v-list-item-content class="mt-0 pt-0">
              <v-card class="elevation-0 d-flex justify-space-between">
                <div>
                  <p
                    class="caption"
                    :style="
                      canManageForms ? 'color: #4caf50' : 'color: #aaaaaa'
                    "
                  >
                    Text
                  </p>
                  <p
                    v-html="selectedRepeatingGroupItemOptions.information.label"
                    class="pt-0"
                  ></p>
                </div>
                <v-icon
                  v-if="canManageForms"
                  color="primary"
                  class="mt-n2"
                  @click="showTextDialog = true"
                >
                  {{ mdiPencil }}
                </v-icon>

                <v-dialog
                  v-model="showTextDialog"
                  max-width="600px"
                  :retain-focus="false"
                  :fullscreen="$vuetify.breakpoint.xsOnly"
                >
                  <TextForm
                    @text-form-close="showTextDialog = false"
                    @input="
                      showTextDialog = false;
                      $emit('input', selectedRepeatingGroupItemOptions);
                    "
                    v-model="
                      selectedRepeatingGroupItemOptions.information.label
                    "
                  />
                </v-dialog>
              </v-card>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="px-0 pt-0 mt-0"
            v-if="getType(selectedRepeatingGroupItemOptions) === 'URL'"
          >
            <v-list-item-content class="mt-0 pt-0">
              <validation-provider
                v-slot="{ errors, valid }"
                name="URL"
                rules="required"
              >
                <v-text-field
                  v-model="selectedRepeatingGroupItemOptions.information.url"
                  label="URL"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="name"
                  hint="Input a valid URL"
                  @change="onSubmit()"
                  :disabled="!canManageForms"
                />
              </validation-provider>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="px-0 pt-0 mt-0"
            v-if="getType(selectedRepeatingGroupItemOptions) === 'URL'"
          >
            <v-list-item-content class="mt-0 pt-0">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Label"
                rules="required"
              >
                <v-text-field
                  v-model="selectedRepeatingGroupItemOptions.information.label"
                  label="Label"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="name"
                  hint="Input a valid URL"
                  @change="onSubmit()"
                  :disabled="!canManageForms"
                />
              </validation-provider>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="px-0 pt-0 mt-0"
            v-if="getType(selectedRepeatingGroupItemOptions) === 'IMAGE'"
          >
            <v-list-item-content class="mt-0 pt-0">
              <section class="d-flex align-center">
                <v-switch
                  @change="onSubmit()"
                  v-model="
                    selectedRepeatingGroupItemOptions.information.dynamic
                  "
                  label="Dynamic"
                  :disabled="disableDynamicSwitch || !canManageForms"
                ></v-switch>

                <v-tooltip bottom v-if="disableDynamicSwitch">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>
                        {{ mdiInformation }}
                      </v-icon>
                    </v-btn>
                  </template>

                  <span v-if="!showImageInputs">
                    Remove default advanced setting or change default value
                    setting to Custom Value to enable.
                  </span>
                  <span v-else>
                    Dynamic info images work only with Custom Value repeating
                    groups
                  </span>
                </v-tooltip>
              </section>

              <validation-provider
                v-slot="{ errors, valid }"
                name="Label"
                v-if="showImageInputs"
              >
                <v-text-field
                  v-model="selectedRepeatingGroupItemOptions.information.label"
                  label="Label"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="name"
                  @change="onSubmit()"
                  :disabled="!canManageForms"
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors, valid }"
                name="Image URL"
                rules="required"
                v-if="showImageInputs"
              >
                <v-text-field
                  v-model="selectedRepeatingGroupItemOptions.information.url"
                  label="Image URL"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="name"
                  hint="Input a valid URL"
                  @change="onSubmit()"
                  :disabled="!canManageForms"
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors, valid }"
                name="Open URL"
                v-if="showImageInputs"
              >
                <v-text-field
                  v-model="
                    selectedRepeatingGroupItemOptions.information.openUrl
                  "
                  label="Open URL"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="name"
                  hint="Input a valid URL"
                  @change="onSubmit()"
                  :disabled="!canManageForms"
                />
              </validation-provider>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="px-0 pt-0 mt-0">
            <v-list-item-content class="mt-0 pt-0">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Visible"
                rules="required"
              >
                <v-select
                  v-model="visibleCondition"
                  :items="visibilityOptions"
                  label="Visible"
                  :error-messages="errors"
                  :success="valid"
                  item-text="label"
                  item-value="value"
                  @change="onSubmit()"
                  hide-details
                  :disabled="!canManageForms"
                ></v-select>
              </validation-provider>

              <ComparisonConditionForm
                v-if="['conditional'].includes(visibleCondition)"
                v-model="selectedRepeatingGroupItemOptions.information.visible"
                :formDefinition="formDefinition"
                :selectedItem="selectedRepeatingGroupItemOptions"
                @input="onSubmit()"
              />
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="px-0 pt-0 mt-0">
            <v-list-item-content class="mt-0 pt-0">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Create"
                rules="required"
              >
                <v-select
                  v-model="createCondition"
                  :items="createOptions"
                  label="Create"
                  :error-messages="errors"
                  :success="valid"
                  item-text="label"
                  item-value="value"
                  @change="onSubmit()"
                  hide-details
                  :disabled="!canManageForms"
                >
                </v-select>
              </validation-provider>

              <CreateComparisonConditionForm
                v-if="['conditional'].includes(createCondition)"
                v-model="selectedRepeatingGroupItemOptions.information.create"
                :formDefinition="formDefinition"
                :selectedItem="selectedRepeatingGroupItemOptions"
                @input="onSubmit()"
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import {
  visibilityOptions,
  comparisonTypes,
  createOptions,
} from "@/constants/choices";
import { cloneDeep } from "lodash";
import TextForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/information-form/TextForm";
import {
  mdiPencil,
  mdiDotsVertical,
  mdiDragVertical,
  mdiDelete,
  mdiInformation,
} from "@mdi/js";
import ComparisonConditionForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/ComparisonConditionForm";
import CreateComparisonConditionForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/CreateComparisonConditionForm";
import { DEPENDANT_OPTIONS } from "@/constants/advancedSettings";
import permissionsMixin from "@/mixins/permissionsMixin";

export default {
  name: "InformationForm",
  mixins: [permissionsMixin],
  components: {
    TextForm,
    ComparisonConditionForm,
    CreateComparisonConditionForm,
  },
  props: {
    value: Object,
    formDefinition: Object,
    layer: Object,
    gisDataFields: Array,
    userDataFields: Array,
    fields: Array,
    selectedItemOptions: Object,
  },
  computed: {
    showImageInputs() {
      return !(
        !this.disableDynamicSwitch &&
        this.selectedRepeatingGroupItemOptions.information.dynamic
      );
    },
    disableDynamicSwitch() {
      return [
        DEPENDANT_OPTIONS.USER,
        DEPENDANT_OPTIONS.GIS_DATA,
        DEPENDANT_OPTIONS.GIS,
      ].includes(this.selectedItemOptions.question?.default?.type);
    },
    selectedVisibleDependantQuestion() {
      const { dependantId } =
        this.selectedRepeatingGroupItemOptions.information.visible;
      const formDefinition = { ...this.formDefinition };
      const sectionItems = formDefinition.form.sections
        .map((s) => s.items)
        .flat();
      return sectionItems.find(({ id }) => id === dependantId);
    },
    dependantOptions() {
      const formDefinition = { ...this.formDefinition };
      const sectionItems = formDefinition.form.sections.map((s) => s.items);
      return sectionItems
        .flat()
        .filter(({ id, type: itemType }) => {
          return id !== this.selectedItem.id && itemType !== "INFORMATION";
        })
        .map(({ id, number, question: { label } = {} }) => ({
          value: id,
          label: `${number} ${label}`,
        }));
    },
    visibleComparisonOptions() {
      const formDefinition = { ...this.formDefinition };
      const items = formDefinition.form.sections.map((s) => s.items).flat();
      const dependantItem = items.find(
        ({ id }) =>
          id ===
          this.selectedRepeatingGroupItemOptions.information.visible.dependantId
      );
      if (dependantItem?.information?.type === "NUMBER") {
        return comparisonTypes;
      }
      return [
        {
          value: "NOT_EQUAL",
          label: "Not Equal",
        },
        {
          value: "EQUAL",
          label: "Equal",
        },
      ];
    },
  },
  data() {
    return {
      selectedRepeatingGroupItemOptions: {
        information: {},
      },
      visibilityOptions,
      showTextDialog: false,
      mdiPencil,
      requiredCondition: "ALWAYS",
      visibleCondition: "ALWAYS",
      createCondition: "ALWAYS",
      createOptions,
      mdiDragVertical,
      mdiDotsVertical,
      mdiDelete,
      mdiInformation,
    };
  },
  methods: {
    getType(selectedRepeatingGroupItemOptions) {
      return selectedRepeatingGroupItemOptions?.information?.type;
    },
    async onSubmit() {
      await this.$nextTick();
      const success = await this.$refs?.informationForm?.validate();

      if (!success) {
        return;
      }
      this.$emit("input", cloneDeep(this.selectedRepeatingGroupItemOptions));
    },
    getSelectedItemOptions() {
      this.selectedRepeatingGroupItemOptions = cloneDeep(this.value);
      if (!this.selectedRepeatingGroupItemOptions?.information?.create) {
        this.selectedRepeatingGroupItemOptions.information.create = {
          condition: "ALWAYS",
        };
      }

      if (!this.selectedRepeatingGroupItemOptions?.question?.visible) {
        this.visibleCondition = "ALWAYS";
      }

      if (
        !["NEVER", "ALWAYS"].includes(
          this.selectedRepeatingGroupItemOptions.information?.visible?.condition
        )
      ) {
        this.visibleCondition = "conditional";
      } else {
        this.visibleCondition =
          this.selectedRepeatingGroupItemOptions?.information?.visible?.condition;
      }

      if (
        this.selectedRepeatingGroupItemOptions?.information?.create &&
        !["ALWAYS"].includes(
          this.selectedRepeatingGroupItemOptions?.information?.create?.condition
        )
      ) {
        this.createCondition = "conditional";
      } else {
        this.createCondition =
          this.selectedRepeatingGroupItemOptions?.information?.visible?.condition;
      }

      if (!this.selectedRepeatingGroupItemOptions?.information?.default) {
        this.selectedRepeatingGroupItemOptions.information.default = {};
      }
    },
  },
  watch: {
    "selectedRepeatingGroupItemOptions.information.dynamic"() {
      this.selectedRepeatingGroupItemOptions.information.label = "";
      this.selectedRepeatingGroupItemOptions.information.url = "";
      this.selectedRepeatingGroupItemOptions.information.openUrl = "";
      this.visibleCondition = "ALWAYS";
      this.createCondition = "ALWAYS";
    },
    visibleCondition(val) {
      if (["NEVER", "ALWAYS"].includes(val)) {
        this.selectedRepeatingGroupItemOptions.information.visible.condition =
          val;
        this.selectedRepeatingGroupItemOptions.information.visible.dependantId =
          undefined;
        this.selectedRepeatingGroupItemOptions.information.visible.value =
          undefined;
      }
    },
    createCondition(val) {
      if (["ALWAYS"].includes(val)) {
        this.selectedRepeatingGroupItemOptions.information.create.condition =
          val;
        this.selectedRepeatingGroupItemOptions.information.create.dependent =
          undefined;
        this.selectedRepeatingGroupItemOptions.information.create.dependentFieldId =
          undefined;
        this.selectedRepeatingGroupItemOptions.information.create.value =
          undefined;
      }
    },
  },
  beforeMount() {
    this.getSelectedItemOptions();
  },
};
</script>
