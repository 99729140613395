var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-toolbar",
        { attrs: { dark: "", color: "primary" } },
        [
          _c("v-toolbar-title", [_vm._v("Edit Text")]),
          _c("v-spacer"),
          _c(
            "v-btn",
            { attrs: { icon: "", dark: "" }, on: { click: _vm.closeForm } },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "pt-3" },
        [
          _c("validation-observer", { ref: "textForm" }, [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.onSubmit.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("validation-provider", {
                          attrs: { name: "Label", rules: "required" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors, valid }) {
                                return [
                                  _vm.showEditor
                                    ? _c("QuillEditor", {
                                        attrs: {
                                          valid: valid,
                                          errors: errors,
                                          disabled: _vm.readOnly,
                                        },
                                        model: {
                                          value: _vm.text,
                                          callback: function ($$v) {
                                            _vm.text = $$v
                                          },
                                          expression: "text",
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "d-flex justify-end",
                        attrs: { cols: "12" },
                      },
                      [
                        _c(
                          "v-btn",
                          { attrs: { color: "primary", type: "submit" } },
                          [_vm._v("Save Text")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }