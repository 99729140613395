var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "elevation-2" },
    [
      _c(
        "v-card-text",
        { staticClass: "px-6 pt-0" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("validation-provider", {
                    attrs: { name: "Observations" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors, valid }) {
                          return [
                            _c("v-text-field", {
                              attrs: {
                                label: "Observations",
                                "hide-details": "auto",
                                "error-messages": errors,
                                success: valid,
                                color: "primary",
                                name: "value",
                                disabled: !_vm.canManageForms,
                              },
                              on: { input: _vm.onInput },
                              model: {
                                value:
                                  _vm.formDefinition.form
                                    .city_works_webhook_config
                                    .on_form_submission.inspection_comments
                                    .ObservationSum,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formDefinition.form
                                      .city_works_webhook_config
                                      .on_form_submission.inspection_comments,
                                    "ObservationSum",
                                    $$v
                                  )
                                },
                                expression:
                                  "\n              formDefinition.form.city_works_webhook_config.on_form_submission\n                .inspection_comments.ObservationSum\n            ",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("validation-provider", {
                    attrs: { name: "Repairs" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors, valid }) {
                          return [
                            _c("v-text-field", {
                              attrs: {
                                label: "Repairs",
                                "hide-details": "auto",
                                "error-messages": errors,
                                success: valid,
                                color: "primary",
                                name: "value",
                                disabled: !_vm.canManageForms,
                              },
                              on: { input: _vm.onInput },
                              model: {
                                value:
                                  _vm.formDefinition.form
                                    .city_works_webhook_config
                                    .on_form_submission.inspection_comments
                                    .RepairsMade,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formDefinition.form
                                      .city_works_webhook_config
                                      .on_form_submission.inspection_comments,
                                    "RepairsMade",
                                    $$v
                                  )
                                },
                                expression:
                                  "\n              formDefinition.form.city_works_webhook_config.on_form_submission\n                .inspection_comments.RepairsMade\n            ",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.activityType === "workorder"
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("validation-provider", {
                        attrs: { name: "Work Order Comments" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ errors, valid }) {
                                return [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Comments",
                                      "hide-details": "auto",
                                      "error-messages": errors,
                                      success: valid,
                                      color: "primary",
                                      name: "value",
                                      disabled: !_vm.canManageForms,
                                    },
                                    on: { input: _vm.onInput },
                                    model: {
                                      value:
                                        _vm.formDefinition.form
                                          .city_works_webhook_config
                                          .on_form_submission
                                          .work_order_comment,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formDefinition.form
                                            .city_works_webhook_config
                                            .on_form_submission,
                                          "work_order_comment",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "\n              formDefinition.form.city_works_webhook_config.on_form_submission\n                .work_order_comment\n            ",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          449656777
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("validation-provider", {
                        attrs: { name: "Work Order Instructions" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ errors, valid }) {
                                return [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Instructions",
                                      "hide-details": "auto",
                                      "error-messages": errors,
                                      success: valid,
                                      color: "primary",
                                      name: "value",
                                      disabled: !_vm.canManageForms,
                                    },
                                    on: { input: _vm.onInput },
                                    model: {
                                      value:
                                        _vm.formDefinition.form
                                          .city_works_webhook_config
                                          .on_form_submission
                                          .work_order_instructions,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formDefinition.form
                                            .city_works_webhook_config
                                            .on_form_submission,
                                          "work_order_instructions",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "\n              formDefinition.form.city_works_webhook_config.on_form_submission\n                .work_order_instructions\n            ",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1399272058
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.activityType === "Inspection"
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("validation-provider", {
                        attrs: { name: "Recommendation" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "Recommendation",
                                    "hide-details": "auto",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "value",
                                    disabled: !_vm.canManageForms,
                                  },
                                  on: { input: _vm.onInput },
                                  model: {
                                    value:
                                      _vm.formDefinition.form
                                        .city_works_webhook_config
                                        .on_form_submission.inspection_comments
                                        .ForemanRecomnd,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formDefinition.form
                                          .city_works_webhook_config
                                          .on_form_submission
                                          .inspection_comments,
                                        "ForemanRecomnd",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "\n              formDefinition.form.city_works_webhook_config.on_form_submission\n                .inspection_comments.ForemanRecomnd\n            ",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("validation-provider", {
                    attrs: { name: "Condition Rating" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors, valid }) {
                          return [
                            _c("v-text-field", {
                              attrs: {
                                label: "Condition Rating",
                                "hide-details": "auto",
                                "error-messages": errors,
                                success: valid,
                                color: "primary",
                                name: "value",
                                disabled: !_vm.canManageForms,
                              },
                              on: { input: _vm.onInput },
                              model: {
                                value:
                                  _vm.formDefinition.form
                                    .city_works_webhook_config
                                    .on_form_submission.inspection_comments
                                    .CondRating,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formDefinition.form
                                      .city_works_webhook_config
                                      .on_form_submission.inspection_comments,
                                    "CondRating",
                                    $$v
                                  )
                                },
                                expression:
                                  "\n              formDefinition.form.city_works_webhook_config.on_form_submission\n                .inspection_comments.CondRating\n            ",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }