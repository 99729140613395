<template>
  <section :class="{ selected }" class="w-100">
    <p class="caption">
      {{ `${item.number} ${item.question.label}` }}
    </p>

    <validation-observer ref="positiveResponseInputForm">
      <v-card v-for="p of positiveResponseValues" :key="p.label">
        <v-card-text>
          <h2
            v-if="
              [
                ARKANSAS_LOCATE_REQUEST_PROVIDER_ID,
                DIGGERS_HOTLINE_LOCATE_REQUEST_PROVIDER_ID,
              ].includes(item.question.locateRequestProviderId)
            "
          >
            Positive Response
          </h2>
          <h2 v-else>
            {{ p.positive_response_label }}
          </h2>
          <validation-provider
            v-slot="{ errors, valid }"
            name="Response"
            rules="required"
          >
            <v-radio-group
              v-if="showAllOptions"
              v-model="p.response"
              :disabled="readOnly"
              @input="onInputChange()"
              @change="onInputChange()"
              :error-messages="errors"
              :success="valid"
            >
              <v-radio
                v-for="option of positiveResponseChoices"
                :key="option.value"
                :value="option.value"
                :label="option.label"
                :readonly="readOnly"
              >
              </v-radio>
            </v-radio-group>

            <v-select
              v-else
              label="Response"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="positiveResponse"
              :items="positiveResponseChoices"
              item-text="label"
              item-value="value"
              v-model="p.response"
              :readonly="readOnly"
              @input="onInputChange()"
            />
          </validation-provider>

          <section v-if="positiveResponseCommentChoices.length > 0">
            <validation-provider
              v-slot="{ errors, valid }"
              name="Comments"
              rules="required"
            >
              <v-radio-group
                v-if="item.question.showAllComments"
                v-model="p.comment"
                :disabled="readOnly"
                @input="onInputChange()"
                @change="onInputChange()"
                :error-messages="errors"
                :success="valid"
              >
                <template #label>
                  <div class="caption">Comment</div>
                </template>

                <v-radio
                  v-for="c of positiveResponseCommentChoices"
                  :key="c.id"
                  :value="c.text"
                  :label="c.text"
                  :readonly="readOnly"
                >
                </v-radio>
              </v-radio-group>
              <v-select
                v-else
                label="Comments"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="comments"
                :items="positiveResponseCommentChoices"
                item-text="text"
                item-value="text"
                v-model="p.comment"
                :readonly="readOnly"
                @input="onInputChange()"
              />
            </validation-provider>
          </section>
          <validation-provider
            v-else
            v-slot="{ errors, valid }"
            name="Comments"
            :rules="{ required: isCommentRequired(p) }"
          >
            <v-text-field
              v-model="p.comment"
              label="Comments"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="name"
              ref="labelInput"
              :readonly="readOnly"
              @input="onInputChange()"
              persistent-hint
              hint="Notes entered here will be returned to the exacavator in the positive response. Max 255 characters"
            />
          </validation-provider>
        </v-card-text>
      </v-card>
    </validation-observer>
  </section>
</template>

<script>
import dependantValueMixin from "@/mixins/dependantValueMixin";
import { DEPENDANT_OPTIONS } from "@/constants/advancedSettings";
import { cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";

const ARKANSAS_LOCATE_REQUEST_PROVIDER_ID =
  "2612014f-8a32-44ce-8885-d8d0e8e6b89f";
const DIGGERS_HOTLINE_LOCATE_REQUEST_PROVIDER_ID =
  "e9e19ee3-d880-4cdf-8d66-3e4be97a81cb";
const GEORGIA_LOCATE_REQUEST_PROVIDER_ID =
  "04b30d2e-5542-4c03-9acb-1fe20bc9997f";

const getFormDefinitionValue = (formDefinition, fieldId) => {
  const sectionItems = formDefinition.form.sections
    ?.map((s) => s.items)
    ?.flat();
  const item = sectionItems.find(({ id }) => id === fieldId);
  return item?.value;
};

const checkFormValue = (visible, fieldValue) => {
  const { value, condition } = visible;
  if (!isNaN(fieldValue)) {
    if (condition === "EQUAL") {
      return +fieldValue === +value;
    } else if (condition === "GREATER_THAN") {
      return +fieldValue > +value;
    } else if (condition === "GREATER_THAN_OR_EQUAL") {
      return +fieldValue >= +value;
    } else if (condition === "LESS_THAN") {
      return +fieldValue < +value;
    } else if (condition === "LESS_THAN_OR_EQUAL") {
      return +fieldValue <= +value;
    } else if (condition === "NOT_EQUAL") {
      return +fieldValue != +value;
    }
  } else {
    if (condition === "EQUAL") {
      return fieldValue === value;
    } else if (condition === "GREATER_THAN") {
      return fieldValue > value;
    } else if (condition === "GREATER_THAN_OR_EQUAL") {
      return fieldValue >= value;
    } else if (condition === "LESS_THAN") {
      return fieldValue < value;
    } else if (condition === "LESS_THAN_OR_EQUAL") {
      return fieldValue <= value;
    } else if (condition === "NOT_EQUAL") {
      return fieldValue !== value;
    }
  }
};

const COLORADO_LOCATE_REQUEST_PROVIDER_ID =
  "25d9828c-5487-4f41-a1ac-7b3e5d179a90";
const COLORADO_POSITIVE_RESPONSES_REQUIRING_COMMENT = [
  "008",
  "021",
  "022",
  "023",
];

export default {
  name: "PositiveResponseInput",
  props: {
    item: Object,
    formDefinition: Object,
    value: Array,
    selected: Boolean,
    readOnly: Boolean,
  },
  mixins: [dependantValueMixin],
  data() {
    return {
      selectedValue: "",
      positiveResponseValues: [],
      ARKANSAS_LOCATE_REQUEST_PROVIDER_ID,
      DIGGERS_HOTLINE_LOCATE_REQUEST_PROVIDER_ID,
    };
  },
  computed: {
    positiveResponseCommentChoices() {
      if (!Array.isArray(this.item.question?.comments)) {
        return [];
      }
      const choices = this.item.question?.comments?.map?.((c) => {
        return {
          id: uuidv4(),
          text: c,
        };
      });
      const otherChoice = {
        id: uuidv4(),
        text: "Other",
      };
      return [...choices, otherChoice];
    },
    showAllOptions() {
      return this.item.question?.showAllOptions;
    },
    positiveResponses() {
      return this.item.question.positiveResponses.filter((r) => r.visible);
    },
    positiveResponseChoices() {
      if (!Array.isArray(this.item.question.options)) {
        return [];
      }
      return this.item.question.options
        .filter((o) => {
          const { visible } = o;
          const { type, field } = visible;
          if (type === DEPENDANT_OPTIONS.FORM_ITEM) {
            const fieldValue = getFormDefinitionValue(
              this.formDefinition,
              field
            );
            return checkFormValue(visible, fieldValue);
          }
          return true;
        })
        .map((o) => {
          const { label, value } = o;
          return { label, value };
        });
    },
  },
  methods: {
    isCommentRequired(positiveResponseValue) {
      return (
        this.item.question?.locateRequestProviderId ===
          COLORADO_LOCATE_REQUEST_PROVIDER_ID &&
        COLORADO_POSITIVE_RESPONSES_REQUIRING_COMMENT.includes(
          positiveResponseValue.response
        )
      );
    },
    onInputChange() {
      this.$emit("input", cloneDeep(this.positiveResponseValues));
    },
  },
  watch: {
    positiveResponses: {
      immediate: true,
      deep: true,
      handler(val) {
        const { value } = this;
        if (Array.isArray(val)) {
          if (val.length > 0 && (!Array.isArray(value) || value.length === 0)) {
            this.positiveResponseValues = [
              ...val.map((v) => {
                const { label, showCode } = v;
                return {
                  response: "",
                  comment: "",
                  positive_response_label: label || showCode,
                };
              }),
            ];
            if (
              [
                ARKANSAS_LOCATE_REQUEST_PROVIDER_ID,
                DIGGERS_HOTLINE_LOCATE_REQUEST_PROVIDER_ID,
                GEORGIA_LOCATE_REQUEST_PROVIDER_ID,
              ].includes(this.item.question?.locateRequestProviderId)
            ) {
              const positiveResponseLabels = this.positiveResponseValues.map(
                (v) => v.positive_response_label
              );
              this.positiveResponseValues = [
                {
                  response: "",
                  comment: "",
                  positive_response_label: positiveResponseLabels.join(","),
                },
              ];
            }
          } else {
            this.positiveResponseValues = [{ response: "", comment: "" }];
          }
        }
      },
    },
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        if (Array.isArray(val) && val.length > 0) {
          this.positiveResponseValues = cloneDeep(val);
        }
      },
    },
  },
};
</script>

<style scoped>
.w-100 {
  width: 100%;
}
</style>
