<template>
  <v-card>
    <v-card-text>
      <validation-observer>
        <v-row>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors, valid }"
              name="To"
              rules="required|email_or_params"
            >
              <v-combobox
                label="To *"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="to"
                :hint="`Email or {{params.x}}`"
                persistent-hint
                v-model="formDefinition.form.emailSettings[index].toEmails"
                chips
                multiple
                item-text="label"
                item-value="value"
                @input="onInput"
                append-icon=""
                :disabled="!canManageForms"
              >
                <template v-slot:selection="{ item }">
                  <v-chip
                    close
                    color="primary"
                    @click:close="onClickClose(index, 'toEmails', item)"
                    :disabled="!canManageForms"
                  >
                    <template>{{ getChipDisplayName(item) }}</template>
                  </v-chip>
                </template>
              </v-combobox>
            </validation-provider>
          </v-col>

          <v-col cols="12" md="6">
            <validation-provider
              v-slot="{ errors, valid }"
              name="CC"
              rules="email_or_params"
            >
              <v-combobox
                label="CC"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="cc"
                :hint="`Email or {{params.x}}`"
                persistent-hint
                v-model="formDefinition.form.emailSettings[index].cc"
                chips
                multiple
                item-text="label"
                item-value="value"
                @input="onInput"
                append-icon=""
                :disabled="!canManageForms"
              >
                <template v-slot:selection="{ selected, item }">
                  <v-chip
                    :input-value="selected"
                    close
                    color="primary"
                    @click:close="onClickClose(index, 'cc', item)"
                    :disabled="!canManageForms"
                  >
                    <template v-if="item.value">{{ item.value }}</template>
                    <template v-else>{{ item }}</template>
                  </v-chip>
                </template>
              </v-combobox>
            </validation-provider>
          </v-col>

          <v-col cols="12" md="6">
            <validation-provider
              v-slot="{ errors, valid }"
              name="BCC"
              rules="email_or_params"
            >
              <v-combobox
                label="BCC"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="bcc"
                :hint="`Email or {{params.x}}`"
                persistent-hint
                v-model="formDefinition.form.emailSettings[index].bcc"
                chips
                multiple
                item-text="label"
                item-value="value"
                @input="onInput"
                append-icon=""
                :disabled="!canManageForms"
              >
                <template v-slot:selection="{ selected, item }">
                  <v-chip
                    :input-value="selected"
                    close
                    color="primary"
                    @click:close="onClickClose(index, 'bcc', item)"
                    :disabled="!canManageForms"
                  >
                    <template v-if="item.value">{{ item.value }}</template>
                    <template v-else>{{ item }}</template>
                  </v-chip>
                </template>
              </v-combobox>
            </validation-provider>
          </v-col>

          <v-col cols="12" md="6">
            <validation-provider
              v-slot="{ errors, valid }"
              name="From Name"
              rules="required"
            >
              <v-text-field
                label="From Name"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="value"
                v-model="formDefinition.form.emailSettings[index].fromName"
                @input="onInput"
                hint="The name, not email, of who it is from"
                persistent-hint
                height="42px"
                :disabled="!canManageForms"
              >
              </v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="12" md="6" style="margin-top: 10px">
            <validation-provider
              v-slot="{ errors, valid }"
              name="Reply To"
              rules="single_email_or_params"
            >
              <v-combobox
                label="Reply to Email"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="replyTo"
                :hint="`Email or {{params.x}}`"
                persistent-hint
                v-model="formDefinition.form.emailSettings[index].replyTo"
                item-text="label"
                item-value="value"
                @input="onInput"
                :disabled="!canManageForms"
              >
                <template v-slot:selection="{ selected, item }">
                  <v-chip
                    :input-value="selected"
                    close
                    color="primary"
                    @click:close="
                      formDefinition.form.emailSettings[index].replyTo =
                        undefined
                    "
                    :disabled="!canManageForms"
                  >
                    <template v-if="item.value">{{ item.value }}</template>
                    <template v-else>{{ item }}</template>
                  </v-chip>
                </template>
              </v-combobox>
            </validation-provider>
          </v-col>
        </v-row>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>

<script>
import { cloneDeep } from "lodash";
import questionsMixin from "@/mixins/questionsMixin";
import permissionsMixin from "@/mixins/permissionsMixin";

export default {
  name: "EmailFromToForm",
  props: {
    value: Object,
    index: Number,
  },
  mixins: [questionsMixin, permissionsMixin],
  data() {
    return {
      formDefinition: {},
    };
  },
  computed: {
    emailQuestions() {
      const formDefinition = { ...this.formDefinition };
      const sectionItems = formDefinition.form.sections.map((s) => s.items);
      return sectionItems
        .flat()
        .filter(({ type: itemType, question: { type } = {} }) => {
          return itemType === "QUESTION" && type === "EMAIL";
        })
        .map(({ id, number, question: { label } = {} }) => {
          return {
            value: `{{params.${id}}}`,
            label: `${number} ${label}`,
          };
        });
    },
    questionChoices() {
      return this.questions.map(({ id, number, question: { label } = {} }) => ({
        value: id,
        label: `${number} ${label}`,
      }));
    },
  },
  methods: {
    onClickClose(emailSettingIndex, prop, item) {
      this.formDefinition.form.emailSettings[emailSettingIndex][prop] = [
        ...this.formDefinition.form.emailSettings[emailSettingIndex][
          prop
        ].filter((email) => email !== item),
      ];
      this.$emit("input", this.formDefinition);
    },
    onInput() {
      this.$emit("input", this.formDefinition);
    },
    getChipDisplayName(value) {
      if (typeof value !== "string") {
        return;
      }
      const [number] = value?.match?.(/\d+/) ?? [];
      const isParam = /^{{params.\d+}}$/.test(value);
      if (isParam) {
        const question = this.questionChoices.find((q) => +q.value === +number);
        const { label } = question;
        return label;
      }
      return value;
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.formDefinition = cloneDeep(val);
      },
    },
  },
};
</script>

<style scoped>
textarea {
  border: none;
  resize: none;
}

textarea:focus {
  outline: none;
}
</style>
