<template>
  <node-view-wrapper
    as="div"
    class="missing-image-param"
    :style="{
      width: `${node.attrs.maxWidth}px`,
      height: `${node.attrs.maxHeight}px`,
    }"
  >
    <node-view-wrapper
      as="img"
      :src="node.attrs.paramSrc"
      class="missing-image-param-image"
      :style="{
        'max-width': `${node.attrs.maxWidth}px`,
        'max-height': `${node.attrs.maxHeight}px`,
      }"
    />
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from "@tiptap/vue-2";

export default {
  components: {
    NodeViewWrapper,
  },
  props: nodeViewProps,
};
</script>

<style>
.missing-image-param {
  background: #e0e0e0;
  border-radius: 16px;
  font-size: 14px;
  padding: 5px 12px;
  display: inline-block;
  background-image: url(~@/assets/image_placeholder_landscape_error.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.missing-image-param-image {
  visibility: hidden;
}
</style>
