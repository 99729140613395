<template>
  <v-card>
    <v-card-text class="pt-2 pb-3 px-4">
      <v-color-picker
        dot-size="15"
        swatches-max-height="200"
        :show-swatches="true"
        @update:color="onColorUpdate($event)"
        mode="hexa"
        mode.sync="hexa"
        hide-mode-switch
        v-model="colorSelection"
      ></v-color-picker>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn text @click="$emit('cancel')"> Cancel </v-btn>
      <v-btn color="primary" @click="$emit('color-saved', colorSelection)">
        Set Color
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "ColorForm",
  data() {
    return {
      colorSelection: "#000000",
    };
  },
  methods: {
    onColorUpdate(data) {
      this.colorSelection = data.hex;
    },
  },
};
</script>
