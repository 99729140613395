export default {
  computed: {
    questions() {
      const { sections } = this.formDefinition.form;
      const nestedItems = sections.map(({ items }) => items);
      return nestedItems.flat().filter(({ id, type: itemType }) => {
        return id !== this.questionId && itemType === "QUESTION";
      });
    },
  },
};
