var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-edit-dialog",
    {
      attrs: {
        "save-text": "Save",
        "cancel-text": "Cancel",
        large: "",
        persistent: "",
      },
      on: { cancel: _vm.onCancel, close: _vm.onCancel, save: _vm.onSave },
      scopedSlots: _vm._u([
        {
          key: "input",
          fn: function () {
            return [
              _c(
                "div",
                [
                  _c(
                    "validation-observer",
                    { ref: "editInfoImageForm" },
                    [
                      _c("validation-provider", {
                        attrs: { name: "Label" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "Label",
                                    "hide-details": "auto",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "name",
                                  },
                                  model: {
                                    value:
                                      _vm.selectedRepeatingGroupItemOptions
                                        .information.label,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectedRepeatingGroupItemOptions
                                          .information,
                                        "label",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "selectedRepeatingGroupItemOptions.information.label",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("validation-provider", {
                        attrs: { name: "Image URL", rules: "required" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "Image URL",
                                    "hide-details": "auto",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "name",
                                    hint: "Input a valid URL",
                                  },
                                  model: {
                                    value:
                                      _vm.selectedRepeatingGroupItemOptions
                                        .information.url,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectedRepeatingGroupItemOptions
                                          .information,
                                        "url",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "selectedRepeatingGroupItemOptions.information.url",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("validation-provider", {
                        attrs: { name: "External URL" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "External URL",
                                    "hide-details": "auto",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "name",
                                    hint: "Input a valid URL",
                                  },
                                  model: {
                                    value:
                                      _vm.selectedRepeatingGroupItemOptions
                                        .information.openUrl,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectedRepeatingGroupItemOptions
                                          .information,
                                        "openUrl",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "selectedRepeatingGroupItemOptions.information.openUrl",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }