import { Node, mergeAttributes } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";
import MissingSignaturePlaceholder from "@/components/shared/MissingSignaturePlaceholder";
import { escape } from "lodash";

export default Node.create({
  name: "MissingSignaturePlaceholder",
  group: "block",
  inline: false,
  selectable: true,
  draggable: false,
  atom: true,
  parseHTML() {
    return [
      {
        tag: "img[data-missing-signature-src]",
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ["img", mergeAttributes(HTMLAttributes)];
  },
  addAttributes() {
    return {
      missingSignatureSrc: {
        default: null,
        parseHTML: (element) =>
          element.getAttribute("data-missing-signature-src"),
        renderHTML: (attributes) => {
          return {
            "data-missing-signature-src": escape(
              attributes.missingSignatureSrc
            ),
          };
        },
      },
      src: {
        default: null,
      },
      maxWidth: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-max-width"),
        renderHTML: (attributes) => {
          return {
            "data-max-width": escape(attributes.maxWidth),
          };
        },
      },
      maxHeight: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-max-height"),
        renderHTML: (attributes) => {
          return {
            "data-max-height": escape(attributes.maxHeight),
          };
        },
      },
      class: {
        default: null,
      },
    };
  },
  addNodeView() {
    return VueNodeViewRenderer(MissingSignaturePlaceholder);
  },
});
