<template>
  <v-edit-dialog
    save-text="Save"
    cancel-text="Cancel"
    large
    persistent
    @cancel="onCancel"
    @close="onCancel"
    @save="onSave"
  >
    <slot></slot>

    <template v-slot:input>
      <div>
        <validation-observer ref="editInfoImageForm">
          <validation-provider v-slot="{ errors, valid }" name="Label">
            <v-text-field
              v-model="selectedRepeatingGroupItemOptions.information.label"
              label="Label"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="name"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Image URL"
            rules="required"
          >
            <v-text-field
              v-model="selectedRepeatingGroupItemOptions.information.url"
              label="Image URL"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="name"
              hint="Input a valid URL"
            />
          </validation-provider>

          <validation-provider v-slot="{ errors, valid }" name="External URL">
            <v-text-field
              v-model="selectedRepeatingGroupItemOptions.information.openUrl"
              label="External URL"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="name"
              hint="Input a valid URL"
            />
          </validation-provider>
        </validation-observer>
      </div>
    </template>
  </v-edit-dialog>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  name: "EditInfoImageDialog",
  props: {
    value: Object,
  },
  data() {
    return {
      selectedRepeatingGroupItemOptions: {},
    };
  },
  methods: {
    onCancel() {
      this.$emit("input", cloneDeep(this.value));
    },
    async onSave() {
      const success = await this.$refs?.editInfoImageForm?.validate();
      if (!success) {
        return;
      }
      this.$emit("input", cloneDeep(this.selectedRepeatingGroupItemOptions));
    },
  },
  beforeMount() {
    this.selectedRepeatingGroupItemOptions = cloneDeep(this.value);
  },
};
</script>

<style>
.top-row {
  height: 50px;
}
</style>
