<template>
  <node-view-wrapper
    as="div"
    class="missing-param"
    :data-placeholder="node.attrs.missingParamBlock"
  >
    <span> {{ displayPlaceholder }}</span>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from "@tiptap/vue-2";
import { unescape } from "lodash";

export default {
  components: {
    NodeViewWrapper,
  },
  props: nodeViewProps,
  computed: {
    displayPlaceholder() {
      return `{{${unescape(this.node.attrs.missingParamBlock)}}}`;
    },
  },
};
</script>

<style>
.missing-param {
  background: #80000070;
  border-radius: 16px;
  font-size: 14px;
  padding: 3px 5px;
  display: inline-block;
  word-break: break-all;
}
</style>
