var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showCityworksLaborFormFieldInfoDialog,
        "max-width": "500",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pt-4" },
        [
          _c("v-card-text", [
            _vm.formFieldInfoDialogFieldName === _vm.LABOR_FORM_FIELD.EMPLOYEE
              ? _c("section", [
                  _vm._v(
                    " The form field selected will be used to populate the 'Employee' on the Labor record in the service request. Configure the form field as a single-select field, using the Cityworks ID (number) as the value and the employee's name as the label. Only single-select fields will appear as options for this setting. "
                  ),
                ])
              : _vm.formFieldInfoDialogFieldName === _vm.LABOR_FORM_FIELD.HOURS
              ? _c("section", [
                  _vm._v(
                    " The form field selected will be used to populate the hour on the Labor record in the service request. Only number fields will appear as options for this setting. "
                  ),
                ])
              : _vm.formFieldInfoDialogFieldName ===
                _vm.LABOR_FORM_FIELD.TIME_TYPE
              ? _c("section", [
                  _c("div", [
                    _vm._v(
                      " The form field selected will be used to set the time type. The value needs to match exactly: "
                    ),
                  ]),
                  _c("ul", [
                    _c("li", [_vm._v("Regular")]),
                    _c("li", [_vm._v("Overtime")]),
                    _c("li", [_vm._v("Holiday")]),
                    _c("li", [_vm._v("Benefit")]),
                    _c("li", [_vm._v("Standby")]),
                    _c("li", [_vm._v("Shift Diff.")]),
                    _c("li", [_vm._v("Overhead")]),
                    _c("li", [_vm._v("Other")]),
                  ]),
                  _c("div", { staticClass: "mt-4" }, [
                    _vm._v(
                      " If you want the time to always be classified as 'Regular', for example, set the default value of the form field to 'Regular'. "
                    ),
                  ]),
                ])
              : _vm.formFieldInfoDialogFieldName ===
                _vm.LABOR_FORM_FIELD.DESCRIPTION
              ? _c("section", [
                  _vm._v(
                    " The form field selected will be used to populate the description on the Labor record in the service request. This is an optional field. "
                  ),
                ])
              : _vm._e(),
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end pa-3" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }