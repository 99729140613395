<template>
  <v-dialog
    v-model="showConfirmDialog"
    max-width="600px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-list-item>
        <v-list-item-content>
          Are you sure you want to delete this email setting?
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <div class="d-flex justify-end">
            <v-btn text @click.stop="$emit('confirm-delete')" color="primary">
              Delete
            </v-btn>
            <v-btn text color="primary" @click="$emit('cancel-delete')">
              Cancel
            </v-btn>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmEmailSettingDeleteDialog",
  props: {
    showConfirmDialog: Boolean,
  },
};
</script>
