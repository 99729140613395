var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-toolbar",
        { attrs: { dark: "", color: "primary" } },
        [
          _c("v-toolbar-title", [_vm._v("Calculation")]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { icon: "", dark: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("calculation-list-close")
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            { staticClass: "caption" },
                            [_vm._v(" Formula ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c("v-list-item-content", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("readableQuestion")(
                                    _vm.item.question.calculation,
                                    _vm.formDefinition
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            { staticClass: "caption" },
                            [_vm._v(" Values ")]
                          ),
                        ],
                        1
                      ),
                      _vm._l(_vm.variables, function (v) {
                        return _c(
                          "v-list-item",
                          { key: v.id },
                          [
                            _c("v-list-item-content", [
                              _c(
                                "div",
                                { staticClass: "d-flex justify-space-between" },
                                [
                                  _c("p", [_vm._v(_vm._s(v.label))]),
                                  _c("p", [_vm._v(_vm._s(v.value))]),
                                ]
                              ),
                            ]),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            { staticClass: "caption" },
                            [_vm._v(" Calculation ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c("v-list-item-content", [
                            _vm._v(_vm._s(_vm.calculationExpression) + " "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }