var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showSelectDocDialog,
        "max-width": "400px",
        persistent: "",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", color: "primary" },
            },
            [
              _c("v-toolbar-title", [_vm._v("Select Doc")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("select-doc-dialog-close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "py-3" },
            [
              _c("p", { staticClass: "caption py-0 my-0" }, [_vm._v("Folder")]),
              _c(
                "v-btn",
                {
                  staticClass: "pa-0 ma-0",
                  attrs: {
                    fab: "",
                    "x-small": "",
                    color: "#4F4F4F",
                    width: "20px",
                    height: "20px",
                  },
                },
                [
                  _c("v-icon", { attrs: { color: "white", size: "18px" } }, [
                    _vm._v(" " + _vm._s(_vm.mdiHome) + " "),
                  ]),
                ],
                1
              ),
              _c("v-divider", { staticClass: "my-2" }),
              _c("div", [
                _c("div", { staticClass: "d-flex gap align-center py-1" }, [
                  _c(
                    "div",
                    [
                      _c("v-icon", { attrs: { color: "primary" } }, [
                        _vm._v(" " + _vm._s(_vm.mdiFolder) + " "),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { style: { color: "primary" } }, [
                    _vm._v("Attachments"),
                  ]),
                ]),
                _c("div", { staticClass: "d-flex gap align-center py-1" }, [
                  _c(
                    "div",
                    [
                      _c("v-icon", { attrs: { color: "primary" } }, [
                        _vm._v(" " + _vm._s(_vm.mdiFolder) + " "),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { style: { color: "primary" } }, [
                    _vm._v("Site Photos"),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "d-flex gap align-center py-1 cursor-pointer",
                    on: {
                      click: function ($event) {
                        return _vm.onFileClick({ name: "floorplan.pdf" })
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("v-icon", { attrs: { color: "#4F4F4F" } }, [
                          _vm._v(" " + _vm._s(_vm.mdiFile) + " "),
                        ]),
                      ],
                      1
                    ),
                    _c("div", { style: { color: "primary" } }, [
                      _vm._v("Floorplan.pdf"),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "d-flex gap align-center py-1 cursor-pointer",
                    on: {
                      click: function ($event) {
                        return _vm.onFileClick({ name: "brokenfence.png" })
                      },
                    },
                  },
                  [
                    _c("div", [
                      _c("img", {
                        staticClass: "thumbnail",
                        attrs: { src: require("@/assets/brokenfence.png") },
                      }),
                    ]),
                    _c("div", { style: { color: "primary" } }, [
                      _vm._v("brokenfence.jpg"),
                    ]),
                  ]
                ),
              ]),
              _vm.showSelectPdfPageDialog
                ? _c("SelectPdfPageDialog", {
                    attrs: {
                      showSelectPdfPageDialog: _vm.showSelectPdfPageDialog,
                      selectedFile: _vm.selectedFile,
                      maxWidthHeight: _vm.maxWidthHeight,
                      formResultId: _vm.formResultId,
                    },
                    on: {
                      "select-pdf-page-dialog-close": function ($event) {
                        _vm.showSelectPdfPageDialog = false
                      },
                      "confirm-add-page": _vm.onConfirmAddPage,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }