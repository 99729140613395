var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "pa-0 my-0" },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-0 my-0" },
        [
          _c(
            "v-list",
            { staticClass: "pa-0 my-0" },
            _vm._l(_vm.LOGIC_CHOICES, function (l) {
              return _c(
                "v-list-item",
                {
                  key: l.value,
                  staticClass: "pa-0 my-0",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("logic-choice-selected", l.value)
                    },
                  },
                },
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "body-1 px-4 my-0" },
                    [_vm._v(" " + _vm._s(l.label) + " ")]
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }