<template>
  <v-card
    class="elevation-0"
    :style="selected ? 'background-color: #fff8e1' : ''"
    width="100%"
  >
    <v-card-title
      class="ma-0 pa-0 d-flex flex-row justify-space-between elevation-0"
    >
      <p class="caption">{{ label }}</p>
      <v-btn text @click="clearSignature" class="mt-n3">Clear</v-btn>
    </v-card-title>
    <v-card-text class="pa-0 mb-2" :class="{ selected }">
      <div id="read-only-box" v-if="readOnly"></div>
      <VueSignaturePad
        v-else
        width="100%"
        height="200px"
        ref="signaturePad"
        :options="{ onBegin, onEnd }"
        :customStyle="{ border: '3px black solid' }"
      />
      <p class="caption" v-if="!valid">{{ errors && errors[0] }}</p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SignaturePad",
  props: {
    label: String,
    selected: Boolean,
    formDefinition: Object,
    errors: Array,
    valid: Boolean,
    readOnly: Boolean,
  },
  methods: {
    onBegin() {
      this.$nextTick(() => {
        this.$refs.signaturePad.resizeCanvas();
      });
    },
    clearSignature() {
      this.$refs.signaturePad.clearSignature();
      const { data } = this.$refs.signaturePad.saveSignature();
      this.$emit("input", data);
    },
    onEnd() {
      const { data } = this.$refs.signaturePad.saveSignature();
      this.$emit("input", data);
    },
  },
};
</script>

<style scoped>
#read-only-box {
  width: 100%;
  border: 3px black solid;
  height: 200px;
}
</style>
