<template>
  <validation-provider
    slim
    v-slot="{ errors, valid }"
    :name="item.question.label"
  >
    <v-menu v-model="showTimePicker" :close-on-content-click="false">
      <template v-slot:activator="{ on }">
        <v-text-field
          readonly
          v-model="time"
          v-on="on"
          color="primary"
          :error-messages="errors"
          :success="valid"
          :append-icon="mdiClockOutline"
          @click.stop
          @click:append="on.click"
        ></v-text-field>
      </template>
      <v-time-picker v-model="time" @change="onInput"></v-time-picker>
    </v-menu>
  </validation-provider>
</template>

<script>
import { mdiClockOutline } from "@mdi/js";
export default {
  name: "RepeatinghGroupDefaultValueTimeInput",
  props: { value: String, item: Object },
  data() {
    return { mdiClockOutline, showTimePicker: false };
  },
  methods: {
    async onInput() {
      this.$emit("input", this.time);
      this.showTimePicker = false;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.time = val;
      },
    },
  },
};
</script>
