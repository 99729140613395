var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticClass: "mt-0 mb-0 pt-0 pb-0", attrs: { cols: "12" } },
        [
          ["TEXT", "EMAIL"].includes(_vm.item.question.type)
            ? [
                _c("validation-provider", {
                  attrs: {
                    slim: "",
                    name: _vm.item.question.label,
                    rules: {
                      required: _vm.isRequired,
                      email: _vm.item.question.type === "EMAIL",
                      max: Boolean(_vm.item.question.max_length)
                        ? _vm.item.question.max_length
                        : Infinity,
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors, valid }) {
                          return [
                            _vm.item.question.forInformationOnly
                              ? [
                                  _c("label", { staticClass: "caption" }, [
                                    _vm._v(_vm._s(_vm.item.question.label)),
                                  ]),
                                  _c("p", [
                                    _vm._v(_vm._s(_vm.item.question.value)),
                                  ]),
                                ]
                              : [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.item.question.label,
                                      "hide-details": "auto",
                                      "error-messages": errors,
                                      success: valid,
                                      color: "primary",
                                      name: _vm.item.id,
                                      hint: _vm.item.question.placeholder,
                                      "persistent-hint": "",
                                      disabled: _vm.readOnly,
                                    },
                                    on: { input: _vm.onInput },
                                    model: {
                                      value: _vm.textValue,
                                      callback: function ($$v) {
                                        _vm.textValue = $$v
                                      },
                                      expression: "textValue",
                                    },
                                  }),
                                ],
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2130372160
                  ),
                }),
              ]
            : _vm._e(),
          ["NUMBER"].includes(_vm.item.question.type)
            ? [
                _c("validation-provider", {
                  attrs: {
                    slim: "",
                    name: _vm.item.question.label,
                    rules: {
                      required: _vm.isRequired,
                      max: Boolean(_vm.item.question.max_length)
                        ? _vm.item.question.max_length
                        : Infinity,
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors, valid }) {
                          return [
                            _c("v-text-field", {
                              attrs: {
                                label: _vm.item.question.label,
                                "hide-details": "auto",
                                "error-messages": errors,
                                success: valid,
                                color: "primary",
                                name: _vm.item.id,
                                hint: _vm.item.question.placeholder,
                                "persistent-hint": "",
                                disabled: _vm.readOnly,
                              },
                              on: { input: _vm.onInput },
                              model: {
                                value: _vm.textValue,
                                callback: function ($$v) {
                                  _vm.textValue = _vm._n($$v)
                                },
                                expression: "textValue",
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1904222771
                  ),
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }