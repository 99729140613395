var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("validation-observer", { ref: "form" }, [
    _c(
      "form",
      [
        _c(
          "v-list",
          {
            staticClass: "overflow-y-auto",
            staticStyle: { "max-height": "calc(100vh - 56px)" },
          },
          [
            _c(
              "v-list-item",
              [
                _c("validation-provider", {
                  attrs: { name: "Filter" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ errors, valid }) {
                        return [
                          _c("v-text-field", {
                            attrs: {
                              label: "Form Field",
                              "hide-details": "auto",
                              "error-messages": errors,
                              success: valid,
                              color: "primary",
                              name: "filterText",
                            },
                            model: {
                              value: _vm.filterText,
                              callback: function ($$v) {
                                _vm.filterText = $$v
                              },
                              expression: "filterText",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._l(
              _vm.filteredformDefinitionWithOnlyQuestions.form.sections,
              function (section) {
                return _c(
                  "v-list-item",
                  { key: section.id, staticClass: "pa-0 ma-0" },
                  [
                    _c(
                      "v-list",
                      [
                        _c("v-list-item", { staticClass: "caption" }, [
                          _vm._v(_vm._s(section.name)),
                        ]),
                        _vm._l(section.items, function (item) {
                          return _c(
                            "v-list-item",
                            {
                              key: item.id,
                              staticClass: "question d-block",
                              on: {
                                click: function ($event) {
                                  return _vm.copyToClipboard(item)
                                },
                              },
                            },
                            [
                              _c(
                                "transition",
                                { attrs: { name: "slide-fade" } },
                                [
                                  _vm.showCopyAnimation[item.id]
                                    ? _c("p", { staticClass: "absolute" }, [
                                        _c(
                                          "span",
                                          { staticClass: "param mr-1" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  `{{params.${item.id}\}\}`
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(item.question.label) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _c("p", [
                                _c("span", { staticClass: "param mr-1" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(`{{params.${item.id}\}\}`) +
                                      " "
                                  ),
                                ]),
                                _vm._v(" " + _vm._s(item.question.label) + " "),
                              ]),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                )
              }
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }