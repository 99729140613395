<template>
  <v-dialog :value="showAdvancedSettingsWarningDialog" width="500px">
    <v-card class="pt-5 pb-3">
      <v-card-text>
        <h3>Warning!</h3>
        <div class="py-3">
          If you continue to the implementation of Advanced Settings, you will
          break your form in the UtiliSync app found at app.utilisync.com.
          Contact UtiliSync support if you need help configuring one of these
          settings on your form.
        </div>
      </v-card-text>

      <v-card-actions class="d-flex justify-end align-center px-5">
        <v-btn text color="primary" @click="$emit('cancel')">Cancel</v-btn>
        <v-btn color="primary" @click="$emit('continue')">Continue</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AdvancedSettingsWarningDialog",
  props: {
    showAdvancedSettingsWarningDialog: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    showWarningDialog() {},
  },
};
</script>
