var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showPhotoSizeDialog,
        "max-width": "400",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "validation-observer",
            { ref: "photoSizeForm", attrs: { slim: "" } },
            [
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      staticClass: "elevation-0",
                      attrs: { dark: "", color: "primary" },
                    },
                    [
                      _c("v-toolbar-title", [_vm._v(" Photo Sizing ")]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", dark: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("close-photo-size-dialog")
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-card-text", { staticClass: "elevation-0" }, [
                    _c(
                      "div",
                      { staticClass: "py-3" },
                      [
                        _c("validation-provider", {
                          attrs: {
                            name: "Image Width",
                            rules: {
                              required: true,
                              regex: /\d+|auto/,
                            },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors, valid }) {
                                return [
                                  _c("v-text-field", {
                                    staticClass: "pt-1",
                                    attrs: {
                                      label: "Image Width",
                                      "hide-details": "auto",
                                      "error-messages": errors,
                                      success: valid,
                                      color: "primary",
                                      name: "name",
                                    },
                                    on: {
                                      keydown: _vm.onNumberKeyDown,
                                      change: function ($event) {
                                        return _vm.checkImageWidthVal()
                                      },
                                      input: function ($event) {
                                        return _vm.onImageWidthInput()
                                      },
                                    },
                                    model: {
                                      value: _vm.image.imageWidth,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.image, "imageWidth", $$v)
                                      },
                                      expression: "image.imageWidth",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "py-3" },
                      [
                        _c("validation-provider", {
                          attrs: {
                            name: "Image Height",
                            rules: {
                              required: true,
                              regex: /\d+|auto/,
                            },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors, valid }) {
                                return [
                                  _c("v-text-field", {
                                    staticClass: "pt-1",
                                    attrs: {
                                      label: "Image Height",
                                      "hide-details": "auto",
                                      "error-messages": errors,
                                      success: valid,
                                      color: "primary",
                                      name: "name",
                                    },
                                    on: {
                                      keydown: _vm.onNumberKeyDown,
                                      change: function ($event) {
                                        return _vm.checkImageHeightVal()
                                      },
                                      input: function ($event) {
                                        return _vm.onImageHeightInput()
                                      },
                                    },
                                    model: {
                                      value: _vm.image.imageHeight,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.image, "imageHeight", $$v)
                                      },
                                      expression: "image.imageHeight",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "py-3" },
                      [
                        _c("validation-provider", {
                          attrs: {
                            name: "Image Padding",
                            rules: {
                              required: true,
                              numeric: true,
                              min_value: 0,
                            },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors, valid }) {
                                return [
                                  _c("v-text-field", {
                                    staticClass: "pt-1",
                                    attrs: {
                                      label: "Image Padding",
                                      "hide-details": "auto",
                                      "error-messages": errors,
                                      success: valid,
                                      color: "primary",
                                      name: "name",
                                      type: "number",
                                    },
                                    on: { keydown: _vm.onNumberKeyDown },
                                    model: {
                                      value: _vm.image.imagePadding,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.image,
                                          "imagePadding",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "image.imagePadding",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        { attrs: { color: "primary", type: "submit" } },
                        [_vm._v(" Insert ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }