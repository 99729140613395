<template>
  <span>
    <span class="symbol" v-if="value === MATCH_CHOICE_VALUES.EQUAL"> = </span>
    <span class="symbol" v-else-if="value === MATCH_CHOICE_VALUES.GREATER_THAN">
      &#62;
    </span>
    <span
      class="symbol"
      v-else-if="value === MATCH_CHOICE_VALUES.GREATER_THAN_OR_EQUAL"
    >
      ≥
    </span>
    <span class="symbol" v-else-if="value === MATCH_CHOICE_VALUES.LESS_THAN">
      &#60;
    </span>
    <span
      class="symbol"
      v-else-if="value === MATCH_CHOICE_VALUES.LESS_THAN_OR_EQUAL"
    >
      ≤
    </span>
    <span class="symbol" v-else-if="value === MATCH_CHOICE_VALUES.NOT_EQUAL">
      ≠
    </span>
  </span>
</template>

<script>
import MATCH_CHOICE_VALUES from "@/constants/matchChoiceValues";
import {
  mdiCalendarArrowLeft,
  mdiCalendarArrowRight,
  mdiCalendarRange,
} from "@mdi/js";

export default {
  name: "MatchIcon",
  props: {
    value: String,
  },
  data() {
    return {
      MATCH_CHOICE_VALUES,
      mdiCalendarArrowLeft,
      mdiCalendarArrowRight,
      mdiCalendarRange,
    };
  },
};
</script>

<style scoped>
.symbol {
  font-size: 20px;
}
</style>
