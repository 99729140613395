var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "node-view-wrapper",
    {
      staticClass: "image-param",
      style: _vm.node.attrs.style,
      attrs: { as: "div" },
    },
    [
      _c("node-view-wrapper", {
        staticClass: "image-param-image",
        style: _vm.node.attrs.style,
        attrs: { as: "img", src: _vm.node.attrs.paramSrc },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }