<template>
  <v-dialog
    :value="showRepeatingGroupDialog"
    max-width="600px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Group {{ item.question.label }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeForm">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pt-3" style="height: 60vh; overflow-y: auto">
        <validation-observer ref="groupForm">
          <template v-for="item of groupedItems">
            <template v-if="isVisible(item)">
              <template v-if="item.question">
                <template v-if="['FILE'].includes(item.question.type)">
                  <GroupFileInput
                    v-if="!item.question.isImage"
                    :key="item.id"
                    :item="item"
                    :readOnly="readOnly"
                    v-model="item.value"
                  />
                  <GroupPhotoInput
                    v-else
                    :key="item.id"
                    :item="item"
                    :readOnly="readOnly"
                    v-model="item.value"
                  />
                </template>
                <GroupMultiSelectInput
                  :key="item.id"
                  v-else-if="['MULTI_SELECT'].includes(item.question.type)"
                  :item="item"
                  :readOnly="readOnly"
                  v-model="item.value"
                />
                <GroupSignaturePad
                  v-else-if="['SIGNATURE'].includes(item.question.type)"
                  :key="item.id"
                  :item="item"
                  :readOnly="readOnly"
                  v-model="item.value"
                />
                <GroupSingleSelectInput
                  :key="item.id"
                  v-else-if="['SINGLE_SELECT'].includes(item.question.type)"
                  :item="item"
                  :readOnly="readOnly"
                  :isRequired="isRequired(item)"
                  v-model="item.value"
                />
                <GroupDateInput
                  :key="item.id"
                  v-else-if="['DATE'].includes(item.question.type)"
                  :item="item"
                  :readOnly="readOnly"
                  :isRequired="isRequired(item)"
                  v-model="item.value"
                />
                <GroupTimeInput
                  :key="item.id"
                  v-else-if="['TIME'].includes(item.question.type)"
                  :item="item"
                  :readOnly="readOnly"
                  v-model="item.value"
                />
                <GroupTextInput
                  v-else-if="
                    ['TEXT', 'EMAIL', 'NUMBER'].includes(item.question.type)
                  "
                  :key="item.id"
                  :item="item"
                  :readOnly="readOnly"
                  :formDefinition="formDefinition"
                  :isRequired="isRequired(item)"
                  v-model="item.value"
                  @input="onInput"
                />
              </template>
              <RepeatingGroupInfo
                :key="item.id"
                :item="item"
                :readOnly="readOnly"
                v-else-if="item.information"
              />
            </template>
          </template>
        </validation-observer>
      </v-card-text>

      <v-card-actions class="d-flex justify-end px-5">
        <v-btn color="primary" @click="save"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import GroupFileInput from "@/components/forms/form-builder-edit-form/item-preview/repeating-group/repeating-group-dialog/GroupFileInput";
import GroupMultiSelectInput from "@/components/forms/form-builder-edit-form/item-preview/repeating-group/repeating-group-dialog/GroupMultiSelectInput";
import GroupPhotoInput from "@/components/forms/form-builder-edit-form/item-preview/repeating-group/repeating-group-dialog/GroupPhotoInput";
import GroupSignaturePad from "@/components/forms/form-builder-edit-form/item-preview/repeating-group/repeating-group-dialog/GroupSignaturePad";
import GroupSingleSelectInput from "@/components/forms/form-builder-edit-form/item-preview/repeating-group/repeating-group-dialog/GroupSingleSelectInput";
import GroupTextInput from "@/components/forms/form-builder-edit-form/item-preview/repeating-group/repeating-group-dialog/GroupTextInput";
import GroupDateInput from "@/components/forms/form-builder-edit-form/item-preview/repeating-group/repeating-group-dialog/GroupDateInput";
import GroupTimeInput from "@/components/forms/form-builder-edit-form/item-preview/repeating-group/repeating-group-dialog/GroupTimeInput";
import RepeatingGroupInfo from "@/components/forms/form-builder-edit-form/item-preview/repeating-group/RepeatingGroupInfo";
import { cloneDeep } from "lodash";

export default {
  name: "RepeatingGroupDialog",
  props: {
    showRepeatingGroupDialog: Boolean,
    item: Object,
    readOnly: Boolean,
    previewMode: Boolean,
    formDefinition: Object,
  },
  components: {
    GroupFileInput,
    GroupMultiSelectInput,
    GroupPhotoInput,
    GroupSignaturePad,
    GroupSingleSelectInput,
    GroupTextInput,
    GroupDateInput,
    GroupTimeInput,
    RepeatingGroupInfo,
  },
  computed: {
    groupedItems() {
      return this.item.question.groupedItems;
    },
  },
  beforeMount() {
    for (const item of this.item.question.groupedItems) {
      item.question.value = undefined;
    }
  },
  methods: {
    async onInput() {
      await this.$nextTick();
      this.$forceUpdate();
    },
    closeForm() {
      this.$emit("close-form");
    },
    save() {
      const savedValues = cloneDeep(this.groupedItems);
      this.$emit("form-saved", savedValues);
    },
    isVisible(item) {
      if (!this.previewMode) {
        return true;
      }
      if (item.question) {
        return this.checkVisibility("question", item);
      } else {
        return this.checkVisibility("information", item);
      }
    },
    checkVisibility(type = "question", item) {
      if (item?.[type]?.visible?.condition === "ALWAYS") {
        return true;
      }

      if (item?.[type]?.visible?.condition === "NEVER") {
        return false;
      }

      const { sections = [] } = { ...this.formDefinition?.form };
      for (const section of sections) {
        for (const dependantItem of section.items) {
          if (+dependantItem.id === +item[type]?.visible?.dependantId) {
            if (dependantItem?.question?.type === "GROUP") {
              for (const groupDependantItem of dependantItem?.question
                ?.groupedItems ?? []) {
                if (
                  +groupDependantItem.id ===
                  +item.question.visible.dependantGroupItemId
                ) {
                  const groupDependantItemValue = groupDependantItem.value;
                  if (Array.isArray(groupDependantItemValue)) {
                    if (groupDependantItemValue.length > 1) {
                      return false;
                    } else {
                      const [dependantItemValue] = groupDependantItemValue;
                      return this.checkDependantValue(
                        dependantItemValue,
                        type,
                        "visible",
                        item
                      );
                    }
                  } else {
                    return this.checkDependantValue(
                      groupDependantItemValue,
                      type,
                      "visible",
                      item
                    );
                  }
                }
              }
            } else {
              const dependantItemValue = dependantItem.value;
              if (Array.isArray(dependantItemValue)) {
                if (dependantItemValue.length > 1) {
                  return false;
                } else {
                  const [dependantValue] = dependantItemValue;
                  return this.checkDependantValue(
                    dependantValue,
                    type,
                    "visible",
                    item
                  );
                }
              } else {
                return this.checkDependantValue(
                  dependantItemValue,
                  type,
                  "visible",
                  item
                );
              }
            }
          }
        }
      }
      return item[type]?.visible?.condition === "ALWAYS";
    },
    checkDependantValue(
      dependantItemValue,
      type = "question",
      field = "required",
      item
    ) {
      if (item[type][field].condition === "NOT_EQUAL") {
        return (
          +dependantItemValue !== +item[type][field].value &&
          dependantItemValue !== item[type][field].value
        );
      } else if (item[type][field].condition === "EQUAL") {
        return (
          +dependantItemValue === +item[type][field].value ||
          dependantItemValue === item[type][field].value
        );
      } else if (item[type][field].condition === "GREATER_THAN") {
        return +dependantItemValue > +item[type][field].value;
      } else if (item[type][field].condition === "GREATER_THAN_OR_EQUAL") {
        return +dependantItemValue >= +item[type][field].value;
      } else if (item[type][field].condition === "LESS_THAN") {
        return +dependantItemValue < +item[type][field].value;
      } else if (item[type][field].condition === "LESS_THAN_OR_EQUAL") {
        return +dependantItemValue <= +item[type][field].value;
      }
    },
    isRequired(item) {
      if (item.question.required.condition === "NEVER") {
        return false;
      }

      if (item.question.required.condition === "ALWAYS") {
        return true;
      }
      const { sections } = { ...this.formDefinition.form };
      for (const section of sections) {
        for (const dependantItem of section.items) {
          if (+dependantItem.id === +item.question.required.dependantId) {
            if (dependantItem?.question?.type === "GROUP") {
              for (const groupDependantItem of dependantItem?.question
                ?.groupedItems ?? []) {
                if (
                  +groupDependantItem.id ===
                  +item.question.required.dependantGroupItemId
                ) {
                  const groupDependantItemValue = groupDependantItem.value;
                  if (Array.isArray(groupDependantItemValue)) {
                    if (groupDependantItemValue.length > 1) {
                      return false;
                    } else {
                      const [dependantItemValue] = groupDependantItemValue;
                      return this.checkDependantValue(
                        dependantItemValue,
                        undefined,
                        undefined,
                        item
                      );
                    }
                  } else {
                    return this.checkDependantValue(
                      groupDependantItemValue,
                      undefined,
                      undefined,
                      item
                    );
                  }
                }
              }
            } else {
              const dependantItemValue = dependantItem.value;
              if (Array.isArray(dependantItemValue)) {
                if (dependantItemValue.length > 1) {
                  return false;
                } else {
                  const [dependantValue] = dependantItemValue;
                  return this.checkDependantValue(
                    dependantValue,
                    undefined,
                    undefined,
                    item
                  );
                }
              } else {
                return this.checkDependantValue(
                  dependantItemValue,
                  undefined,
                  undefined,
                  item
                );
              }
            }
          }
        }
      }
      return item.question.required.condition === "ALWAYS";
    },
  },
};
</script>
