<template>
  <v-dialog
    :value="showActionItemDialog"
    max-width="600px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Action Item</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeForm">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pt-3">
        <validation-observer ref="actionItemForm">
          <v-row>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Description"
                rules="required"
              >
                <v-text-field
                  label="Description *"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="value"
                  v-model="actionItem.action_item_description"
                  :disabled="readOnly"
                  :hint="item.question.descriptionHint"
                  persistent-hint
                >
                </v-text-field>
              </validation-provider>
            </v-col>

            <v-col cols="12" class="d-flex gap justify-space-between">
              <div class="half">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Assigned To"
                  rules="required"
                >
                  <v-select
                    autocomplete="off"
                    v-model="assigneeUserOrFieldId"
                    :items="userChoices"
                    hide-details="auto"
                    name="assignedTo"
                    item-value="value"
                    item-text="name"
                    :error-messages="errors"
                    :success="valid"
                    label="Assigned To *"
                    :disabled="readOnly"
                  />
                </validation-provider>
              </div>

              <div
                class="half"
                v-if="assigneeUserOrFieldId === 'manually-add-email'"
              >
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Email"
                  :rules="{ required: true, email: true }"
                >
                  <v-text-field
                    label="Enter Email *"
                    hide-details="auto"
                    :error-messages="errors"
                    :success="valid"
                    color="primary"
                    name="email"
                    v-model="manuallyEnteredAssigneeEmail"
                    :disabled="readOnly"
                    persistent-hint
                  >
                  </v-text-field>
                </validation-provider>
              </div>
            </v-col>

            <v-col cols="12">
              <div class="half">
                <validation-provider
                  slim
                  v-slot="{ errors, valid }"
                  :name="item.question.label"
                  rules="required"
                >
                  <v-menu>
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        label="Due By *"
                        color="primary"
                        v-model="actionItem.due_by"
                        :error-messages="errors"
                        :success="valid"
                        :disabled="readOnly"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="actionItem.due_by"></v-date-picker>
                  </v-menu>
                </validation-provider>
              </div>
            </v-col>

            <v-col cols="12">
              <p class="caption">Photos</p>
              <file-pond
                name="files"
                ref="files"
                label-idle="Tap or drop photos here..."
                allow-multiple
                accepted-file-types="image/jpeg, image/png"
                :files="uploadFiles"
                @addfile="onChange"
                allowFileSizeValidation
                maxFileSize="2MB"
                :disabled="readOnly"
              />
              <v-card
                v-for="(image, index) of actionItem.assigner_photos"
                :key="image.id"
              >
                <v-card-text>
                  <v-list-item>
                    <v-list-item-content>
                      <v-card class="d-flex flex-row justify-start elevation-0">
                        <v-card
                          class="elevation-0 d-flex flex-column justify-center mr-2"
                        >
                          <img :src="image.url" class="thumbnail mr-2" />
                        </v-card>
                        <v-card class="elevation-0" width="100%">
                          <v-text-field
                            :key="image.id"
                            label="Description"
                            color="primary"
                            :name="image.id"
                            v-model="image.description"
                          >
                          </v-text-field>
                        </v-card>
                      </v-card>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-action-text>
                        <v-menu>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon>{{ mdiDotsVertical }}</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item @click="removePhoto(index, image.id)">
                              <v-list-item-title>
                                <v-icon>{{ mdiDelete }}</v-icon>
                                Delete
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>

      <v-card-actions class="d-flex justify-end px-5">
        <v-btn color="primary" @click="save">Save Action Item</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { cloneDeep } from "lodash";
import vueFilePond, { setOptions } from "vue-filepond";
import { mdiDelete, mdiDotsVertical } from "@mdi/js";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import "filepond/dist/filepond.min.css";
import axios from "axios";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

const APIURL = process.env.VUE_APP_API_URL;

const Compress = require("compress.js");
const compress = new Compress();
const FilePond = vueFilePond(FilePondPluginFileEncode);

setOptions({
  imageResizeMode: "contain",
  allowImageTransform: true,
});

export default {
  name: "ActionItemDialog",
  props: {
    showActionItemDialog: Boolean,
    item: Object,
    readOnly: Boolean,
    userGroup: Array,
    formResultId: String,
    formDefinition: Object,
    newActionItems: Array,
    selectedMapServiceId: String,
    gisInfoObjectId: [String, Number],
  },
  data() {
    return {
      actionItem: {
        action_item_description: "",
        assignee_user_id: "",
        due_by: "",
        assigner_photos: [],
      },
      uploadFiles: undefined,
      mdiDelete,
      mdiDotsVertical,
      assigneeUserOrFieldId: undefined,
      manuallyEnteredAssigneeEmail: "",
      users: [],
    };
  },
  components: {
    FilePond,
  },
  watch: {
    defaultActionItemValues: {
      deep: true,
      immediate: true,
      handler(val) {
        const actionItem = cloneDeep(val);
        const { due_by: dueBy } = actionItem;
        if (actionItem.assignee_user_id) {
          this.assigneeUserOrFieldId = actionItem.assignee_user_id;
        } else if (!actionItem.assignee_user_id && actionItem.email_from_from) {
          const nestedItems = this.formDefinition.form.sections.map(
            ({ items }) => items
          );
          const items = nestedItems.flat();
          const { label, value } = actionItem.email_from_from;
          const { id: fieldId } = items.find(
            (it) => it.question?.label === label
          );
          this.manuallyEnteredAssigneeEmail = value;
          this.assigneeUserOrFieldId = ["item", fieldId].join("-");
        } else if (
          !actionItem.assignee_user_id &&
          !actionItem.email_from_from &&
          !actionItem.assigned_to_user_name &&
          !actionItem.assigned_to_user_group_name
        ) {
          this.manuallyEnteredAssigneeEmail = actionItem.assignee_email;
          this.assigneeUserOrFieldId = "manually-add-email";
        }

        if (dueBy) {
          this.actionItem.due_by = moment(dueBy).format("YYYY-MM-DD");
        }
      },
    },
  },
  computed: {
    defaultActionItemValues() {
      const [firstNewActionItem = {}] = this.newActionItems;
      return firstNewActionItem;
    },
    assigneeEmailFieldChoices() {
      return this.formDefinition.form.sections
        .filter((s) => {
          return s.items.some((it) =>
            this.item.question?.populateEmailWithIds?.includes(it.id)
          );
        })
        .map((s) => {
          return [
            {
              header: s.name,
            },
            ...s.items
              .filter((it) =>
                this.item.question?.populateEmailWithIds?.includes(it.id)
              )
              .map((it) => {
                const {
                  id,
                  number,
                  question: { label },
                } = it;
                return {
                  name: `${number} ${label}`,
                  value: ["item", id].join("-"),
                };
              })
              .flat(),
          ];
        })
        .flat();
    },
    userChoices() {
      if (!Array.isArray(this.users)) {
        return [];
      }
      return [
        { header: "User Group" },
        ...this.users.map((u) => {
          const { user_id: value, user_name: name } = u;
          return {
            name,
            value,
          };
        }),
        { divider: true },
        ...this.assigneeEmailFieldChoices,
        { divider: true },
        { name: "Manually Add Email", value: "manually-add-email" },
      ];
    },
  },
  beforeMount() {
    this.actionItem = {
      ...this.actionItem,
      id: uuidv4(),
    };
  },
  methods: {
    closeForm() {
      this.$emit("close-form");
    },
    async save() {
      const success = await this.$refs.actionItemForm.validate();
      if (!success) {
        return;
      }
      if (this.assigneeUserOrFieldId === "manually-add-email") {
        this.actionItem = {
          ...this.actionItem,
          assignee_user_id: null,
          email_from_from: null,
          assignee_email: this.manuallyEnteredAssigneeEmail,
          assigned_to_user_name: null,
          assigned_to_user_group_name: null,
        };
      } else {
        const user = this.users.find(
          (u) => u.user_id === this.assigneeUserOrFieldId
        );
        if (user) {
          const { user_id: userId, f_name: fName, l_name: lName } = user;
          const { organization } = JSON.parse(localStorage.getItem("auth"));
          this.actionItem = {
            ...this.actionItem,
            assignee_user_id: userId,
            email_from_from: null,
            assignee_email: null,
            assigned_to_user_name: `${fName} ${lName}`,
            assigned_to_user_group_name: organization.name,
          };
        } else {
          const nestedItems = this.formDefinition.form.sections.map(
            ({ items }) => items
          );
          const items = nestedItems.flat();
          const [, itemId] = this.assigneeUserOrFieldId.split("-");
          const {
            question: { label },
            value,
          } = items.find((it) => +it.id === +itemId);
          this.actionItem = {
            ...this.actionItem,
            assignee_email: value,
            email_from_from: { label, value },
            assignee_user_id: null,
            assigned_to_user_name: null,
            assigned_to_user_group_name: null,
          };
        }
      }
      const actionItem = cloneDeep(this.actionItem);
      this.$emit("form-saved", actionItem);
    },
    async onChange() {
      const files = this.$refs.files.getFiles();
      const base64ImagePromises = files.map(async (f) => {
        let dataUrl = f.getFileEncodeDataURL();
        const { name: fileName, type: fileType } = f.file;
        const result = await compress.compress([f.file], {
          maxWidth: this.maxWidthHeight,
          maxHeight: this.maxWidthHeight,
          resize: true,
        });
        const [{ data, prefix }] = result;
        dataUrl = `${prefix}${data}`;
        return {
          dataUrl,
          description: "",
          fileName,
          fileType,
          id: uuidv4(),
          name: fileName,
          url: dataUrl,
          thumbnail: dataUrl,
        };
      });

      if (files.length > 0) {
        for (const f of files) {
          this.$refs.files.removeFile(f);
        }

        const base64Images = await Promise.all(base64ImagePromises);
        this.actionItem.assigner_photos = [
          ...this.actionItem.assigner_photos,
          ...base64Images.map((im) => {
            const { id, description, url, thumbnail } = im;
            return { id, description, url, thumbnail };
          }),
        ];
      }
    },
    async removePhoto(index, imageId) {
      this.actionItem.assigner_photos.splice(index, 1);
      await axios.delete(`${APIURL}/files/${imageId}`);
    },
  },
};
</script>

<style scoped>
.thumbnail {
  width: 70px;
}

.half {
  width: 50%;
}

.gap {
  gap: 10px;
}
</style>
