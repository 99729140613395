import axios from "axios";
const APIURL = process.env.VUE_APP_API_URL;

export default {
  methods: {
    async updateAuthObject() {
      if (localStorage.getItem("esri_token")) {
        const portalUrl =
          /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/.test(
            localStorage.getItem("portal-url")
          )
            ? localStorage.getItem("portal-url")
            : "https://www.arcgis.com";
        const resData = await axios.post(`${APIURL}/auth/esri_login`, {
          access_token: localStorage.getItem("esri_token"),
          portal_url: portalUrl,
        });
        const {
          data: {
            results: { refreshToken },
          },
        } = resData;
        localStorage.setItem("refresh_token", refreshToken);
      }
      const refreshTokenData = await axios.post(
        `${APIURL}/auth/refresh_token`,
        {
          refresh_token: localStorage.getItem("refresh_token"),
        }
      );
      const {
        data: {
          results: { refresh_token: refreshToken },
        },
      } = refreshTokenData;
      localStorage.setItem(
        "auth",
        JSON.stringify(refreshTokenData.data.results)
      );
      localStorage.setItem("refresh_token", refreshToken);
      this.auth = JSON.parse(localStorage.getItem("auth"));
    },
  },
};
