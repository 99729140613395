var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("validation-observer", { ref: "cellPaddingForm" }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.save.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "v-card",
          [
            _c(
              "v-card-text",
              { staticClass: "pt-2 pb-3 px-4" },
              [
                _c("validation-provider", {
                  attrs: { name: "Padding", rules: "required" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ errors, valid }) {
                        return [
                          _c("v-text-field", {
                            attrs: {
                              label: "Padding",
                              "hide-details": "auto",
                              "error-messages": errors,
                              success: valid,
                              color: "primary",
                              name: "name",
                              type: "number",
                              hint: "Max value 50",
                              min: "0",
                              max: "50",
                              "persistent-hint": "",
                            },
                            on: { keydown: _vm.onNumberKeyDown },
                            model: {
                              value: _vm.quantityPadding,
                              callback: function ($$v) {
                                _vm.quantityPadding = $$v
                              },
                              expression: "quantityPadding",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "v-card-actions",
              [
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    attrs: { text: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("cancel")
                      },
                    },
                  },
                  [_vm._v(" Cancel ")]
                ),
                _c("v-btn", { attrs: { color: "primary", type: "submit" } }, [
                  _vm._v(" Save "),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }