<template>
  <span>
    <span class="symbol" v-if="value === 'EQUAL'"> = </span>
    <span class="symbol" v-else-if="value === 'GREATER_THAN'"> &#62; </span>
    <span class="symbol" v-else-if="value === 'GREATER_THAN_OR_EQUAL'">
      ≥
    </span>
    <span class="symbol" v-else-if="value === 'LESS_THAN'"> &#60; </span>
    <span class="symbol" v-else-if="value === 'LESS_THAN_OR_EQUAL'"> ≤ </span>
    <span class="symbol" v-else-if="value === 'NOT_EQUAL'"> ≠ </span>
  </span>
</template>

<script>
export default {
  name: "MatchIcon",
  props: {
    value: String,
  },
};
</script>

<style scoped>
.symbol {
  font-size: 20px;
}
</style>
