<template>
  <v-card class="pa-0 my-0">
    <v-card-text class="pa-0 my-0">
      <v-list class="pa-0 my-0">
        <v-list-item
          v-for="l of LOGIC_CHOICES"
          :key="l.value"
          @click="$emit('logic-choice-selected', l.value)"
          class="pa-0 my-0"
        >
          <v-list-item-content class="body-1 px-4 my-0">
            {{ l.label }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { LOGIC } from "@/constants/logicChoices";

const LOGIC_CHOICES = [
  { label: "If", value: LOGIC.IF },
  { label: "Else-If", value: LOGIC.ELSE_IF },
  { label: "Else", value: LOGIC.ELSE },
  { label: "End-If", value: LOGIC.END_IF },
];

export default {
  name: "LogicMenu",
  data() {
    return {
      LOGIC_CHOICES,
    };
  },
};
</script>
