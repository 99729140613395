var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    _vm._l(_vm.values, function (v, index) {
      return _c(
        "v-col",
        { key: index, staticClass: "py-0 mt-0 mb-4", attrs: { cols: "12" } },
        [
          ["TEXT", "EMAIL"].includes(_vm.item.question.type)
            ? [
                _c("validation-provider", {
                  attrs: {
                    slim: "",
                    name: _vm.item.question.label,
                    rules: {
                      required: _vm.isRequired,
                      email: _vm.item.question.type === "EMAIL",
                      max: Boolean(_vm.item.question.max_length)
                        ? _vm.item.question.max_length
                        : Infinity,
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors, valid, validated }) {
                          return [
                            _vm.item.question.isLong
                              ? [
                                  _vm.isRichText
                                    ? _c("QuillEditor", {
                                        attrs: {
                                          label: `${_vm.item.number} ${_vm.item.question.label}`,
                                          placeholder:
                                            _vm.item.question.placeholder,
                                          validated: validated,
                                          valid: valid,
                                          errors: errors,
                                          multipleItems: _vm.values.length > 1,
                                          allowMultiple: _vm.item.allowMultiple,
                                          disabled: _vm.readOnly,
                                          maxChars:
                                            +_vm.item.question.max_length,
                                        },
                                        on: {
                                          input: _vm.onInput,
                                          "remove-entry": _vm.removeEntry,
                                          "add-entry": _vm.addEntry,
                                        },
                                        model: {
                                          value: v.text,
                                          callback: function ($$v) {
                                            _vm.$set(v, "text", $$v)
                                          },
                                          expression: "v.text",
                                        },
                                      })
                                    : _c(
                                        "section",
                                        [
                                          _c("v-textarea", {
                                            attrs: {
                                              label: `${_vm.item.number} ${_vm.item.question.label}`,
                                              validated: validated,
                                              valid: valid,
                                              errors: errors,
                                              disabled: _vm.readOnly,
                                              filled: "",
                                              "hide-details": "",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "12px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.item.question.placeholder
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "section",
                                            {
                                              staticClass:
                                                "d-flex align-center",
                                            },
                                            [
                                              _vm.item.allowMultiple
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "cursor-pointer",
                                                      attrs: { color: "red" },
                                                      on: {
                                                        click: _vm.addEntry,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(_vm.mdiPlus) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.values.length > 1
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "#093637",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeEntry(
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(_vm.mdiMinus) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                ]
                              : [
                                  _vm.item.question.forInformationOnly
                                    ? [
                                        _c(
                                          "label",
                                          { staticClass: "caption" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                `${_vm.item.number} ${_vm.item.question.label}`
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.item.question.default.value
                                            )
                                          ),
                                        ]),
                                      ]
                                    : [
                                        _vm.readOnly
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "mb-n4",
                                                staticStyle: { width: "100%" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      color:
                                                        "rgba(0, 0, 0, 0.38)",
                                                      "font-size": "16px",
                                                      "border-bottom":
                                                        "1px rgba(0, 0, 0, 0.38) dashed",
                                                      "padding-bottom": "4px",
                                                      width: "100%",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.item.number
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.item.question
                                                            .label
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      color:
                                                        "rgba(0, 0, 0, 0.38)",
                                                      "font-size": "12px",
                                                      "padding-top": "4px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.item.question
                                                            .placeholder
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "v-text-field",
                                              {
                                                key: index,
                                                attrs: {
                                                  label: `${_vm.item.number} ${_vm.item.question.label}`,
                                                  "hide-details": "auto",
                                                  "error-messages": errors,
                                                  success: valid,
                                                  color: "primary",
                                                  name: _vm.item.id,
                                                  hint: _vm.item.question
                                                    .placeholder,
                                                  "persistent-hint": "",
                                                },
                                                on: { input: _vm.onInput },
                                                model: {
                                                  value: v.text,
                                                  callback: function ($$v) {
                                                    _vm.$set(v, "text", $$v)
                                                  },
                                                  expression: "v.text",
                                                },
                                              },
                                              [
                                                _vm.item.allowMultiple
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          slot: "append",
                                                          color: "red",
                                                        },
                                                        on: {
                                                          click: _vm.addEntry,
                                                        },
                                                        slot: "append",
                                                      },
                                                      [_vm._v(" mdi-plus ")]
                                                    )
                                                  : _vm._e(),
                                                _vm.values.length > 1
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          slot: "append",
                                                          color: "#093637",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeEntry(
                                                              index
                                                            )
                                                          },
                                                        },
                                                        slot: "append",
                                                      },
                                                      [_vm._v(" mdi-minus ")]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                      ],
                                ],
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ]
            : _vm._e(),
          ["NUMBER"].includes(_vm.item.question.type)
            ? [
                _c("validation-provider", {
                  attrs: {
                    slim: "",
                    name: _vm.item.question.label,
                    rules: {
                      required: _vm.isRequired,
                      max: Boolean(_vm.item.question.max_length)
                        ? _vm.item.question.max_length
                        : Infinity,
                      regex: /^\d+(\.\d+)?$/,
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors, valid }) {
                          return [
                            _vm.readOnly
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "mb-n4",
                                    staticStyle: { width: "100%" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          color: "rgba(0, 0, 0, 0.38)",
                                          "font-size": "16px",
                                          "border-bottom":
                                            "1px rgba(0, 0, 0, 0.38) dashed",
                                          "padding-bottom": "4px",
                                          width: "100%",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.item.number) +
                                            " " +
                                            _vm._s(_vm.item.question.label) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          color: "rgba(0, 0, 0, 0.38)",
                                          "font-size": "12px",
                                          "padding-top": "4px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.item.question.placeholder
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _c(
                                  "v-text-field",
                                  {
                                    key: index,
                                    attrs: {
                                      label: `${_vm.item.number} ${_vm.item.question.label}`,
                                      "hide-details": "auto",
                                      "error-messages": errors,
                                      success: valid,
                                      color: "primary",
                                      name: _vm.item.id,
                                      hint: _vm.item.question.placeholder,
                                      "persistent-hint": "",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.onNumberInput(valid)
                                      },
                                    },
                                    model: {
                                      value: v.text,
                                      callback: function ($$v) {
                                        _vm.$set(v, "text", $$v)
                                      },
                                      expression: "v.text",
                                    },
                                  },
                                  [
                                    _vm.item.allowMultiple
                                      ? _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              slot: "append",
                                              color: "red",
                                            },
                                            on: { click: _vm.addEntry },
                                            slot: "append",
                                          },
                                          [_vm._v(" mdi-plus ")]
                                        )
                                      : _vm._e(),
                                    _vm.values.length > 1
                                      ? _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              slot: "append",
                                              color: "#093637",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeEntry(index)
                                              },
                                            },
                                            slot: "append",
                                          },
                                          [_vm._v(" mdi-minus ")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ]
            : _vm._e(),
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }