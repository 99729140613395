<template>
  <v-card class="m-2">
    <validation-observer ref="createCityworksActivity" v-slot="{ invalid }">
      <form @submit.prevent="createActivity">
        <v-toolbar dark color="primary" class="elevation-0">
          <v-toolbar-title>New Cityworks Activity</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$emit('create-cityworks-activity-close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text
          class="pl-8 pr-8 pb-0"
          :style="{
            'background-color': '#f1f2f1',
            height: $vuetify.breakpoint.xsOnly ? `${contentHeight}px` : '70vh',
            'overflow-y': 'auto',
            'max-height': $vuetify.breakpoint.xsOnly ? undefined : '60vh',
          }"
        >
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" md="3">
                  <validation-provider
                    v-slot="{ errors, valid }"
                    name="Create New Activity"
                  >
                    <v-select
                      autocomplete="off"
                      class="py-0 my-0"
                      label="Create New Activity"
                      hide-details="auto"
                      :error-messages="errors"
                      :success="valid"
                      color="primary"
                      name="createNewActivity"
                      :items="activityOptions"
                      item-text="label"
                      item-value="value"
                      v-model="createCondition"
                      :disabled="!canManageForms"
                    >
                    </v-select>
                  </validation-provider>
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                  v-if="createCondition === 'CONDITIONAL'"
                >
                  <validation-provider
                    v-slot="{ errors, valid }"
                    name="Form Field"
                    rules="required"
                  >
                    <v-select
                      autocomplete="off"
                      class="py-0 my-0"
                      label="Form Field"
                      hide-details="auto"
                      :error-messages="errors"
                      :success="valid"
                      color="primary"
                      name="formField"
                      :items="questionChoices"
                      item-text="label"
                      item-value="value"
                      v-model="questionID"
                      :disabled="!canManageForms"
                    >
                    </v-select>
                  </validation-provider>
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                  v-if="createCondition === 'CONDITIONAL'"
                >
                  <validation-provider
                    v-slot="{ errors, valid }"
                    name="Condition"
                    rules="required"
                  >
                    <v-select
                      autocomplete="off"
                      class="py-0 my-0"
                      label="Condition"
                      hide-details="auto"
                      :error-messages="errors"
                      :success="valid"
                      color="primary"
                      name="questionCondition"
                      :items="questionConditionOptions"
                      item-text="label"
                      item-value="value"
                      v-model="conditionOperator"
                      :disabled="!canManageForms"
                    >
                    </v-select>
                  </validation-provider>
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                  v-if="createCondition === 'CONDITIONAL'"
                >
                  <validation-provider
                    v-slot="{ errors, valid }"
                    name="Value"
                    :rules="{
                      required: true,
                      numeric: questionType === 'NUMBER',
                    }"
                  >
                    <v-text-field
                      v-if="questionType !== 'SINGLE_SELECT'"
                      class="py-0 my-0"
                      label="Value"
                      :error-messages="errors"
                      :success="valid"
                      :type="questionType === 'NUMBER' ? 'number' : ''"
                      color="primary"
                      name="value"
                      item-text="label"
                      item-value="value"
                      v-model="conditionValue"
                      :disabled="!canManageForms"
                    >
                    </v-text-field>
                    <v-select
                      autocomplete="off"
                      v-else
                      class="py-0 my-0"
                      label="Value"
                      :error-messages="errors"
                      :success="valid"
                      color="primary"
                      name="value"
                      item-text="label"
                      item-value="value"
                      v-model="conditionValue"
                      :items="singleSelectOptions"
                      :disabled="!canManageForms"
                    >
                    </v-select>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              :class="createCondition === 'CONDITIONAL' ? 'mt-n4' : ''"
            >
              <validation-provider
                v-slot="{ errors, valid }"
                name="Activity Type"
                rules="required"
              >
                <v-select
                  autocomplete="off"
                  label="Activity Type"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="activityType"
                  :items="activityTypes"
                  item-text="label"
                  item-value="value"
                  v-model="cwType"
                  :disabled="!canManageForms"
                >
                </v-select>
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Domain"
                rules="required"
              >
                <v-select
                  autocomplete="off"
                  label="Domain"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="domain"
                  :items="domainChoices"
                  item-text="label"
                  item-value="value"
                  v-model="cwDomain"
                  @change="onChange('domain')"
                  :disabled="!canManageForms"
                >
                </v-select>
              </validation-provider>
            </v-col>

            <v-col cols="12">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Entity Group"
                rules="required"
              >
                <v-select
                  autocomplete="off"
                  label="Entity Group"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="entityGroup"
                  :items="entityGroupChoices"
                  item-text="label"
                  item-value="value"
                  v-model="cwEntityGroup"
                  @change="onChange('entity_group')"
                  :disabled="!canManageForms"
                >
                </v-select>
              </validation-provider>
            </v-col>

            <v-col cols="12">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Entity Type"
                rules="required"
              >
                <v-select
                  autocomplete="off"
                  label="Entity Type"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="entityType"
                  :items="entityTypeChoices"
                  item-text="label"
                  item-value="value"
                  v-model="cwEntityType"
                  @change="onChange('entity_type')"
                  :disabled="!canManageForms"
                >
                </v-select>
              </validation-provider>
            </v-col>

            <v-col cols="12">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Template"
                rules="required"
              >
                <v-select
                  autocomplete="off"
                  label="Template"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="templateId"
                  :items="templateChoices"
                  item-text="label"
                  item-value="value"
                  v-model="cwTemplate"
                  :disabled="!canManageForms"
                >
                </v-select>
              </validation-provider>
              <section></section>
            </v-col>
            <v-col cols="12" v-if="cwType === 'workorder'">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Work Order Instructions"
              >
                <v-text-field
                  label="Instructions"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="value"
                  v-model="cwInstructions"
                  :disabled="!canManageForms"
                >
                </v-text-field>
              </validation-provider>
            </v-col>
            <div
              class="pl-3"
              v-if="
                formDefinition.form.city_works_webhook_config.type ===
                  'Inspection' && cwType === 'workorder'
              "
            >
              <v-switch
                v-model="attachCurrentActivity"
                label="Attach Current Activity"
                :disabled="!canManageForms"
              ></v-switch>
            </div>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end py-3 px-7" ref="cardAction">
          <v-btn
            color="primary"
            type="submit"
            :disabled="invalid || !canManageForms"
          >
            Save Cityworks Activity
          </v-btn>
        </v-card-actions>
      </form>
    </validation-observer>
  </v-card>
</template>

<script>
import axios from "axios";
import { cloneDeep } from "lodash";
import questionsMixin from "@/mixins/questionsMixin";
import permissionsMixin from "@/mixins/permissionsMixin";

const APIURL = process.env.VUE_APP_API_URL;
const activityTypes = [
  { value: "Inspection", label: "Inspection" },
  { value: "workorder", label: "Work Order" },
];
const activityOptions = [
  { value: "ALWAYS", label: "Always" },
  { value: "CONDITIONAL", label: "Conditional" },
];

const textConditionOptions = [
  { value: "EQUAL", label: "Equal" },
  { value: "NOT_EQUAL", label: "Not Equal" },
];

const numberConditionOptions = [
  { value: "GREATER_THAN", label: "Greater Than" },
  { value: "LESS_THAN", label: "Less Than" },
  { value: "GREATER_THAN_OR_EQUAL", label: "Greater Than or Equal" },
  { value: "LESS_THAN_OR_EQUAL", label: "Less Than or Equal" },
];

export default {
  name: "NewCityworksActivity",
  props: {
    value: Object,
    itemToEdit: Object,
    itemIndex: Number,
  },
  data() {
    return {
      formDefinition: {},
      activityTypes,
      domains: [],
      entityGroups: [],
      entityTypes: [],
      templates: [],
      createCondition: "ALWAYS",
      questionID: null,
      conditionOperator: "EQUAL",
      conditionValue: null,
      cwDomain: undefined,
      cwType: undefined,
      cwEntityGroup: undefined,
      cwEntityType: undefined,
      cwTemplate: undefined,
      cwInstructions: undefined,
      attachCurrentActivity: undefined,
      activityOptions,
    };
  },
  mixins: [questionsMixin, permissionsMixin],
  computed: {
    domainChoices() {
      return this.domains.map((d) => {
        const { DomainId, Description } = d;
        return {
          value: DomainId,
          label: Description,
        };
      });
    },

    entityGroupChoices() {
      return this.entityGroups.map((d) => {
        const { Module, ModuleName } = d;
        return {
          value: Module,
          label: ModuleName,
        };
      });
    },

    entityTypeChoices() {
      return this.entityTypes.map((d) => {
        const { Code, Description } = d;
        return {
          value: Code,
          label: Description,
        };
      });
    },

    templateChoices() {
      if (this.cwType === "Inspection") {
        return this.templates.map((d) => {
          const { InspTemplateId, InspTemplateName } = d;
          return {
            value: InspTemplateId,
            label: InspTemplateName,
          };
        });
      } else if (this.cwType === "workorder") {
        return this.templates.map((d) => {
          const { WOTemplateId, Description } = d;
          return {
            value: WOTemplateId,
            label: Description,
          };
        });
      } else {
        return [];
      }
    },
    questionChoices() {
      const { questions } = this;
      return questions
        .filter(
          (question) =>
            question.question.type === "NUMBER" ||
            question.question.type === "EMAIL" ||
            question.question.type === "TEXT" ||
            question.question.type === "SINGLE_SELECT"
        )
        .map(({ id, question: { label } = {} }) => ({
          value: id,
          label: `${id}. ${label}`,
        }));
    },
    questionConditionOptions() {
      const { questions } = this;
      const question = questions.find(({ id }) => +id === +this.questionID);
      if (!question) {
        return [];
      }
      if (question.question && question.question.type === "NUMBER") {
        return [...textConditionOptions, ...numberConditionOptions];
      }
      return [...textConditionOptions];
    },
    questionType() {
      const { questions } = this;
      const question = questions.find(({ id }) => +id === +this.questionID);
      if (question && question.question) {
        return question.question.type;
      } else {
        return "";
      }
    },
    singleSelectOptions() {
      const { questions } = this;
      const question = questions.find(({ id }) => +id === +this.questionID);
      if (question && question.question) {
        return question.question.options;
      } else {
        return [];
      }
    },
  },
  methods: {
    createActivity() {
      const updatedActivity = {
        condition: this.createCondition,
        questionId: this.questionID,
        question_condition: this.conditionOperator,
        value: this.conditionValue,
        type: this.cwType,
        domain: this.cwDomain,
        entity_group: this.cwEntityGroup,
        entity_type: this.cwEntityType,
        template_id: this.cwTemplate,
        work_order_instructions: this.cwInstructions,
        attach_current_activity: this.attachCurrentActivity,
        finish_date: null,
      };
      if (this.itemIndex !== undefined) {
        this.formDefinition.form.city_works_webhook_config.on_form_submission.create_new_activities[
          this.itemIndex
        ] = updatedActivity;
      } else {
        this.formDefinition.form.city_works_webhook_config.on_form_submission.create_new_activities.push(
          updatedActivity
        );
      }
      this.$emit("input", this.formDefinition);
      this.$emit("create-cityworks-activity-close");
    },
    async getDomains() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/cityworks/domains`);
      this.domains = results;
    },
    async getEntityGroups() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/cityworks/entity_groups`, {
        params: {
          domain_id: this.cwDomain,
        },
      });
      this.entityGroups = results;
    },
    async getEntityTypes() {
      if (!this.cwEntityGroup) {
        return;
      }
      const {
        data: { results },
      } = await axios.get(`${APIURL}/cityworks/entity_types`, {
        params: {
          entity_group: this.cwEntityGroup,
        },
      });
      this.entityTypes = results;
    },
    async getInspectionTemplates() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/cityworks/inspection_templates`, {
        params: {
          entity_type: this.cwEntityType,
        },
      });
      this.templates = results;
    },
    async getWorkorderTemplates() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/cityworks/work_order_templates`, {
        params: {
          entity_type: this.cwEntityType,
        },
      });
      this.templates = results;
    },
    onChange(key) {
      if (key === "domain") {
        this.cwEntityGroup = undefined;
        this.cwEntityType = undefined;
        this.cwTemplate = undefined;
      } else if (key === "entity_group") {
        this.cwEntityType = undefined;
        this.cwTemplate = undefined;
      } else if (key === "entity_type") {
        this.cwTemplate = undefined;
      }
    },
  },
  beforeMount() {
    this.getDomains();
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.formDefinition = cloneDeep(val);
      },
    },
    cwDomain: {
      immediate: true,
      handler() {
        if (this.cwDomain) {
          this.getEntityGroups();
        }
      },
    },
    cwEntityGroup: {
      immediate: true,
      handler() {
        this.getEntityTypes();
      },
    },
    cwEntityType: {
      immediate: true,
      handler() {
        if (this.cwType === "Inspection") {
          this.getInspectionTemplates();
        } else if (this.cwType === "workorder") {
          this.getWorkorderTemplates();
        }
      },
    },
    itemToEdit: {
      immediate: true,
      handler() {
        if (this.itemToEdit.condition) {
          this.createCondition = this.itemToEdit.condition;
        }
        if (this.createCondition === "CONDITIONAL") {
          this.questionID = this.itemToEdit.questionId;
          this.conditionOperator = this.itemToEdit.question_condition;
          this.conditionValue = this.itemToEdit.value;
        }
        this.cwType = this.itemToEdit.type;
        this.cwDomain = this.itemToEdit.domain;
        this.cwEntityGroup = this.itemToEdit.entity_group;
        this.cwEntityType = this.itemToEdit.entity_type;
        this.cwTemplate = this.itemToEdit.template_id;
        this.cwInstructions = this.itemToEdit.work_order_instructions;
        this.attachCurrentActivity = this.itemToEdit.attach_current_activity;
      },
    },
  },
};
</script>
