export const ADVANCED_SETTINGS_CHOICES = {
  DEFAULT: "default",
  CREATE: "create",
  REQUIRE: "require",
  VISIBILITY: "visibility",
};

export const CONDITION_CHOICES = {
  ALWAYS: "ALWAYS",
  CONDITIONAL: "CONDITIONAL",
  NEVER: "NEVER",
};

export const DEFAULT_CONDITION_CHOICES = [
  { value: CONDITION_CHOICES.ALWAYS, label: "Always" },
  { value: CONDITION_CHOICES.CONDITIONAL, label: "Conditional" },
];

export const VISIBILITY_CONDITION_CHOICES = [
  { value: CONDITION_CHOICES.ALWAYS, label: "Always" },
  { value: CONDITION_CHOICES.NEVER, label: "Never" },
  { value: CONDITION_CHOICES.CONDITIONAL, label: "Conditional" },
];

export const APPLY_IF = {
  ANY: "ANY",
  ALL: "ALL",
};

export const APPLY_IF_CHOICES = [
  { value: APPLY_IF.ANY, label: "ANY" },
  { value: APPLY_IF.ALL, label: "ALL" },
];

export const DEPENDANT_OPTIONS = {
  LOCATE_DATA: "LOCATE_DATA",
  USER: "USER",
  GIS_DATA: "GIS_DATA",
  ORGANIZATION: "ORGANIZATION",
  FORM_ITEM: "FORM_ITEM",
  GIS: "GIS",
  CURRENT: "CURRENT",
  CUSTOM: "CUSTOM",
};
