var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      style: {
        width: `${_vm.contentWidth}px`,
        height: `${_vm.contentHeight}px`,
      },
    },
    [
      _c("HtmlEditor", {
        attrs: { name: "templateHtml", height: `${_vm.contentHeight}px` },
        on: { input: _vm.submit },
        model: {
          value: _vm.selectedHtml,
          callback: function ($$v) {
            _vm.selectedHtml = $$v
          },
          expression: "selectedHtml",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }