<template>
  <v-list class="pa-0 ma-0">
    <v-list-item class="d-flex flex-wrap pa-0 ma-0">
      <v-list-item-content class="pa-1 ma-0" style="width: 110px">
        <img
          class="icon pa-0 mx-0 my-1"
          v-for="style of borderStyles"
          :key="style"
          @click="onBorderClick(style)"
          :src="require(`@/assets/img/borders/border-${style}.svg`)"
        />
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import BORDER_STYLE from "@/constants/borderStyle";

const borderStyles = [...Object.values(BORDER_STYLE)];

export default {
  name: "TableBorderMenu",
  data() {
    return {
      BORDER_STYLE,
      borderStyles,
    };
  },
  methods: {
    onBorderClick(borderStyle) {
      this.$emit("border-style-selected", borderStyle);
    },
  },
};
</script>

<style scoped>
.icon {
  flex-basis: 25px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.icon:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
</style>
