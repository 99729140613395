var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "validation-observer",
    { ref: "selectForm" },
    [
      _c(
        "v-toolbar",
        {
          ref: "toolbar",
          staticClass: "elevation-0",
          attrs: { color: "primary", height: "56px" },
        },
        [
          _c(
            "v-list-item",
            { staticClass: "px-0", attrs: { "two-line": "" } },
            [
              _c("v-list-item-content", [
                _c(
                  "div",
                  { staticClass: "d-flex justify-space-between" },
                  [
                    _c(
                      "v-list-item-title",
                      { staticClass: "white--text text-h6" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("itemTitle")(
                                _vm.selectedControlType,
                                _vm.selectedItem
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", color: "white" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("form-control-form-submitted")
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("mdi-close")])],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.onSubmit()
              _vm.$emit("form-control-form-submitted")
            },
          },
        },
        [
          _c(
            "v-list",
            {
              staticClass: "overflow-y-auto",
              staticStyle: { "max-height": "calc(100vh - 56px)" },
              attrs: { dense: "" },
            },
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("validation-provider", {
                        attrs: { name: "Label", rules: "required" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-text-field", {
                                  ref: "labelInput",
                                  attrs: {
                                    label: "Label",
                                    "hide-details": "auto",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "name",
                                    disabled: !_vm.canManageForms,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onSubmit()
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.selectedItemOptions.question.label,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectedItemOptions.question,
                                        "label",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "selectedItemOptions.question.label",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "mt-0 mb-0 pt-0" },
                    [
                      _c("validation-provider", {
                        attrs: { name: "Hint" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "Hint",
                                    "hide-details": "auto",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "placeholder",
                                    disabled: !_vm.canManageForms,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onSubmit()
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.selectedItemOptions.question
                                        .placeholder,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectedItemOptions.question,
                                        "placeholder",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "selectedItemOptions.question.placeholder",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "d-flex justify-space-between align-center px-4",
                },
                [
                  _c("div", { staticClass: "caption" }, [_vm._v("Choices")]),
                  _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "px-2",
                          attrs: {
                            text: "",
                            color: "primary",
                            disabled: !_vm.canManageForms,
                          },
                          on: {
                            click: function ($event) {
                              _vm.showAddChoiceDialog = true
                            },
                          },
                        },
                        [_vm._v(" + Add Choice ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "ml-n2" },
                    [
                      _c(
                        "draggable",
                        {
                          staticClass: "pa-0 ma-0",
                          attrs: {
                            list: _vm.selectedItemOptions.question.options,
                            disabled: !_vm.canManageForms,
                          },
                          on: {
                            end: function ($event) {
                              return _vm.onSubmit()
                            },
                          },
                        },
                        _vm._l(
                          _vm.selectedItemOptions.question.options,
                          function (option, index) {
                            return _c(
                              "div",
                              {
                                key: option.id,
                                staticClass:
                                  "pa-0 d-flex align-center justify-space-between mt-n8 mb-n3",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex cursor-pointer" },
                                  [
                                    _vm.canManageForms
                                      ? _c(
                                          "div",
                                          { staticClass: "mt-6" },
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                _vm._s(_vm.mdiDragVertical)
                                              ),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "ml-n2" },
                                      [
                                        _c(
                                          "v-list",
                                          {
                                            attrs: {
                                              dense: "",
                                              "two-line": "",
                                              "max-width": "110",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-list-item",
                                              { staticClass: "mr-n8" },
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c("v-list-item-subtitle", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(option.value) +
                                                          " "
                                                      ),
                                                    ]),
                                                    _c("v-list-item-title", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(option.label) +
                                                          " "
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "pl-0 pr-0 mr-0",
                                        attrs: {
                                          text: "",
                                          "x-small": "",
                                          disabled: !_vm.canManageForms,
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.showEditChoiceDialog = true
                                            _vm.selectedOption = option
                                            _vm.selectedOptionIndex = index
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "primary" } },
                                          [_vm._v(_vm._s(_vm.mdiPencil))]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "pl-0 pr-0 mr-0",
                                        attrs: {
                                          text: "",
                                          "x-small": "",
                                          disabled: !_vm.canManageForms,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeChoice(index)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "primary" } },
                                          [_vm._v(_vm._s(_vm.mdiDelete))]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                      _c(
                        "v-dialog",
                        {
                          attrs: {
                            "max-width": "600px",
                            persistent: "",
                            fullscreen: _vm.$vuetify.breakpoint.xsOnly,
                          },
                          model: {
                            value: _vm.showEditChoiceDialog,
                            callback: function ($$v) {
                              _vm.showEditChoiceDialog = $$v
                            },
                            expression: "showEditChoiceDialog",
                          },
                        },
                        [
                          _vm.showEditChoiceDialog
                            ? _c("ChoiceForm", {
                                attrs: {
                                  option: _vm.selectedOption,
                                  index: _vm.selectedOptionIndex,
                                  selectedItemOptions: _vm.selectedItemOptions,
                                  formDefinition: _vm.formDefinition,
                                  selectedItem: _vm.selectedItem,
                                },
                                on: {
                                  "choice-submitted": _vm.onChoiceSubmitted,
                                  "choice-form-close": function ($event) {
                                    _vm.showEditChoiceDialog = false
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-dialog",
                        {
                          attrs: {
                            "max-width": "600px",
                            fullscreen: _vm.$vuetify.breakpoint.xsOnly,
                          },
                          model: {
                            value: _vm.showAddChoiceDialog,
                            callback: function ($$v) {
                              _vm.showAddChoiceDialog = $$v
                            },
                            expression: "showAddChoiceDialog",
                          },
                        },
                        [
                          _vm.showAddChoiceDialog
                            ? _c("ChoiceForm", {
                                attrs: {
                                  selectedItemOptions: _vm.selectedItemOptions,
                                  formDefinition: _vm.formDefinition,
                                  selectedItem: _vm.selectedItem,
                                },
                                on: {
                                  "choice-submitted": _vm.onChoiceSubmitted,
                                  "choice-form-close": function ($event) {
                                    _vm.showAddChoiceDialog = false
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "my-0 py-0" },
                    [
                      _c("v-switch", {
                        staticClass: "my-0 py-0",
                        attrs: {
                          label: "Choices are Images",
                          disabled: !_vm.canManageForms,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onSubmit()
                          },
                        },
                        model: {
                          value: _vm.selectedItemOptions.question.isImage,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.selectedItemOptions.question,
                              "isImage",
                              $$v
                            )
                          },
                          expression: "selectedItemOptions.question.isImage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.selectedItemOptions.question.isImage
                ? _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "my-0 py-0" },
                        [
                          _c("v-switch", {
                            staticClass: "my-0 py-0",
                            attrs: {
                              label: "Include 'Other' Option",
                              disabled: !_vm.canManageForms,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.onSubmit()
                              },
                            },
                            model: {
                              value: _vm.selectedItemOptions.question.hasOther,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.selectedItemOptions.question,
                                  "hasOther",
                                  $$v
                                )
                              },
                              expression:
                                "selectedItemOptions.question.hasOther",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.selectedItemOptions.question.isImage
                ? _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "my-0 py-0" },
                        [
                          _c("v-switch", {
                            staticClass: "my-0 py-0",
                            attrs: {
                              label: "Show All Choices at Once",
                              disabled: !_vm.canManageForms,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.onSubmit()
                              },
                            },
                            model: {
                              value:
                                _vm.selectedItemOptions.question.showAllOptions,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.selectedItemOptions.question,
                                  "showAllOptions",
                                  $$v
                                )
                              },
                              expression:
                                "selectedItemOptions.question.showAllOptions",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-list-item",
                { staticClass: "my-0 py-0" },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("validation-provider", {
                        attrs: { name: "Visible" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-select", {
                                  attrs: {
                                    autocomplete: "off",
                                    items: _vm.createOptions,
                                    label: "Create",
                                    "error-messages": errors,
                                    success: valid,
                                    "item-text": "label",
                                    "item-value": "value",
                                    "hide-details": "",
                                    disabled: !_vm.canManageForms,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onSubmit()
                                    },
                                  },
                                  model: {
                                    value: _vm.createCondition,
                                    callback: function ($$v) {
                                      _vm.createCondition = $$v
                                    },
                                    expression: "createCondition",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      ["conditional"].includes(_vm.createCondition)
                        ? _c("CreateComparisonConditionForm", {
                            attrs: {
                              formDefinition: _vm.formDefinition,
                              selectedItem: _vm.selectedItem,
                              disabled: !_vm.canManageForms,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.onSubmit()
                              },
                            },
                            model: {
                              value: _vm.selectedItemOptions.question.create,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.selectedItemOptions.question,
                                  "create",
                                  $$v
                                )
                              },
                              expression: "selectedItemOptions.question.create",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                { staticClass: "my-0 py-0" },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("validation-provider", {
                        attrs: { name: "Required", rules: "required" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-select", {
                                  attrs: {
                                    autocomplete: "off",
                                    items: _vm.requiredOptions,
                                    label: "Required",
                                    "error-messages": errors,
                                    success: valid,
                                    "item-text": "label",
                                    "item-value": "value",
                                    "hide-details": "",
                                    disabled: !_vm.canManageForms,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onSubmit()
                                    },
                                  },
                                  model: {
                                    value: _vm.requiredCondition,
                                    callback: function ($$v) {
                                      _vm.requiredCondition = $$v
                                    },
                                    expression: "requiredCondition",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      ["conditional"].includes(_vm.requiredCondition)
                        ? _c("ComparisonConditionForm", {
                            attrs: {
                              formDefinition: _vm.formDefinition,
                              selectedItem: _vm.selectedItem,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.onSubmit()
                              },
                            },
                            model: {
                              value: _vm.selectedItemOptions.question.required,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.selectedItemOptions.question,
                                  "required",
                                  $$v
                                )
                              },
                              expression:
                                "selectedItemOptions.question.required",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              ["F", "R"].includes(_vm.layerType)
                ? _c(
                    "v-list-item",
                    { staticClass: "my-0 py-0" },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("validation-provider", {
                            attrs: { name: "Update ArcGIS Field" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors, valid }) {
                                    return [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Update ArcGIS Field",
                                          "hide-details": "auto",
                                          "error-messages": errors,
                                          success: valid,
                                          color: "primary",
                                          name: "updateEsriObject",
                                          disabled: !_vm.canManageForms,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.onSubmit()
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.selectedItemOptions.question
                                              .updateEsriObject,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.selectedItemOptions.question,
                                              "updateEsriObject",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selectedItemOptions.question.updateEsriObject",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              426153213
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("AdvancedSettingsForm", {
                        attrs: {
                          formDefinition: _vm.formDefinition,
                          layer: _vm.layer,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.onSubmit()
                          },
                        },
                        model: {
                          value: _vm.selectedItemOptions,
                          callback: function ($$v) {
                            _vm.selectedItemOptions = $$v
                          },
                          expression: "selectedItemOptions",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c("v-list-item-content", [
                    _vm.hasAdvancedSettings
                      ? _c("div", { staticClass: "d-flex flex-wrap gap" }, [
                          _vm.hasDefaultSettings
                            ? _c(
                                "section",
                                {
                                  staticClass:
                                    "text-uppercase d-flex primary--text gap align-center",
                                  on: { click: _vm.onDefaultSettingClick },
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "Default " + _vm._s(_vm.defaultSetting)
                                    ),
                                  ]),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        disabled: !_vm.canManageForms,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "primary" } },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.mdiPencil) + " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.hasVisibleSettings
                            ? _c(
                                "section",
                                {
                                  staticClass:
                                    "text-uppercase d-flex primary--text gap align-center",
                                  on: { click: _vm.onVisibleSettingClick },
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "Visible " + _vm._s(_vm.visibleSetting)
                                    ),
                                  ]),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        disabled: !_vm.canManageForms,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "primary" } },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.mdiPencil) + " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ])
                      : _c(
                          "div",
                          { staticClass: "d-flex align-center justify-center" },
                          [_vm._v(" No advanced settings ")]
                        ),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "my-0 py-0" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            type: "submit",
                            width: "100%",
                            disabled: !_vm.canManageForms,
                          },
                        },
                        [_vm._v(" Update Item ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showAdvancedDefaultSettingsDialog
            ? _c("AdvancedDefaultSettingsDialog", {
                attrs: {
                  showAdvancedDefaultSettingsDialog:
                    _vm.showAdvancedDefaultSettingsDialog,
                  formDefinition: _vm.formDefinition,
                  layer: _vm.layer,
                },
                on: {
                  "advanced-settings-dialog-close": function ($event) {
                    _vm.showAdvancedDefaultSettingsDialog = false
                  },
                  input: function ($event) {
                    return _vm.onSubmit()
                  },
                },
                model: {
                  value: _vm.selectedItemOptions,
                  callback: function ($$v) {
                    _vm.selectedItemOptions = $$v
                  },
                  expression: "selectedItemOptions",
                },
              })
            : _vm._e(),
          _vm.showAdvancedVisibilitySettingsDialog
            ? _c("AdvancedVisibilitySettingsDialog", {
                attrs: {
                  showAdvancedVisibilitySettingsDialog:
                    _vm.showAdvancedVisibilitySettingsDialog,
                  formDefinition: _vm.formDefinition,
                  layer: _vm.layer,
                  selectedItem: _vm.selectedItem,
                },
                on: {
                  "advanced-settings-dialog-close": function ($event) {
                    _vm.showAdvancedVisibilitySettingsDialog = false
                  },
                  input: function ($event) {
                    return _vm.onSubmit()
                  },
                },
                model: {
                  value: _vm.selectedItemOptions,
                  callback: function ($$v) {
                    _vm.selectedItemOptions = $$v
                  },
                  expression: "selectedItemOptions",
                },
              })
            : _vm._e(),
          _c("AdvancedSettingsWarningDialog", {
            attrs: {
              showAdvancedSettingsWarningDialog:
                _vm.showAdvancedSettingsWarningDialog,
            },
            on: {
              cancel: function ($event) {
                _vm.showAdvancedSettingsWarningDialog = false
              },
              continue: _vm.openAdvancedSettingDialog,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }