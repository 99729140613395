var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex justify-space-between align-center" },
        [
          _c("div", { staticClass: "caption" }, [_vm._v(_vm._s(_vm.label))]),
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "primary", disabled: _vm.readOnly },
              on: {
                click: function ($event) {
                  _vm.showAddImageDialog = true
                },
              },
            },
            [_vm._v(" Add ")]
          ),
        ],
        1
      ),
      _c("v-simple-table", [
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v("Image")]),
            _c("th", [_vm._v("Description")]),
            _c("th"),
          ]),
        ]),
        _vm.base64Images.length > 0
          ? _c(
              "tbody",
              _vm._l(_vm.base64Images, function (image, index) {
                return _c("tr", { key: image.id }, [
                  _c("td", [
                    _c("img", {
                      staticClass: "thumbnail",
                      attrs: { src: image.url },
                    }),
                  ]),
                  _c(
                    "td",
                    [
                      _c("v-text-field", {
                        attrs: { label: "Description", color: "primary" },
                        on: {
                          change: function ($event) {
                            return _vm.$emit("input", _vm.base64Images)
                          },
                        },
                        model: {
                          value: image.description,
                          callback: function ($$v) {
                            _vm.$set(image, "description", $$v)
                          },
                          expression: "image.description",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("td", [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-end align-center" },
                      [
                        _c(
                          "v-menu",
                          {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            { attrs: { icon: "" } },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v(_vm._s(_vm.mdiDotsVertical)),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c(
                              "v-list",
                              [
                                _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.removePhoto(index, image.id)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-title",
                                      [
                                        _c("v-icon", [
                                          _vm._v(_vm._s(_vm.mdiDelete)),
                                        ]),
                                        _vm._v(" Delete "),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ])
              }),
              0
            )
          : _c("tbody", [
              _c("tr", [
                _c("td", { attrs: { colspan: "3" } }, [
                  _c("div", { staticClass: "d-flex justify-center" }, [
                    _vm._v("No Images"),
                  ]),
                ]),
              ]),
            ]),
      ]),
      _c("AddImageDialog", {
        attrs: {
          showAddImageDialog: _vm.showAddImageDialog,
          maxWidthHeight: _vm.maxWidthHeight,
          readOnly: _vm.readOnly,
          allowMultiple: _vm.allowMultiple,
        },
        on: {
          "add-photo-dialog-close": function ($event) {
            _vm.showAddImageDialog = false
            _vm.$emit("input", _vm.base64Images)
          },
        },
        model: {
          value: _vm.base64Images,
          callback: function ($$v) {
            _vm.base64Images = $$v
          },
          expression: "base64Images",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }