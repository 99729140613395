var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "elevation-0 mx-auto overflow-y-auto",
      attrs: { width: "calc(100vw - 56px)", height: "calc(100vh - 56px)" },
    },
    [
      _c("v-card-text", { staticClass: "elevation-0" }, [
        _c(
          "div",
          { staticStyle: { "max-width": "600px", margin: "0 auto" } },
          [
            _c(
              "v-row",
              [
                _vm._l(
                  _vm.formDefinition.form.emailSettings,
                  function (emailSetting, index) {
                    return _c(
                      "v-col",
                      { key: emailSetting.id, attrs: { cols: "12" } },
                      [
                        _c(
                          "v-card",
                          { staticClass: "card-background elevation-0" },
                          [
                            _c(
                              "v-toolbar",
                              {
                                staticClass: "elevation-0",
                                attrs: { color: "#e5e5e5" },
                              },
                              [
                                _c("v-toolbar-title", [_vm._v(" Email ")]),
                                _c("v-spacer"),
                                _c(
                                  "v-menu",
                                  {
                                    attrs: { "offset-y": "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    { attrs: { icon: "" } },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-dots-vertical"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c(
                                      "v-list",
                                      [
                                        _c(
                                          "v-list-item",
                                          { staticClass: "pl-0 pr-0" },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  text: "",
                                                  disabled: !_vm.canManageForms,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    _vm.showConfirmDeleteDialog = true
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { staticClass: "mr-2" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.mdiDelete) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" Delete "),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "ConfirmEmailSettingDeleteDialog",
                                              {
                                                attrs: {
                                                  showConfirmDialog:
                                                    _vm.showConfirmDeleteDialog,
                                                },
                                                on: {
                                                  "confirm-delete": function (
                                                    $event
                                                  ) {
                                                    _vm.removeEmailSetting(
                                                      index
                                                    )
                                                    _vm.showConfirmDeleteDialog = false
                                                  },
                                                  "cancel-delete": function (
                                                    $event
                                                  ) {
                                                    _vm.showConfirmDeleteDialog = false
                                                  },
                                                },
                                              }
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("v-card-text", [
                              _c(
                                "form",
                                [
                                  _c("EmailFromToForm", {
                                    attrs: { index: index },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$emit(
                                          "input",
                                          _vm.formDefinition
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.formDefinition,
                                      callback: function ($$v) {
                                        _vm.formDefinition = $$v
                                      },
                                      expression: "formDefinition",
                                    },
                                  }),
                                  _c(
                                    "v-card",
                                    { staticClass: "mt-3 mb-3" },
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "Subject",
                                                      rules: "required",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function ({
                                                            errors,
                                                            valid,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "Subject *",
                                                                    "hide-details":
                                                                      "auto",
                                                                    "error-messages":
                                                                      errors,
                                                                    success:
                                                                      valid,
                                                                    color:
                                                                      "primary",
                                                                    name: "value",
                                                                    disabled:
                                                                      !_vm.canManageForms,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$emit(
                                                                          "input",
                                                                          _vm.formDefinition
                                                                        )
                                                                      },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .formDefinition
                                                                        .form
                                                                        .emailSettings[
                                                                        index
                                                                      ].subject,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .formDefinition
                                                                            .form
                                                                            .emailSettings[
                                                                            index
                                                                          ],
                                                                          "subject",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "\n                              formDefinition.form.emailSettings[index].subject\n                            ",
                                                                  },
                                                                }
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "Attachment Name",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function ({
                                                            errors,
                                                            valid,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "Attachment Name *",
                                                                    "hide-details":
                                                                      "auto",
                                                                    "error-messages":
                                                                      errors,
                                                                    success:
                                                                      valid,
                                                                    color:
                                                                      "primary",
                                                                    name: "value",
                                                                    disabled:
                                                                      !_vm.canManageForms,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$emit(
                                                                          "input",
                                                                          _vm.formDefinition
                                                                        )
                                                                      },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .formDefinition
                                                                        .form
                                                                        .emailSettings[
                                                                        index
                                                                      ]
                                                                        .attachmentName,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .formDefinition
                                                                            .form
                                                                            .emailSettings[
                                                                            index
                                                                          ],
                                                                          "attachmentName",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "\n                              formDefinition.form.emailSettings[index]\n                                .attachmentName\n                            ",
                                                                  },
                                                                }
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card",
                                    { staticClass: "mt-3 mb-3" },
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "3",
                                                  },
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "Send Email",
                                                      rules: "required",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function ({
                                                            errors,
                                                            valid,
                                                          }) {
                                                            return [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  autocomplete:
                                                                    "off",
                                                                  label:
                                                                    "Send Email *",
                                                                  "hide-details":
                                                                    "auto",
                                                                  "error-messages":
                                                                    errors,
                                                                  success:
                                                                    valid,
                                                                  color:
                                                                    "primary",
                                                                  name: "sendEmail",
                                                                  items:
                                                                    _vm.sendEmailOptions,
                                                                  "item-text":
                                                                    "label",
                                                                  "item-value":
                                                                    "value",
                                                                  disabled:
                                                                    !_vm.canManageForms,
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onSendEmailInput(
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .sendEmailSettings[
                                                                      index
                                                                    ],
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.sendEmailSettings,
                                                                        index,
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "sendEmailSettings[index]",
                                                                },
                                                              }),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                              !["ALWAYS"].includes(
                                                _vm.sendEmailSettings[index]
                                              )
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "3",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "validation-provider",
                                                        {
                                                          attrs: {
                                                            name: "Form Question",
                                                            rules: "required",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function ({
                                                                  errors,
                                                                  valid,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-select",
                                                                      {
                                                                        attrs: {
                                                                          autocomplete:
                                                                            "off",
                                                                          label:
                                                                            "Form Question *",
                                                                          "hide-details":
                                                                            "auto",
                                                                          "error-messages":
                                                                            errors,
                                                                          success:
                                                                            valid,
                                                                          color:
                                                                            "primary",
                                                                          name: "dependantId",
                                                                          items:
                                                                            _vm.questionChoices,
                                                                          "item-text":
                                                                            "label",
                                                                          "item-value":
                                                                            "value",
                                                                          disabled:
                                                                            !_vm.canManageForms,
                                                                        },
                                                                        on: {
                                                                          input:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$emit(
                                                                                "input",
                                                                                _vm.formDefinition
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .formDefinition
                                                                              .form
                                                                              .emailSettings[
                                                                              index
                                                                            ]
                                                                              .condition
                                                                              .dependantId,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .formDefinition
                                                                                  .form
                                                                                  .emailSettings[
                                                                                  index
                                                                                ]
                                                                                  .condition,
                                                                                "dependantId",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "\n                              formDefinition.form.emailSettings[index]\n                                .condition.dependantId\n                            ",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              !["ALWAYS"].includes(
                                                _vm.sendEmailSettings[index]
                                              )
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "3",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "validation-provider",
                                                        {
                                                          attrs: {
                                                            name: "Comparison",
                                                            rules: "required",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function ({
                                                                  errors,
                                                                  valid,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-select",
                                                                      {
                                                                        attrs: {
                                                                          autocomplete:
                                                                            "off",
                                                                          label:
                                                                            "Comparison *",
                                                                          "hide-details":
                                                                            "auto",
                                                                          "error-messages":
                                                                            errors,
                                                                          success:
                                                                            valid,
                                                                          color:
                                                                            "primary",
                                                                          name: "dependantId",
                                                                          items:
                                                                            _vm.comparisonTypes,
                                                                          "item-text":
                                                                            "label",
                                                                          "item-value":
                                                                            "value",
                                                                          disabled:
                                                                            !_vm.canManageForms,
                                                                        },
                                                                        on: {
                                                                          input:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$emit(
                                                                                "input",
                                                                                _vm.formDefinition
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .formDefinition
                                                                              .form
                                                                              .emailSettings[
                                                                              index
                                                                            ]
                                                                              .condition
                                                                              .condition,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .formDefinition
                                                                                  .form
                                                                                  .emailSettings[
                                                                                  index
                                                                                ]
                                                                                  .condition,
                                                                                "condition",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "\n                              formDefinition.form.emailSettings[index]\n                                .condition.condition\n                            ",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              !["ALWAYS"].includes(
                                                _vm.sendEmailSettings[index]
                                              )
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "3",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "validation-provider",
                                                        {
                                                          attrs: {
                                                            name: "Value",
                                                            rules: {
                                                              required: true,
                                                              numeric:
                                                                _vm.isNumericOnly(
                                                                  index
                                                                ),
                                                            },
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function ({
                                                                  errors,
                                                                  valid,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-text-field",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "Value *",
                                                                          "hide-details":
                                                                            "auto",
                                                                          "error-messages":
                                                                            errors,
                                                                          success:
                                                                            valid,
                                                                          color:
                                                                            "primary",
                                                                          name: "value",
                                                                          disabled:
                                                                            !_vm.canManageForms,
                                                                        },
                                                                        on: {
                                                                          input:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$emit(
                                                                                "input",
                                                                                _vm.formDefinition
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .formDefinition
                                                                              .form
                                                                              .emailSettings[
                                                                              index
                                                                            ]
                                                                              .condition
                                                                              .value,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .formDefinition
                                                                                  .form
                                                                                  .emailSettings[
                                                                                  index
                                                                                ]
                                                                                  .condition,
                                                                                "value",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "\n                              formDefinition.form.emailSettings[index]\n                                .condition.value\n                            ",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card",
                                    { staticClass: "mt-3 mb-3" },
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: "Rich Text",
                                              disabled: !_vm.canManageForms,
                                            },
                                            model: {
                                              value:
                                                _vm.formDefinition.form
                                                  .emailSettings[index]
                                                  .bodyRichText,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formDefinition.form
                                                    .emailSettings[index],
                                                  "bodyRichText",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                        formDefinition.form.emailSettings[index].bodyRichText\n                      ",
                                            },
                                          }),
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "Body",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({
                                                    errors,
                                                    valid,
                                                  }) {
                                                    return [
                                                      _vm.formDefinition.form
                                                        .emailSettings[index]
                                                        .bodyRichText
                                                        ? _c("QuillEditor", {
                                                            attrs: {
                                                              errors: errors,
                                                              valid: valid,
                                                              disabled:
                                                                !_vm.canManageForms,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                return _vm.$emit(
                                                                  "input",
                                                                  _vm.formDefinition
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .formDefinition
                                                                  .form
                                                                  .emailSettings[
                                                                  index
                                                                ].body,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .formDefinition
                                                                      .form
                                                                      .emailSettings[
                                                                      index
                                                                    ],
                                                                    "body",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "\n                          formDefinition.form.emailSettings[index].body\n                        ",
                                                            },
                                                          })
                                                        : _c("v-textarea", {
                                                            attrs: {
                                                              errors: errors,
                                                              valid: valid,
                                                              label: "Body",
                                                              disabled:
                                                                !_vm.canManageForms,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                return _vm.$emit(
                                                                  "input",
                                                                  _vm.formDefinition
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .formDefinition
                                                                  .form
                                                                  .emailSettings[
                                                                  index
                                                                ].body,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .formDefinition
                                                                      .form
                                                                      .emailSettings[
                                                                      index
                                                                    ],
                                                                    "body",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "\n                          formDefinition.form.emailSettings[index].body\n                        ",
                                                            },
                                                          }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "primary",
                          disabled: !_vm.canManageForms,
                        },
                        on: { click: _vm.addEmailSetting },
                      },
                      [_vm._v(" + Email ")]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }