<template>
  <v-card class="pa-0 ma-0" elevation="0">
    <v-card-text class="pa-0 ma-0">
      {{ label }}
      <section
        class="pa-0 ma-0 overflow-y-auto"
        :style="{ border: getBorder() }"
      >
        <div ref="codeEditor"></div>
      </section>
      <div v-if="Array.isArray(errorMessages)">
        {{ errorMessages.join(". ") }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { EditorView, basicSetup } from "codemirror";
import { html } from "@codemirror/lang-html";
import { Compartment } from "@codemirror/state";
import { lintGutter } from "@codemirror/lint";
import sleep from "@/mixins/sleep";

const pretty = require("pretty");

export default {
  name: "Htmleditor",
  props: {
    value: String,
    height: { type: String, default: "50vh" },
    label: String,
    errorMessages: Array,
    success: Boolean,
    outlined: Boolean,
  },
  data() {
    return {
      editor: undefined,
    };
  },
  async mounted() {
    await sleep(2000);
    await this.$nextTick();
    const { value, height } = this;
    const language = new Compartment();
    this.editor = new EditorView({
      extensions: [
        EditorView.lineWrapping,
        basicSetup,
        language.of(html()),
        lintGutter(),
        EditorView.updateListener.of((v) => {
          if (v.docChanged) {
            this.$emit("input", this.editor.state.doc.toString());
          }
        }),
      ],
      mode: "text/html",
      extraKeys: { "Ctrl-Space": "autocomplete" },
      parent: this.$refs.codeEditor,
      doc: pretty(value),
    });
    await this.$nextTick();
    if (this.$refs.codeEditor) {
      this.$refs.codeEditor.style.height = height;
    }
  },
  methods: {
    getBorder() {
      const { success, outlined } = this;
      if (outlined) {
        if (success) {
          return `1px solid #4caf50`;
        } else {
          return `1px solid #ff5252`;
        }
      }
      return "none";
    },
  },
};
</script>
