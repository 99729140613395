<template>
  <div>
    <v-card class="elevation-0" width="100%" :class="{ selected }">
      <v-card-text class="pa-0 ma-0 mb-2">
        <v-row>
          <template v-if="isImage">
            <v-col cols="12">
              <p class="caption">
                {{ item.question.label }}
              </p>
              <v-checkbox
                v-for="option in options"
                :key="option.id"
                :value="option.value"
                v-model="selectedValues"
                :disabled="readOnly"
              >
                <template v-slot:label>
                  <img :src="option.value" style="width: 64px" />
                </template>
              </v-checkbox>
            </v-col>
          </template>
          <template v-else>
            <template v-if="item.question.showAllOptions">
              <v-col cols="12">
                <p class="caption">
                  {{ item.question.label }}
                </p>
                <v-checkbox
                  v-for="option in options"
                  :key="option.id"
                  :value="option.value"
                  :label="option.label"
                  v-model="selectedValues"
                  :disabled="readOnly"
                >
                </v-checkbox>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="12">
                <validation-provider
                  slim
                  v-slot="{ errors, valid }"
                  :name="item.question.label"
                  :rules="{
                    required: isRequired,
                  }"
                >
                  <v-select
                    autocomplete="off"
                    :items="options"
                    item-text="label"
                    item-value="value"
                    :label="item.question.label"
                    v-model="selectedValues"
                    @change="onInputChange"
                    :error-messages="errors"
                    :success="valid"
                    multiple
                    :disabled="readOnly"
                  >
                  </v-select>
                </validation-provider>
              </v-col>
            </template>
          </template>

          <v-col cols="12" v-if="showTextInput">
            <validation-provider
              v-slot="{ errors, valid }"
              :name="item.question.label"
              rules="required"
            >
              <v-text-field
                v-model="otherValue"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                :name="item.id"
                @input="onInputChange"
                :disabled="readOnly"
              >
              </v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "GroupMultiSelectInput",
  props: {
    item: Object,
    formDefinition: Object,
    value: [Array, String],
    selected: Boolean,
    readOnly: Boolean,
  },
  computed: {
    isImage() {
      return this.item?.question?.isImage;
    },

    options() {
      if (this.item.question.isImage) {
        return [...this.item.question.options];
      } else {
        if (this.item.question.hasOther) {
          return [
            ...this.item.question.options,
            {
              value: "other",
              label: "Other",
            },
          ];
        } else {
          return [...this.item.question.options];
        }
      }
    },
    showTextInput() {
      return (
        Array.isArray(this.selectedValues) &&
        this.selectedValues.includes("other")
      );
    },
    isRequired() {
      const { sections = [] } = { ...this.formDefinition?.form };
      for (const section of sections) {
        for (const dependantItem of section.items) {
          if (+dependantItem.id === +this.item.question.required.dependantId) {
            const dependantItemValue = dependantItem.value;
            if (Array.isArray(dependantItemValue)) {
              if (dependantItemValue.length > 1) {
                return false;
              } else {
                const [dependantValue] = dependantItemValue;
                return this.checkDependantValue(dependantValue);
              }
            } else {
              return this.checkDependantValue(dependantItemValue);
            }
          }
        }
      }
      return this.item?.question?.required?.condition === "ALWAYS";
    },
  },
  data() {
    return {
      otherValue: undefined,
      selectedValues: [],
    };
  },
  methods: {
    onInputChange() {
      this.$emit(
        "input",
        this.selectedValues
          .filter((v) => Boolean(v))
          .map((v) => {
            if (v === "other") {
              return {
                key: this.otherValue ?? "",
                val: v,
              };
            }
            return {
              key: v,
              val: v,
            };
          })
      );
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        if (Array.isArray(val)) {
          const otherValue = val.find(({ val }) => val === "other");
          this.selectedValues = val.map(({ val }) => val);
          if (otherValue && otherValue.key) {
            this.otherValue = otherValue.key;
          }
        } else {
          if (typeof val === "string") {
            this.selectedValues = [
              {
                key: val,
                val: val,
              },
            ];
          }
        }
      },
    },
  },
};
</script>

<style scoped></style>
