<template>
  <v-dialog
    :value="showAdvancedDefaultSettingsDialog"
    max-width="600px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
  >
    <v-toolbar dark color="primary">
      <v-toolbar-title>
        <div><b>Default</b></div>
        <div class="caption">Advanced Settings</div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="$emit('advanced-settings-dialog-close')">
        <v-icon>
          {{ mdiClose }}
        </v-icon>
      </v-btn>
    </v-toolbar>
    <validation-observer slim ref="advancedSettingsForm" v-slot="{ invalid }">
      <v-card class="rounded-0">
        <v-card-text class="py-3">
          <div
            class="gap align-center"
            :class="{ 'd-flex': $vuetify.breakpoint.smAndUp }"
          >
            <validation-provider
              class="select"
              v-slot="{ errors, valid }"
              name="Default Value"
            >
              <v-select
                v-model="selectedItemOptions.question.default.type"
                :items="defaultValueOptions"
                label="Default Value"
                :error-messages="errors"
                :success="valid"
                item-text="label"
                item-value="value"
                hide-details
                class="defaultValue"
                @change="onDefaultValueChange"
              ></v-select>
            </validation-provider>

            <template v-if="showDefaultValueDropDown">
              <validation-provider
                slim
                v-if="
                  ['CUSTOM'].includes(selectedItemOptions.question.default.type)
                "
                v-slot="{ errors, valid }"
                name="Custom Value"
                :rules="{
                  required: ![FIELD_TYPES.GROUP].includes(
                    selectedItemOptions.question.type
                  ),
                  email: [FIELD_TYPES.EMAIL].includes(
                    selectedItemOptions.question.type
                  ),
                }"
              >
                <v-select
                  v-if="
                    [
                      FIELD_TYPES.SINGLE_SELECT,
                      FIELD_TYPES.MULTI_SELECT,
                    ].includes(selectedItemOptions.question.type)
                  "
                  :items="selectedItemOptions.question.options"
                  item-text="label"
                  item-value="value"
                  label="Custom Value"
                  v-model="selectedItemOptions.question.default.value"
                  :error-messages="errors"
                  :success="valid"
                  :multiple="
                    FIELD_TYPES.MULTI_SELECT ===
                    selectedItemOptions.question.type
                  "
                  hide-details
                >
                </v-select>
                <v-btn
                  text
                  color="primary"
                  v-else-if="
                    [FIELD_TYPES.GROUP].includes(
                      selectedItemOptions.question.type
                    )
                  "
                  @click="showRepeatingGroupDefaultValuesDialog = true"
                  style="font-size: 16px"
                >
                  {{ hasDefaultValues ? "Update" : "Add" }} Values
                </v-btn>
                <v-text-field
                  v-else
                  v-model="selectedItemOptions.question.default.value"
                  label="Custom Value"
                  :error-messages="errors"
                  :success="valid"
                  hide-details
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors, valid }"
                name="Add Minutes"
                rules="min_value:0"
                v-else-if="
                  ['CURRENT'].includes(
                    selectedItemOptions.question.default.type
                  ) && FIELD_TYPES.TIME === selectedItemOptions.question.type
                "
              >
                <v-text-field
                  v-model="selectedItemOptions.question.default.value"
                  label="Add Minutes"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="addMinutes"
                  @change="onSubmit()"
                />
              </validation-provider>

              <validation-provider
                v-else
                v-slot="{ errors, valid }"
                :name="fieldPlaceholderText"
                rules="required"
                :key="fieldPlaceholderText"
              >
                <v-text-field
                  v-if="
                    selectedItemOptions.question.default.type === 'GIS' &&
                    mapService.token_type !== 'NONE' &&
                    !isEsriTokenPresent
                  "
                  v-model="selectedItemOptions.question.default.value"
                  :label="fieldPlaceholderText"
                  :error-messages="errors"
                  :success="valid"
                  item-text="label"
                  item-value="value"
                  hide-details
                >
                </v-text-field>
                <v-select
                  v-else
                  v-model="selectedItemOptions.question.default.value"
                  :items="allDefaultValueOptions"
                  :label="fieldPlaceholderText"
                  :error-messages="errors"
                  :success="valid"
                  item-text="label"
                  item-value="value"
                  hide-details
                  :menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true,
                  }"
                >
                  <template v-slot:prepend-item v-if="userDataSelected">
                    <v-list-item
                      @click.stop="
                        showNewUserDataInput = true;
                        idForEditing = undefined;
                      "
                      style="width: 400px"
                      :disabled="showNewUserDataInput"
                    >
                      <span
                        class="font-weight-bold"
                        :class="!showNewUserDataInput ? 'primary--text' : ''"
                      >
                        + USER DATA
                      </span>
                    </v-list-item>
                    <div
                      v-if="showNewUserDataInput"
                      style="background-color: rgb(240, 240, 240); width: 400px"
                      class="pb-2 pt-2 pl-2 pr-0"
                      @click.stop=""
                    >
                      <div
                        class="d-flex justify-space-between align-center"
                        style="width: 400px"
                      >
                        <div>
                          <v-text-field
                            v-model="newUserData.name"
                            label="New User Data"
                            hide-details="auto"
                            color="primary"
                            name="newUserData"
                            class="pt-4 px-2 pb-2"
                            style="background-color: white; width: 280px"
                            autofocus
                          >
                          </v-text-field>
                        </div>
                        <div class="d-flex">
                          <v-btn
                            icon
                            color="primary"
                            @click="createUserData"
                            :disabled="newUserData.name === ''"
                          >
                            <v-icon>{{ mdiCheck }}</v-icon>
                          </v-btn>
                          <v-btn
                            icon
                            class="mr-2"
                            color="primary"
                            @click.stop="
                              newUserData.name = '';
                              showNewUserDataInput = false;
                            "
                          >
                            <v-icon>{{ mdiDelete }}</v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:item="{ item }" v-if="userDataSelected">
                    <v-list-item
                      style="width: 400px"
                      @click="
                        selectedItemOptions.question.default.value = item.value;
                        showNewUserDataInput = false;
                        idForEditing = undefined;
                      "
                      :class="item.value === idForEditing ? 'px-0' : ''"
                    >
                      <div
                        class="d-flex justify-space-between align-center"
                        style="width: 400px"
                      >
                        <div
                          v-if="item.value === idForEditing"
                          class="d-flex justify-space-between align-center px-4 py-3"
                          style="
                            background-color: rgb(240, 240, 240);
                            width: 400px;
                          "
                          @click.stop=""
                        >
                          <div>
                            <v-text-field
                              v-model="item.label"
                              hide-details="auto"
                              label="Edit User Data"
                              color="primary"
                              name="fieldBeingEdited"
                              class="pt-4 px-2 pb-2"
                              style="background-color: white; width: 280px"
                              autofocus
                            >
                            </v-text-field>
                          </div>
                          <div>
                            <v-btn
                              icon
                              color="primary"
                              @click="updateUserData(item.value, item.label)"
                              :disabled="item.label === ''"
                            >
                              <v-icon>{{ mdiCheck }}</v-icon>
                            </v-btn>
                          </div>
                        </div>
                        <div v-else>
                          {{ item.label }}
                        </div>
                        <div
                          v-if="
                            item.value !== 'f_name' &&
                            item.value !== 'l_name' &&
                            item.value !== 'full_name' &&
                            item.value !== 'email' &&
                            item.value !== idForEditing
                          "
                        >
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>
                                  {{ mdiDotsVertical }}
                                </v-icon>
                              </v-btn>
                            </template>
                            <v-list class="pa-0 ma-0">
                              <v-list-item
                                class="pa-0 ma-0"
                                @click="
                                  idForEditing = item.value;
                                  showNewUserDataInput = false;
                                "
                              >
                                <v-btn text color="primary" class="menu-button">
                                  <v-icon color="primary" class="mr-1">{{
                                    mdiPencil
                                  }}</v-icon>
                                  Edit
                                </v-btn>
                              </v-list-item>
                              <v-list-item
                                class="pa-0 ma-0"
                                @click="
                                  idForDeletion = item.value;
                                  showDeleteConfirmation = true;
                                "
                              >
                                <v-btn text color="primary" class="menu-button">
                                  <v-icon color="primary" class="mr-1">{{
                                    mdiDelete
                                  }}</v-icon>
                                  Delete
                                </v-btn>
                              </v-list-item>
                              <!-- deleteUserData(item.value) -->
                            </v-list>
                          </v-menu>
                        </div>
                      </div>
                    </v-list-item>
                  </template>
                </v-select>
              </validation-provider>
            </template>

            <v-dialog
              v-model="showDeleteConfirmation"
              max-width="600px"
              :fullscreen="$vuetify.breakpoint.xsOnly"
              style="z-index: 9999"
            >
              <v-card>
                <v-list-item>
                  <v-list-item-content class="pl-3">
                    <div class="text-h5 py-3">Delete User Data Field?</div>
                    Are you sure you want to delete this field? This action
                    cannot be undone.
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <div class="d-flex justify-end">
                      <v-btn
                        text
                        @click="
                          showDeleteConfirmation = false;
                          idForDeletion = undefined;
                        "
                        color="primary"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="primary"
                        @click="
                          deleteUserData(idForDeletion);
                          showDeleteConfirmation = false;
                        "
                      >
                        Delete Field
                      </v-btn>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-dialog>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Condition"
              rules="required"
              class="select"
              v-if="showDefaultConditionDropDown"
            >
              <v-select
                v-model="selectedItemOptions.question.default.applyDefault"
                :items="DEFAULT_CONDITION_CHOICES"
                name="condition"
                label="Condition"
                :error-messages="errors"
                :success="valid"
                item-text="label"
                item-value="value"
                @change="onConditionChange"
                hide-details
              >
              </v-select>
            </validation-provider>
          </div>

          <section
            :key="selectedItemOptions.question.default.applyDefault"
            v-if="
              selectedItemOptions.question.default.applyDefault ===
              CONDITION_CHOICES.CONDITIONAL
            "
          >
            <p class="caption py-0 my-0">Conditional Settings</p>

            <div
              class="d-flex align-end gap py-0 my-0"
              v-if="
                selectedItemOptions.question.default.applyDefaultConditions
                  .conditions.length > 1
              "
            >
              <div>
                <b> Apply if </b>
              </div>

              <validation-provider
                v-slot="{ errors, valid }"
                rules="required"
                class="select"
              >
                <v-select
                  height="20px"
                  v-model="
                    selectedItemOptions.question.default.applyDefaultConditions
                      .applyIf
                  "
                  :items="APPLY_IF_CHOICES"
                  name="applyIf"
                  :error-messages="errors"
                  :success="valid"
                  item-text="label"
                  item-value="value"
                  hide-details
                >
                </v-select>
              </validation-provider>

              <div>
                <b> of the conditions are matched </b>
              </div>
            </div>

            <template v-if="showConditions">
              <div
                class="align-center justify-space-between"
                :class="{ 'd-flex': $vuetify.breakpoint.smAndUp }"
                v-for="(condition, index) of selectedItemOptions.question
                  .default.applyDefaultConditions.conditions"
                :key="condition.id"
              >
                <div
                  class="align-center gap"
                  :class="{ 'd-flex': $vuetify.breakpoint.smAndUp }"
                >
                  <validation-provider
                    v-slot="{ errors, valid }"
                    name="Dependant"
                    rules="required"
                    class="select"
                  >
                    <v-select
                      v-model="condition.type"
                      :items="dependantOptions"
                      label="Dependant"
                      :error-messages="errors"
                      :success="valid"
                      item-text="label"
                      item-value="value"
                      @change="onDependantChange()"
                      hide-details
                    >
                    </v-select>
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors, valid }"
                    name="Field"
                    rules="required"
                    :key="condition.type"
                    v-if="condition.type"
                    style="width: 120px"
                  >
                    <v-select
                      :key="condition.type"
                      v-model="condition.field"
                      :items="getDependantValueOptions(condition)"
                      label="Field"
                      :error-messages="errors"
                      :success="valid"
                      item-text="label"
                      item-value="value"
                      hide-details
                    ></v-select>
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors, valid }"
                    name="Condition"
                    rules="required"
                    class="select"
                    v-if="condition.type"
                  >
                    <v-select
                      v-model="condition.condition"
                      :items="comparisonTypes"
                      name="Condition"
                      :error-messages="errors"
                      :success="valid"
                      item-text="label"
                      item-value="value"
                      @change="onSubmit()"
                      hide-details
                    >
                      <template #item="{ item: { label, value } }">
                        <div>
                          <MatchIcon :value="value" />
                          {{ label }}
                        </div>
                      </template>

                      <template #selection="{ item: { value } }">
                        <div>
                          <MatchIcon :value="value" />
                        </div>
                      </template>
                    </v-select>
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors, valid }"
                    name="Value"
                    rules="required"
                    v-if="condition.type"
                    style="min-width: 170px"
                  >
                    <v-select
                      v-if="getGisDataFieldOptionChoices(condition).length > 0"
                      v-model="condition.value"
                      :items="getGisDataFieldOptionChoices(condition)"
                      label="Value"
                      :error-messages="errors"
                      :success="valid"
                      item-text="label"
                      item-value="value"
                      hide-details
                    ></v-select>
                    <v-text-field
                      v-else
                      v-model="condition.value"
                      label="Value"
                      hide-details
                      :error-messages="errors"
                      :success="valid"
                      color="primary"
                      name="value"
                    />
                  </validation-provider>
                </div>

                <v-btn
                  icon
                  color="primary"
                  @click="removeCondition(index)"
                  :class="{ 'ml-3': $vuetify.breakpoint.smAndUp }"
                >
                  <v-icon>
                    {{ mdiDelete }}
                  </v-icon>
                </v-btn>
              </div>
            </template>

            <div
              class="mx-0 px-0"
              v-if="
                selectedItemOptions.question.default.applyDefault ===
                CONDITION_CHOICES.CONDITIONAL
              "
            >
              <v-btn
                text
                color="primary"
                class="px-0 mx-0"
                @click="addCondition"
                :disabled="invalid"
              >
                <v-icon x-small>
                  {{ mdiPlus }}
                </v-icon>
                Add Condition
              </v-btn>
            </div>
          </section>

          <RepeatingGroupDefaultValuesDialog
            v-if="showRepeatingGroupDefaultValuesDialog"
            :showRepeatingGroupDefaultValuesDialog="
              showRepeatingGroupDefaultValuesDialog
            "
            @repeating-group-default-values-dialog-close="
              showRepeatingGroupDefaultValuesDialog = false
            "
            @addOrDeleteRows="setAddOrDeleteRowsSettings($event)"
            :selectedItemOptions="selectedItemOptions"
            :formDefinition="formDefinition"
            v-model="selectedItemOptions.question.default.value"
          />
        </v-card-text>

        <v-card-actions class="d-flex justify-end">
          <v-btn color="primary" :disabled="invalid" @click="onSubmit">
            Save Settings
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-dialog>
</template>

<script>
import {
  mdiClose,
  mdiDelete,
  mdiPlus,
  mdiCheck,
  mdiPencil,
  mdiDotsVertical,
} from "@mdi/js";
import axios from "axios";
import { cloneDeep } from "lodash";
import {
  comparisonTypes,
  defaultValueTypes,
  requiredOptions,
  visibilityOptions,
  orgDataOptions,
  locateRequestDataValues,
  displayInfoOnlyOptions,
  createOptions,
} from "@/constants/choices";
import { v4 as uuidv4 } from "uuid";
import MatchIcon from "@/components/shared/MatchIcon";
import {
  CONDITION_CHOICES,
  DEFAULT_CONDITION_CHOICES,
  APPLY_IF,
  APPLY_IF_CHOICES,
  DEPENDANT_OPTIONS,
} from "@/constants/advancedSettings";
import { FIELD_TYPES } from "@/constants/question";
import MATCH_CHOICE_VALUES from "@/constants/matchChoiceValues";
import RepeatingGroupDefaultValuesDialog from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/advanced-settings-form/advanced-default-settings-dialog/RepeatingGroupDefaultValuesDialog.vue";

const APIURL = process.env.VUE_APP_API_URL;
const MISS_DIG_EMAIL_LOCATE_REQUEST_PROVIDER_ID =
  "476a5275-d26d-47a7-a210-77b59740a073";

export default {
  name: "AdvancedDefaultSettingsDialog",
  props: {
    showAdvancedDefaultSettingsDialog: Boolean,
    formDefinition: Object,
    value: Object,
    layer: Object,
  },
  components: { MatchIcon, RepeatingGroupDefaultValuesDialog },
  computed: {
    isEsriTokenPresent() {
      return Boolean(localStorage.getItem("esri_token"));
    },
    userDataSelected() {
      return [DEPENDANT_OPTIONS.USER].includes(
        this.selectedItemOptions.question.default.type
      );
    },
    hasDefaultValues() {
      return (
        Array.isArray(this.selectedItemOptions?.question?.default?.value) &&
        this.selectedItemOptions?.question?.default?.value?.length > 0
      );
    },
    showDefaultValueDropDown() {
      return (
        this.selectedItemOptions.question.default.type &&
        (this.selectedItemOptions.question.default.type !==
          DEPENDANT_OPTIONS.CURRENT ||
          this.selectedItemOptions.question.type === FIELD_TYPES.TIME)
      );
    },
    showDefaultConditionDropDown() {
      return (
        this.selectedItemOptions.question.default.value ||
        (this.selectedItemOptions.question.default.type ===
          DEPENDANT_OPTIONS.CURRENT &&
          this.selectedItemOptions.question.type === FIELD_TYPES.DATE)
      );
    },
    fieldPlaceholderText() {
      if (
        [DEPENDANT_OPTIONS.LOCATE_DATA].includes(
          this.selectedItemOptions.question.default.type
        )
      ) {
        return "811 Ticket";
      } else if (
        [DEPENDANT_OPTIONS.USER].includes(
          this.selectedItemOptions.question.default.type
        )
      ) {
        return "User Field";
      } else if (
        [DEPENDANT_OPTIONS.GIS_DATA].includes(
          this.selectedItemOptions.question.default.type
        )
      ) {
        return "UtiliSync Field";
      } else if (
        [DEPENDANT_OPTIONS.ORGANIZATION].includes(
          this.selectedItemOptions.question.default.type
        )
      ) {
        return "Organization Data";
      } else if (
        [DEPENDANT_OPTIONS.GIS].includes(
          this.selectedItemOptions.question.default.type
        )
      ) {
        return "GIS Field";
      }
      return "Field";
    },
    allDefaultValueOptions() {
      if (
        [DEPENDANT_OPTIONS.LOCATE_DATA].includes(
          this.selectedItemOptions.question.default.type
        )
      ) {
        const locateRequestProviderAccount =
          this.locateRequestProviderAccounts.find((a) => {
            return (
              a.locate_request_provider_account_id ===
              this.mapService.locate_request_provider_account_id
            );
          });
        if (
          locateRequestProviderAccount?.locate_request_provider_id ===
          MISS_DIG_EMAIL_LOCATE_REQUEST_PROVIDER_ID
        ) {
          return this.locateRequestDataValues.filter(({ value }) => {
            return !["meet", "boring", "location"].includes(value);
          });
        }
        return this.locateRequestDataValues;
      } else if (
        [DEPENDANT_OPTIONS.USER].includes(
          this.selectedItemOptions.question.default.type
        )
      ) {
        return this.userDataChoices;
      } else if (
        [DEPENDANT_OPTIONS.GIS_DATA].includes(
          this.selectedItemOptions.question.default.type
        )
      ) {
        return this.gisDataChoices;
      } else if (
        [DEPENDANT_OPTIONS.ORGANIZATION].includes(
          this.selectedItemOptions.question.default.type
        )
      ) {
        return this.orgDataOptions;
      } else if (
        [DEPENDANT_OPTIONS.GIS].includes(
          this.selectedItemOptions.question.default.type
        )
      ) {
        return this.fieldChoices;
      }
      return [];
    },
    fieldChoices() {
      const fieldChoices =
        this.fields?.map(({ name, alias }) => ({
          value: name,
          label: alias || name,
        })) ?? [];
      return fieldChoices;
    },
    userDataChoices() {
      const apiChoices = this.userDataFields.map(
        ({ user_data_field_id: value, name: label }) => ({
          value,
          label,
        })
      );
      return [
        { value: "f_name", label: "First Name" },
        { value: "l_name", label: "Last Name" },
        { value: "full_name", label: "Full Name" },
        { value: "email", label: "Email" },
        ...apiChoices,
      ];
    },
    gisDataChoices() {
      return this.gisDataFields.map(
        ({ gis_data_field_id: value, name, alias }) => ({
          value,
          label: alias || name,
        })
      );
    },
    dependantOptions() {
      if (this.layer?.service_type === "L") {
        return [
          {
            value: DEPENDANT_OPTIONS.LOCATE_DATA,
            label: "811 Ticket",
          },
          {
            value: DEPENDANT_OPTIONS.GIS,
            label: "GIS Field",
          },
          {
            value: DEPENDANT_OPTIONS.GIS_DATA,
            label: "UtiliSync Field",
          },
        ];
      }
      return [
        {
          value: DEPENDANT_OPTIONS.GIS,
          label: "GIS Field",
        },
        {
          value: DEPENDANT_OPTIONS.GIS_DATA,
          label: "UtiliSync Field",
        },
      ];
    },
    defaultValueOptions() {
      const gisFieldArray =
        this.mapService.service_type === "F"
          ? [
              {
                value: DEPENDANT_OPTIONS.GIS,
                label: "GIS Field",
              },
            ]
          : [];
      if (this.selectedItemOptions.question.type === FIELD_TYPES.SIGNATURE) {
        return [
          {
            value: null,
            label: "None",
          },
          {
            value: DEPENDANT_OPTIONS.USER,
            label: "User Data",
          },
          {
            value: DEPENDANT_OPTIONS.GIS_DATA,
            label: "UtiliSync Field",
          },
        ];
      } else if (FIELD_TYPES.DATE === this.selectedItemOptions.question.type) {
        return [
          {
            value: null,
            label: "None",
          },
          {
            value: DEPENDANT_OPTIONS.CURRENT,
            label: "Current Date",
          },
          {
            value: DEPENDANT_OPTIONS.USER,
            label: "User Data",
          },
          {
            value: DEPENDANT_OPTIONS.CUSTOM,
            label: "Custom Value",
          },
          ...gisFieldArray,
          {
            value: DEPENDANT_OPTIONS.GIS_DATA,
            label: "UtiliSync Field",
          },
        ];
      } else if (FIELD_TYPES.TIME === this.selectedItemOptions.question.type) {
        return [
          {
            value: null,
            label: "None",
          },
          {
            value: DEPENDANT_OPTIONS.CURRENT,
            label: "Current Time",
          },
          {
            value: DEPENDANT_OPTIONS.USER,
            label: "User Data",
          },
          {
            value: DEPENDANT_OPTIONS.CUSTOM,
            label: "Custom Value",
          },
          ...gisFieldArray,
          {
            value: DEPENDANT_OPTIONS.GIS_DATA,
            label: "UtiliSync Field",
          },
        ];
      } else if (FIELD_TYPES.GROUP === this.selectedItemOptions.question.type) {
        const hasDynamicInfoImage =
          this.selectedItemOptions.question?.groupedItems?.some((g) => {
            return g?.information?.dynamic;
          });
        if (hasDynamicInfoImage) {
          return [
            {
              value: null,
              label: "None",
            },
            {
              value: DEPENDANT_OPTIONS.CUSTOM,
              label: "Custom Value",
            },
          ];
        }
        return [
          {
            value: null,
            label: "None",
          },
          {
            value: DEPENDANT_OPTIONS.USER,
            label: "User Data",
          },
          {
            value: DEPENDANT_OPTIONS.CUSTOM,
            label: "Custom Value",
          },
          ...gisFieldArray,
          {
            value: DEPENDANT_OPTIONS.GIS_DATA,
            label: "UtiliSync Field",
          },
        ];
      }

      if (
        this.selectedItemOptions.question.isLong ||
        this.selectedItemOptions.question.type === "EMAIL"
      ) {
        const defaultValueTypes = this.defaultValueTypes.filter(
          ({ label }) => label !== "Organization Data"
        );
        if (this.layer?.service_type === "L") {
          defaultValueTypes.push({
            value: DEPENDANT_OPTIONS.LOCATE_DATA,
            label: "811 Ticket",
          });
        }
        return defaultValueTypes;
      }

      const defaultValueTypes = [...this.defaultValueTypes];

      if (this.layer?.service_type === "L") {
        defaultValueTypes.push({
          value: DEPENDANT_OPTIONS.LOCATE_DATA,
          label: "811 Ticket",
        });
      }
      return defaultValueTypes;
    },
  },
  data() {
    return {
      mdiClose,
      mdiDelete,
      mdiPlus,
      mdiCheck,
      mdiPencil,
      mdiDotsVertical,
      fields: [],
      gisDataFields: [],
      userDataFields: [],
      selectedItemOptions: {},
      comparisonTypes,
      defaultValueTypes,
      requiredOptions,
      visibilityOptions,
      orgDataOptions,
      locateRequestDataValues,
      displayInfoOnlyOptions,
      createOptions,
      CONDITION_CHOICES,
      DEFAULT_CONDITION_CHOICES,
      showConditions: false,
      APPLY_IF_CHOICES,
      FIELD_TYPES,
      showRepeatingGroupDefaultValuesDialog: false,
      mapService: {},
      newUserData: { name: "" },
      showNewUserDataInput: false,
      idForEditing: undefined,
      idForDeletion: undefined,
      showDeleteConfirmation: false,
      locateRequestProviderAccounts: [],
    };
  },
  beforeMount() {
    this.getUserDataFields();
    this.getSelectedItemOptions();
    this.getGisDataFields();
    this.getFeatureService();
    this.getMapService();
    this.getLocateRequestProviderAccounts();
    this.showConditions = true;
  },
  methods: {
    async getLocateRequestProviderAccounts() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/locate_request_provider_accounts`);
      this.locateRequestProviderAccounts = results;
    },
    async createUserData() {
      this.userDataFields.push(this.newUserData);

      await axios.put(`${APIURL}/user_data_fields`, {
        user_data_fields: this.userDataFields,
      });
      this.showNewUserDataInput = false;
      this.newUserData.name = "";
      await this.getUserDataFields();
    },
    async updateUserData(id, name) {
      this.userDataFields.find((item) => item.user_data_field_id === id).name =
        name;
      await axios.put(`${APIURL}/user_data_fields`, {
        user_data_fields: this.userDataFields,
      });
      this.idForEditing = undefined;
      await this.getUserDataFields();
    },
    async deleteUserData(id) {
      this.userDataFields = this.userDataFields.filter(
        (item) => item.user_data_field_id !== id
      );

      await axios.put(`${APIURL}/user_data_fields`, {
        user_data_fields: this.userDataFields,
      });

      await this.getUserDataFields();
    },
    setAddOrDeleteRowsSettings(addOrDeleteRowSetting) {
      this.selectedItemOptions.question.addOrDeleteRows = addOrDeleteRowSetting;
    },
    async getMapService() {
      const { map_service_id: mapServiceId } = this.formDefinition;
      const {
        data: { results },
      } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
      this.mapService = results;
    },
    onDefaultValueChange() {
      const { type } = this.selectedItemOptions.question.default;
      if (type !== null) {
        this.selectedItemOptions.question.default = {
          type,
          value: null,
          applyDefault: CONDITION_CHOICES.ALWAYS,
          applyDefaultConditions: { applyIf: APPLY_IF.ANY, conditions: [] },
        };
      }
    },
    onConditionChange() {
      if (!this.selectedItemOptions.question.default.applyDefault) {
        this.selectedItemOptions.question.default.applyDefault =
          CONDITION_CHOICES.ALWAYS;
      }

      if (
        this.selectedItemOptions.question.default.applyDefault ===
        CONDITION_CHOICES.ALWAYS
      ) {
        this.selectedItemOptions.question.default.applyDefaultConditions.conditions =
          [];
      } else if (
        this.selectedItemOptions.question.default.applyDefault ===
        CONDITION_CHOICES.CONDITIONAL
      ) {
        if (
          this.selectedItemOptions.question.default.applyDefaultConditions
            .conditions.length === 0
        ) {
          const condition = {
            id: uuidv4(),
            type: null,
            field: null,
            value: null,
            condition: MATCH_CHOICE_VALUES.EQUAL,
          };
          this.selectedItemOptions.question.default.applyDefaultConditions.conditions.push(
            condition
          );
        }
      }
    },
    getGisDataFieldOptionChoices(condition) {
      if (condition.type === DEPENDANT_OPTIONS.GIS_DATA) {
        return (
          this.gisDataFields
            ?.find((g) => g.gis_data_field_id === this.createOptions.field)
            ?.gis_data_field_options?.map(({ label, value }) => {
              return {
                label,
                value,
              };
            }) ?? []
        );
      }
      return [];
    },
    getDependantValueOptions(condition) {
      if ([DEPENDANT_OPTIONS.LOCATE_DATA].includes(condition.type)) {
        return this.locateRequestDataValues;
      } else if ([DEPENDANT_OPTIONS.USER].includes(condition.type)) {
        return this.userDataChoices;
      } else if ([DEPENDANT_OPTIONS.GIS_DATA].includes(condition.type)) {
        return this.gisDataChoices;
      } else if ([DEPENDANT_OPTIONS.ORGANIZATION].includes(condition.type)) {
        return this.orgDataOptions;
      } else if ([DEPENDANT_OPTIONS.GIS].includes(condition.type)) {
        return this.fieldChoices;
      }
      return [];
    },
    async getGisDataFields() {
      if (this.formDefinition?.map_service_id) {
        const {
          data: { results },
        } = await axios.get(`${APIURL}/gis_data_fields`, {
          params: { map_service_id: this.formDefinition.map_service_id },
        });
        this.gisDataFields = results;
      }
    },
    async getUserDataFields() {
      const { user_group_id: userGroupId } = JSON.parse(
        localStorage.getItem("auth")
      );

      const {
        data: { results },
      } = await axios.get(`${APIURL}/user_data_fields`, {
        params: {
          user_group_id: userGroupId,
        },
      });
      this.userDataFields = results;
      this.userDataFields.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0; // Names are equal
      });
    },
    async getFeatureService() {
      try {
        const { map_service_id: mapServiceId } = this.formDefinition;
        const {
          data: {
            results: { service_url: featureServiceUrl, token_type: tokenType },
          },
        } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);

        const token =
          tokenType === "AGOL" ? localStorage.getItem("esri_token") : undefined;
        const params = {
          f: "pjson",
          token,
        };
        const {
          data: { fields },
        } = await axios.get(featureServiceUrl, { params });
        this.fields = fields;
      } catch (error) {
        this.fields = [];
      }
    },
    getSelectedItemOptions() {
      this.selectedItemOptions = cloneDeep(this.value);

      if (!this.selectedItemOptions?.question?.default) {
        let value = null;
        if (
          this.selectedItemOptions.question.type === FIELD_TYPES.SINGLE_SELECT
        ) {
          value = null;
        } else if (
          this.selectedItemOptions.question.type === FIELD_TYPES.MULTI_SELECT
        ) {
          value = [];
        }
        this.selectedItemOptions.question.default = {
          type: null,
          value,
          applyDefault: null,
          applyDefaultConditions: { applyIf: APPLY_IF.ANY, conditions: [] },
        };
      }

      if (!this.selectedItemOptions.question?.default?.applyDefaultConditions) {
        this.selectedItemOptions.question.default.applyDefaultConditions = {
          applyIf: APPLY_IF.ANY,
          conditions: [
            {
              id: uuidv4(),
              type: null,
              value: null,
              condition: MATCH_CHOICE_VALUES.EQUAL,
            },
          ],
        };
      }

      if (
        !this.selectedItemOptions.question?.default?.applyDefaultConditions
          .applyIf
      ) {
        this.selectedItemOptions.question.default.applyDefaultConditions.applyIf =
          APPLY_IF.ANY;
      }

      this.selectedItemOptions.question.default.applyDefaultConditions.conditions =
        this.selectedItemOptions.question.default.applyDefaultConditions.conditions.map(
          (c) => {
            const { type, field, value, condition } = c;
            return {
              id: uuidv4(),
              type,
              field,
              value,
              condition,
            };
          }
        );

      if (
        this.selectedItemOptions.question.type === FIELD_TYPES.MULTI_SELECT &&
        !Array.isArray(this.selectedItemOptions?.question?.default?.value)
      ) {
        this.selectedItemOptions.question.default.value = [];
      }

      if (!this.selectedItemOptions.question.forInformationOnly) {
        this.selectedItemOptions.question.forInformationOnly = false;
      }
    },
    async onSubmit() {
      const success = await this.$refs.advancedSettingsForm.validate();
      if (!success) {
        return;
      }
      const selectedItemOptions = cloneDeep(this.selectedItemOptions);
      selectedItemOptions.question.default.applyDefaultConditions.conditions =
        selectedItemOptions.question.default.applyDefaultConditions.conditions.map(
          (c) => {
            const { type, field, value, condition } = c;
            return { type, field, value, condition };
          }
        );
      this.$emit("input", selectedItemOptions);
      this.$emit("advanced-settings-dialog-close");
    },
    async addCondition() {
      this.showConditions = false;
      const newCondition = {
        id: uuidv4(),
        type: null,
        value: null,
        condition: MATCH_CHOICE_VALUES.EQUAL,
      };
      this.selectedItemOptions.question.default.applyDefaultConditions.conditions.push(
        newCondition
      );
      await this.$nextTick();
      this.showConditions = true;
    },
    async onDependantChange() {
      this.showConditions = false;
      await this.$nextTick();
      this.showConditions = true;
    },
    async removeCondition(index) {
      this.showConditions = false;
      this.selectedItemOptions.question.default.applyDefaultConditions.conditions.splice(
        index,
        1
      );
      await this.$nextTick();
      this.showConditions = true;
    },
  },
};
</script>

<style scoped>
.select >>> .v-select.v-input--is-dirty input {
  width: 0;
}

.select >>> .v-select__selection {
  max-width: none;
}

.select >>> .v-select__selections {
  max-width: 200px;
  overflow: hidden;
  flex-wrap: nowrap;
}

.select >>> .v-select__selections .v-select__selection {
  min-width: fit-content;
}

.select >>> .v-select:not(.v-input--is-dirty) input {
  width: 100px;
}

.menu-button::before {
  display: none;
}
</style>
