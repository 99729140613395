var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mx-0 d-flex flex-column align-center",
      staticStyle: { "background-color": "#f1f2f1" },
    },
    [
      _vm.formDefinition.html_merge_definition_version === 2
        ? _c(
            "div",
            {
              staticClass: "d-flex flex-grow-1 border-left border-right",
              class: { "flex-column": _vm.$vuetify.breakpoint.xsOnly },
              style: {
                height: _vm.$vuetify.breakpoint.xsOnly
                  ? undefined
                  : `calc(100vh - 56px)`,
                width: `100%`,
              },
            },
            [
              !_vm.$vuetify.breakpoint.xsOnly
                ? _c(
                    "section",
                    {
                      staticClass: "white overflow-y-auto border-right",
                      staticStyle: {
                        height: "calc(100vh - 56px)",
                        width: "300px",
                      },
                    },
                    [
                      _c(
                        "v-expansion-panels",
                        {
                          staticClass: "flex-grow-0",
                          staticStyle: { width: "300px" },
                          attrs: { flat: "", multiple: "", accordion: "" },
                          model: {
                            value: _vm.openPanels,
                            callback: function ($$v) {
                              _vm.openPanels = $$v
                            },
                            expression: "openPanels",
                          },
                        },
                        [
                          _c(
                            "v-expansion-panel",
                            { attrs: { value: 0 } },
                            [
                              _c("v-expansion-panel-header", [
                                _c("h2", [_vm._v("Configuration")]),
                              ]),
                              _c(
                                "v-expansion-panel-content",
                                { staticClass: "border-top" },
                                [
                                  _c("FormConfigurationForm", {
                                    attrs: { disabled: _vm.disabled },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$emit("input", $event)
                                      },
                                    },
                                    model: {
                                      value: _vm.formDefinition,
                                      callback: function ($$v) {
                                        _vm.formDefinition = $$v
                                      },
                                      expression: "formDefinition",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-expansion-panel",
                            {
                              staticClass: "border-top border-bottom",
                              attrs: { value: 1 },
                            },
                            [
                              _c(
                                "v-expansion-panel-header",
                                { staticClass: "header" },
                                [_vm._v(" Form Fields ")]
                              ),
                              _c(
                                "v-expansion-panel-content",
                                { staticClass: "border-top" },
                                [
                                  _c("FormFieldsForm", {
                                    attrs: {
                                      formDefinition: _vm.formDefinition,
                                    },
                                    on: {
                                      "form-field-selected":
                                        _vm.onFormFieldSelected,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "section",
                {
                  staticClass:
                    "d-flex justify-center pa-0 overflow-hidden flex-grow-1",
                  staticStyle: { height: "100%" },
                },
                [
                  _c("validation-provider", {
                    attrs: { name: "Form Template Editor" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ errors, valid }) {
                            return [
                              Array.isArray(_vm.formDefinition.form.sections)
                                ? _c("TemplateEditor", {
                                    ref: "templateEditor",
                                    attrs: {
                                      errors: errors,
                                      valid: valid,
                                      disabled: _vm.disabled,
                                      formDefinition: _vm.formDefinition,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$emit(
                                          "input",
                                          _vm.formDefinition
                                        )
                                      },
                                      "input:header": _vm.onHeaderInput,
                                      "input:footer": _vm.onFooterInput,
                                      "input:docSettings":
                                        _vm.onInputDocSettings,
                                      "input:version": _vm.onVersionInput,
                                    },
                                    model: {
                                      value:
                                        _vm.formDefinition
                                          .html_merge_definition,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formDefinition,
                                          "html_merge_definition",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formDefinition.html_merge_definition",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2564744694
                    ),
                  }),
                ],
                1
              ),
            ]
          )
        : _c("div", [
            _c(
              "div",
              {
                staticClass: "d-flex flex-grow-1 border-left border-right",
                class: { "flex-column": _vm.$vuetify.breakpoint.xsOnly },
                style: {
                  height: _vm.$vuetify.breakpoint.xsOnly
                    ? undefined
                    : `calc(100vh - 56px)`,
                  width: `100%`,
                },
              },
              [
                !_vm.$vuetify.breakpoint.xsOnly
                  ? _c(
                      "section",
                      {
                        staticClass: "white overflow-y-auto border-right",
                        staticStyle: {
                          height: "calc(100vh - 56px)",
                          width: "300px",
                        },
                      },
                      [
                        _c(
                          "v-expansion-panels",
                          {
                            staticClass: "flex-grow-0",
                            staticStyle: { width: "300px" },
                            attrs: { flat: "", multiple: "", accordion: "" },
                            model: {
                              value: _vm.openPanels,
                              callback: function ($$v) {
                                _vm.openPanels = $$v
                              },
                              expression: "openPanels",
                            },
                          },
                          [
                            _c(
                              "v-expansion-panel",
                              { attrs: { value: 0 } },
                              [
                                _c("v-expansion-panel-header", [
                                  _c("h2", [_vm._v("Configuration")]),
                                ]),
                                _c(
                                  "v-expansion-panel-content",
                                  { staticClass: "border-top" },
                                  [
                                    _c("FormConfigurationForm", {
                                      attrs: {
                                        disabled: _vm.disabled,
                                        showPageSettings: false,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.$emit("input", $event)
                                        },
                                      },
                                      model: {
                                        value: _vm.formDefinition,
                                        callback: function ($$v) {
                                          _vm.formDefinition = $$v
                                        },
                                        expression: "formDefinition",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "section",
                  { staticClass: "border-left" },
                  [
                    !_vm.editV1Code
                      ? _c(
                          "div",
                          { staticClass: "d-flex justify-end white" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.editV1Code = true
                                  },
                                },
                              },
                              [
                                _c("v-icon", [
                                  _vm._v(" " + _vm._s(_vm.mdiCodeTags) + " "),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "d-flex white align-center justify-space-between",
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.editV1Code = false
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { size: "20" } }, [
                                  _vm._v(_vm._s(_vm.mdiChevronLeft)),
                                ]),
                                _vm._v(" Doc Builder "),
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.showChangeFormDefinitionVersionDialog = true
                                  },
                                },
                              },
                              [_vm._v(" Form Version 2 ")]
                            ),
                          ],
                          1
                        ),
                    _vm.editV1Code
                      ? _c("Version1TemplateEditor", {
                          on: {
                            input: function ($event) {
                              return _vm.$emit("input", _vm.formDefinition)
                            },
                          },
                          model: {
                            value: _vm.formDefinition.html_merge_definition,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formDefinition,
                                "html_merge_definition",
                                $$v
                              )
                            },
                            expression: "formDefinition.html_merge_definition",
                          },
                        })
                      : _c("Version1TemplateView", {
                          attrs: { htmlCode: _vm.htmlCode },
                        }),
                  ],
                  1
                ),
              ]
            ),
          ]),
      _c("ChangeFormDefinitionVersionDialog", {
        attrs: {
          showChangeFormDefinitionVersionDialog:
            _vm.showChangeFormDefinitionVersionDialog,
          versionNumber: 1,
        },
        on: {
          cancel: function ($event) {
            _vm.showChangeFormDefinitionVersionDialog = false
          },
          "change-form-version": _vm.onVersionInput,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }