<template>
  <validation-observer ref="calculationForm">
    <v-toolbar color="primary" height="56px" class="elevation-0" ref="toolbar">
      <v-list-item two-line class="px-0">
        <v-list-item-content>
          <div class="d-flex justify-space-between">
            <v-list-item-title class="white--text text-h6">
              {{ selectedControlType | itemTitle(selectedItem) }}
            </v-list-item-title>
            <v-btn
              icon
              color="white"
              @click="$emit('form-control-form-submitted')"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-toolbar>
    <form
      @submit.prevent="
        onSubmit();
        $emit('form-control-form-submitted');
      "
    >
      <v-list
        dense
        style="max-height: calc(100vh - 56px)"
        class="overflow-y-auto"
      >
        <v-list-item class="my-0 py-0">
          <v-list-item-content>
            <validation-provider
              v-slot="{ errors, valid }"
              name="Label"
              rules="required"
            >
              <v-text-field
                v-model="selectedItemOptions.question.label"
                label="Label"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="name"
                @change="onSubmit()"
                ref="labelInput"
                :disabled="!canManageForms"
              />
            </validation-provider>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="my-0 py-0">
          <v-list-item-content>
            <v-card class="elevation-0 d-flex justify-space-between">
              <p class="caption">Calculation</p>
              <v-icon
                color="primary"
                class="mt-n2"
                @click="showCalculationDialog = true"
                :disabled="!canManageForms"
              >
                {{ mdiPencil }}
              </v-icon>
            </v-card>
            <v-card class="elevation-0">
              {{
                selectedItemOptions.question.calculation
                  | readableQuestion(formDefinition)
              }}
            </v-card>

            <v-dialog
              v-model="showCalculationDialog"
              max-width="600px"
              :fullscreen="$vuetify.breakpoint.xsOnly"
            >
              <FormulaForm
                v-if="showCalculationDialog"
                :formDefinition="formDefinition"
                :questionId="selectedItem.id"
                @formula-form-submitted="onFormulaFormSubmitted"
                @formula-form-close="showCalculationDialog = false"
              />
            </v-dialog>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="my-0 py-0" v-if="['F', 'R'].includes(layerType)">
          <v-list-item-content>
            <validation-provider
              v-slot="{ errors, valid }"
              name="Update ArcGIS Field"
            >
              <v-text-field
                v-model="selectedItemOptions.question.updateEsriObject"
                label="Update ArcGIS Field"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="updateEsriObject"
                @change="onSubmit()"
                :disabled="!canManageForms"
              />
            </validation-provider>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="my-0 py-0">
          <v-list-item-content>
            <v-switch
              v-model="selectedItemOptions.showInReport"
              label="Show on Form Submissions Page"
              @change="onSubmit()"
              class="my-0 py-0"
              :disabled="!canManageForms"
            ></v-switch>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="my-0 py-0">
          <v-list-item-content>
            <validation-provider v-slot="{ errors, valid }" name="Visible">
              <v-select
                autocomplete="off"
                v-model="createCondition"
                :items="createOptions"
                label="Create"
                :error-messages="errors"
                :success="valid"
                item-text="label"
                item-value="value"
                @change="onSubmit()"
                hide-details
                :disabled="!canManageForms"
              >
              </v-select>
            </validation-provider>

            <CreateComparisonConditionForm
              v-if="['conditional'].includes(createCondition)"
              v-model="selectedItemOptions.question.create"
              :formDefinition="formDefinition"
              :selectedItem="selectedItem"
              @input="onSubmit()"
            />
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <AdvancedSettingsForm
              v-model="selectedItemOptions"
              :formDefinition="formDefinition"
              :selectedItem="selectedItem"
              :layer="layer"
              @input="onSubmit()"
            />
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <div class="d-flex flex-wrap gap" v-if="hasAdvancedSettings">
              <section
                class="text-uppercase d-flex primary--text gap align-center"
                v-if="hasVisibleSettings"
                @click="
                  if (canManageForms) {
                    showAdvancedVisibilitySettingsDialog = true;
                  }
                "
              >
                <div>Visible {{ visibleSetting }}</div>

                <v-btn icon :disabled="!canManageForms">
                  <v-icon color="primary">
                    {{ mdiPencil }}
                  </v-icon>
                </v-btn>
              </section>
            </div>
            <div v-else class="d-flex align-center justify-center">
              No advanced settings
            </div>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="my-0 py-0">
          <v-list-item-content>
            <v-btn
              color="primary"
              type="submit"
              width="100%"
              :disabled="!canManageForms"
            >
              Update Item
            </v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <AdvancedVisibilitySettingsDialog
        v-if="showAdvancedVisibilitySettingsDialog"
        :showAdvancedVisibilitySettingsDialog="
          showAdvancedVisibilitySettingsDialog
        "
        :formDefinition="formDefinition"
        :layer="layer"
        :selectedItem="selectedItem"
        @advanced-settings-dialog-close="
          showAdvancedVisibilitySettingsDialog = false
        "
        v-model="selectedItemOptions"
        @input="onSubmit()"
      />
    </form>
  </validation-observer>
</template>

<script>
import {
  comparisonTypes,
  defaultValueTypes,
  requiredOptions,
  visibilityOptions,
  createOptions,
} from "@/constants/choices";
import { mdiPencil } from "@mdi/js";
import { cloneDeep } from "lodash";
import FormulaForm from "./calculation-form/FormulaForm";
import sleep from "@/mixins/sleep";
import CreateComparisonConditionForm from "./shared/CreateComparisonConditionForm";
import { CONDITION_CHOICES } from "@/constants/advancedSettings";
import axios from "axios";
import AdvancedSettingsForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/AdvancedSettingsForm.vue";
import AdvancedVisibilitySettingsDialog from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/advanced-settings-form/AdvancedVisibilitySettingsDialog.vue";
import permissionsMixin from "@/mixins/permissionsMixin";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "CalculationForm",
  components: {
    FormulaForm,
    AdvancedSettingsForm,
    AdvancedVisibilitySettingsDialog,
    CreateComparisonConditionForm,
  },
  props: {
    selectedItem: Object,
    formDefinition: Object,
    selectedControlType: String,
  },
  data() {
    return {
      selectedItemOptions: {
        question: {},
      },
      defaultValueTypes,
      requiredOptions,
      visibilityOptions,
      comparisonTypes,
      mdiPencil,
      showCalculationDialog: false,
      requiredCondition: "ALWAYS",
      createCondition: "ALWAYS",
      createOptions,
      layer: {},
      showAdvancedVisibilitySettingsDialog: false,
    };
  },
  mixins: [permissionsMixin],
  computed: {
    hasAdvancedSettings() {
      const { hasVisibleSettings } = this;
      return hasVisibleSettings;
    },
    hasVisibleSettings() {
      return (
        this.selectedItemOptions.question.visible?.applyVisible !==
        CONDITION_CHOICES.ALWAYS
      );
    },
    visibleSetting() {
      if (
        this.selectedItemOptions?.question?.visible?.applyVisible === "ALWAYS"
      ) {
        return "";
      }
      return this.selectedItemOptions?.question?.visible?.applyVisible;
    },
    layerType() {
      return this.layer.service_type;
    },
  },
  async mounted() {
    if (!this.selectedItemOptions.question.label) {
      await this.$nextTick();
      await sleep(500);
      this.$refs.labelInput.$refs?.input?.focus();
    }
  },
  methods: {
    async getLayer() {
      const { map_service_id: mapServiceId } = this.formDefinition;
      const {
        data: { results },
      } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
      this.layer = results;
    },
    async onSubmit() {
      const success = await this.$refs.calculationForm.validate();
      if (!success) {
        return;
      }
      this.$emit("input", this.selectedItemOptions);
    },
    onFormulaFormSubmitted(ev) {
      this.selectedItemOptions.question.calculation = ev;
      this.showCalculationDialog = false;
      const params = [...ev.matchAll(/{{(.*?)}}/g)];
      this.selectedItemOptions.question.watchIds = params.map((p) => {
        const [, param] = p.toString().split(",");
        const [, questionId] = param.split(".");
        return +questionId;
      });
      this.onSubmit();
    },
    getSelectedItemOptions() {
      this.selectedItemOptions = cloneDeep(this.selectedItem);
      if (
        this.selectedItemOptions?.question?.required &&
        !["NEVER", "ALWAYS"].includes(
          this.selectedItemOptions?.question?.required?.condition
        )
      ) {
        this.requiredCondition = "conditional";
      } else {
        this.requiredCondition =
          this.selectedItemOptions?.question?.required?.condition;
      }

      if (
        this.selectedItemOptions?.question?.create &&
        !["ALWAYS"].includes(
          this.selectedItemOptions?.question?.create?.condition
        )
      ) {
        this.createCondition = "conditional";
      } else {
        this.createCondition =
          this.selectedItemOptions?.question?.create?.condition;
      }

      if (!this.selectedItemOptions.question.default) {
        this.selectedItemOptions.question.default = {};
      }
    },
  },
  beforeMount() {
    this.getSelectedItemOptions();
    this.getLayer();
  },
  watch: {
    requiredCondition(val) {
      if (["NEVER", "ALWAYS"].includes(val)) {
        this.selectedItemOptions.question.required.condition = val;
        this.selectedItemOptions.question.required.dependantId = undefined;
        this.selectedItemOptions.question.required.value = undefined;
      }
    },
    createCondition(val) {
      if (["ALWAYS"].includes(val)) {
        this.selectedItemOptions.question.create.condition = val;
        this.selectedItemOptions.question.create.dependent = undefined;
        this.selectedItemOptions.question.create.dependentFieldId = undefined;
        this.selectedItemOptions.question.create.value = undefined;
      }
    },
  },
};
</script>
