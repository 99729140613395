var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex justify-space-between" },
    [
      _c("validation-observer", { ref: "signatureForm" }, [
        _c(
          "form",
          [
            _vm.selectedRepeatingGroupItemOptions &&
            _vm.selectedRepeatingGroupItemOptions.question
              ? _c(
                  "v-list",
                  [
                    _c(
                      "v-list-item",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c("validation-provider", {
                              attrs: { name: "Label", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Label",
                                            "hide-details": "auto",
                                            "error-messages": errors,
                                            success: valid,
                                            color: "primary",
                                            name: "name",
                                            disabled: !_vm.canManageForms,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.onSubmit()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm
                                                .selectedRepeatingGroupItemOptions
                                                .question.label,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm
                                                  .selectedRepeatingGroupItemOptions
                                                  .question,
                                                "label",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "selectedRepeatingGroupItemOptions.question.label",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1296071596
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c("validation-provider", {
                              attrs: { name: "Required", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            autocomplete: "off",
                                            items: _vm.groupItemRequiredOptions,
                                            label: "Required",
                                            "error-messages": errors,
                                            success: valid,
                                            "item-text": "label",
                                            "item-value": "value",
                                            "hide-details": "",
                                            disabled: !_vm.canManageForms,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.onSubmit()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm
                                                .selectedRepeatingGroupItemOptions
                                                .question.required.condition,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm
                                                  .selectedRepeatingGroupItemOptions
                                                  .question.required,
                                                "condition",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                  selectedRepeatingGroupItemOptions.question.required\n                    .condition\n                ",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1312171232
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-switch", {
                              staticClass: "my-0 py-0",
                              attrs: {
                                label: "Allow Multiple",
                                disabled: !_vm.canManageForms,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.onSubmit()
                                },
                              },
                              model: {
                                value:
                                  _vm.selectedRepeatingGroupItemOptions
                                    .allowMultiple,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectedRepeatingGroupItemOptions,
                                    "allowMultiple",
                                    $$v
                                  )
                                },
                                expression:
                                  "selectedRepeatingGroupItemOptions.allowMultiple",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }