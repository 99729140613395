<template>
  <node-view-wrapper
    as="div"
    class="statement"
    :data-placeholder="node.attrs.statement"
  >
    {{ displayPlaceholder }}
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from "@tiptap/vue-2";
import { unescape } from "lodash";

export default {
  components: {
    NodeViewWrapper,
  },
  props: nodeViewProps,
  computed: {
    displayPlaceholder() {
      return `{% ${unescape(this.node.attrs.statement)} %}`;
    },
  },
};
</script>

<style>
.statement {
  background: #e0e0e0;
  border-radius: 16px;
  font-size: 14px;
  padding: 3px 5px;
  display: inline-block;
  word-break: break-all;
}
</style>
