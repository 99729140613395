import { render, staticRenderFns } from "./TextForm.vue?vue&type=template&id=52fa40a6&"
import script from "./TextForm.vue?vue&type=script&lang=js&"
export * from "./TextForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('52fa40a6')) {
      api.createRecord('52fa40a6', component.options)
    } else {
      api.reload('52fa40a6', component.options)
    }
    module.hot.accept("./TextForm.vue?vue&type=template&id=52fa40a6&", function () {
      api.rerender('52fa40a6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/forms/form-builder-edit-form/form-control-edit-form/repeating-group-form/repeating-group-items/TextForm.vue"
export default component.exports