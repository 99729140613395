<template>
  <validation-observer ref="imageSizeFieldsForm" slim>
    <v-card>
      <v-card-text class="elevation-0">
        <div class="py-3">
          <validation-provider
            v-slot="{ errors, valid }"
            name="Image Width"
            :rules="{
              required: true,
              regex: /\d+|auto/,
            }"
          >
            <v-text-field
              v-model="image.imageWidth"
              label="Image Width"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="name"
              class="pt-1"
              @keydown="onNumberKeyDown"
              @change="
                checkImageWidthVal();
                save();
              "
              @input="onImageWidthInput()"
            />
          </validation-provider>
        </div>

        <div class="py-3">
          <validation-provider
            v-slot="{ errors, valid }"
            name="Image Height"
            :rules="{
              required: true,
              regex: /\d+|auto/,
            }"
          >
            <v-text-field
              v-model="image.imageHeight"
              label="Image Height"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="name"
              class="pt-1"
              @keydown="onNumberKeyDown"
              @change="
                checkImageHeightVal();
                save();
              "
              @input="onImageHeightInput()"
            />
          </validation-provider>
        </div>

        <div class="py-3">
          <validation-provider
            v-slot="{ errors, valid }"
            name="Image Padding"
            :rules="{ required: true, numeric: true, min_value: 0 }"
          >
            <v-text-field
              v-model.number="image.imagePadding"
              label="Image Padding"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="name"
              class="pt-1"
              type="number"
              @keydown="onNumberKeyDown"
              @change="save()"
            />
          </validation-provider>
        </div>
      </v-card-text>
    </v-card>
  </validation-observer>
</template>

<script>
export default {
  name: "ImageSizeFields",
  props: {
    value: Object,
    default: () => ({
      imageWidth: 0,
      imageHeight: 0,
      imagePadding: 0,
    }),
  },
  data() {
    return {
      image: {
        imageWidth: 0,
        imageHeight: 0,
        imagePadding: 0,
      },
    };
  },
  methods: {
    onImageWidthInput() {
      if (!isNaN(this.image.imageWidth)) {
        this.image.imageHeight = "auto";
      }
    },
    onImageHeightInput() {
      if (!isNaN(this.image.imageHeight)) {
        this.image.imageWidth = "auto";
      }
    },
    checkImageWidthVal() {
      if (!isNaN(this.image.imageWidth)) {
        if (+this.image.imageWidth > 740) {
          this.image.imageWidth = 740;
        } else if (+this.image.imageWidth < 0) {
          this.image.imageWidth = 0;
        }
      }
    },
    checkImageHeightVal() {
      if (!isNaN(this.image.imageHeight)) {
        if (+this.image.imageHeight > 740) {
          this.image.imageHeight = 740;
        } else if (+this.image.imageHeight < 0) {
          this.image.imageHeight = 0;
        }
      }
    },
    onNumberKeyDown(e) {
      if (["e", "+", "-", "*", "/"].includes(e.key.toLowerCase())) {
        e.preventDefault();
      }
    },
    async save() {
      const success = await this.$refs.imageSizeFieldsForm.validate();
      if (!success) {
        return;
      }
      const { selectedFormField: formField } = this;
      this.$emit("input", { ...this.image, formField });
    },
  },
  beforeMount() {
    this.image = { ...this.value };
  },
};
</script>
