<template>
  <v-row>
    <v-col cols="12">
      <validation-provider
        slim
        v-slot="{ errors, valid }"
        :name="item.question.label"
        :rules="{ required: isRequired }"
      >
        <v-menu max-width="100%">
          <template v-slot:activator="{ on }">
            <v-text-field
              v-on="on"
              :label="item.question.label"
              color="primary"
              v-model="dateValue"
              @input="$emit('input', dateValue)"
              :error-messages="errors"
              :success="valid"
              :disabled="readOnly"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateValue"
            @input="$emit('input', dateValue)"
          ></v-date-picker>
        </v-menu>
      </validation-provider>
    </v-col>
  </v-row>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  name: "GroupDateInput",
  props: {
    item: Object,
    readOnly: Boolean,
    value: String,
    isRequired: Boolean,
  },
  data() {
    return {
      dateValue: undefined,
    };
  },
  beforeMount() {
    this.dateValue = cloneDeep(this.value);
  },
};
</script>
