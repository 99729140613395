import { mergeAttributes, Node } from "@tiptap/core";

const Section = Node.create({
  name: "section",
  priority: 1000,
  group: "block",
  content: "block*",
  parseHTML() {
    return [{ tag: "section" }];
  },
  renderHTML({ HTMLAttributes }) {
    return [
      "section",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
  addAttributes() {
    return {
      style: {
        default: null,
      },
      class: {
        default: null,
      },
    };
  },
});

export default Section;
