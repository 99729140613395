<template>
  <v-card class="elevation-0 mx-auto" max-width="600">
    <v-card-text class="pa-0">
      <div v-if="formDefinition.form.relatedTable">
        <div class="card-background py-4">
          <v-card class="elevation-2">
            <v-card-text v-if="!noTokenAndNotNoneTokenType">
              <div class="d-flex justify-space-between align-center">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="ArcGIS Related Table"
                >
                  <v-select
                    autocomplete="off"
                    label="ArcGIS Related Table"
                    hide-details="auto"
                    :error-messages="errors"
                    :success="valid"
                    color="primary"
                    name="arcGisRelatedTable"
                    :items="arcGisRelatedTables"
                    :disabled="disableTableDropdown || !canManageForms"
                    item-text="label"
                    item-value="value"
                    v-model="relationshipId"
                    @change="onChange"
                  >
                  </v-select>
                </validation-provider>
                <v-btn
                  text
                  @click="goToSelectedRelatedTable()"
                  width="30px"
                  min-width="30px"
                  height="30px"
                  min-height="30px"
                  :disabled="!canManageForms"
                >
                  <v-icon color="#1976d2">{{ mdiOpenInNew }}</v-icon>
                </v-btn>
              </div>
            </v-card-text>
            <v-card-text
              v-else
              style="background-color: rgb(255, 130, 0)"
              class="d-flex justify-center"
            >
              <div class="white--text font-weight-medium">
                Sign in with an ArcGIS user to view Related Table name.
              </div>
            </v-card-text>
          </v-card>
        </div>
        <div class="card-background">
          <v-card class="elevation-2">
            <v-card-text>
              <FeatureServiceFieldsTable
                v-model="formDefinition"
                :fields="computedFields"
                :questionChoices="questionChoices"
                :noTokenAndNotNoneTokenType="noTokenAndNotNoneTokenType"
                @input="$emit('input', formDefinition)"
              />
            </v-card-text>
          </v-card>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { cloneDeep } from "lodash";
import axios from "axios";
import FeatureServiceFieldsTable from "@/components/forms/form-builder-edit-form/arcgis-related-table/FeatureServiceFieldsTable";
import questionsMixin from "@/mixins/questionsMixin";
import { mdiInformation, mdiOpenInNew } from "@mdi/js";
import permissionsMixin from "@/mixins/permissionsMixin";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "ArcGisRelatedTableForm",
  components: {
    FeatureServiceFieldsTable,
  },
  mixins: [questionsMixin, permissionsMixin],
  props: {
    value: Object,
  },
  data() {
    return {
      mdiInformation,
      mdiOpenInNew,
      fields: [],
      relationships: [],
      relationshipId: undefined,
      layerTokenType: "",
      disableTableDropdown: false,
      formDefinition: undefined,
      selectedFormDefinitionId: undefined,
      featureServiceUrl: "",
    };
  },
  computed: {
    computedFields() {
      return this.fields;
    },
    arcGisRelatedTables() {
      const { relationships } = this;
      return [
        {
          value: null,
          label: "None",
        },
        ...relationships.map(({ name, relatedTableId }) => ({
          label: name || relatedTableId,
          value: relatedTableId,
        })),
      ];
    },
    isEsriTokenPresent() {
      return Boolean(localStorage.getItem("esri_token"));
    },
    questionChoices() {
      const { questions } = this;
      return [
        {
          value: null,
          label: "None",
        },
        ...questions.map(({ id, number, question: { label } = {} }) => ({
          value: id,
          label: `${number} ${label}`,
        })),
      ];
    },
    noTokenAndNotNoneTokenType() {
      return !this.isEsriTokenPresent && this.layerTokenType !== "NONE";
    },
  },
  async beforeMount() {
    // await this.getRelationships();
    await this.getRelatedTableFields();
  },
  methods: {
    goToSelectedRelatedTable() {
      const finalFeatureServiceUrl = `${this.featureServiceUrl.slice(
        0,
        this.featureServiceUrl.lastIndexOf("/")
      )}/${this.relationshipId}`;
      const token = localStorage.getItem("esri_token");
      if (this.layerTokenType === "AGOL" && token) {
        window.open(`${finalFeatureServiceUrl}?token=${token}`, "blank");
      } else {
        window.open(finalFeatureServiceUrl, "blank");
      }
    },
    async getFormDefinition() {
      const { data } = await axios.get(
        `${APIURL}/form_definitions/${this.selectedFormDefinitionId}`
      );
      this.formDefinition = data;
    },
    disableTable() {
      if (!this.isEsriTokenPresent && this.layerTokenType === "NONE") {
        this.disableTableDropdown = false;
        return;
      } else if (this.isEsriTokenPresent) {
        this.disableTableDropdown = false;
        return;
      }

      this.disableTableDropdown = true;
    },
    onChange() {
      const { formDefinition, relationshipId } = this;
      formDefinition.form.relatedTable = {
        ...formDefinition.form.relatedTable,
        relationshipId,
      };
      this.getRelatedTableFields();

      this.$emit("input", formDefinition);
    },
    async getRelationships() {
      const { map_service_id: mapServiceId } = this.formDefinition;
      const {
        data: {
          results: { service_url: featureServiceUrl, token_type: tokenType },
        },
      } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
      this.layerTokenType = tokenType;
      const token =
        tokenType === "AGOL" ? localStorage.getItem("esri_token") : undefined;
      const params = {
        f: "pjson",
        token,
      };
      const {
        data: { relationships = [] },
      } = await axios.get(featureServiceUrl, { params });
      this.relationships = relationships;
      this.featureServiceUrl = featureServiceUrl;
    },
    async getRelatedTableFields() {
      if (
        this.formDefinition &&
        this.formDefinition.map_service_id &&
        (!this.noTokenAndNotNoneTokenType ||
          (this.isEsriTokenPresent && this.layerTokenType === "NONE"))
      ) {
        const { map_service_id: mapServiceId } = this.formDefinition;
        await this.getRelationships();
        const {
          data: {
            results: { service_url: featureServiceUrl, token_type: tokenType },
          },
        } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
        const token =
          tokenType === "AGOL" ? localStorage.getItem("esri_token") : undefined;
        const params = {
          f: "pjson",
          token,
        };
        const finalFeatureServiceUrl = `${featureServiceUrl.slice(
          0,
          this.featureServiceUrl.lastIndexOf("/")
        )}/${this.relationshipId}`;
        const {
          data: { fields = [] },
        } = await axios.get(finalFeatureServiceUrl, { params });
        this.fields = [...fields];
        if (this.formDefinition.form && this.formDefinition.form.relatedTable) {
          const { fields: oldFields = [] } =
            this.formDefinition.form.relatedTable;
          for (const [index, field] of this.fields.entries()) {
            const oldField = oldFields.find((f) => f.name === field.name);
            if (oldField) {
              const { selectedQuestionId } = oldField;
              this.fields[index] = {
                ...field,
                selectedQuestionId,
              };
            } else {
              this.fields[index] = {
                ...field,
                selectedQuestionId: null,
              };
            }
          }
          this.formDefinition.form.relatedTable.fields = this.fields;
        } else {
          this.formDefinition.form.relatedTable.fields = this.fields;
        }
      } else {
        await this.getFormDefinition();
        await this.getRelationships();
        await this.$nextTick();
        this.fields = [...this.formDefinition.form.relatedTable.fields];
      }
      this.$emit("input", this.formDefinition);
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      async handler(val) {
        this.formDefinition = cloneDeep(val);
        if (
          this.formDefinition.form.relatedTable === null ||
          typeof this.formDefinition.form.relatedTable !== "object"
        ) {
          this.formDefinition.form.relatedTable = {
            relationshipId: null,
            fields: [],
          };
        }
        const { relationshipId } = this.formDefinition.form.relatedTable;
        this.relationshipId = relationshipId;
      },
    },
    layerTokenType: {
      immediate: true,
      async handler() {
        this.disableTable();
      },
    },
    "$route.query.form": {
      immediate: true,
      deep: true,
      async handler() {
        const formDefId = this.$route.query.form;
        if (formDefId) {
          this.selectedFormDefinitionId = formDefId;
        }
      },
    },
  },
};
</script>

<style scoped>
.card-background {
  background-color: #f1f2f1;
}

th {
  line-height: 18px;
}

.v-select__selections input {
  display: none !important;
}
.v-select__selection {
  max-width: 100% !important;
}
</style>
