<template>
  <div>
    <p class="caption">{{ item.question.label }}</p>
    <file-pond
      name="files"
      ref="files"
      label-idle="Drop files here..."
      :allow-multiple="item.question.allowMultiple"
      accepted-file-types="image/jpeg, image/png"
      :files="uploadFiles"
      @addfile="onChange"
      allowFileSizeValidation
      maxFileSize="2MB"
      :disabled="readOnly"
    />
    <v-card v-for="(image, index) of base64Images" :key="image.id">
      <v-card-text>
        <v-list-item>
          <v-list-item-content>
            <v-card class="d-flex flex-row justify-start elevation-0">
              <v-card
                class="elevation-0 d-flex flex-column justify-center mr-2"
              >
                <img :src="image.dataUrl" class="thumbnail mr-2" />
              </v-card>
              <v-card class="elevation-0" width="100%" v-if="showDescription">
                <v-text-field
                  :key="image.id"
                  label="Description"
                  color="primary"
                  :name="image.id"
                >
                </v-text-field>
              </v-card>
            </v-card>
          </v-list-item-content>

          <v-list-item-action>
            <v-list-item-action-text>
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>{{ mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="removePhoto(index, image.id)">
                    <v-list-item-title>
                      <v-icon>{{ mdiDelete }}</v-icon>
                      Delete
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action-text>
          </v-list-item-action>
        </v-list-item>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import vueFilePond, { setOptions } from "vue-filepond";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import "filepond/dist/filepond.min.css";
import { v4 as uuidv4 } from "uuid";
import { mdiDotsVertical, mdiDelete } from "@mdi/js";
import { cloneDeep } from "lodash";

const Compress = require("compress.js");
const compress = new Compress();
const FilePond = vueFilePond(FilePondPluginFileEncode);

setOptions({
  imageResizeMode: "contain",
  allowImageTransform: true,
});

export default {
  name: "GroupPhotoInput",
  props: {
    item: Object,
    allowMultiple: Boolean,
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    readOnly: Boolean,
  },
  components: {
    FilePond,
  },
  data() {
    return {
      uploadFiles: undefined,
      base64Images: [],
      showPhotoEditor: {},
      resizedImageWidth: 0,
      resizedImageHeight: 0,
      mdiDotsVertical,
      mdiDelete,
    };
  },
  methods: {
    async onChange() {
      const files = this.$refs.files.getFiles();
      const { maxWidthHeight } = this.item.question;
      const base64ImagePromises = files.map(async (f) => {
        let dataUrl = f.getFileEncodeDataURL();
        const { name: fileName, type: fileType } = f.file;
        const result = await compress.compress([f.file], {
          maxWidth: maxWidthHeight,
          maxHeight: maxWidthHeight,
          resize: true,
        });
        const [{ data, prefix }] = result;
        dataUrl = `${prefix}${data}`;
        return {
          dataUrl,
          description: "",
          fileName,
          fileType,
          id: uuidv4(),
          name: fileName,
          url: dataUrl,
          uploaded: false,
        };
      });

      if (files.length > 0) {
        for (const f of files) {
          this.$refs.files.removeFile(f);
        }

        const base64Images = await Promise.all(base64ImagePromises);
        this.base64Images = [...this.base64Images, ...base64Images];
        this.$emit("input", this.base64Images);
      }
    },
    async removePhoto(index) {
      this.base64Images.splice(index, 1);
      this.$emit("input", this.base64Images);
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.base64Images = cloneDeep(val);
      },
    },
  },
};
</script>
