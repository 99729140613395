<template>
  <v-row>
    <v-col
      cols="12"
      v-for="(v, index) in values"
      :key="index"
      class="py-0 mt-0 mb-4"
    >
      <template v-if="['TEXT', 'EMAIL'].includes(item.question.type)">
        <validation-provider
          slim
          v-slot="{ errors, valid, validated }"
          :name="item.question.label"
          :rules="{
            required: isRequired,
            email: item.question.type === 'EMAIL',
            max: Boolean(item.question.max_length)
              ? item.question.max_length
              : Infinity,
          }"
        >
          <template v-if="item.question.isLong">
            <QuillEditor
              v-if="isRichText"
              v-model="v.text"
              @input="onInput"
              :label="`${item.number} ${item.question.label}`"
              :placeholder="item.question.placeholder"
              :validated="validated"
              :valid="valid"
              :errors="errors"
              :multipleItems="values.length > 1"
              :allowMultiple="item.allowMultiple"
              :disabled="readOnly"
              :maxChars="+item.question.max_length"
              @remove-entry="removeEntry"
              @add-entry="addEntry"
            />
            <section v-else>
              <v-textarea
                :label="`${item.number} ${item.question.label}`"
                :validated="validated"
                :valid="valid"
                :errors="errors"
                :disabled="readOnly"
                filled
                hide-details
              ></v-textarea>
              <span style="font-size: 12px">{{
                item.question.placeholder
              }}</span>

              <section class="d-flex align-center">
                <v-icon
                  color="red"
                  @click="addEntry"
                  v-if="item.allowMultiple"
                  class="cursor-pointer"
                >
                  {{ mdiPlus }}
                </v-icon>
                <v-icon
                  color="#093637"
                  @click="removeEntry(index)"
                  v-if="values.length > 1"
                >
                  {{ mdiMinus }}
                </v-icon>
              </section>
            </section>
          </template>
          <template v-else>
            <template v-if="item.question.forInformationOnly">
              <label class="caption">{{
                `${item.number} ${item.question.label}`
              }}</label>
              <p>{{ item.question.default.value }}</p>
            </template>
            <template v-else>
              <div v-if="readOnly" class="mb-n4" style="width: 100%">
                <div
                  style="
                    color: rgba(0, 0, 0, 0.38);
                    font-size: 16px;
                    border-bottom: 1px rgba(0, 0, 0, 0.38) dashed;
                    padding-bottom: 4px;
                    width: 100%;
                  "
                >
                  {{ item.number }} {{ item.question.label }}
                </div>
                <div
                  style="
                    color: rgba(0, 0, 0, 0.38);
                    font-size: 12px;
                    padding-top: 4px;
                  "
                >
                  {{ item.question.placeholder }}
                </div>
              </div>
              <v-text-field
                :key="index"
                v-model="v.text"
                :label="`${item.number} ${item.question.label}`"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                :name="item.id"
                :hint="item.question.placeholder"
                persistent-hint
                @input="onInput"
                v-else
              >
                <v-icon
                  slot="append"
                  color="red"
                  @click="addEntry"
                  v-if="item.allowMultiple"
                >
                  mdi-plus
                </v-icon>
                <v-icon
                  slot="append"
                  color="#093637"
                  @click="removeEntry(index)"
                  v-if="values.length > 1"
                >
                  mdi-minus
                </v-icon>
              </v-text-field>
            </template>
          </template>
        </validation-provider>
      </template>

      <template v-if="['NUMBER'].includes(item.question.type)">
        <validation-provider
          slim
          v-slot="{ errors, valid }"
          :name="item.question.label"
          :rules="{
            required: isRequired,
            max: Boolean(item.question.max_length)
              ? item.question.max_length
              : Infinity,
            regex: /^\d+(\.\d+)?$/,
          }"
        >
          <div v-if="readOnly" class="mb-n4" style="width: 100%">
            <div
              style="
                color: rgba(0, 0, 0, 0.38);
                font-size: 16px;
                border-bottom: 1px rgba(0, 0, 0, 0.38) dashed;
                padding-bottom: 4px;
                width: 100%;
              "
            >
              {{ item.number }} {{ item.question.label }}
            </div>
            <div
              style="
                color: rgba(0, 0, 0, 0.38);
                font-size: 12px;
                padding-top: 4px;
              "
            >
              {{ item.question.placeholder }}
            </div>
          </div>
          <v-text-field
            :key="index"
            v-model="v.text"
            :label="`${item.number} ${item.question.label}`"
            hide-details="auto"
            :error-messages="errors"
            :success="valid"
            color="primary"
            :name="item.id"
            :hint="item.question.placeholder"
            persistent-hint
            @input="onNumberInput(valid)"
            v-else
          >
            <v-icon
              slot="append"
              color="red"
              @click="addEntry"
              v-if="item.allowMultiple"
            >
              mdi-plus
            </v-icon>
            <v-icon
              slot="append"
              color="#093637"
              @click="removeEntry(index)"
              v-if="values.length > 1"
            >
              mdi-minus
            </v-icon>
          </v-text-field>
        </validation-provider>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import dependantValueMixin from "@/mixins/dependantValueMixin";
import QuillEditor from "@/components/shared/QuillEditor";
import { mdiPlus, mdiMinus } from "@mdi/js";

export default {
  name: "TextInput",
  props: {
    item: Object,
    formDefinition: Object,
    value: [Array, Number, String],
    readOnly: Boolean,
  },
  components: {
    QuillEditor,
  },
  mixins: [dependantValueMixin],
  data() {
    return {
      values: [{ text: "" }],
      showEditor: false,
      mdiPlus,
      mdiMinus,
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        if (Array.isArray(val)) {
          this.values = val.map((v) => ({ text: v }));
        } else {
          this.values = [val].map((v) => ({ text: v }));
        }
      },
    },
    "item.question.isLong": {
      immediate: true,
      async handler() {
        this.showEditor = false;
        await this.$nextTick();
        this.showEditor = true;
      },
    },
  },
  computed: {
    isRequired() {
      const { sections } = { ...this.formDefinition.form };
      for (const section of sections) {
        for (const dependantItem of section.items) {
          if (+dependantItem.id === +this.item.question.required.dependantId) {
            if (dependantItem?.question?.type === "GROUP") {
              for (const groupDependantItem of dependantItem?.question
                ?.groupedItems ?? []) {
                const groupDependantItemValue = groupDependantItem.value;
                if (Array.isArray(groupDependantItemValue)) {
                  if (groupDependantItemValue.length > 1) {
                    return false;
                  } else {
                    const [dependantItemValue] = groupDependantItemValue;
                    return this.checkDependantValue(dependantItemValue);
                  }
                } else {
                  return this.checkDependantValue(groupDependantItemValue);
                }
              }
            } else {
              const dependantItemValue = dependantItem.value;
              if (Array.isArray(dependantItemValue)) {
                if (dependantItemValue.length > 1) {
                  return false;
                } else {
                  const [dependantValue] = dependantItemValue;
                  return this.checkDependantValue(dependantValue);
                }
              } else {
                return this.checkDependantValue(dependantItemValue);
              }
            }
          }
        }
      }
      return this.item.question.required.condition === "ALWAYS";
    },
    isRichText() {
      const { richText } = this.item.question;
      return typeof richText !== "boolean" || richText;
    },
  },
  methods: {
    addEntry() {
      this.values.push({ text: undefined });
    },
    removeEntry(index) {
      this.values.splice(index, 1);
    },
    onInput() {
      if (this.values.length > 1) {
        const values = this.values.map((v) => v.text);
        this.$emit("input", values);
      } else {
        const [{ text }] = this.values;
        this.$emit("input", text);
      }
    },
    onNumberInput() {
      if (this.values.length > 1) {
        const allNumbers = this.values.every((v) => !isNaN(+v));
        if (allNumbers) {
          const values = this.values.map((v) => +v.text);
          this.$emit("input", values);
        }
      } else {
        const [{ text }] = this.values;
        if (!isNaN(+text)) {
          this.$emit("input", text);
        }
      }
    },
  },
};
</script>
