import { Node, mergeAttributes } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";
import SignaturePlaceholder from "@/components/shared/SignaturePlaceholder";
import { escape } from "lodash";

export default Node.create({
  name: "SignaturePlaceholder",
  group: "inline",
  inline: true,
  selectable: true,
  draggable: false,
  atom: true,
  parseHTML() {
    return [
      {
        tag: "img[data-signature-src]",
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ["img", mergeAttributes(HTMLAttributes)];
  },
  addAttributes() {
    return {
      signatureSrc: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-signature-src"),
        renderHTML: (attributes) => {
          return {
            "data-signature-src": escape(attributes.signatureSrc),
          };
        },
      },
      src: {
        default: null,
      },
      maxWidth: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-max-width"),
        renderHTML: (attributes) => {
          let widthStyle = {};
          if (attributes.maxWidth) {
            widthStyle = { style: `width: ${attributes.maxWidth}px` };
          }
          return {
            "data-max-width": attributes.maxWidth,
            ...widthStyle,
          };
        },
      },
      maxHeight: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-max-height"),
        renderHTML: (attributes) => {
          return {
            "data-max-height": attributes.maxHeight,
            style: `height: ${attributes.maxHeight}px`,
          };
        },
      },
      class: {
        default: null,
      },
    };
  },
  addNodeView() {
    return VueNodeViewRenderer(SignaturePlaceholder);
  },
});
