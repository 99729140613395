<template>
  <v-card class="elevation-2">
    <v-card-text class="py-0 px-0">
      <div
        v-if="
          cwTemplate &&
          formDefinition.form.city_works_webhook_config.on_form_submission
            .observations.length > 0
        "
      >
        <div
          v-for="(item, index) in formDefinition.form.city_works_webhook_config
            .on_form_submission.observations"
          :key="item.QuestionId"
        >
          <section
            class="justify-space-between align-center px-5 py-1"
            :class="{ 'd-flex': $vuetify.breakpoint.smAndUp }"
          >
            <div style="font-size: 16px" class="py-5">{{ item.Question }}</div>

            <div
              :style="{
                'max-width': $vuetify.breakpoint.smAndUp ? '250px' : '100%',
              }"
            >
              <validation-provider
                v-slot="{ errors, valid }"
                name="Observation Mappings"
              >
                <v-autocomplete
                  autocomplete="off"
                  class="mt-0 pt-0"
                  label="Form Field"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="activityType"
                  :items="questionChoices"
                  item-text="label"
                  item-value="value"
                  v-model="item.utiliSync_question_id"
                  @change="onChange()"
                  :disabled="!canManageForms"
                >
                </v-autocomplete>
              </validation-provider>
            </div>
          </section>
          <v-divider
            v-if="
              index <
              formDefinition.form.city_works_webhook_config.on_form_submission
                .observations.length -
                1
            "
          ></v-divider>
        </div>
      </div>
      <div v-else class="py-4">
        <div v-if="!cwTemplate" style="text-align: center">
          No observations set up on selected template
        </div>
        <div v-else style="text-align: center">No observations available</div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { cloneDeep } from "lodash";
import questionsMixin from "@/mixins/questionsMixin";
import axios from "axios";
import permissionsMixin from "@/mixins/permissionsMixin";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "CityworksObservationMappings",
  props: {
    value: Object,
    activityType: String,
  },
  mixins: [questionsMixin, permissionsMixin],
  data() {
    return {
      formDefinition: {},
      copiedFormDefinition: {},
      inspectionObservationData: {},
      observationQuestions: [],
    };
  },
  computed: {
    questionChoices() {
      const { questions } = this;
      const fieldChoices = questions
        .filter(
          (question) =>
            question.question.type === "NUMBER" ||
            question.question.type === "EMAIL" ||
            question.question.type === "TEXT" ||
            question.question.type === "MULTI_SELECT" ||
            question.question.type === "SINGLE_SELECT" ||
            question.question.type === "DATE" ||
            question.question.type === "TIME"
        )
        .map(({ id, question: { label } }) => {
          return {
            value: id,
            label: `${id}. ${label}`,
          };
        });
      return [{ value: null, label: "None" }, ...fieldChoices];
    },
    cwTemplate() {
      return this.formDefinition.form.city_works_webhook_config.template_id;
    },
  },
  methods: {
    async getObservations() {
      const {
        data: { results: inspectionQa },
      } = await axios.get(`${APIURL}/cityworks/inspection_QA`, {
        params: {
          template_id:
            this.formDefinition.form.city_works_webhook_config.template_id,
        },
      });
      const {
        data: { results: inspectionTemplateQa },
      } = await axios.get(`${APIURL}/cityworks/inspection_template_QA`, {
        params: {
          template_id:
            this.formDefinition.form.city_works_webhook_config.template_id,
        },
      });
      this.formDefinition.form.city_works_webhook_config.on_form_submission.observations =
        [...inspectionQa.Questions].map((question) => {
          const answer = [...inspectionQa.Answers].find((answer) => {
            return +answer.QuestionId === +question.QuestionId;
          });
          const inspectionTemplateQaAnswers = inspectionTemplateQa
            .map((t) => t.Answers)
            .flat();
          const qaTemplateAnswer = [...inspectionTemplateQaAnswers].find(
            (answer) => {
              return +answer.QuestionId === +question.QuestionId;
            }
          );

          let answerType = "text";
          if (+qaTemplateAnswer?.AnswerFormat === 11) {
            answerType = "number";
          } else if (+qaTemplateAnswer?.AnswerFormat === 1) {
            answerType = "date";
          }

          const fromSubmissionObservation =
            this.formDefinition.form.city_works_webhook_config.on_form_submission.observations.find(
              (fromSubmissionObservation) => {
                return (
                  +question.QuestionId === +fromSubmissionObservation.QuestionId
                );
              }
            );
          const observation = {
            ...question,
            utiliSync_question_id:
              fromSubmissionObservation?.utiliSync_question_id,
            AnswerId: answer?.AnswerId,
            AnswerType: answerType,
          };

          return observation;
        });
    },
    getSelectedField(index) {
      return this.formDefinition.form.city_works_webhook_config
        .on_form_submission.observations.length === 0
        ? null
        : this.formDefinition.form.city_works_webhook_config.on_form_submission
            .observations[index].utiliSync_question_id;
    },
    onChange() {
      this.$emit("input", this.formDefinition);
    },
  },

  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.formDefinition = cloneDeep(val);
      },
    },
    cwTemplate: {
      immediate: true,
      async handler() {
        if (this.formDefinition.form.city_works_webhook_config.template_id) {
          await this.getObservations();
          this.onChange();
        }
      },
    },
  },
};
</script>
