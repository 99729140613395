<template>
  <validation-observer ref="sectionForm">
    <v-toolbar color="primary" height="56px" class="elevation-0" ref="toolbar">
      <v-list-item two-line class="px-0">
        <v-list-item-content>
          <div class="d-flex justify-space-between">
            <v-list-item-title class="white--text text-h6">
              {{ selectedControlType | itemTitle(selectedItem) }}
            </v-list-item-title>
            <v-btn
              icon
              color="white"
              @click="$emit('form-control-form-submitted')"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-toolbar>
    <form
      @submit.prevent="
        onSubmit();
        $emit('form-control-form-submitted');
      "
    >
      <v-list
        dense
        style="max-height: calc(100vh - 56px)"
        class="overflow-y-auto"
      >
        <v-list-item class="my-0 py-0">
          <v-list-item-content>
            <validation-provider
              v-slot="{ errors, valid }"
              name="Section Label"
              rules="required"
            >
              <v-text-field
                v-model="selectedItemOptions.name"
                label="Section Label"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="name"
                @change="onSubmit()"
                :disabled="!canManageForms"
              />
            </validation-provider>
          </v-list-item-content>
        </v-list-item>

        <v-list-item dense>
          <v-switch
            v-model="selectedItemOptions.numbered"
            label="Number Options"
            @change="onSubmit()"
            :disabled="!canManageForms"
          >
          </v-switch>
        </v-list-item>

        <v-list-item dense>
          <v-switch
            v-model="selectedItemOptions.isOpenByDefault"
            label="Section Open By Default"
            @change="onSubmit()"
            class="my-0 py-0"
            :disabled="!canManageForms"
          >
          </v-switch>
        </v-list-item>

        <v-list-item dense>
          <v-switch
            v-model="selectedItemOptions.hideHeader"
            label="Hide Section Header"
            @change="onSubmit()"
            class="my-0 py-0"
            :disabled="!canManageForms"
          >
          </v-switch>
        </v-list-item>

        <v-list-item class="my-0 py-0">
          <v-list-item-content>
            <validation-provider v-slot="{ errors, valid }" name="Visible">
              <v-select
                v-model="visibleCondition"
                :items="visibilityOptions"
                label="Visible"
                :error-messages="errors"
                :success="valid"
                item-text="label"
                item-value="value"
                @change="onSubmit()"
                hide-details
                :disabled="!canManageForms"
              >
              </v-select>
            </validation-provider>

            <ComparisonConditionForm
              v-if="['conditional'].includes(visibleCondition)"
              v-model="selectedItemOptions.visible"
              :formDefinition="formDefinition"
              :selectedItem="selectedItem"
              @input="onSubmit()"
              itemType="section"
            />
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="mt-0 mb-0 pt-0 pb-0">
            <v-btn color="primary" type="submit" width="100%">
              Update Item
            </v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </form>
  </validation-observer>
</template>

<script>
import { cloneDeep } from "lodash";
import ComparisonConditionForm from "./shared/ComparisonConditionForm";
import {
  comparisonTypes,
  defaultValueTypes,
  requiredOptions,
  visibilityOptions,
  orgDataOptions,
} from "@/constants/choices";
import permissionsMixin from "@/mixins/permissionsMixin";

export default {
  name: "SectionForm",
  components: {
    ComparisonConditionForm,
  },
  mixins: [permissionsMixin],
  props: {
    selectedItem: Object,
    selectedControlType: String,
    formDefinition: Object,
  },
  data() {
    return {
      selectedItemOptions: {},
      requiredCondition: "ALWAYS",
      visibleCondition: "ALWAYS",
      defaultValueTypes,
      requiredOptions,
      visibilityOptions,
      orgDataOptions,
      comparisonTypes,
    };
  },
  methods: {
    onSubmit() {
      this.$emit("input", this.selectedItemOptions);
    },
    getSelectedItemOptions() {
      this.selectedItemOptions = cloneDeep(this.selectedItem);
      if (!this.selectedItemOptions.visible) {
        this.selectedItemOptions.visible = {
          condition: "ALWAYS",
        };
      }

      if (
        this.selectedItemOptions?.visible &&
        !["NEVER", "ALWAYS"].includes(
          this.selectedItemOptions?.visible?.condition
        )
      ) {
        this.visibleCondition = "conditional";
      } else {
        this.visibleCondition = this.selectedItemOptions.visible.condition;
      }
    },
  },
  watch: {
    visibleCondition(val) {
      if (!this.selectedItemOptions.visible) {
        this.selectedItemOptions.visible = {};
      }
      if (["NEVER", "ALWAYS"].includes(val)) {
        this.selectedItemOptions.visible.condition = val;
        this.selectedItemOptions.visible.dependantId = undefined;
        this.selectedItemOptions.visible.value = undefined;
      }
    },
  },
  beforeMount() {
    this.getSelectedItemOptions();
  },
};
</script>
