import { Node, mergeAttributes } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";
import MissingImagePlaceholder from "@/components/shared/MissingImagePlaceholder";
import { escape } from "lodash";

export default Node.create({
  name: "MissingImagePlaceholder",
  group: "block",
  inline: false,
  selectable: true,
  draggable: false,
  atom: true,
  parseHTML() {
    return [
      {
        tag: "img[data-missing-param-src]",
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ["img", mergeAttributes(HTMLAttributes)];
  },
  addAttributes() {
    return {
      missingParamSrc: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-missing-param-src"),
        renderHTML: (attributes) => {
          return {
            "data-missing-param-src": escape(attributes.paramSrc),
          };
        },
      },
      src: {
        default: null,
      },
      width: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-width"),
        renderHTML: (attributes) => {
          if (!attributes.width) {
            return {};
          }
          const width = `${attributes.width}px`;
          return {
            "data-max-width": attributes.width,
            style: `width: ${attributes.width === "auto" ? "auto" : width}`,
          };
        },
      },
      height: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-height"),
        renderHTML: (attributes) => {
          if (!attributes.height) {
            return {};
          }
          const height = `${attributes.height}px`;
          return {
            "data-max-height": attributes.height,
            style: `height: ${attributes.height === "auto" ? "auto" : height}`,
          };
        },
      },
      maxWidth: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-max-width"),
        renderHTML: (attributes) => {
          if (!attributes.maxWidth) {
            return {};
          }
          const maxWidth = `${attributes.maxWidth}px`;
          return {
            "data-max-width": attributes.maxWidth,
            style: `max-width: ${
              attributes.maxWidth === "auto" ? "auto" : maxWidth
            }`,
          };
        },
      },
      maxHeight: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-max-height"),
        renderHTML: (attributes) => {
          if (!attributes.maxHeight) {
            return {};
          }
          const maxHeight = `${attributes.maxHeight}px`;
          return {
            "data-max-height": attributes.maxHeight,
            style: `max-height: ${
              attributes.maxHeight === "auto" ? "auto" : maxHeight
            }`,
          };
        },
      },
      padding: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-padding"),
        renderHTML: (attributes) => {
          if (!attributes.padding) {
            return {};
          }
          const padding = `${attributes.padding}px`;
          return {
            "data-padding": attributes.padding,
            style: `padding: ${padding}`,
          };
        },
      },
      class: {
        default: null,
      },
    };
  },
  addNodeView() {
    return VueNodeViewRenderer(MissingImagePlaceholder);
  },
});
