<template>
  <div>
    <v-expansion-panels v-model="openItems" multiple accordion>
      <draggable
        v-model="repeatingGroupItems"
        group="repeating-group-items"
        class="list-group"
        handle=".section-handle"
        @end="assignNumbersToItems"
        :disabled="!canManageForms"
      >
        <v-expansion-panel
          v-for="(r, index) of repeatingGroupItems"
          :key="r.id"
        >
          <v-expansion-panel-header class="mx-0 px-0">
            <section class="d-flex align-center justify-space-between">
              <div>
                <v-icon class="handle cursor-pointer" v-if="canManageForms">
                  {{ mdiDragVertical }}
                </v-icon>
                <template v-if="r.question">
                  {{ getQuestionType(r) | itemTitle(r) }}
                </template>
                <template v-elseif="r.information">
                  {{ getInfoType(r) | itemTitle(r) }}
                </template>
              </div>

              <v-menu v-if="canManageForms">
                <template v-slot:activator="{ on, attrs }">
                  <div icon v-bind="attrs" v-on="on" class="cursor-pointer">
                    <v-icon>{{ mdiDotsVertical }}</v-icon>
                  </div>
                </template>

                <v-list>
                  <v-list-item @click="onRemoveRepeatingGroupItem(r.id)">
                    <v-list-item-title>
                      <v-icon>{{ mdiDelete }}</v-icon>
                      Delete
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </section>
          </v-expansion-panel-header>
          <v-expansion-panel-content eager class="mx-0 px-0">
            <DateTimeForm
              :key="`${r.id}-${index}`"
              v-if="['DATE', 'TIME'].includes(getQuestionType(r))"
              v-model="repeatingGroupItems[index]"
              :formDefinition="formDefinition"
              :layer="layer"
              :gisDataFields="gisDataFields"
              :userDataFields="userDataFields"
              :fields="fields"
              :type="getQuestionType(r)"
              @input="$emit('input', repeatingGroupItems)"
            />
            <template v-else-if="['FILE'].includes(getQuestionType(r))">
              <FileForm
                :key="`${r.id}-${index}`"
                v-if="!r.question.isImage"
                v-model="repeatingGroupItems[index]"
                @input="$emit('input', repeatingGroupItems)"
              />
              <PhotoForm
                :key="`${r.id}-${index}`"
                v-else
                v-model="repeatingGroupItems[index]"
                @input="$emit('input', repeatingGroupItems)"
              />
            </template>
            <SelectForm
              :key="`${r.id}-${index}`"
              v-else-if="
                ['SINGLE_SELECT', 'MULTI_SELECT'].includes(getQuestionType(r))
              "
              v-model="repeatingGroupItems[index]"
              :formDefinition="formDefinition"
              :layer="layer"
              :gisDataFields="gisDataFields"
              :userDataFields="userDataFields"
              :fields="fields"
              @input="$emit('input', repeatingGroupItems)"
            />
            <SignatureForm
              :key="`${r.id}-${index}`"
              v-else-if="['SIGNATURE'].includes(getQuestionType(r))"
              v-model="repeatingGroupItems[index]"
              @input="$emit('input', repeatingGroupItems)"
            />
            <TextForm
              v-else-if="
                ['TEXT', 'EMAIL', 'NUMBER'].includes(getQuestionType(r))
              "
              :key="`${r.id}-${index}`"
              v-model="repeatingGroupItems[index]"
              :formDefinition="formDefinition"
              :layer="layer"
              :gisDataFields="gisDataFields"
              :userDataFields="userDataFields"
              :fields="fields"
              @input="$emit('input', repeatingGroupItems)"
            />
            <InformationForm
              v-else-if="
                ['HEADER', 'URL', 'TEXT', 'IMAGE'].includes(getInfoType(r))
              "
              :key="`${r.id}-${index}`"
              v-model="repeatingGroupItems[index]"
              :formDefinition="formDefinition"
              :layer="layer"
              :gisDataFields="gisDataFields"
              :userDataFields="userDataFields"
              :fields="fields"
              :selectedItemOptions="selectedItemOptions"
              @input="$emit('input', repeatingGroupItems)"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </draggable>
    </v-expansion-panels>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import DateTimeForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/repeating-group-form/repeating-group-items/DateTimeForm";
import FileForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/repeating-group-form/repeating-group-items/FileForm";
import PhotoForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/repeating-group-form/repeating-group-items/PhotoForm";
import SelectForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/repeating-group-form/repeating-group-items/SelectForm";
import SignatureForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/repeating-group-form/repeating-group-items/SignatureForm";
import InformationForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/repeating-group-form/repeating-group-items/InformationForm";
import TextForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/repeating-group-form/repeating-group-items/TextForm";
import draggable from "vuedraggable";
import axios from "axios";
import { mdiDragVertical, mdiDotsVertical, mdiDelete } from "@mdi/js";
import permissionsMixin from "@/mixins/permissionsMixin";
const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "RepeatingGroupItems",
  props: {
    value: Array,
    formDefinition: Object,
    selectedItemOptions: Object,
  },
  data() {
    return {
      repeatingGroupItems: [],
      layer: {},
      gisDataFields: [],
      userDataFields: [],
      fields: [],
      openItems: [],
      mdiDragVertical,
      mdiDotsVertical,
      mdiDelete,
    };
  },
  mixins: [permissionsMixin],
  components: {
    DateTimeForm,
    FileForm,
    PhotoForm,
    SelectForm,
    SignatureForm,
    TextForm,
    draggable,
    InformationForm,
  },
  beforeMount() {
    this.repeatingGroupItems = cloneDeep(this.value);
    this.getGisDataFields();
  },
  methods: {
    getQuestionType(item) {
      return item?.question?.type;
    },
    getInfoType(item) {
      return item?.information?.type;
    },
    assignNumbersToItems() {
      for (const [index, item] of this.repeatingGroupItems.entries()) {
        this.repeatingGroupItems[index] = {
          ...item,
          id: index + 1,
        };
      }
      this.$emit("input", this.repeatingGroupItems);
    },
    async getLayer() {
      const { map_service_id: mapServiceId } = this.formDefinition;
      const {
        data: { results },
      } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
      this.layer = results;
    },
    onRemoveRepeatingGroupItem(selectedRepeatingGroupItemOptionsId) {
      this.$emit(
        "remove-repeating-group-item",
        selectedRepeatingGroupItemOptionsId
      );
    },
    async getGisDataFields() {
      if (this.formDefinition.map_service_id) {
        const {
          data: { results },
        } = await axios.get(`${APIURL}/gis_data_fields`, {
          params: { map_service_id: this.formDefinition.map_service_id },
        });
        this.gisDataFields = results;
      }
    },
    async getFeatureService() {
      try {
        const { map_service_id: mapServiceId } = this.formDefinition;
        const {
          data: {
            results: { service_url: featureServiceUrl, token_type: tokenType },
          },
        } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
        const token =
          tokenType === "AGOL" ? localStorage.getItem("esri_token") : undefined;
        const params = {
          f: "pjson",
          token,
        };
        const {
          data: { fields },
        } = await axios.get(featureServiceUrl, { params });
        this.fields = fields;
      } catch (error) {
        this.fields = [];
      }
    },
    async getUserDataFields() {
      const { user_group_id: userGroupId } = JSON.parse(
        localStorage.getItem("auth")
      );

      const {
        data: { results },
      } = await axios.get(`${APIURL}/user_data_fields`, {
        params: {
          user_group_id: userGroupId,
        },
      });
      this.userDataFields = results;
    },
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        if (Array.isArray(val)) {
          this.repeatingGroupItems = cloneDeep(val);
        }
      },
    },
  },
};
</script>
