<template>
  <div>
    <validation-provider
      slim
      v-slot="{ errors, valid }"
      :name="item.question.label"
      :rules="{ required: isRequired }"
    >
      <v-card class="elevation-0" :class="{ selected }" width="100%">
        <v-card-title
          class="ma-0 pa-0 d-flex flex-row justify-space-between elevation-0"
        >
          <p class="caption">{{ item.question.label }}</p>
          <v-btn text @click="clearSignature" class="mt-n3">Clear</v-btn>
        </v-card-title>
        <v-card-text class="pa-0 mb-2" :class="{ selected }">
          <div id="read-only-box" v-if="readOnly"></div>
          <VueSignaturePad
            v-else
            width="100%"
            height="200px"
            ref="signaturePad"
            :options="{ onBegin, onEnd }"
            :customStyle="{ border: '3px black solid' }"
          />
          <p class="caption" v-if="!valid">{{ errors && errors[0] }}</p>
        </v-card-text>
      </v-card>
    </validation-provider>
  </div>
</template>

<script>
export default {
  name: "GroupSignaturePad",
  props: {
    item: Object,
    selected: Boolean,
    readOnly: Boolean,
  },
  computed: {
    isRequired() {
      return this.item?.question?.required?.condition === "ALWAYS";
    },
  },
  methods: {
    onBegin() {
      this.$nextTick(() => {
        this.$refs.signaturePad.resizeCanvas();
      });
    },
    clearSignature() {
      this.$refs.signaturePad.clearSignature();
      const { data } = this.$refs.signaturePad.saveSignature();
      this.$emit("input", data);
    },
    onEnd() {
      const { data } = this.$refs.signaturePad.saveSignature();
      this.$emit("input", data);
    },
  },
};
</script>

<style scoped>
#read-only-box {
  width: 100%;
  border: 3px black solid;
  height: 200px;
}
</style>
