<template>
  <span>
    <span class="symbol" v-if="value === LOGIC_COMPARISON.EQUALS"> = </span>
    <span class="symbol" v-else-if="value === LOGIC_COMPARISON.CONTAINS">
      ⊂
    </span>
    <span class="symbol" v-else-if="value === LOGIC_COMPARISON.GREATER_THAN">
      &#62;
    </span>
    <span
      class="symbol"
      v-else-if="value === LOGIC_COMPARISON.GREATER_THAN_OR_EQUAL"
    >
      ≥
    </span>
    <span class="symbol" v-else-if="value === LOGIC_COMPARISON.LESS_THAN">
      &#60;
    </span>
    <span
      class="symbol"
      v-else-if="value === LOGIC_COMPARISON.LESS_THAN_OR_EQUAL"
    >
      ≤
    </span>
    <span class="symbol" v-else-if="value === LOGIC_COMPARISON.DOES_NOT_EQUAL">
      ≠
    </span>
    <span class="symbol" v-else-if="value === LOGIC_COMPARISON.NOT_EMPTY">
      <v-icon small :color="selected ? '#4caf50' : undefined">
        {{ mdiFormTextboxPassword }}
      </v-icon>
    </span>
  </span>
</template>

<script>
import { LOGIC_COMPARISON } from "@/constants/logicChoices";
import {
  mdiCalendarArrowLeft,
  mdiCalendarArrowRight,
  mdiCalendarRange,
  mdiFormTextboxPassword,
} from "@mdi/js";

export default {
  name: "LogicComparisonIcon",
  props: {
    value: String,
    selected: Boolean,
  },
  data() {
    return {
      LOGIC_COMPARISON,
      mdiCalendarArrowLeft,
      mdiCalendarArrowRight,
      mdiCalendarRange,
      mdiFormTextboxPassword,
    };
  },
};
</script>

<style scoped>
.symbol {
  font-size: 20px;
}
</style>
