<template>
  <validation-observer ref="form">
    <form>
      <v-list style="max-height: calc(100vh - 56px)" class="overflow-y-auto">
        <v-list-item>
          <validation-provider v-slot="{ errors, valid }" name="Filter">
            <v-text-field
              v-model="filterText"
              label="Form Field"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="filterText"
            />
          </validation-provider>
        </v-list-item>
        <v-list-item
          v-for="section of filteredformDefinitionWithOnlyQuestions.form
            .sections"
          :key="section.id"
          class="pa-0 ma-0"
        >
          <v-list>
            <v-list-item class="caption">{{ section.name }}</v-list-item>
            <v-list-item
              v-for="item of section.items"
              :key="item.id"
              class="question d-block"
              @click="copyToClipboard(item)"
            >
              <transition name="slide-fade">
                <p v-if="showCopyAnimation[item.id]" class="absolute">
                  <span class="param mr-1">
                    {{ `{{params.${item.id}\}\}` }}
                  </span>
                  {{ item.question.label }}
                </p>
              </transition>
              <p>
                <span class="param mr-1">
                  {{ `{{params.${item.id}\}\}` }}
                </span>
                {{ item.question.label }}
              </p>
            </v-list-item>
          </v-list>
        </v-list-item>
      </v-list>
    </form>
  </validation-observer>
</template>

<script>
import questionsMixin from "@/mixins/questionsMixin";
import { cloneDeep } from "lodash";

export default {
  name: "FormPlaceholdersForm",
  mixins: [questionsMixin],
  props: {
    formDefinition: Object,
    type: String,
  },
  data() {
    return {
      filterText: "",
      showCopyAnimation: {},
    };
  },
  computed: {
    formDefinitionWithOnlyQuestions() {
      const formDefinition = cloneDeep(this.formDefinition);
      for (const [sectionIndex] of formDefinition.form.sections.entries()) {
        const { items } = formDefinition.form.sections[sectionIndex];
        formDefinition.form.sections[sectionIndex].items = items.filter(
          ({ question }) => typeof question === "object" && question !== null
        );
      }
      return formDefinition;
    },
    questionChoices() {
      const { type, formDefinitionWithOnlyQuestions, filterQuestionsByType } =
        this;
      const formDefinition = cloneDeep(formDefinitionWithOnlyQuestions);
      if (type === "formula") {
        return filterQuestionsByType(formDefinition, [
          "NUMBER",
          "SINGLE_SELECT",
        ]);
      }
      return formDefinitionWithOnlyQuestions;
    },
    filteredformDefinitionWithOnlyQuestions() {
      const { filterText, questionChoices } = this;
      const formDefinition = cloneDeep(questionChoices);
      if (!filterText) {
        return questionChoices;
      }
      for (const [sectionIndex] of formDefinition.form.sections.entries()) {
        const { items } = formDefinition.form.sections[sectionIndex];
        formDefinition.form.sections[sectionIndex].items = items
          .filter(({ question: { label } = {} }) =>
            label.toLowerCase().includes(filterText.toLowerCase())
          )
          .sort((a, b) => +a.id - +b.id);
      }
      return formDefinition;
    },
  },
  methods: {
    filterQuestionsByType(formDefinition, questionTypes) {
      const formDef = cloneDeep(formDefinition);
      for (const [sectionIndex] of formDef.form.sections.entries()) {
        const { items } = formDef.form.sections[sectionIndex];
        formDef.form.sections[sectionIndex].items = items
          .filter(
            ({ question }) =>
              typeof question === "object" &&
              question !== null &&
              questionTypes.includes(question.type)
          )
          .sort((a, b) => +a.id - +b.id);
      }
      return formDef;
    },
    async copyToClipboard(item) {
      await navigator.clipboard.writeText(`{{params.${item.id}}}`);
      this.$emit("placeholder-copied");
      this.$set(this.showCopyAnimation, item.id, true);
      setTimeout(() => {
        this.$set(this.showCopyAnimation, item.id, false);
      }, 1000);
    },
  },
};
</script>

<style scoped>
.param {
  color: rgb(33, 150, 243);
}

.question {
  cursor: pointer;
}

.absolute {
  position: absolute;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  animation: slide-fade 0.5s;
}

.slide-fade-enter,
.slide-fade-leave-to {
  animation: slide-fade 0.5s;
  opacity: 0;
}

@keyframes slide-fade {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-3px);
  }
  50% {
    transform: translateY(-6px);
  }
  75% {
    transform: translateY(-9px);
  }
  100% {
    transform: translateY(-10px);
  }
}
</style>
