var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showAddAdvancedInfoSettingsDialog,
        "max-width": "600px",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
      },
    },
    [
      _c(
        "v-toolbar",
        { attrs: { dark: "", color: "primary" } },
        [
          _c("v-toolbar-title", [_vm._v(" Add Advanced Setting ")]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { icon: "", dark: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("add-advanced-settings-dialog-close")
                },
              },
            },
            [_c("v-icon", [_vm._v(" " + _vm._s(_vm.mdiClose) + " ")])],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "rounded-0" },
        [
          _c(
            "v-card-text",
            [
              _c("p", { staticClass: "caption" }, [
                _vm._v("Select an advanced setting type to add"),
              ]),
              _c(
                "v-card",
                {
                  staticClass: "my-3",
                  on: {
                    click: function ($event) {
                      return _vm.$emit(
                        "advanced-settings-selected",
                        _vm.ADVANCED_SETTINGS_CHOICES.CREATE
                      )
                    },
                  },
                },
                [
                  _c(
                    "v-card-text",
                    {
                      staticClass: "d-flex align-center justify-space-between",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex gap" },
                        [
                          _c("v-icon", { attrs: { color: "primary" } }, [
                            _vm._v(" " + _vm._s(_vm.mdiPlaylistPlus) + " "),
                          ]),
                          _c("div", [
                            _c("div", { staticClass: "primary--text" }, [
                              _c("b", [_vm._v("Create")]),
                            ]),
                            _c("div", { staticClass: "primary--text" }, [
                              _vm._v(
                                " Set the conditions for the creation of this field "
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "v-btn",
                            { attrs: { icon: "", color: "primary" } },
                            [
                              _c("v-icon", { attrs: { "x-large": "" } }, [
                                _vm._v(" " + _vm._s(_vm.mdiMenuRight) + " "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              !_vm.hasVisibleSettings
                ? _c(
                    "v-card",
                    {
                      staticClass: "my-3",
                      on: {
                        click: function ($event) {
                          return _vm.$emit(
                            "advanced-settings-selected",
                            _vm.ADVANCED_SETTINGS_CHOICES.VISIBILITY
                          )
                        },
                      },
                    },
                    [
                      _c(
                        "v-card-text",
                        {
                          staticClass:
                            "d-flex align-center justify-space-between",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex gap" },
                            [
                              _c("v-icon", { attrs: { color: "primary" } }, [
                                _vm._v(" " + _vm._s(_vm.mdiEye) + " "),
                              ]),
                              _c("div", [
                                _c("div", { staticClass: "primary--text" }, [
                                  _c("b", [_vm._v("Visibility")]),
                                ]),
                                _c("div", { staticClass: "primary--text" }, [
                                  _vm._v(
                                    " Set the conditions for the visibility of this field "
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "v-btn",
                                { attrs: { icon: "", color: "primary" } },
                                [
                                  _c("v-icon", { attrs: { "x-large": "" } }, [
                                    _vm._v(
                                      " " + _vm._s(_vm.mdiMenuRight) + " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }