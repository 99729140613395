<template>
  <validation-provider
    slim
    v-slot="{ errors, valid }"
    :name="item.question.label"
  >
    <v-menu v-model="showDatePicker" :close-on-content-click="false">
      <template v-slot:activator="{ on }">
        <v-text-field
          readonly
          v-on="on"
          color="primary"
          v-model="date"
          :error-messages="errors"
          :success="valid"
          @click.stop
          @click:append="on.click"
          :append-icon="mdiCalendarBlankOutline"
        ></v-text-field>
      </template>
      <v-date-picker v-model="date" @change="onInput"></v-date-picker>
    </v-menu>
  </validation-provider>
</template>

<script>
import { mdiCalendarBlankOutline } from "@mdi/js";
export default {
  name: "RepeatinghGroupDefaultValueDateInput",
  props: { value: String, item: Object },
  data() {
    return { mdiCalendarBlankOutline, showDatePicker: false };
  },
  methods: {
    async onInput() {
      this.$emit("input", this.date);
      this.showDatePicker = false;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.date = val;
      },
    },
  },
};
</script>
