var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _vm.value === _vm.MATCH_CHOICE_VALUES.EQUAL
      ? _c("span", { staticClass: "symbol" }, [_vm._v(" = ")])
      : _vm.value === _vm.MATCH_CHOICE_VALUES.GREATER_THAN
      ? _c("span", { staticClass: "symbol" }, [_vm._v(" > ")])
      : _vm.value === _vm.MATCH_CHOICE_VALUES.GREATER_THAN_OR_EQUAL
      ? _c("span", { staticClass: "symbol" }, [_vm._v(" ≥ ")])
      : _vm.value === _vm.MATCH_CHOICE_VALUES.LESS_THAN
      ? _c("span", { staticClass: "symbol" }, [_vm._v(" < ")])
      : _vm.value === _vm.MATCH_CHOICE_VALUES.LESS_THAN_OR_EQUAL
      ? _c("span", { staticClass: "symbol" }, [_vm._v(" ≤ ")])
      : _vm.value === _vm.MATCH_CHOICE_VALUES.NOT_EQUAL
      ? _c("span", { staticClass: "symbol" }, [_vm._v(" ≠ ")])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }