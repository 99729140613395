<template>
  <div class="ma-0 pa-0 elevation-0 mx-auto">
    <section class="elevation-0 pa-0">
      <div class="ma-0 pa-0">
        <EditTemplate
          :activeTab="activeTab"
          :disabled="!canManageForms"
          :selectedFormDefLayer="selectedFormDefLayer"
          :isLocateRequestLayer="isLocateRequestLayer"
          v-model="formDefinition"
          @input="$emit('input', formDefinition)"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import questionsMixin from "@/mixins/questionsMixin";
import EditTemplate from "@/components/forms/form-builder-edit-form/report-template-form/EditTemplate.vue";
import permissionsMixin from "@/mixins/permissionsMixin";

export default {
  name: "ReportTemplateForm",
  props: {
    value: Object,
    activeTab: String,
    htmlVersion: Number,
    selectedFormDefLayer: Object,
    isLocateRequestLayer: Boolean,
  },
  components: {
    EditTemplate,
  },
  mixins: [questionsMixin, permissionsMixin],
  computed: {
    questionChoices() {
      return this.questions.map(({ id, number, question: { label } = {} }) => ({
        id,
        value: `{{params.${id}}}`,
        label: `${number} ${label}`,
      }));
    },
  },
  data() {
    return {
      formDefinition: {},
      showEditTemplateDialog: false,
      showPreviewTemplateDialog: false,
      showPlaceholderDrawer: false,
      showVersionAlert: false,
      tab: 0,
    };
  },
  methods: {
    updateFormTemplate() {
      this.showVersionAlert = false;
      this.formDefinition.html_merge_definition_version = 2;
      this.$emit("input", this.formDefinition);
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.formDefinition = cloneDeep(val);
      },
    },
  },
  beforeMount() {
    if (
      this.formDefinition.html_merge_definition_version === 1 ||
      this.formDefinition.html_merge_definition_version === null
    ) {
      this.showVersionAlert = true;
    }
  },
  mounted() {
    if (this.formDefinition.form?.formDescription?.pdfFileName) {
      this.tab = 1;
    }
  },
};
</script>

<style scoped>
textarea {
  border: none;
}

textarea:focus {
  outline: none;
}
</style>
