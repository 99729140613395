import { Node, mergeAttributes } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";
import PageBreak from "@/components/shared/PageBreak";

export default Node.create({
  name: "PageBreak",
  group: "block",
  inline: false,
  selectable: true,
  draggable: false,
  atom: true,
  parseHTML() {
    return [
      {
        tag: "div.pageBreak",
      },
    ];
  },
  addAttributes() {
    return {
      class: {
        default: "pageBreak",
      },
    };
  },
  renderHTML({ HTMLAttributes }) {
    return ["div", mergeAttributes(HTMLAttributes)];
  },
  addNodeView() {
    return VueNodeViewRenderer(PageBreak);
  },
});
