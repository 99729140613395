var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showPreviewTemplateDialog,
        "max-width": "800px",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-0 ma-0" },
        [
          _c(
            "v-toolbar",
            { ref: "toolbar", attrs: { dark: "", color: "primary" } },
            [
              _c("v-toolbar-title", [_vm._v("Sample PDF")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("preview-template-dialog-close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              staticClass: "pa-0 ma-0 flex-grow-1 d-flex flex-column",
              style: {
                "background-color": "#f1f2f1",
                height: _vm.$vuetify.breakpoint.xsOnly
                  ? `${_vm.contentHeight}px`
                  : "65vh",
                "overflow-y": "auto",
                "max-height": _vm.$vuetify.breakpoint.xsOnly
                  ? undefined
                  : "60vh",
              },
            },
            [
              !_vm.templateNotFound
                ? _c("div", { staticClass: "d-flex flex-grow-1" }, [
                    _c("iframe", {
                      key: _vm.generatedPdfUrl,
                      staticClass: "flex-grow-1",
                      attrs: {
                        src: _vm.generatedPdfUrl,
                        type: "application/pdf",
                      },
                    }),
                  ])
                : _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-grow-1 justify-center align-center",
                    },
                    [_vm._v(" Template not found ")]
                  ),
              _vm.showEditFormDataDialog
                ? _c("EditFormDataDialog", {
                    attrs: {
                      showEditFormDataDialog: _vm.showEditFormDataDialog,
                      formDefinition: _vm.computedFormDefinition,
                    },
                    on: {
                      "edit-form-dialog-close": function ($event) {
                        _vm.showEditFormDataDialog = false
                      },
                      input: function ($event) {
                        _vm.computedFormDefinition = $event
                      },
                      "form-data-submitted": function ($event) {
                        _vm.params = $event
                        _vm.showEditFormDataDialog = false
                        _vm.generatePdfPreview()
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { ref: "cardAction", staticClass: "d-flex justify-end py-4" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", dark: "" },
                  on: {
                    click: function ($event) {
                      _vm.showEditFormDataDialog = true
                    },
                  },
                },
                [_vm._v(" Edit Form Data ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }