import { render, staticRenderFns } from "./AddAdvancedSettingsDialog.vue?vue&type=template&id=427b9a24&scoped=true&"
import script from "./AddAdvancedSettingsDialog.vue?vue&type=script&lang=js&"
export * from "./AddAdvancedSettingsDialog.vue?vue&type=script&lang=js&"
import style0 from "./AddAdvancedSettingsDialog.vue?vue&type=style&index=0&id=427b9a24&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "427b9a24",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('427b9a24')) {
      api.createRecord('427b9a24', component.options)
    } else {
      api.reload('427b9a24', component.options)
    }
    module.hot.accept("./AddAdvancedSettingsDialog.vue?vue&type=template&id=427b9a24&scoped=true&", function () {
      api.rerender('427b9a24', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/forms/form-builder-edit-form/form-control-edit-form/shared/advanced-settings-form/AddAdvancedSettingsDialog.vue"
export default component.exports