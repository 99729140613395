<template>
  <validation-observer>
    <v-list dense>
      <v-list-item class="pr-0 mr-0 my-0 py-0">
        <v-list-item-content>
          <validation-provider v-slot="{ errors, valid }" name="Dependent">
            <v-select
              autocomplete="off"
              v-model="createOptions.dependent"
              :items="dependentValueOptions"
              label="Dependent"
              :error-messages="errors"
              :success="valid"
              item-text="label"
              item-value="value"
              @change="$emit('input', createOptions)"
              hide-details
            ></v-select>
          </validation-provider>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        :key="createOptions.dependent"
        class="pr-0 mr-0 my-0 py-0"
        v-if="['USER'].includes(createOptions.dependent)"
      >
        <v-list-item-content>
          <validation-provider
            v-slot="{ errors, valid }"
            name="User Field"
            rules="required"
          >
            <v-select
              autocomplete="off"
              v-model="createOptions.dependentFieldId"
              :items="userDataChoices"
              label="User Field"
              :error-messages="errors"
              :success="valid"
              item-text="label"
              item-value="value"
              @change="$emit('input', createOptions)"
              hide-details
            ></v-select>
          </validation-provider>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        :key="createOptions.dependent"
        v-else-if="['GIS_DATA'].includes(createOptions.dependent)"
        class="pr-0 mr-0 my-0 py-0"
      >
        <v-list-item-content>
          <validation-provider
            v-slot="{ errors, valid }"
            name="UtiliSync Field"
            rules="required"
          >
            <v-select
              autocomplete="off"
              v-model="createOptions.dependentFieldId"
              :items="gisDataChoices"
              label="UtiliSync Field"
              :error-messages="errors"
              :success="valid"
              item-text="label"
              item-value="value"
              @change="$emit('input', createOptions)"
              hide-details
            ></v-select>
          </validation-provider>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        :key="createOptions.dependent"
        v-else-if="['GIS'].includes(createOptions.dependent)"
        class="pr-0 mr-0 my-0 py-0"
      >
        <v-list-item-content>
          <validation-provider
            v-slot="{ errors, valid }"
            name="GIS Field"
            rules="required"
          >
            <v-select
              autocomplete="off"
              v-model="createOptions.dependentFieldId"
              :items="fieldChoices"
              name="gisField"
              label="GIS Field"
              :error-messages="errors"
              :success="valid"
              item-text="label"
              item-value="value"
              @change="
                $emit('input', createOptions);
                gisDataValueChoices();
              "
              hide-details
            ></v-select>
          </validation-provider>
        </v-list-item-content>
      </v-list-item>

      <v-list-item class="pr-0 mr-0 my-0 py-0">
        <v-list-item-content>
          <validation-provider
            v-slot="{ errors, valid }"
            name="Comparison"
            rules="required"
          >
            <v-select
              autocomplete="off"
              v-model="createOptions.condition"
              :items="comparisonTypes"
              label="Comparison"
              :error-messages="errors"
              :success="valid"
              item-text="label"
              item-value="value"
              @change="$emit('input', createOptions)"
              hide-details
            ></v-select>
          </validation-provider>
        </v-list-item-content>
      </v-list-item>

      <v-list-item class="pr-0 mr-0 my-0 py-0">
        <v-list-item-content>
          <validation-provider
            v-slot="{ errors, valid }"
            name="Value"
            rules="required"
          >
            <v-select
              autocomplete="off"
              v-if="gisDataFieldOptionChoices.length > 0"
              v-model="createOptions.value"
              :items="gisDataFieldOptionChoices"
              label="Value"
              :error-messages="errors"
              :success="valid"
              item-text="label"
              item-value="value"
              @change="$emit('input', createOptions)"
              hide-details
            ></v-select>
            <v-text-field
              v-else
              v-model="createOptions.value"
              label="Value"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="value"
              @change="$emit('input', createOptions)"
            />
          </validation-provider>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </validation-observer>
</template>

<script>
import { cloneDeep } from "lodash";
import axios from "axios";
import { comparisonTypes } from "@/constants/choices";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "CreateComparisonConditionForm",
  props: {
    value: Object,
    formDefinition: Object,
    selectedItem: Object,
    edit: Boolean,
  },
  computed: {
    fieldChoices() {
      const fieldChoices = this.fields?.map(({ name, alias }) => ({
        value: name,
        label: alias || name,
      }));
      return fieldChoices ?? [];
    },
    userDataChoices() {
      const apiChoices = this.userDataFields?.map(
        ({ user_data_field_id: value, name: label }) => ({
          value,
          label,
        })
      );
      return [
        { value: "f_name", label: "First Name" },
        { value: "l_name", label: "Last Name" },
        { value: "full_name", label: "Full Name" },
        { value: "email", label: "Email" },
        ...apiChoices,
      ];
    },
    gisDataChoices() {
      return this.gisDataFields?.map(
        ({ gis_data_field_id: value, name, alias }) => ({
          value,
          label: alias || name,
        })
      );
    },
    dependentValueOptions() {
      return this.defaultValueTypes;
    },
    gisDataFieldOptionChoices() {
      if (this.createOptions.dependent === "GIS_DATA") {
        return (
          this.gisDataFields
            ?.find(
              (g) => g.gis_data_field_id === this.createOptions.dependentFieldId
            )
            ?.gis_data_field_options?.map(({ label, value }) => {
              return {
                label,
                value,
              };
            }) ?? []
        );
      }
      return [];
    },
  },
  data() {
    return {
      gisDataFields: [],
      userDataFields: [],
      defaultValueTypes: [
        {
          value: "USER",
          label: "User Data",
        },
        {
          value: "GIS",
          label: "GIS Field",
        },
        {
          value: "GIS_DATA",
          label: "UtiliSync Field",
        },
      ],
      createOptions: {},
      comparisonTypes,
      fields: [],
      layer: {},
    };
  },
  methods: {
    async getUserDataFields() {
      const { user_group_id: userGroupId } = JSON.parse(
        localStorage.getItem("auth")
      );

      const {
        data: { results },
      } = await axios.get(`${APIURL}/user_data_fields`, {
        params: {
          user_group_id: userGroupId,
        },
      });
      this.userDataFields = results;
    },
    async getGisDataFields() {
      if (this.formDefinition.map_service_id) {
        const {
          data: { results },
        } = await axios.get(`${APIURL}/gis_data_fields`, {
          params: { map_service_id: this.formDefinition.map_service_id },
        });
        this.gisDataFields = results;
      }
    },
    async getFeatureService() {
      try {
        const { map_service_id: mapServiceId } = this.formDefinition;
        const {
          data: {
            results: { service_url: featureServiceUrl, token_type: tokenType },
          },
        } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
        const token =
          tokenType === "AGOL" ? localStorage.getItem("esri_token") : undefined;
        const params = {
          f: "pjson",
          token,
        };
        const {
          data: { fields },
        } = await axios.get(featureServiceUrl, { params });
        this.fields = fields;
      } catch (error) {
        this.fields = [];
      }
    },
    async getLayer() {
      const { map_service_id: mapServiceId } = this.formDefinition;
      const {
        data: { results },
      } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
      this.layer = results;
    },
  },
  mounted() {
    this.getLayer();
    this.getUserDataFields();
    this.getFeatureService();
    this.getGisDataFields();
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.createOptions = cloneDeep(val) ?? {};
      },
    },
  },
};
</script>
