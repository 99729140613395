<template>
  <validation-observer slim ref="imageForm">
    <form @submit.prevent="submit">
      <v-card>
        <v-card-text class="pt-2 pb-3 px-4">
          <div>
            <validation-provider
              v-slot="{ errors, valid }"
              name="Input URL"
              :rules="{
                required: true,
                regex:
                  /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
              }"
            >
              <v-textarea
                rows="1"
                auto-grow
                v-model="image.inputImageURL"
                label="Input URL"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="name"
                class="pt-1"
              />
            </validation-provider>
          </div>

          <div class="py-3">
            <validation-provider
              v-slot="{ errors, valid }"
              name="Image Width"
              :rules="{
                required: true,
                regex: /\d+|auto/,
              }"
            >
              <v-text-field
                v-model="image.imageWidth"
                label="Image Width"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="name"
                class="pt-1"
                @keydown="onNumberKeyDown"
                @change="checkImageWidthVal()"
                @input="onImageWidthInput()"
              />
            </validation-provider>
          </div>

          <div class="py-3">
            <validation-provider
              v-slot="{ errors, valid }"
              name="Image Height"
              :rules="{
                required: true,
                regex: /\d+|auto/,
              }"
            >
              <v-text-field
                v-model="image.imageHeight"
                label="Image Height"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="name"
                class="pt-1"
                @keydown="onNumberKeyDown"
                @change="checkImageHeightVal()"
                @input="onImageHeightInput()"
              />
            </validation-provider>
          </div>

          <div class="py-3">
            <validation-provider
              v-slot="{ errors, valid }"
              name="Image Padding"
              :rules="{ required: true, numeric: true, min_value: 0 }"
            >
              <v-text-field
                v-model.number="image.imagePadding"
                label="Image Padding"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="name"
                class="pt-1"
                type="number"
                @keydown="onNumberKeyDown"
              />
            </validation-provider>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="$emit('cancel')"> Cancel </v-btn>
          <v-btn color="primary" type="submit"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </validation-observer>
</template>

<script>
export default {
  name: "ImageForm",
  data() {
    return {
      image: {
        inputImageURL: "",
        imageWidth: 0,
        imageHeight: 0,
        imagePadding: 0,
      },
    };
  },
  methods: {
    onImageWidthInput() {
      if (!isNaN(this.image.imageWidth)) {
        this.image.imageHeight = "auto";
      }
    },
    onImageHeightInput() {
      if (!isNaN(this.image.imageHeight)) {
        this.image.imageWidth = "auto";
      }
    },
    checkImageWidthVal() {
      if (!isNaN(this.image.imageWidth)) {
        if (+this.image.imageWidth > 740) {
          this.image.imageWidth = 740;
        } else if (+this.image.imageWidth < 0) {
          this.image.imageWidth = 0;
        }
      }
    },
    checkImageHeightVal() {
      if (!isNaN(this.image.imageHeight)) {
        if (+this.image.imageHeight > 740) {
          this.image.imageHeight = 740;
        } else if (+this.image.imageHeight < 0) {
          this.image.imageHeight = 0;
        }
      }
    },
    onNumberKeyDown(e) {
      if (["e", "+", "-", "*", "/"].includes(e.key.toLowerCase())) {
        e.preventDefault();
      }
    },
    async submit() {
      const success = await this.$refs.imageForm.validate();
      if (!success) {
        return;
      }
      this.$emit("add-image", { ...this.image });
    },
  },
};
</script>
