<template>
  <v-card class="elevation-2">
    <v-card-text class="pl-6 pr-6">
      <v-row>
        <v-col cols="12">
          <validation-provider v-slot="{ errors, valid }" name="Activity Type">
            <v-select
              autocomplete="off"
              label="Activity Type"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="activityType"
              :items="activityTypes"
              item-text="label"
              item-value="value"
              v-model="copiedFormDefinition.form.city_works_webhook_config.type"
              @change="
                onChange(
                  'type',
                  copiedFormDefinition.form.city_works_webhook_config.type
                )
              "
              :disabled="!canManageForms"
            >
            </v-select>
          </validation-provider>
        </v-col>
        <v-col cols="12">
          <validation-provider v-slot="{ errors, valid }" name="Domain">
            <v-select
              autocomplete="off"
              v-if="!cityworksAuthInvalid"
              label="Domain"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="domain"
              :items="domainChoices"
              item-text="label"
              item-value="value"
              v-model="
                copiedFormDefinition.form.city_works_webhook_config.domain
              "
              @change="
                onChange(
                  'domain',
                  copiedFormDefinition.form.city_works_webhook_config.domain
                )
              "
              :disabled="!canManageForms"
            >
            </v-select>
            <v-text-field
              v-else
              label="Domain"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="domain"
              v-model="
                copiedFormDefinition.form.city_works_webhook_config.domain
              "
              @change="
                onChange(
                  'domain',
                  copiedFormDefinition.form.city_works_webhook_config.domain
                )
              "
              :disabled="!canManageForms"
            >
            </v-text-field>

            <section
              v-if="getDomainsError || cityworksAuthInvalid"
              :class="{
                'red--text': !cityworksAuthInvalid,
                'orange--text': cityworksAuthInvalid,
              }"
            >
              <template v-if="!cityworksAuthInvalid">
                Error domains: {{ getDomainsError }}
              </template>
              <template v-else>
                A list of values is not available because you are not signed in
                to Cityworks
              </template>
            </section>
          </validation-provider>
        </v-col>
        <v-col cols="12">
          <validation-provider v-slot="{ errors, valid }" name="Entity Group">
            <v-select
              autocomplete="off"
              v-if="!cityworksAuthInvalid"
              label="Entity Group"
              hide-details
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="entityGroup"
              :items="entityGroupChoices"
              item-text="label"
              item-value="value"
              v-model="
                copiedFormDefinition.form.city_works_webhook_config.entity_group
              "
              @change="
                onChange(
                  'entity_group',
                  copiedFormDefinition.form.city_works_webhook_config
                    .entity_group
                )
              "
              :disabled="!canManageForms"
            >
            </v-select>
            <v-text-field
              v-else
              label="Entity Group"
              hide-details
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="entityGroup"
              v-model="
                copiedFormDefinition.form.city_works_webhook_config.entity_group
              "
              @change="
                onChange(
                  'entity_group',
                  copiedFormDefinition.form.city_works_webhook_config
                    .entity_group
                )
              "
              :disabled="!canManageForms"
            >
            </v-text-field>

            <section
              v-if="getEntityGroupsError || cityworksAuthInvalid"
              :class="{
                'red--text': !cityworksAuthInvalid,
                'orange--text': cityworksAuthInvalid,
              }"
            >
              <template v-if="!cityworksAuthInvalid">
                Error getting entity groups: {{ getEntityGroupsError }}
              </template>
              <template v-else>
                A list of values is not available because you are not signed in
                to Cityworks
              </template>
            </section>
          </validation-provider>
        </v-col>
        <v-col cols="12">
          <validation-provider v-slot="{ errors, valid }" name="Entity Type">
            <v-select
              autocomplete="off"
              v-if="!cityworksAuthInvalid"
              label="Entity Type"
              hide-details
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="entityType"
              :items="entityTypeChoices"
              item-text="label"
              item-value="value"
              v-model="
                copiedFormDefinition.form.city_works_webhook_config.entity_type
              "
              @change="
                onChange(
                  'entity_type',
                  copiedFormDefinition.form.city_works_webhook_config
                    .entity_type
                )
              "
              :disabled="!canManageForms"
            >
            </v-select>
            <v-text-field
              v-else
              label="Entity Type"
              hide-details
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="entityType"
              v-model="
                copiedFormDefinition.form.city_works_webhook_config.entity_type
              "
              @change="
                onChange(
                  'entity_type',
                  copiedFormDefinition.form.city_works_webhook_config
                    .entity_type
                )
              "
              :disabled="!canManageForms"
            >
            </v-text-field>

            <section
              v-if="getEntityTypesError || cityworksAuthInvalid"
              :class="{
                'red--text': !cityworksAuthInvalid,
                'orange--text': cityworksAuthInvalid,
              }"
            >
              <template v-if="!cityworksAuthInvalid">
                Error getting entity types: {{ getEntityTypesError }}
              </template>
              <template v-else>
                A list of values is not available because you are not signed in
                to Cityworks
              </template>
            </section>
          </validation-provider>
        </v-col>
        <v-col cols="12">
          <validation-provider v-slot="{ errors, valid }" name="Template">
            <v-select
              autocomplete="off"
              v-if="!cityworksAuthInvalid"
              label="Template"
              hide-details
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="templateId"
              :items="templateChoices"
              item-text="label"
              item-value="value"
              v-model="
                copiedFormDefinition.form.city_works_webhook_config.template_id
              "
              @change="
                onChange(
                  'template_id',
                  copiedFormDefinition.form.city_works_webhook_config
                    .template_id
                )
              "
              :disabled="!canManageForms"
            >
            </v-select>
            <v-text-field
              v-else
              label="Template"
              hide-details
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="templateId"
              v-model="
                copiedFormDefinition.form.city_works_webhook_config.template_id
              "
              @change="
                onChange(
                  'template_id',
                  copiedFormDefinition.form.city_works_webhook_config
                    .template_id
                )
              "
              :disabled="!canManageForms"
            >
            </v-text-field>

            <section
              v-if="
                getInspectionTemplatesError ||
                getWorkOrderTemplatesError ||
                cityworksAuthInvalid
              "
              :class="{
                'red--text': !cityworksAuthInvalid,
                'orange--text': cityworksAuthInvalid,
              }"
            >
              <template
                v-if="!cityworksAuthInvalid && getInspectionTemplatesError"
              >
                Error getting Template:
                {{ getInspectionTemplatesError }}
              </template>
              <template
                v-else-if="!cityworksAuthInvalid && getWorkOrderTemplatesError"
              >
                Error getting Template:
                {{ getWorkOrderTemplatesError }}
              </template>
              <template v-else>
                A list of values is not available because you are not signed in
                to Cityworks
              </template>
            </section>
          </validation-provider>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { cloneDeep } from "lodash";
import permissionsMixin from "@/mixins/permissionsMixin";

import axios from "axios";

const APIURL = process.env.VUE_APP_API_URL;

const activityTypes = [
  { value: "Inspection", label: "Inspection" },
  { value: "workorder", label: "Work Order" },
];

export default {
  name: "TemplateForm",
  props: {
    value: Object,
  },
  mixins: [permissionsMixin],
  data() {
    return {
      formDefinition: {},
      copiedFormDefinition: {},
      activityTypes,
      domains: [],
      entityGroups: [],
      entityTypes: [],
      templates: [],
      getInspectionTemplatesError: "",
      getWorkOrderTemplatesError: "",
      getEntityTypesError: "",
      getEntityGroupsError: "",
      getDomainsError: "",
      cityworksAuthInvalid: false,
    };
  },
  computed: {
    domainChoices() {
      return this.domains.map((d) => {
        const { DomainId, Description } = d;
        return {
          value: DomainId,
          label: Description,
        };
      });
    },
    cwDomain() {
      return this.copiedFormDefinition.form.city_works_webhook_config.domain;
    },
    cwType() {
      return this.copiedFormDefinition.form.city_works_webhook_config.type;
    },
    entityGroupChoices() {
      return this.entityGroups.map((d) => {
        const { Module, ModuleName } = d;
        return {
          value: Module,
          label: ModuleName,
        };
      });
    },
    cwEntityGroup() {
      return this.copiedFormDefinition.form.city_works_webhook_config
        .entity_group;
    },
    entityTypeChoices() {
      return this.entityTypes.map((d) => {
        const { Code, Description } = d;
        return {
          value: Code,
          label: Description,
        };
      });
    },
    cwEntityType() {
      return this.copiedFormDefinition.form.city_works_webhook_config
        .entity_type;
    },
    templateChoices() {
      if (this.cwType === "Inspection") {
        return this.templates.map((d) => {
          const { InspTemplateId, InspTemplateName } = d;
          return {
            value: InspTemplateId,
            label: InspTemplateName,
          };
        });
      } else if (this.cwType === "workorder") {
        return this.templates.map((d) => {
          const { WOTemplateId, Description } = d;
          return {
            value: WOTemplateId,
            label: Description,
          };
        });
      } else {
        return [];
      }
    },
    cwTemplate() {
      return this.copiedFormDefinition.form.city_works_webhook_config
        .template_id;
    },
  },
  methods: {
    async validateCityworksToken() {
      const auth = JSON.parse(localStorage.getItem("auth")) ?? {};
      const { cityworks_token: cityworksToken } = auth ?? {};
      if (!cityworksToken) {
        this.cityworksAuthInvalid = true;
        return;
      }

      try {
        const {
          data: { error },
        } = await axios.get(`${APIURL}/cityworks/validate_token`);
        this.cityworksAuthInvalid = Boolean(error);
      } catch (error) {
        this.cityworksAuthInvalid = true;
      } finally {
        if (this.cityworksAuthInvalid) {
          // eslint-disable-next-line no-unused-vars
          const { cityworks_token, ...newAuth } = auth;
          localStorage.setItem("auth", JSON.stringify(newAuth));
        }
      }
    },
    async getDomains() {
      if (this.cityworksAuthInvalid) {
        return;
      }
      try {
        const {
          data: { results },
        } = await axios.get(`${APIURL}/cityworks/domains`);
        this.domains = results;
      } catch (error) {
        this.getDomainsError = error.response?.data?.error;
      }
    },
    async getEntityGroups() {
      if (this.cityworksAuthInvalid) {
        return;
      }
      try {
        const {
          data: { results },
        } = await axios.get(`${APIURL}/cityworks/entity_groups`, {
          params: {
            domain_id:
              this.copiedFormDefinition.form.city_works_webhook_config.domain,
          },
        });
        this.entityGroups = results;
      } catch (error) {
        this.getEntityGroupsError = error.response?.data?.error;
      }
    },
    async getEntityTypes() {
      if (
        this.cityworksAuthInvalid ||
        !this.copiedFormDefinition.form.city_works_webhook_config.entity_group
      ) {
        return;
      }
      try {
        const {
          data: { results },
        } = await axios.get(`${APIURL}/cityworks/entity_types`, {
          params: {
            entity_group:
              this.copiedFormDefinition.form.city_works_webhook_config
                .entity_group,
          },
        });
        this.entityTypes = results;
      } catch (error) {
        this.getEntityTypesError = error.response?.data?.error;
      }
    },
    async getInspectionTemplates() {
      this.getInspectionTemplatesError = "";
      this.getWorkOrderTemplatesError = "";
      if (this.cityworksAuthInvalid) {
        return;
      }
      try {
        const {
          data: { results },
        } = await axios.get(`${APIURL}/cityworks/inspection_templates`, {
          params: {
            entity_type:
              this.copiedFormDefinition.form.city_works_webhook_config
                .entity_type,
          },
        });
        this.templates = results;
      } catch (error) {
        this.getInspectionTemplatesError = error.response?.data?.error;
      }
    },
    async getWorkorderTemplates() {
      this.getInspectionTemplatesError = "";
      this.getWorkOrderTemplatesError = "";
      if (this.cityworksAuthInvalid) {
        return;
      }
      try {
        const {
          data: { results },
        } = await axios.get(`${APIURL}/cityworks/work_order_templates`, {
          params: {
            entity_type:
              this.copiedFormDefinition.form.city_works_webhook_config
                .entity_type,
          },
        });
        this.templates = results;
      } catch (error) {
        this.getWorkOrderTemplatesError = error.response?.data?.error;
      }
    },
    onChange(key, value) {
      if (key === "domain") {
        delete this.copiedFormDefinition.form.city_works_webhook_config
          .entity_group;
        delete this.copiedFormDefinition.form.city_works_webhook_config
          .entity_type;
        delete this.copiedFormDefinition.form.city_works_webhook_config
          .template_id;
      } else if (key === "entity_group") {
        this.getInspectionTemplatesError = "";
        this.getWorkOrderTemplatesError = "";
        this.getEntityTypesError = "";
        delete this.copiedFormDefinition.form.city_works_webhook_config
          .entity_type;
        delete this.copiedFormDefinition.form.city_works_webhook_config
          .template_id;
      } else if (key === "entity_type") {
        delete this.copiedFormDefinition.form.city_works_webhook_config
          .template_id;
      }
      this.$set(this.formDefinition.form.city_works_webhook_config, key, value);
      this.$emit("input", this.formDefinition);
    },
  },
  beforeMount() {
    this.getDomains();
  },
  mounted() {
    this.validateCityworksToken();
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.formDefinition = cloneDeep(val);
        this.copiedFormDefinition = { ...this.formDefinition };
      },
    },
    cwDomain: {
      immediate: true,
      async handler() {
        if (this.cwDomain) {
          await this.validateCityworksToken();
          await this.getEntityGroups();
        }
      },
    },
    cwEntityGroup: {
      immediate: true,
      async handler() {
        await this.validateCityworksToken();
        await this.getEntityTypes();
      },
    },
    cwEntityType: {
      immediate: true,
      async handler() {
        await this.validateCityworksToken();
        if (
          this.copiedFormDefinition.form.city_works_webhook_config.type ===
          "Inspection"
        ) {
          await this.getInspectionTemplates();
        } else if (
          this.copiedFormDefinition.form.city_works_webhook_config.type ===
          "workorder"
        ) {
          await this.getWorkorderTemplates();
        }
      },
    },
  },
};
</script>
