<template>
  <v-dialog
    :value="showEditFormDataDialog"
    max-width="600px"
    :retain-focus="false"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>
          {{ formDefinition.form.formDescription.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('edit-form-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text style="height: 65vh; overflow-y: auto">
        <validation-observer ref="form">
          <form @submit.prevent="submit">
            <v-card-text class="pl-0 pr-0">
              <div class="pl-2 pr-2 pb-2" v-if="computedFormDefinition.form">
                <div
                  v-for="section in computedFormDefinition.form.sections"
                  :key="section.id"
                >
                  <div>
                    <b>{{ section.name }}</b>
                  </div>
                  <div>
                    <v-row>
                      <v-col
                        cols="12"
                        v-for="item in section.items"
                        :key="item.id"
                      >
                        <template v-if="isQuestion(item)">
                          <validation-provider>
                            <TextInput
                              v-if="
                                [
                                  'TEXT',
                                  'EMAIL',
                                  'NUMBER',
                                  'DATE',
                                  'TIME',
                                ].includes(item.question.type)
                              "
                              v-model="item.value"
                              :item="item"
                              :formDefinition="computedFormDefinition"
                              color="primary"
                              @input="onInput"
                            />

                            <SingleSelectInput
                              v-if="
                                ['SINGLE_SELECT'].includes(item.question.type)
                              "
                              :item="item"
                              v-model="item.value"
                              :formDefinition="computedFormDefinition"
                              @input="onInput"
                            />

                            <MultiSelectInput
                              v-if="
                                ['MULTI_SELECT'].includes(item.question.type)
                              "
                              :item="item"
                              v-model="item.value"
                              :formDefinition="computedFormDefinition"
                              @input="onInput"
                            />

                            <SignaturePad
                              v-if="['SIGNATURE'].includes(item.question.type)"
                              v-model="item.value"
                              :item="item"
                              :formDefinition="computedFormDefinition"
                              @input="onInput"
                            />

                            <CalculationInput
                              v-if="
                                ['CALCULATION'].includes(item.question.type)
                              "
                              :formDefinition="computedFormDefinition"
                              :item="item"
                            />

                            <div v-if="['FILE'].includes(item.question.type)">
                              <PhotoInput
                                v-if="item.question.isImage"
                                :label="`${item.number} ${item.question.label}`"
                                :id="item.id"
                                :allowMultiple="item.allowMultiple"
                                :showDescription="item.question.showDescription"
                                v-model="item.value"
                                :maxWidthHeight="item.question.maxWidthHeight"
                                :formDefinition="computedFormDefinition"
                                :item="item"
                              />

                              <FileInput
                                v-else
                                v-model="item.value"
                                :id="item.id"
                                :label="`${item.number} ${item.question.label}`"
                                :allowMultiple="item.allowMultiple"
                                :formDefinition="computedFormDefinition"
                                :item="item"
                              />
                            </div>
                          </validation-provider>
                        </template>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </div>
            </v-card-text>
          </form>
        </validation-observer>
      </v-card-text>

      <v-card-actions class="d-flex justify-end py-4">
        <v-btn color="primary" text @click="submit">Preview PDF</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SignaturePad from "@/components/forms/form-builder-edit-form/item-preview/SignaturePad";
import TextInput from "@/components/forms/form-builder-edit-form/item-preview/TextInput";
import PhotoInput from "@/components/forms/form-builder-edit-form/item-preview/PhotoInput";
import FileInput from "@/components/forms/form-builder-edit-form/item-preview/FileInput";
import SingleSelectInput from "@/components/forms/form-builder-edit-form/item-preview/SingleSelectInput";
import MultiSelectInput from "@/components/forms/form-builder-edit-form/item-preview/MultiSelectInput";
import CalculationInput from "@/components/forms/form-builder-edit-form/item-preview/CalculationInput";
import { cloneDeep } from "lodash";

export default {
  name: "EditFormDataDialog",
  props: {
    showEditFormDataDialog: Boolean,
    formDefinition: Object,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    SignaturePad,
    TextInput,
    PhotoInput,
    FileInput,
    SingleSelectInput,
    MultiSelectInput,
    CalculationInput,
  },
  data() {
    return {
      computedFormDefinition: {},
    };
  },
  beforeMount() {
    this.computedFormDefinition = cloneDeep(this.formDefinition);
  },
  methods: {
    isQuestion(item) {
      return Object.prototype.hasOwnProperty.call(item, "question");
    },
    async onInput() {
      await this.$nextTick();
      this.$emit("input", this.computedFormDefinition);
    },
    async submit() {
      const success = await this.$refs.form.validate();
      if (!success) {
        return;
      }
      const items = cloneDeep(
        this.computedFormDefinition.form.sections
          .map((c) => c.items)
          .filter(
            (item) =>
              item?.question?.type !== "FILE" ||
              (item?.question?.type === "FILE" && item?.question?.isImage)
          )
          .flat()
      );
      for (const item of items) {
        if (
          item?.question?.type === "FILE" &&
          item?.question?.isImage &&
          Array.isArray(item.value)
        ) {
          const [firstImg, ...restImgs] = item.value.map(
            ({ id, url, description }) => ({
              id,
              url,
              description,
            })
          );
          item.value = firstImg;
          if (Array.isArray(restImgs) && restImgs.length > 0) {
            item.children = restImgs;
          }
        }
      }
      const params = items.reduce((acc, item) => {
        if (item?.question?.type === "NUMBER") {
          return {
            ...acc,
            [item.id]: item.value ?? 0,
          };
        } else {
          return {
            ...acc,
            [item.id]: item.value ?? "",
          };
        }
      }, {});
      this.$emit("form-data-submitted", params);
    },
  },
};
</script>
