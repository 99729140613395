<template>
  <section :class="{ selected }">
    <div>
      <span class="caption">
        {{ item.question.number }} {{ item.question.label }}
      </span>
    </div>

    <div class="d-flex align-center">
      <div>
        {{ result }}
      </div>
      <v-btn text @click.stop="showCalculationDialog = true">
        <v-icon>{{ mdiInformationOutline }}</v-icon>
      </v-btn>
    </div>

    <v-dialog
      v-model="showCalculationDialog"
      max-width="600px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <CalculationList
        :formDefinition="formDefinition"
        :item="item"
        @calculation-list-close="showCalculationDialog = false"
      />
    </v-dialog>
  </section>
</template>

<script>
import { mdiInformationOutline } from "@mdi/js";
import { evaluate } from "mathjs";
import CalculationList from "./calculation-input/CalculationList.vue";

export default {
  name: "CalculationInput",
  components: {
    CalculationList,
  },
  props: {
    item: Object,
    formDefinition: Object,
    selected: Boolean,
  },
  data() {
    return {
      mdiInformationOutline,
      showCalculationDialog: false,
      result: 0,
    };
  },
  methods: {
    calculateResult() {
      if (this.item.question.calculation) {
        const { sections } = { ...this.formDefinition.form };
        const nestedItems = sections.map(({ items }) => items);
        const items = nestedItems.flat().filter(({ question }) => {
          return ["NUMBER", "SINGLE_SELECT"].includes(question?.type);
        });

        let expression = this.item.question.calculation;
        const params = [
          ...this.item.question.calculation.matchAll(/{{(.*?)}}/g),
        ];
        for (const p of params) {
          const [fullParam, param] = p.toString().split(",");
          const [, questionId] = param.split(".");
          const item = items.find((i) => +i.id === +questionId);
          expression = expression.replace(fullParam, item?.value ?? 0);
        }
        try {
          const result = evaluate(expression);
          const [, decimal] = result.toString().split(".");
          const decimalLength = decimal?.length ?? 0;
          return +result.toFixed(Math.min(2, decimalLength));
        } catch (error) {
          return 0;
        }
      }
      return 0;
    },
  },
  watch: {
    formDefinition: {
      deep: true,
      immediate: true,
      handler() {
        this.result = this.calculateResult();
      },
    },
    item: {
      deep: true,
      immediate: true,
      handler() {
        this.result = this.calculateResult();
      },
    },
  },
};
</script>
