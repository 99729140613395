<template>
  <node-view-wrapper as="div" class="pageBreak"> </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from "@tiptap/vue-2";

export default {
  components: {
    NodeViewWrapper,
  },
  props: nodeViewProps,
};
</script>
