<template>
  <v-card
    width="100%"
    elevation="0"
    :style="selected ? 'background-color: #fff8e1' : ''"
  >
    <v-card-text>
      <v-row>
        <v-col cols="12" class="d-flex align-center justify-space-between">
          <div>
            {{ item.question.label }}
          </div>
          <div>
            <v-btn
              text
              color="primary"
              :disabled="readOnly"
              @click.stop="showActionItemDialog = true"
            >
              + Add
            </v-btn>
          </div>
        </v-col>

        <v-col cols="12" class="pa-0 ma-0">
          <v-card
            v-if="noActionItems"
            :style="selected ? 'background-color: #fff8e1' : ''"
          >
            <v-card-text class="d-flex justify-center">
              No {{ item.question.label }}
            </v-card-text>
          </v-card>
          <v-card v-else>
            <v-card-text class="pa-0 ma-0">
              <v-data-table
                :headers="headers"
                hide-default-footer
                disable-pagination
                group-by="groupPosition"
                :items="mappedActionItems"
                item-key="action_item_id"
                class="pa-0 ma-0"
              >
                <template v-slot:[`group.header`]="{ isOpen, toggle, items }">
                  <td
                    colspan="8"
                    class="ma-0 pa-0 group-toggle group-header"
                    @click="toggle"
                  >
                    <div class="d-flex ml-2">
                      <v-btn text x-small>
                        <v-icon>
                          {{ isOpen ? mdiChevronUp : mdiChevronDown }}
                        </v-icon>
                      </v-btn>
                      <div class="ml-2 text-capitalize">
                        <b>{{ getGroupHeader(items) }}</b>
                      </div>
                    </div>
                  </td>
                </template>

                <template v-slot:[`item.due_by`]="{ item }">
                  {{ item.due_by | formatVariableDate("MM/DD/YYYY") }}
                </template>

                <template v-slot:[`item.action_item_id`]="{ item }">
                  <v-btn
                    icon
                    color="primary"
                    @click.stop="onActionItemClick(item)"
                  >
                    <v-icon>
                      {{ mdiPencil }}
                    </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <ActionItemDialog
        v-if="showActionItemDialog"
        :showActionItemDialog="showActionItemDialog"
        :item="item"
        :readOnly="readOnly"
        :formResultId="formResultId"
        :formDefinition="formDefinition"
        :newActionItems="actionItems.newActionItems"
        :selectedMapServiceId="selectedMapServiceId"
        @form-saved="onFormSaved"
        @close-form="showActionItemDialog = false"
      />

      <EditActionItemDialog
        v-if="showEditActionItemDialog"
        :showEditActionItemDialog="showEditActionItemDialog"
        :item="item"
        :selectedActionItem="selectedActionItem"
        :readOnly="readOnly"
        :formResultId="formResultId"
        :formDefinition="formDefinition"
        :selectedMapServiceId="selectedMapServiceId"
        @form-saved="onEditFormSaved"
        @close-form="showEditActionItemDialog = false"
        @delete-action-item="deleteActionItem"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiPencil, mdiChevronUp, mdiChevronDown } from "@mdi/js";
import ActionItemDialog from "@/components/forms/form-builder-edit-form/item-preview/action-item/ActionItemDialog";
import EditActionItemDialog from "@/components/forms/form-builder-edit-form/item-preview/action-item/EditActionItemDialog";
import { cloneDeep } from "lodash";
import ACTION_ITEM_STATUSES from "@/constants/actionItemStatuses";
import { v4 as uuidv4 } from "uuid";

const GROUP_POSITIONS = {
  [ACTION_ITEM_STATUSES.OPEN]: 0,
  [ACTION_ITEM_STATUSES.READY_FOR_REVIEW]: 1,
  [ACTION_ITEM_STATUSES.REJECTED]: 2,
  [ACTION_ITEM_STATUSES.CANCELED]: 4,
  [ACTION_ITEM_STATUSES.CLOSED]: 4,
  [ACTION_ITEM_STATUSES.NEW]: 5,
};
const headers = [
  {
    text: "Due By",
    align: "start",
    value: "due_by",
  },
  {
    text: "Assigned To",
    align: "start",
    value: "assignee",
  },
  {
    text: "Description",
    align: "start",
    value: "action_item_description",
  },
  {
    text: "",
    align: "end",
    value: "action_item_id",
  },
];

export default {
  name: "ActionItem",
  props: {
    item: Object,
    formDefinition: Object,
    value: Object,
    canEdit: Boolean,
    formResultId: String,
    alreadySubmittedFinalOnline: Boolean,
    isEditingFinalForm: Boolean,
    gisInfos: Array,
    selectedGisInfo: Object,
    selectedMapServiceId: String,
    readOnly: Boolean,
    selected: Boolean,
  },
  components: {
    ActionItemDialog,
    EditActionItemDialog,
  },
  async beforeMount() {
    if (this.value) {
      this.actionItems = { ...this.value };
    }
  },
  methods: {
    onFormSaved(actionItem) {
      if (!Array.isArray(this.actionItems.newActionItems)) {
        this.actionItems.newActionItems = [];
      }
      this.actionItems.newActionItems.push(actionItem);
      this.showActionItemDialog = false;
      this.$emit("input", cloneDeep(this.actionItems));
    },
    onEditFormSaved(actionItem) {
      const selectedActionItemIndex = this.actionItems.newActionItems.findIndex(
        (a) => a.id === actionItem.id
      );
      this.$set(this.actionItems.newActionItems, selectedActionItemIndex, {
        ...actionItem,
      });
      this.selectedActionItem = {};
      this.showEditActionItemDialog = false;
      this.$emit("input", cloneDeep(this.actionItems));
    },
    deleteActionItem(actionItem) {
      const selectedActionItemIndex = this.actionItems.newActionItems.findIndex(
        (a) => a.id === actionItem.id
      );
      this.actionItems.newActionItems.splice(selectedActionItemIndex, 1);
      this.selectedActionItem = {};
      this.showEditActionItemDialog = false;
      this.$emit("input", cloneDeep(this.actionItems));
    },
    getAssigneeValue(actionItem) {
      const user = this.users
        .map((u) => {
          const { f_name: fName, l_name: lName, user_id: userId } = u;
          return {
            name: `${fName} ${lName}`,
            userId,
          };
        })
        .find((u) => u.userId === actionItem?.assignee_user_id);
      if (user) {
        return user.name;
      } else {
        if (actionItem?.email_from_from) {
          return actionItem?.email_from_from.value;
        } else {
          return actionItem?.assignee_email;
        }
      }
    },
    async onActionItemSaved() {
      await this.loadActionItems();
      this.selectedActionItem = this.actionItems[this.selectedActionItemIndex];
    },
    async loadActionItems() {
      await this.getActionItemsByFeature();
    },
    onActionItemClick(item) {
      if (item.status === "new") {
        this.selectedActionItem = item;
        this.showEditActionItemDialog = true;
      } else {
        this.selectedOpenActionItem = item;
        this.showActionItemTabActionItemDialog = true;
      }
    },
    getGroupHeader(items) {
      const [item] = items;
      return item?.displayStatus;
    },
  },
  data() {
    return {
      mdiPencil,
      mdiChevronUp,
      mdiChevronDown,
      showActionItemDialog: false,
      showEditActionItemDialog: false,
      actionItems: {
        newActionItems: [],
        openActionItems: [],
      },
      selectedActionItem: {},
      selectedActionItemIndex: undefined,
      showActionItemTabActionItemDialog: false,
      selectedOpenActionItem: {},
      selectedOpenActionItemIndex: undefined,
      ACTION_ITEM_STATUSES,
      headers,
      users: [],
    };
  },
  computed: {
    noActionItems() {
      return (
        (this.actionItems?.newActionItems?.length ?? 0) === 0 &&
        (this.actionItems?.openActionItems?.length ?? 0) === 0
      );
    },
    isDisabled() {
      if (this.alreadySubmittedFinalOnline) {
        return !this.canEdit || !this.isEditingFinalForm;
      }
      return !this.canEdit;
    },
    mappedActionItems() {
      const openItems = this.actionItems.openActionItems.map((it) => {
        const { status } = it;
        return {
          ...it,
          assignee: this.getAssigneeValue(it),
          displayStatus:
            status === ACTION_ITEM_STATUSES.OPEN
              ? "Ready for Response"
              : status,
          groupPosition: GROUP_POSITIONS[status],
        };
      });

      const newItems = this.actionItems.newActionItems.map((it) => {
        return {
          ...it,
          assignee: this.getAssigneeValue(it),
          status: ACTION_ITEM_STATUSES.NEW,
          displayStatus: ACTION_ITEM_STATUSES.NEW,
          action_item_id: uuidv4(),
          groupPosition: GROUP_POSITIONS[ACTION_ITEM_STATUSES.NEW],
        };
      });
      return [...openItems, ...newItems];
    },
  },
};
</script>
