<template>
  <v-card class="pa-0 ma-0">
    <v-card-text class="pa-0 ma-0">
      <div
        ref="codeEditor"
        :style="{
          width: `${contentWidth}px`,
          height: `${contentHeight - 37}px`,
        }"
      ></div>
    </v-card-text>
  </v-card>
</template>

<script>
import { EditorView, basicSetup } from "codemirror";
import { html } from "@codemirror/lang-html";
import { Compartment } from "@codemirror/state";
import { lintGutter } from "@codemirror/lint";

const pretty = require("pretty");

export default {
  name: "TemplateCodeEditor",
  props: {
    value: String,
    contentHeight: Number,
  },
  data() {
    return {
      editor: undefined,
      contentWidth: window.innerWidth - 420,
    };
  },
  async mounted() {
    const { value = "\n".repeat(10) } = this;
    const language = new Compartment();
    this.editor = new EditorView({
      extensions: [
        EditorView.lineWrapping,
        basicSetup,
        language.of(html()),
        lintGutter(),
        EditorView.updateListener.of((v) => {
          if (v.docChanged) {
            this.$emit("input", this.editor.state.doc.toString());
          }
        }),
      ],
      mode: "text/html",
      extraKeys: { "Ctrl-Space": "autocomplete" },
      parent: this.$refs.codeEditor,
      doc: pretty(value),
    });
    await this.$nextTick();
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      if (this.$vuetify.breakpoint.xsOnly) {
        this.contentWidth = window.innerWidth - 70;
      } else {
        this.contentWidth = window.innerWidth - 380;
      }
    },
  },
  watch: {
    value(val) {
      this.editor.dispatch?.({
        changes: {
          from: 0,
          to: this.editor?.state?.doc?.length,
          insert: pretty(val),
        },
      });
    },
    "$vuetify.breakpoint.xsOnly"() {
      this.onResize();
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
