var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        { staticClass: "py-1 my-1" },
        [
          _c(
            "section",
            { staticClass: "d-flex justify-space-between align-center" },
            [
              _c("div", [
                _vm._v(
                  "This UtiliBot will add comments to Cityworks service request"
                ),
              ]),
              _c("v-switch", {
                attrs: {
                  label: "Enable Integration",
                  disabled: !_vm.canManageForms,
                },
                on: {
                  change: function ($event) {
                    return _vm.$emit("input", _vm.formDefinition)
                  },
                },
                model: {
                  value:
                    _vm.formDefinition.form.city_works_webhook_config
                      .add_service_request_comment.enabled,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.formDefinition.form.city_works_webhook_config
                        .add_service_request_comment,
                      "enabled",
                      $$v
                    )
                  },
                  expression:
                    "\n          formDefinition.form.city_works_webhook_config\n            .add_service_request_comment.enabled\n        ",
                },
              }),
            ],
            1
          ),
          _c("p", [
            _vm._v("Customize the comment to be added on the service request."),
          ]),
          _c("SimpleFormCommentTemplateEditor", {
            staticClass: "my-3",
            attrs: {
              disabled: !_vm.canManage811Codes,
              formDefinition: _vm.formDefinition,
            },
            on: { input: _vm.onChange },
            model: {
              value:
                _vm.formDefinition.form.city_works_webhook_config
                  .add_service_request_comment.template,
              callback: function ($$v) {
                _vm.$set(
                  _vm.formDefinition.form.city_works_webhook_config
                    .add_service_request_comment,
                  "template",
                  $$v
                )
              },
              expression:
                "\n        formDefinition.form.city_works_webhook_config\n          .add_service_request_comment.template\n      ",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }