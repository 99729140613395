<template>
  <section>
    <div class="d-flex align-center justify-space-between">
      <div>Advanced Settings</div>

      <v-btn
        text
        color="primary"
        @click="showAddAdvancedInfoSettingsDialog = true"
        :disabled="!canManageForms"
      >
        + Add
      </v-btn>
    </div>

    <AddAdvancedInfoSettingsDialog
      v-if="showAddAdvancedInfoSettingsDialog"
      :showAddAdvancedInfoSettingsDialog="showAddAdvancedInfoSettingsDialog"
      :selectedItemOptions="selectedItemOptions"
      @advanced-settings-selected="onAdvancedSettingSelected"
      @add-advanced-settings-dialog-close="
        showAddAdvancedInfoSettingsDialog = false
      "
    />

    <AdvancedInfoVisibilitySettingsDialog
      v-if="showAdvancedInfoVisibilitySettingsDialog"
      :showAdvancedInfoVisibilitySettingsDialog="
        showAdvancedInfoVisibilitySettingsDialog
      "
      :formDefinition="formDefinition"
      :layer="layer"
      :selectedItem="selectedItem"
      @advanced-settings-dialog-close="
        showAdvancedInfoVisibilitySettingsDialog = false
      "
      v-model="selectedItemOptions"
      @input="onInput"
    />
  </section>
</template>

<script>
import { cloneDeep } from "lodash";
import AdvancedInfoVisibilitySettingsDialog from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/advanced-settings-form/AdvancedInfoVisibilitySettingsDialog.vue";
import AddAdvancedInfoSettingsDialog from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/advanced-settings-form/AddAdvancedInfoSettingsDialog.vue";
import { ADVANCED_SETTINGS_CHOICES } from "@/constants/advancedSettings";
import permissionsMixin from "@/mixins/permissionsMixin";

export default {
  name: "AdvancedInfoSettingsForm",
  props: {
    value: Object,
    formDefinition: Object,
    layer: Object,
    selectedItem: Object,
  },
  components: {
    AdvancedInfoVisibilitySettingsDialog,
    AddAdvancedInfoSettingsDialog,
  },
  mixins: [permissionsMixin],

  watch: {
    value: {
      deep: true,
      handler(val) {
        this.selectedItemOptions = cloneDeep(val);
      },
    },
  },
  data() {
    return {
      selectedItemOptions: {},
      showAdvancedInfoVisibilitySettingsDialog: false,
      showAddAdvancedInfoSettingsDialog: false,
      advancedSettingType: ADVANCED_SETTINGS_CHOICES.DEFAULT,
    };
  },
  beforeMount() {
    this.selectedItemOptions = cloneDeep(this.value);
  },
  methods: {
    onAdvancedSettingSelected(advancedSettingType) {
      this.showAddAdvancedInfoSettingsDialog = false;
      if (advancedSettingType === ADVANCED_SETTINGS_CHOICES.VISIBILITY) {
        this.showAdvancedInfoVisibilitySettingsDialog = true;
      }
    },
    onInput(selectedItemOptions) {
      this.$emit("input", selectedItemOptions);
    },
  },
};
</script>
