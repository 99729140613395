<template>
  <div
    class="overflow-auto dark d-flex justify-center"
    :style="{ width: `${contentWidth}px`, height: `${contentHeight}px` }"
  >
    <v-card width="804px" height="fit-content" class="ma-6">
      <v-card-text class="pa-8">
        <div v-html="htmlCode" v-if="htmlCode"></div>
        <template v-else> &nbsp; </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Version1TemplateView",
  props: {
    htmlCode: String,
  },
  data() {
    return {
      contentWidth: window.innerWidth - 420,
      contentHeight: window.innerHeight - 56,
    };
  },
  methods: {
    onResize() {
      if (window.innerWidth <= 600) {
        this.contentWidth = window.innerWidth - 59;
      } else {
        this.contentWidth = window.innerWidth - 359;
      }

      if (window.innerWidth <= 600) {
        this.contentHeight = window.innerHeight - 80;
      } else {
        this.contentHeight = window.innerHeight - 92;
      }
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style scoped>
.dark {
  background-color: rgb(111, 111, 111);
}
</style>
