var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "pa-0 ma-0" },
    [
      _c("v-card-text", { staticClass: "pa-0 ma-0" }, [
        _c("div", {
          ref: "codeEditor",
          style: {
            width: `${_vm.contentWidth}px`,
            height: `${_vm.contentHeight - 37}px`,
          },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }