<template>
  <validation-observer ref="timeForm">
    <v-toolbar color="primary" height="56px" class="elevation-0" ref="toolbar">
      <v-list-item two-line class="px-0">
        <v-list-item-content>
          <div class="d-flex justify-space-between">
            <v-list-item-title class="white--text text-h6">
              {{ selectedControlType | itemTitle(selectedItem) }}
            </v-list-item-title>
            <v-btn
              icon
              color="white"
              @click="$emit('form-control-form-submitted')"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-toolbar>
    <form
      @submit.prevent="
        onSubmit();
        $emit('form-control-form-submitted');
      "
    >
      <v-list
        dense
        style="max-height: calc(100vh - 56px)"
        class="overflow-y-auto"
      >
        <v-list-item class="my-0 py-0">
          <v-list-item-content>
            <validation-provider
              v-slot="{ errors, valid }"
              name="Label"
              rules="required"
            >
              <v-text-field
                v-model="selectedItemOptions.question.label"
                label="Label"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="name"
                @change="onSubmit()"
                ref="labelInput"
                :disabled="!canManageForms"
              />
            </validation-provider>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="my-0 py-0">
          <v-list-item-content>
            <validation-provider v-slot="{ errors, valid }" name="Hint">
              <v-text-field
                v-model="selectedItemOptions.question.placeholder"
                label="Hint"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="placeholder"
                @change="onSubmit()"
                :disabled="!canManageForms"
              />
            </validation-provider>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="my-0 py-0">
          <v-list-item-content>
            <validation-provider v-slot="{ errors, valid }" name="Visible">
              <v-select
                autocomplete="off"
                v-model="createCondition"
                :items="createOptions"
                label="Create"
                :error-messages="errors"
                :success="valid"
                item-text="label"
                item-value="value"
                @change="onSubmit()"
                hide-details
                :disabled="!canManageForms"
              >
              </v-select>
            </validation-provider>

            <CreateComparisonConditionForm
              v-if="['conditional'].includes(createCondition)"
              v-model="selectedItemOptions.question.create"
              :formDefinition="formDefinition"
              :selectedItem="selectedItem"
              @input="onSubmit()"
            />
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          class="mt-n6 pt-0"
          v-if="selectedItemOptions.question.default.type === 'CURRENT'"
        >
          <v-list-item-content>
            <validation-provider
              v-slot="{ errors, valid }"
              name="Add Minutes"
              rules="min_value:0"
            >
              <v-text-field
                v-model="selectedItemOptions.question.default.value"
                label="Add Minutes"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="addMinutes"
                @change="onSubmit()"
                :disabled="!canManageForms"
              />
            </validation-provider>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="my-0 py-0">
          <v-list-item-content>
            <validation-provider
              v-slot="{ errors, valid }"
              name="Required"
              rules="required"
            >
              <v-select
                autocomplete="off"
                v-model="requiredCondition"
                :items="requiredOptions"
                label="Required"
                :error-messages="errors"
                :success="valid"
                item-text="label"
                item-value="value"
                @change="onSubmit()"
                hide-details=""
                :disabled="!canManageForms"
              ></v-select>
            </validation-provider>

            <ComparisonConditionForm
              v-if="['conditional'].includes(requiredCondition)"
              v-model="selectedItemOptions.question.required"
              :formDefinition="formDefinition"
              :selectedItem="selectedItem"
              @input="onSubmit()"
            />
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="my-0 py-0" v-if="['F', 'R'].includes(layerType)">
          <v-list-item-content class="my-0 py-0">
            <validation-provider
              v-slot="{ errors, valid }"
              name="Update ArcGIS Field"
            >
              <v-text-field
                v-model="selectedItemOptions.question.updateEsriObject"
                label="Update ArcGIS Field"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="updateEsriObject"
                @change="onSubmit()"
                :disabled="!canManageForms"
              />
            </validation-provider>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="my-0 py-0">
            <v-switch
              v-model="selectedItemOptions.allowMultiple"
              label="Allow Multiple"
              @change="onSubmit()"
              :disabled="!canManageForms"
            ></v-switch>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="my-0 py-0">
            <v-switch
              v-model="selectedItemOptions.showInReport"
              label="Show on Form Submissions Page"
              @change="onSubmit()"
              :disabled="!canManageForms"
            ></v-switch>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <AdvancedSettingsForm
              v-model="selectedItemOptions"
              :formDefinition="formDefinition"
              :selectedItem="selectedItem"
              :layer="layer"
              @input="onSubmit()"
            />
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <div class="d-flex flex-wrap gap" v-if="hasAdvancedSettings">
              <section
                class="text-uppercase d-flex primary--text gap align-center"
                v-if="hasDefaultSettings"
                @click="onDefaultSettingClick"
              >
                <div>Default {{ defaultSetting }}</div>

                <v-btn icon :disabled="!canManageForms">
                  <v-icon color="primary">
                    {{ mdiPencil }}
                  </v-icon>
                </v-btn>
              </section>

              <section
                class="text-uppercase d-flex primary--text gap align-center"
                v-if="hasVisibleSettings"
                @click="onVisibleSettingClick"
              >
                <div>Visible {{ visibleSetting }}</div>

                <v-btn icon :disabled="!canManageForms">
                  <v-icon color="primary">
                    {{ mdiPencil }}
                  </v-icon>
                </v-btn>
              </section>
            </div>
            <div v-else class="d-flex align-center justify-center">
              No advanced settings
            </div>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="my-0 py-0">
            <v-btn
              :disabled="!canManageForms"
              color="primary"
              type="submit"
              width="100%"
            >
              Update Item
            </v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <AdvancedDefaultSettingsDialog
        v-if="showAdvancedDefaultSettingsDialog"
        :showAdvancedDefaultSettingsDialog="showAdvancedDefaultSettingsDialog"
        :formDefinition="formDefinition"
        :layer="layer"
        @advanced-settings-dialog-close="
          showAdvancedDefaultSettingsDialog = false
        "
        v-model="selectedItemOptions"
        @input="onSubmit()"
      />

      <AdvancedVisibilitySettingsDialog
        v-if="showAdvancedVisibilitySettingsDialog"
        :showAdvancedVisibilitySettingsDialog="
          showAdvancedVisibilitySettingsDialog
        "
        :formDefinition="formDefinition"
        :layer="layer"
        :selectedItem="selectedItem"
        @advanced-settings-dialog-close="
          showAdvancedVisibilitySettingsDialog = false
        "
        v-model="selectedItemOptions"
        @input="onSubmit()"
      />

      <AdvancedSettingsWarningDialog
        :showAdvancedSettingsWarningDialog="showAdvancedSettingsWarningDialog"
        @cancel="showAdvancedSettingsWarningDialog = false"
        @continue="openAdvancedSettingDialog"
      />
    </form>
  </validation-observer>
</template>

<script>
import {
  comparisonTypes,
  defaultValueTypes,
  requiredOptions,
  visibilityOptions,
  orgDataOptions,
  locateRequestDataValues,
  createOptions,
} from "@/constants/choices";
import axios from "axios";
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash";
import ComparisonConditionForm from "./shared/ComparisonConditionForm";
import sleep from "@/mixins/sleep";
import CreateComparisonConditionForm from "./shared/CreateComparisonConditionForm";
import AdvancedSettingsForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/AdvancedSettingsForm.vue";
import AdvancedDefaultSettingsDialog from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/advanced-settings-form/AdvancedDefaultSettingsDialog.vue";
import AdvancedVisibilitySettingsDialog from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/advanced-settings-form/AdvancedVisibilitySettingsDialog.vue";
import {
  CONDITION_CHOICES,
  ADVANCED_SETTINGS_CHOICES,
} from "@/constants/advancedSettings";
import { mdiPencil } from "@mdi/js";
import AdvancedSettingsWarningDialog from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/advanced-settings-form/add-advanced-settings-dialog/AdvancedSettingsWarningDialog";
import permissionsMixin from "@/mixins/permissionsMixin";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "TimeForm",
  props: {
    selectedItem: Object,
    formDefinition: Object,
    edit: Boolean,
    selectedControlType: String,
  },
  components: {
    ComparisonConditionForm,
    CreateComparisonConditionForm,
    AdvancedSettingsForm,
    AdvancedDefaultSettingsDialog,
    AdvancedVisibilitySettingsDialog,
    AdvancedSettingsWarningDialog,
  },
  mixins: [permissionsMixin],
  computed: {
    layerType() {
      return this.layer.service_type;
    },
    defaultSetting() {
      if (
        this.selectedItemOptions?.question?.default?.applyDefault === "ALWAYS"
      ) {
        return "";
      }
      return this.selectedItemOptions?.question?.default?.applyDefault;
    },
    visibleSetting() {
      if (
        this.selectedItemOptions?.question?.visible?.applyVisible === "ALWAYS"
      ) {
        return "";
      }
      return this.selectedItemOptions?.question?.visible?.applyVisible;
    },
    hasAdvancedSettings() {
      const { hasDefaultSettings, hasVisibleSettings } = this;
      return hasDefaultSettings || hasVisibleSettings;
    },
    hasDefaultSettings() {
      return this.selectedItemOptions.question.default.type !== null;
    },
    hasVisibleSettings() {
      return (
        this.selectedItemOptions.question.visible?.applyVisible !==
        CONDITION_CHOICES.ALWAYS
      );
    },
    fieldChoices() {
      const fieldChoices = this.fields.map(({ name, alias }) => ({
        value: name,
        label: alias || name,
      }));
      return fieldChoices;
    },
    userDataChoices() {
      const apiChoices = this.userDataFields.map(
        ({ user_data_field_id: value, name: label }) => ({
          value,
          label,
        })
      );
      return [
        { value: "f_name", label: "First Name" },
        { value: "l_name", label: "Last Name" },
        { value: "full_name", label: "Full Name" },
        { value: "email", label: "Email" },
        ...apiChoices,
      ];
    },
    gisDataChoices() {
      return this.gisDataFields.map(
        ({ gis_data_field_id: value, name, alias }) => ({
          value,
          label: alias || name,
        })
      );
    },
    dependantOptions() {
      const formDefinition = { ...this.formDefinition };
      const sectionItems = formDefinition.form.sections.map((s) => s.items);
      return sectionItems
        .flat()
        .filter(({ id, type: itemType }) => {
          return id !== this.selectedItem.id && itemType !== "INFORMATION";
        })
        .map(({ id, number, question: { label } = {} }) => ({
          value: id,
          label: `${number} ${label}`,
        }));
    },
    requiredComparisonOptions() {
      const formDefinition = { ...this.formDefinition };
      const items = formDefinition.form.sections.map((s) => s.items).flat();
      const dependantItem = items.find(
        ({ id }) =>
          id === this.selectedItemOptions.question.required.dependantId
      );
      if (
        dependantItem &&
        dependantItem.question &&
        dependantItem.question.type === "NUMBER"
      ) {
        return comparisonTypes;
      }
      return [
        {
          value: "NOT_EQUAL",
          label: "Not Equal",
        },
        {
          value: "EQUAL",
          label: "Equal",
        },
      ];
    },
    visibleComparisonOptions() {
      const formDefinition = { ...this.formDefinition };
      const items = formDefinition.form.sections.map((s) => s.items).flat();
      const dependantItem = items.find(
        ({ id }) => id === this.selectedItemOptions.question.visible.dependantId
      );
      if (
        dependantItem &&
        dependantItem.question &&
        dependantItem.question.type === "NUMBER"
      ) {
        return comparisonTypes;
      }
      return [
        {
          value: "NOT_EQUAL",
          label: "Not Equal",
        },
        {
          value: "EQUAL",
          label: "Equal",
        },
      ];
    },
    defaultValueOptions() {
      const defaultValueTypes = [
        ...this.defaultValueTypes.filter(
          ({ label }) => label !== "Organization Data"
        ),
        {
          value: "CURRENT",
          label: "Current Time",
        },
      ];
      if (this.layer.service_type === "L") {
        defaultValueTypes.push({
          value: "LOCATE_DATA",
          label: "811 Ticket",
        });
      }
      return defaultValueTypes;
    },
    ...mapGetters(["newFormData"]),
  },
  data() {
    return {
      selectedItemOptions: {
        question: {},
      },
      oldSelectedItemOptions: {
        question: {
          default: {},
        },
      },
      defaultValueTypes,
      requiredOptions,
      visibilityOptions,
      orgDataOptions,
      comparisonTypes,
      gisDataFields: [],
      userDataFields: [],
      layer: {},
      fields: [],
      locateRequestDataValues,
      showSaveDiscardChangesDialog: false,
      requiredCondition: "ALWAYS",
      createCondition: "ALWAYS",
      createOptions,
      showAdvancedDefaultSettingsDialog: false,
      showAdvancedVisibilitySettingsDialog: false,
      advancedSettingType: "",
      mdiPencil,
      showAdvancedSettingsWarningDialog: false,
    };
  },
  methods: {
    onDefaultSettingClick() {
      if (!this.canManageForms) {
        return;
      }
      this.advancedSettingType = ADVANCED_SETTINGS_CHOICES.DEFAULT;
      const hasOldSetting =
        !this.oldSelectedItemOptions?.question?.default?.applyDefault;

      if (hasOldSetting) {
        this.showAdvancedSettingsWarningDialog = true;
      } else {
        this.showAdvancedDefaultSettingsDialog = true;
      }
    },
    onVisibleSettingClick() {
      if (!this.canManageForms) {
        return;
      }
      this.advancedSettingType = ADVANCED_SETTINGS_CHOICES.VISIBILITY;
      const hasOldSetting = Boolean(
        this.oldSelectedItemOptions?.question?.visible?.condition
      );

      if (hasOldSetting) {
        this.showAdvancedSettingsWarningDialog = true;
      } else {
        this.showAdvancedVisibilitySettingsDialog = true;
      }
    },
    openAdvancedSettingDialog() {
      this.showAdvancedSettingsWarningDialog = false;
      if (this.advancedSettingType === ADVANCED_SETTINGS_CHOICES.DEFAULT) {
        this.showAdvancedDefaultSettingsDialog = true;
      } else if (
        this.advancedSettingType === ADVANCED_SETTINGS_CHOICES.VISIBILITY
      ) {
        this.showAdvancedVisibilitySettingsDialog = true;
      }
    },
    async onSubmit() {
      const success = await this.$refs.timeForm.validate();
      if (!success) {
        return;
      }
      this.$emit("input", this.selectedItemOptions);
    },
    async getGisDataFields() {
      if (this.edit) {
        if (this.formDefinition.map_service_id) {
          const {
            data: { results },
          } = await axios.get(`${APIURL}/gis_data_fields`, {
            params: { map_service_id: this.formDefinition.map_service_id },
          });
          this.gisDataFields = results;
        }
      } else {
        const { mapServiceId } = this.newFormData;
        const {
          data: { results },
        } = await axios.get(`${APIURL}/gis_data_fields`, {
          params: { map_service_id: mapServiceId },
        });
        this.gisDataFields = results;
      }
    },
    async getUserDataFields() {
      const { user_group_id: userGroupId } = JSON.parse(
        localStorage.getItem("auth")
      );

      const {
        data: { results },
      } = await axios.get(`${APIURL}/user_data_fields`, {
        params: {
          user_group_id: userGroupId,
        },
      });
      this.userDataFields = results;
    },
    async getLayer() {
      const { map_service_id: mapServiceId } = this.formDefinition;
      const {
        data: { results },
      } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
      this.layer = results;
    },
    async getFeatureService() {
      try {
        const { map_service_id: mapServiceId } = this.formDefinition;
        const {
          data: {
            results: { service_url: featureServiceUrl, token_type: tokenType },
          },
        } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
        const token =
          tokenType === "AGOL" ? localStorage.getItem("esri_token") : undefined;
        const params = {
          f: "pjson",
          token,
        };
        const {
          data: { fields },
        } = await axios.get(featureServiceUrl, { params });
        this.fields = fields;
      } catch (error) {
        this.fields = [];
      }
    },
    getSelectedItemOptions() {
      this.selectedItemOptions = cloneDeep(this.selectedItem);
      if (
        this.selectedItemOptions.question &&
        this.selectedItemOptions.question.required &&
        !["NEVER", "ALWAYS"].includes(
          this.selectedItemOptions.question.required.condition
        )
      ) {
        this.requiredCondition = "conditional";
      }

      if (
        this.selectedItemOptions?.question?.create &&
        !["ALWAYS"].includes(
          this.selectedItemOptions?.question?.create?.condition
        )
      ) {
        this.createCondition = "conditional";
      } else {
        this.createCondition =
          this.selectedItemOptions.question.visible.condition;
      }

      if (!this.selectedItemOptions.question.default) {
        this.selectedItemOptions.question.default = {};
      }
    },
  },
  beforeMount() {
    this.getUserDataFields();
    this.getSelectedItemOptions();
  },
  async mounted() {
    if (!this.selectedItemOptions.question.label) {
      await this.$nextTick();
      await sleep(500);
      this.$refs.labelInput.$refs.input.focus();
    }
  },
  watch: {
    selectedItem: {
      deep: true,
      immediate: true,
      handler(val) {
        this.selectedItemOptions = cloneDeep(val);
      },
    },
    formDefinition: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.map_service_id) {
          this.getGisDataFields();
          this.getLayer();
        }
        this.getFeatureService();
      },
    },
    createCondition(val) {
      if (["ALWAYS"].includes(val)) {
        this.selectedItemOptions.question.create.condition = val;
        this.selectedItemOptions.question.create.dependent = undefined;
        this.selectedItemOptions.question.create.dependentFieldId = undefined;
        this.selectedItemOptions.question.create.value = undefined;
      }
    },
  },
};
</script>
