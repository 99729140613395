var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      style: _vm.selected ? "background-color: #fff8e1" : "",
      attrs: { width: "100%", elevation: "0" },
    },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex align-center justify-space-between",
                  attrs: { cols: "12" },
                },
                [
                  _c("div", [
                    _vm._v(" " + _vm._s(_vm.item.question.label) + " "),
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            color: "primary",
                            disabled: _vm.readOnly,
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.showActionItemDialog = true
                            },
                          },
                        },
                        [_vm._v(" + Add ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "v-col",
                { staticClass: "pa-0 ma-0", attrs: { cols: "12" } },
                [
                  _vm.noActionItems
                    ? _c(
                        "v-card",
                        {
                          style: _vm.selected
                            ? "background-color: #fff8e1"
                            : "",
                        },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "d-flex justify-center" },
                            [
                              _vm._v(
                                " No " + _vm._s(_vm.item.question.label) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "v-card",
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "pa-0 ma-0" },
                            [
                              _c("v-data-table", {
                                staticClass: "pa-0 ma-0",
                                attrs: {
                                  headers: _vm.headers,
                                  "hide-default-footer": "",
                                  "disable-pagination": "",
                                  "group-by": "groupPosition",
                                  items: _vm.mappedActionItems,
                                  "item-key": "action_item_id",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: `group.header`,
                                      fn: function ({ isOpen, toggle, items }) {
                                        return [
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "ma-0 pa-0 group-toggle group-header",
                                              attrs: { colspan: "8" },
                                              on: { click: toggle },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "d-flex ml-2" },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        text: "",
                                                        "x-small": "",
                                                      },
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              isOpen
                                                                ? _vm.mdiChevronUp
                                                                : _vm.mdiChevronDown
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "ml-2 text-capitalize",
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.getGroupHeader(
                                                              items
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: `item.due_by`,
                                      fn: function ({ item }) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("formatVariableDate")(
                                                  item.due_by,
                                                  "MM/DD/YYYY"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: `item.action_item_id`,
                                      fn: function ({ item }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                icon: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.onActionItemClick(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.mdiPencil) +
                                                    " "
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showActionItemDialog
            ? _c("ActionItemDialog", {
                attrs: {
                  showActionItemDialog: _vm.showActionItemDialog,
                  item: _vm.item,
                  readOnly: _vm.readOnly,
                  formResultId: _vm.formResultId,
                  formDefinition: _vm.formDefinition,
                  newActionItems: _vm.actionItems.newActionItems,
                  selectedMapServiceId: _vm.selectedMapServiceId,
                },
                on: {
                  "form-saved": _vm.onFormSaved,
                  "close-form": function ($event) {
                    _vm.showActionItemDialog = false
                  },
                },
              })
            : _vm._e(),
          _vm.showEditActionItemDialog
            ? _c("EditActionItemDialog", {
                attrs: {
                  showEditActionItemDialog: _vm.showEditActionItemDialog,
                  item: _vm.item,
                  selectedActionItem: _vm.selectedActionItem,
                  readOnly: _vm.readOnly,
                  formResultId: _vm.formResultId,
                  formDefinition: _vm.formDefinition,
                  selectedMapServiceId: _vm.selectedMapServiceId,
                },
                on: {
                  "form-saved": _vm.onEditFormSaved,
                  "close-form": function ($event) {
                    _vm.showEditActionItemDialog = false
                  },
                  "delete-action-item": _vm.deleteActionItem,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }