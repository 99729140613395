var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "div",
        { staticClass: "d-flex align-center justify-space-between" },
        [
          _c("div", [_vm._v("Advanced Settings")]),
          _c(
            "v-btn",
            {
              attrs: {
                text: "",
                color: "primary",
                disabled: !_vm.canManageForms,
              },
              on: {
                click: function ($event) {
                  _vm.showAddAdvancedInfoSettingsDialog = true
                },
              },
            },
            [_vm._v(" + Add ")]
          ),
        ],
        1
      ),
      _vm.showAddAdvancedInfoSettingsDialog
        ? _c("AddAdvancedInfoSettingsDialog", {
            attrs: {
              showAddAdvancedInfoSettingsDialog:
                _vm.showAddAdvancedInfoSettingsDialog,
              selectedItemOptions: _vm.selectedItemOptions,
            },
            on: {
              "advanced-settings-selected": _vm.onAdvancedSettingSelected,
              "add-advanced-settings-dialog-close": function ($event) {
                _vm.showAddAdvancedInfoSettingsDialog = false
              },
            },
          })
        : _vm._e(),
      _vm.showAdvancedInfoVisibilitySettingsDialog
        ? _c("AdvancedInfoVisibilitySettingsDialog", {
            attrs: {
              showAdvancedInfoVisibilitySettingsDialog:
                _vm.showAdvancedInfoVisibilitySettingsDialog,
              formDefinition: _vm.formDefinition,
              layer: _vm.layer,
              selectedItem: _vm.selectedItem,
            },
            on: {
              "advanced-settings-dialog-close": function ($event) {
                _vm.showAdvancedInfoVisibilitySettingsDialog = false
              },
              input: _vm.onInput,
            },
            model: {
              value: _vm.selectedItemOptions,
              callback: function ($$v) {
                _vm.selectedItemOptions = $$v
              },
              expression: "selectedItemOptions",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }