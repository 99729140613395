var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "node-view-wrapper",
    {
      staticClass: "signature-param",
      style: {
        width: `${_vm.node.attrs.maxWidth}px`,
        height: `${_vm.node.attrs.maxHeight}px`,
      },
      attrs: { as: "div" },
    },
    [
      _c("node-view-wrapper", {
        staticClass: "signature-param-image",
        style: {
          "max-width": `${_vm.node.attrs.maxWidth}px`,
          "max-height": `${_vm.node.attrs.maxHeight}px`,
        },
        attrs: { as: "img", src: _vm.node.attrs.signatureSrc },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }