var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("validation-observer", {
    ref: "tableForm",
    attrs: { slim: "" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ valid: isFormValid }) {
          return [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.insertTable.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "v-card",
                  [
                    _c(
                      "v-card-text",
                      { staticClass: "py-3 px-4" },
                      [
                        _c("validation-provider", {
                          attrs: {
                            name: "Number of Rows",
                            rules: {
                              min_value: 0,
                              max_value: 100,
                              required: true,
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ errors, valid }) {
                                  return [
                                    _c("v-text-field", {
                                      staticClass: "pt-1",
                                      attrs: {
                                        label: "Number of Rows",
                                        "hide-details": "auto",
                                        "error-messages": errors,
                                        success: valid,
                                        color: "primary",
                                        name: "name",
                                        type: "number",
                                        hint: "Max value 100",
                                        min: "0",
                                        max: "100",
                                        "persistent-hint": "",
                                      },
                                      on: {
                                        keydown: _vm.onNumberKeyDown,
                                        change: function ($event) {
                                          return _vm.checkRowVal()
                                        },
                                        blur: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                      model: {
                                        value: _vm.numRow,
                                        callback: function ($$v) {
                                          _vm.numRow = $$v
                                        },
                                        expression: "numRow",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c("validation-provider", {
                          attrs: {
                            name: "Number of Columns",
                            rules: {
                              min_value: 0,
                              max_value: 20,
                              required: true,
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ errors, valid }) {
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Number of Columns",
                                        "hide-details": "auto",
                                        "error-messages": errors,
                                        success: valid,
                                        color: "primary",
                                        name: "name",
                                        type: "number",
                                        hint: "Max value 20",
                                        min: "0",
                                        max: "20",
                                        "persistent-hint": "",
                                      },
                                      on: {
                                        keydown: _vm.onNumberKeyDown,
                                        change: function ($event) {
                                          return _vm.checkColVal()
                                        },
                                        blur: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                      model: {
                                        value: _vm.numCol,
                                        callback: function ($$v) {
                                          _vm.numCol = $$v
                                        },
                                        expression: "numCol",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { text: "" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("cancel")
                              },
                            },
                          },
                          [_vm._v(" Cancel ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "primary",
                              type: "submit",
                              disabled: !isFormValid,
                              dark: isFormValid,
                            },
                          },
                          [_vm._v(" Insert ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }