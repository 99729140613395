var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        { staticClass: "pt-2 pb-3 px-4" },
        [
          _c("v-color-picker", {
            attrs: {
              "dot-size": "15",
              "swatches-max-height": "200",
              "show-swatches": true,
              mode: "hexa",
              "mode.sync": "hexa",
              "hide-mode-switch": "",
            },
            on: {
              "update:color": function ($event) {
                return _vm.onColorUpdate($event)
              },
            },
            model: {
              value: _vm.colorSelection,
              callback: function ($$v) {
                _vm.colorSelection = $$v
              },
              expression: "colorSelection",
            },
          }),
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { text: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("cancel")
                },
              },
            },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "v-btn",
            {
              attrs: { color: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$emit("color-saved", _vm.colorSelection)
                },
              },
            },
            [_vm._v(" Set Color ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }