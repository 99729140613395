var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "600px",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
      },
      model: {
        value: _vm.showConfirmDialog,
        callback: function ($$v) {
          _vm.showConfirmDialog = $$v
        },
        expression: "showConfirmDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-list-item",
            [
              _c("v-list-item-content", [
                _vm._v(
                  " Are you sure you want to delete this " +
                    _vm._s(_vm.itemType) +
                    "? "
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-list-item",
            [
              _c("v-list-item-content", [
                _c(
                  "div",
                  { staticClass: "d-flex justify-end" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { text: "", color: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("cancel-delete")
                          },
                        },
                      },
                      [_vm._v(" Cancel ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "primary" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.$emit("confirm-delete")
                          },
                        },
                      },
                      [_vm._v(" Delete ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }