var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showChangeFormDefinitionVersionDialog,
        persistent: "",
        width: "500",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "elevation-0" },
        [
          _c("v-card-text", { staticClass: "py-3" }, [
            _c("h2", { staticClass: "py-2" }, [_vm._v("Change Form Version")]),
            _c("p", [
              _vm._v(
                " Are you sure you want to change the form version? This will " +
                  _vm._s(_vm.versionNumber) +
                  ". Save the Form with the new version number and " +
                  _vm._s(_vm.versionNumberToSwitchTo) +
                  ". Reload the page. Most of the formatting will likely be lost. "
              ),
            ]),
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("cancel")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { dark: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit(
                        "change-form-version",
                        _vm.versionNumberToSwitchTo
                      )
                    },
                  },
                },
                [_vm._v(" Change Form Version ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }