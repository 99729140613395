<template>
  <v-card class="elevation-0 mx-auto" max-width="600">
    <v-card-text class="pa-0">
      <v-row>
        <v-col cols="12" class="card-background" v-if="!isEsriTokenPresent">
          <v-alert color="#FB8C00" rounded elevation="0" class="alert">
            Sign in using ArcGIS to test secured layers
          </v-alert>
        </v-col>

        <v-col cols="12" class="card-background pl-6">
          <v-switch
            v-model="
              formDefinition.form.locate_request_settings.archive_to_service
            "
            label="Enable Save Locate Request UtiliBot"
            @change="$emit('input', formDefinition)"
            class="mb-0 pb-0"
          ></v-switch>
        </v-col>

        <v-col cols="12" class="card-background">
          <v-card class="elevation-2">
            <v-card-text>
              <validation-provider
                v-slot="{ errors, valid }"
                name="Feature Service URL"
                :rules="{
                  required: true,
                  regex:
                    /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
                }"
              >
                <v-text-field
                  label="Feature Service URL"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="featureServiceUrl"
                  v-model="
                    formDefinition.form.locate_request_settings
                      .feature_service_url
                  "
                  @input="$emit('input', formDefinition)"
                >
                </v-text-field>
              </validation-provider>

              <v-switch
                v-model="
                  formDefinition.form.locate_request_settings.is_secure_service
                "
                label="Secured Feature Service"
                @change="$emit('input', formDefinition)"
                class="mb-0 pb-0"
              ></v-switch>
            </v-card-text>

            <v-card-actions class="pb-5 pr-4">
              <v-spacer></v-spacer>
              <v-btn type="button" color="primary" @click="testLayerConnection">
                Test Connection
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="12" class="card-background" v-if="testSuccess">
          <v-alert color="#4CAF50" rounded elevation="0" class="alert">
            Success! Connected to feature service.
          </v-alert>
        </v-col>
        <v-col cols="12" class="card-background" v-else>
          <v-alert
            color="white"
            rounded
            elevation="0"
            class="error"
            v-if="errorDetail"
          >
            {{ errorDetail }}
          </v-alert>
        </v-col>

        <v-col
          cols="12"
          v-if="
            Array.isArray(formDefinition.form.locate_request_settings.mappings)
          "
          class="mt-n5 mb-n5"
        >
          <v-card class="elevation-2">
            <v-card-text>
              <FeatureServiceFieldsTable
                v-model="formDefinition"
                :questionChoices="questionChoices"
                :formIsOnLocateLayer="
                  Boolean(formDefinition.form.locate_request_settings)
                "
                @input="$emit('input', formDefinition)"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { cloneDeep } from "lodash";
import axios from "axios";
import questionsMixin from "@/mixins/questionsMixin";
import FeatureServiceFieldsTable from "@/components/forms/form-builder-edit-form/save-811-ticket-form/FeatureServiceFieldsTable";

const VUE_APP_TMS_APP_URL = process.env.VUE_APP_TMS_APP_URL;

export default {
  name: "Save811TicketForm",
  components: {
    FeatureServiceFieldsTable,
  },
  props: {
    value: Object,
  },
  mixins: [questionsMixin],
  computed: {
    isEsriTokenPresent() {
      return Boolean(localStorage.getItem("esri_token"));
    },
    questionChoices() {
      const { questions } = this;
      return [
        {
          value: null,
          label: "None",
        },
        {
          value: `${VUE_APP_TMS_APP_URL}/ticket/{{locate_request_id}}`,
          label: "UtiliSync811 Deep Link",
        },
        ...questions.map(({ id, number, question: { label } = {} }) => ({
          value: id,
          label: `${number} ${label}`,
        })),
      ];
    },
  },
  data() {
    return {
      formDefinition: {},
      testSuccess: false,
      fields: [],
      errorDetail: "",
    };
  },
  methods: {
    async testLayerConnection() {
      this.errorDetail = "";
      try {
        const token = this.formDefinition.form.locate_request_settings
          .is_secure_service
          ? localStorage.getItem("esri_token")
          : undefined;
        const params = {
          f: "pjson",
          token,
        };
        const {
          data: { fields, error },
        } = await axios.get(
          this.formDefinition.form.locate_request_settings.feature_service_url,
          { params }
        );
        if (!error) {
          this.formDefinition.form.locate_request_settings.mappings = [
            ...fields,
          ].map(({ name }) => ({
            esri_field_name: name,
          }));
          this.testSuccess = true;
          this.$emit("input", this.formDefinition);
        } else {
          const {
            details: [detail],
          } = error;
          this.errorDetail = detail;
          this.testSuccess = false;
        }
      } catch (error) {
        this.testSuccess = false;
      }
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.formDefinition = cloneDeep(val);
        if (!this.formDefinition.form.locate_request_settings) {
          this.formDefinition.form.locate_request_settings = {
            is_secure_service: false,
            archive_to_service: false,
          };
        }
      },
    },
  },
};
</script>

<style scoped>
.alert {
  color: white;
}

.card-background {
  background-color: #f1f2f1;
}
</style>
