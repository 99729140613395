import { Node, mergeAttributes } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";
import Statement from "@/components/shared/Statement";
import { escape, unescape } from "lodash";

export default Node.create({
  name: "statement",
  group: "block",
  inline: false,
  selectable: true,
  draggable: false,
  atom: true,
  parseHTML() {
    return [
      {
        tag: "[data-statement]",
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(HTMLAttributes),
      `{% ${unescape(HTMLAttributes["data-statement"])} %}`,
    ];
  },
  addAttributes() {
    return {
      statement: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-statement"),
        renderHTML: (attributes) => ({
          "data-statement": escape(attributes.statement),
        }),
      },
      class: {
        default: null,
      },
    };
  },
  addNodeView() {
    return VueNodeViewRenderer(Statement);
  },
});
