import { Node, mergeAttributes } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";
import ImagePlaceholder from "@/components/shared/ImagePlaceholder";
import { escape } from "lodash";

export default Node.create({
  name: "ImagePlaceholder",
  group: "block",
  inline: false,
  selectable: true,
  draggable: false,
  atom: true,
  parseHTML() {
    return [
      {
        tag: "img[data-param-src]",
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ["img", mergeAttributes(HTMLAttributes)];
  },
  addAttributes() {
    return {
      paramSrc: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-param-src"),
        renderHTML: (attributes) => {
          return {
            "data-param-src": escape(attributes.paramSrc),
          };
        },
      },
      src: {
        default: null,
      },
      style: {
        default: null,
      },
      class: {
        default: null,
      },
    };
  },
  addNodeView() {
    return VueNodeViewRenderer(ImagePlaceholder);
  },
});
