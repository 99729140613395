<template>
  <div class="d-flex justify-space-between">
    <validation-observer ref="selectForm">
      <form>
        <v-list
          v-if="
            selectedRepeatingGroupItemOptions &&
            selectedRepeatingGroupItemOptions.question
          "
          dense
        >
          <v-list-item class="pa-0 ma-0">
            <v-list-item-content>
              <validation-provider
                v-slot="{ errors, valid }"
                name="Label"
                rules="required"
              >
                <v-text-field
                  v-model="selectedRepeatingGroupItemOptions.question.label"
                  label="Label"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="name"
                  @change="onSubmit()"
                  :disabled="!canManageForms"
                />
              </validation-provider>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="pa-0 ma-0">
            <v-list-item-content class="mt-0 mb-0 pt-0">
              <validation-provider v-slot="{ errors, valid }" name="Hint">
                <v-text-field
                  v-model="
                    selectedRepeatingGroupItemOptions.question.placeholder
                  "
                  label="Hint"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="hint"
                  @change="onSubmit()"
                  :disabled="!canManageForms"
                />
              </validation-provider>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="mt-0 pt-0 px-0 mx-0">
            <v-list-item-content
              class="mt-0 pt-0"
              :key="selectedRepeatingGroupItemOptions.question.default.type"
            >
              <validation-provider
                v-slot="{ errors, valid }"
                name="Default Value"
              >
                <v-select
                  autocomplete="off"
                  v-model="
                    selectedRepeatingGroupItemOptions.question.default.type
                  "
                  :items="defaultValueOptions"
                  label="Default Value"
                  :error-messages="errors"
                  :success="valid"
                  item-text="label"
                  item-value="value"
                  @change="onSubmit()"
                  hide-details
                  :disabled="!canManageForms"
                ></v-select>
              </validation-provider>

              <v-list
                v-if="
                  ['LOCATE_DATA'].includes(
                    selectedRepeatingGroupItemOptions.question.default.type
                  )
                "
                class="pa-0 ma-0"
                :key="selectedRepeatingGroupItemOptions.question.default.type"
              >
                <v-list-item class="pr-0 mr-0">
                  <v-list-item-content class="pt-0 mt-0">
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="811 Ticket"
                      rules="required"
                    >
                      <v-select
                        autocomplete="off"
                        v-model="
                          selectedRepeatingGroupItemOptions.question.default
                            .value
                        "
                        :items="locateRequestDataValues"
                        label="811 Ticket"
                        :error-messages="errors"
                        :success="valid"
                        item-text="label"
                        item-value="value"
                        @change="onSubmit()"
                        hide-details
                        :disabled="!canManageForms"
                      ></v-select>
                    </validation-provider>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-list
                v-if="
                  ['USER'].includes(
                    selectedRepeatingGroupItemOptions.question.default.type
                  )
                "
                class="pa-0 ma-0"
                :key="selectedRepeatingGroupItemOptions.question.default.type"
              >
                <v-list-item class="pr-0 mr-0">
                  <v-list-item-content>
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="User Field"
                      rules="required"
                    >
                      <v-select
                        autocomplete="off"
                        v-model="
                          selectedRepeatingGroupItemOptions.question.default
                            .value
                        "
                        :items="userDataChoices"
                        label="User Field"
                        :error-messages="errors"
                        :success="valid"
                        item-text="label"
                        item-value="value"
                        @change="onSubmit()"
                        hide-details
                        :disabled="!canManageForms"
                      ></v-select>
                    </validation-provider>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-list
                v-if="
                  ['GIS_DATA'].includes(
                    selectedRepeatingGroupItemOptions.question.default.type
                  )
                "
                class="pa-0 ma-0"
                :key="selectedRepeatingGroupItemOptions.question.default.type"
              >
                <v-list-item class="pr-0 mr-0">
                  <v-list-item-content>
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="UtiliSync Field"
                      rules="required"
                    >
                      <v-select
                        autocomplete="off"
                        v-model="
                          selectedRepeatingGroupItemOptions.question.default
                            .value
                        "
                        :items="gisDataChoices"
                        label="UtiliSync Field"
                        :error-messages="errors"
                        :success="valid"
                        item-text="label"
                        item-value="value"
                        @change="onSubmit()"
                        hide-details
                        :disabled="!canManageForms"
                      ></v-select>
                    </validation-provider>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-list
                v-if="
                  ['CUSTOM'].includes(
                    selectedRepeatingGroupItemOptions.question.default.type
                  )
                "
                :key="selectedRepeatingGroupItemOptions.question.default.type"
              >
                <v-list-item class="pr-0 mr-0">
                  <v-list-item-content class="pa-0 ma-0">
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="Default Value"
                      rules="required"
                    >
                      <v-text-field
                        v-model="
                          selectedRepeatingGroupItemOptions.question.default
                            .value
                        "
                        label="Default Value"
                        :error-messages="errors"
                        :success="valid"
                        @change="onSubmit()"
                        :disabled="!canManageForms"
                      />
                    </validation-provider>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-list
                v-if="
                  ['ORGANIZATION'].includes(
                    selectedRepeatingGroupItemOptions.question.default.type
                  )
                "
                :key="selectedRepeatingGroupItemOptions.question.default.type"
              >
                <v-list-item class="pr-0 mr-0">
                  <v-list-item-content class="my-0 py-0">
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="Organization Data"
                      rules="required"
                    >
                      <v-select
                        autocomplete="off"
                        v-model="
                          selectedRepeatingGroupItemOptions.question.default
                            .value
                        "
                        :items="orgDataOptions"
                        name="Organization Data"
                        :error-messages="errors"
                        :success="valid"
                        item-text="label"
                        item-value="value"
                        placeholder="Organization Data"
                        @change="onSubmit()"
                        :disabled="!canManageForms"
                      ></v-select>
                    </validation-provider>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-list
                v-if="
                  ['GIS'].includes(
                    selectedRepeatingGroupItemOptions.question.default.type
                  )
                "
                :key="selectedRepeatingGroupItemOptions.question.default.type"
              >
                <v-list-item class="mr-0 pr-0">
                  <v-list-item-content v-if="fieldChoices.length > 0">
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="Default Value"
                      rules="required"
                    >
                      <v-select
                        autocomplete="off"
                        v-model="
                          selectedRepeatingGroupItemOptions.question.default
                            .value
                        "
                        :items="fieldChoices"
                        name="gisField"
                        label="GIS Field"
                        :error-messages="errors"
                        :success="valid"
                        item-text="label"
                        item-value="value"
                        @change="onSubmit()"
                        hide-details
                        :disabled="!canManageForms"
                      ></v-select>
                    </validation-provider>
                  </v-list-item-content>

                  <v-list-item-content class="ma-0 pa-0" v-else>
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="Default Value"
                      rules="required"
                    >
                      <v-text-field
                        class="ma-0 pa-0"
                        v-model="
                          selectedRepeatingGroupItemOptions.question.default
                            .value
                        "
                        label="Default Value"
                        :error-messages="errors"
                        :success="valid"
                        @change="onSubmit()"
                      />
                    </validation-provider>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="pa-0 ma-0">
            <v-list-item-content class="mt-0 mb-0 pt-0">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Required"
                rules="required"
              >
                <v-select
                  autocomplete="off"
                  v-model="requiredCondition"
                  :items="groupItemRequiredOptions"
                  label="Required"
                  :error-messages="errors"
                  :success="valid"
                  item-text="label"
                  item-value="value"
                  @change="onSubmit()"
                  hide-details
                  :disabled="!canManageForms"
                ></v-select>
              </validation-provider>

              <ComparisonConditionForm
                v-if="['conditional'].includes(requiredCondition)"
                v-model="selectedRepeatingGroupItemOptions.question.required"
                :formDefinition="formDefinition"
                :selectedItem="selectedRepeatingGroupItemOptions"
                @input="onSubmit()"
                isRepeatingGroupItem
              />
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="pa-0 ma-0">
            <v-list-item-content class="my-0 pt-0">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Visible"
                rules="required"
              >
                <v-select
                  autocomplete="off"
                  v-model="visibleCondition"
                  :items="groupItemVisibleOptions"
                  label="Visible"
                  :error-messages="errors"
                  :success="valid"
                  item-text="label"
                  item-value="value"
                  @change="onSubmit()"
                  hide-details
                  :disabled="!canManageForms"
                ></v-select>
              </validation-provider>

              <ComparisonConditionForm
                v-if="['conditional'].includes(visibleCondition)"
                v-model="selectedRepeatingGroupItemOptions.question.visible"
                :formDefinition="formDefinition"
                :selectedItem="selectedRepeatingGroupItemOptions"
                @input="onSubmit()"
                isRepeatingGroupItem
              />
            </v-list-item-content>
          </v-list-item>

          <div class="d-flex justify-space-between align-center">
            <div class="caption">Choices</div>
            <div>
              <v-btn
                text
                @click="showAddChoiceDialog = true"
                color="primary"
                class="px-2"
                :disabled="!canManageForms"
              >
                + Choice
              </v-btn>
            </div>
          </div>

          <v-list-item class="pa-0 ma-0">
            <v-list-item-content>
              <draggable
                :list="selectedRepeatingGroupItemOptions.question.options"
                @end="onSubmit()"
                class="pa-0 ma-0"
                :disabled="!canManageForms"
                handle=".section-handle"
              >
                <div
                  v-for="(option, index) of selectedRepeatingGroupItemOptions
                    .question.options"
                  :key="option.id"
                  class="pa-0 d-flex align-center justify-space-between mt-n8 mb-n3"
                >
                  <div class="d-flex cursor-pointer">
                    <div class="mt-6">
                      <v-icon v-if="canManageForms">{{
                        mdiDragVertical
                      }}</v-icon>
                    </div>
                    <div class="ml-n2">
                      <v-list dense two-line max-width="110">
                        <v-list-item class="mr-n8">
                          <v-list-item-content>
                            <v-list-item-subtitle>
                              {{ option.value }}
                            </v-list-item-subtitle>
                            <v-list-item-title>
                              {{ option.label }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>
                  </div>

                  <div class="d-flex">
                    <v-btn
                      text
                      @click="
                        showEditChoiceDialog = true;
                        selectedOption = option;
                        selectedOptionIndex = index;
                      "
                      :disabled="!canManageForms"
                      x-small
                      class="pl-0 pr-0 mr-0"
                    >
                      <v-icon color="primary">{{ mdiPencil }}</v-icon>
                    </v-btn>
                    <v-btn
                      text
                      @click="removeChoice(index)"
                      x-small
                      class="pl-0 pr-0 mr-0"
                      :disabled="!canManageForms"
                    >
                      <v-icon color="primary">{{ mdiDelete }}</v-icon>
                    </v-btn>
                  </div>
                </div>
              </draggable>

              <v-dialog
                v-model="showEditChoiceDialog"
                max-width="600px"
                persistent
                :fullscreen="$vuetify.breakpoint.xsOnly"
              >
                <ChoiceForm
                  v-if="showEditChoiceDialog"
                  @choice-submitted="onChoiceSubmitted"
                  :option="selectedOption"
                  :index="selectedOptionIndex"
                  :selectedItemOptions="selectedRepeatingGroupItemOptions"
                  @choice-form-close="showEditChoiceDialog = false"
                />
              </v-dialog>

              <v-dialog
                v-model="showAddChoiceDialog"
                max-width="600px"
                :fullscreen="$vuetify.breakpoint.xsOnly"
              >
                <ChoiceForm
                  v-if="showAddChoiceDialog"
                  @choice-submitted="onChoiceSubmitted"
                  :selectedItemOptions="selectedRepeatingGroupItemOptions"
                  @choice-form-close="showAddChoiceDialog = false"
                />
              </v-dialog>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="pa-0 ma-0">
            <v-list-item-content class="my-0 py-0">
              <v-switch
                v-model="selectedRepeatingGroupItemOptions.question.isImage"
                label="Choices are Images"
                @change="onSubmit()"
                class="my-0 py-0"
                :disabled="!canManageForms"
              ></v-switch>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="pa-0 ma-0"
            v-if="!selectedRepeatingGroupItemOptions.question.isImage"
          >
            <v-list-item-content class="my-0 py-0">
              <v-switch
                v-model="selectedRepeatingGroupItemOptions.question.hasOther"
                label="Include 'Other' Option"
                @change="onSubmit()"
                class="my-0 py-0"
                :disabled="!canManageForms"
              ></v-switch>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="pa-0 ma-0"
            v-if="!selectedRepeatingGroupItemOptions.question.isImage"
          >
            <v-list-item-content class="my-0 py-0">
              <v-switch
                v-model="
                  selectedRepeatingGroupItemOptions.question.showAllOptions
                "
                label="Show All Choices at Once"
                @change="onSubmit()"
                class="my-0 py-0"
                :disabled="!canManageForms"
              ></v-switch>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="pa-0 ma-0">
            <v-list-item-content class="my-0 py-0">
              <v-switch
                v-model="
                  selectedRepeatingGroupItemOptions.question.default
                    .applyDefaultValue
                "
                label="Apply Default Value"
                @change="onSubmit()"
                class="my-0 py-0"
                :disabled="!canManageForms"
              >
              </v-switch>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import {
  mdiDragVertical,
  mdiDotsVertical,
  mdiDelete,
  mdiPencil,
} from "@mdi/js";
import { cloneDeep } from "lodash";
import {
  comparisonTypes,
  defaultValueTypes,
  groupItemRequiredOptions,
  groupItemVisibleOptions,
  visibilityOptions,
  orgDataOptions,
} from "@/constants/choices";
import ComparisonConditionForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/ComparisonConditionForm";
import ChoiceForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/select-form/ChoiceForm.vue";
import draggable from "vuedraggable";
import permissionsMixin from "@/mixins/permissionsMixin";

export default {
  name: "SelectForm",
  mixins: [permissionsMixin],
  data() {
    return {
      mdiDragVertical,
      mdiDotsVertical,
      mdiDelete,
      mdiPencil,
      selectedRepeatingGroupItemOptions: {},
      comparisonTypes,
      defaultValueTypes,
      groupItemRequiredOptions,
      groupItemVisibleOptions,
      visibilityOptions,
      orgDataOptions,
      selectedOption: {},
      showAddChoiceDialog: false,
      showEditChoiceDialog: false,
      requiredCondition: "ALWAYS",
      visibleCondition: "ALWAYS",
    };
  },
  props: {
    value: Object,
    formDefinition: Object,
    layer: Object,
    gisDataFields: Array,
    userDataFields: Array,
    fields: Array,
  },
  computed: {
    gisDataChoices() {
      return this.gisDataFields.map(
        ({ gis_data_field_id: value, name, alias }) => ({
          value,
          label: alias || name,
        })
      );
    },
    fieldChoices() {
      const fieldChoices = this.fields.map(({ name, alias }) => ({
        value: name,
        label: alias || name,
      }));
      return fieldChoices;
    },
    userDataChoices() {
      const apiChoices = this.userDataFields.map(
        ({ user_data_field_id: value, name: label }) => ({
          value,
          label,
        })
      );
      return [
        { value: "f_name", label: "First Name" },
        { value: "l_name", label: "Last Name" },
        { value: "full_name", label: "Full Name" },
        { value: "email", label: "Email" },
        ...apiChoices,
      ];
    },
    defaultValueOptions() {
      if (
        this.selectedRepeatingGroupItemOptions.question.type === "SIGNATURE"
      ) {
        return [
          {
            value: null,
            label: "None",
          },
          {
            value: "USER",
            label: "User Data",
          },
          {
            value: "GIS_DATA",
            label: "UtiliSync Field",
          },
        ];
      }

      if (
        this.selectedRepeatingGroupItemOptions.question.isLong ||
        this.selectedRepeatingGroupItemOptions.question.type === "EMAIL"
      ) {
        const defaultValueTypes = this.defaultValueTypes.filter(
          ({ label }) => label !== "Organization Data"
        );
        if (this.layer.service_type === "L") {
          defaultValueTypes.push({
            value: "LOCATE_DATA",
            label: "811 Ticket",
          });
        }
        return defaultValueTypes;
      }

      const defaultValueTypes = [...this.defaultValueTypes];
      if (this.layer.service_type === "L") {
        defaultValueTypes.push({
          value: "LOCATE_DATA",
          label: "811 Ticket",
        });
      }
      return defaultValueTypes;
    },
  },
  components: { ChoiceForm, draggable, ComparisonConditionForm },
  beforeMount() {
    this.getSelectedItemOptions();
  },
  methods: {
    getSelectedItemOptions() {
      this.selectedRepeatingGroupItemOptions = cloneDeep(this.value);
      if (!this.selectedRepeatingGroupItemOptions?.question?.required) {
        this.selectedRepeatingGroupItemOptions.question.required = {
          condition: "NEVER",
        };
      }

      if (!this.selectedRepeatingGroupItemOptions?.question?.visible) {
        this.selectedRepeatingGroupItemOptions.question.visible = {
          condition: "ALWAYS",
        };
      }

      if (!this.selectedRepeatingGroupItemOptions?.question?.default) {
        this.selectedRepeatingGroupItemOptions.question.default = {};
      }
      if (
        !["NEVER", "ALWAYS"].includes(
          this.selectedRepeatingGroupItemOptions?.question?.required.condition
        )
      ) {
        this.requiredCondition = "conditional";
      } else {
        this.requiredCondition =
          this.selectedRepeatingGroupItemOptions.question.required.condition;
      }

      if (!this.selectedRepeatingGroupItemOptions?.question?.visible) {
        this.visibleCondition = "ALWAYS";
      }

      if (
        !["NEVER", "ALWAYS"].includes(
          this.selectedRepeatingGroupItemOptions?.question?.visible?.condition
        )
      ) {
        this.visibleCondition = "conditional";
      } else {
        this.visibleCondition =
          this.selectedRepeatingGroupItemOptions.question.visible.condition;
      }

      if (!this.selectedRepeatingGroupItemOptions?.question?.default) {
        this.selectedRepeatingGroupItemOptions.question.default = {
          type: null,
        };
      }
    },
    async onSubmit() {
      const success = await this.$refs.selectForm.validate();
      if (!success) {
        return;
      }
      const options = cloneDeep(this.selectedRepeatingGroupItemOptions);
      options.question.options = options.question.options.map(
        ({ value, label }) => ({
          value,
          label,
        })
      );
      this.$emit("input", options);
    },
    onChoiceSubmitted(choice, index) {
      if (typeof index === "number") {
        this.$set(
          this.selectedRepeatingGroupItemOptions.question.options,
          index,
          choice
        );
        this.showEditChoiceDialog = false;
      } else {
        this.selectedRepeatingGroupItemOptions.question.options.push(choice);
        this.showAddChoiceDialog = false;
      }
      this.onSubmit();
    },
    async removeChoice(index) {
      this.selectedRepeatingGroupItemOptions.question.options.splice(index, 1);
      await this.onSubmit();
    },
  },
  watch: {
    requiredCondition(val) {
      if (["NEVER", "ALWAYS"].includes(val)) {
        this.selectedRepeatingGroupItemOptions.question.required.condition =
          val;
        this.selectedRepeatingGroupItemOptions.question.required.dependantId =
          undefined;
        this.selectedRepeatingGroupItemOptions.question.required.dependantGroupItemId =
          undefined;
        this.selectedRepeatingGroupItemOptions.question.required.value =
          undefined;
      }
    },
    visibleCondition(val) {
      if (["NEVER", "ALWAYS"].includes(val)) {
        this.selectedRepeatingGroupItemOptions.question.visible.condition = val;
        this.selectedRepeatingGroupItemOptions.question.visible.dependantId =
          undefined;
        this.selectedRepeatingGroupItemOptions.question.visible.dependantGroupItemId =
          undefined;
        this.selectedRepeatingGroupItemOptions.question.visible.value =
          undefined;
      }
    },
  },
};
</script>
