<template>
  <v-dialog
    :value="showEditPositiveResponseCommentChoicesDialog"
    max-width="1000px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title> Edit Positive Response Comments </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$emit('close-dialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text style="background-color: #f1f2f1" class="pa-4">
          <section class="d-flex justify-end">
            <v-btn text @click="addComment">+ Add Comment</v-btn>
          </section>

          <v-simple-table height="40vh" fixed-header>
            <thead>
              <tr>
                <th></th>
                <th colspan="2">Comment (Max 255 Characters)</th>
              </tr>
            </thead>

            <draggable
              :list="comments"
              tag="tbody"
              v-if="comments.length > 0"
              @end="onSubmit()"
            >
              <tr v-for="(c, index) of comments" :key="c.id">
                <td class="pl-1 pr-0 mx-0 cursor-grab">
                  <v-icon>{{ mdiDragVertical }}</v-icon>
                </td>
                <td>
                  <validation-provider
                    v-slot="{ errors, valid }"
                    name="Comment"
                    rules="required|max:255"
                  >
                    <v-text-field
                      v-model.trim="c.text"
                      hide-details="auto"
                      :error-messages="errors"
                      :success="valid"
                      color="primary"
                    />
                  </validation-provider>
                </td>
                <td>
                  <section class="d-flex justify-end">
                    <v-btn icon @click="deleteComment(index)">
                      <v-icon>
                        {{ mdiDelete }}
                      </v-icon>
                    </v-btn>
                  </section>
                </td>
              </tr>
            </draggable>
            <tbody v-else>
              <tr>
                <td colspan="3">
                  <section class="d-flex justify-center">
                    No Comments Added
                  </section>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>

        <v-card-actions class="d-flex pa-5 justify-end">
          <v-btn
            color="primary"
            @click="
              onSubmit();
              $emit('close-dialog');
            "
          >
            Save Choices
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { cloneDeep } from "lodash";
import { mdiDragVertical, mdiDelete } from "@mdi/js";
import draggable from "vuedraggable";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "EditPositiveResponseCommentChoicesDialog",
  props: {
    showEditPositiveResponseCommentChoicesDialog: Boolean,
    value: Object,
    formDefinition: Object,
  },
  components: {
    draggable,
  },
  beforeMount() {
    this.selectedItemOptions = cloneDeep(this.value);
    if (!Array.isArray(this.selectedItemOptions.question.comments)) {
      this.selectedItemOptions.question.comments = [];
    }
    this.comments = this.selectedItemOptions.question.comments.map((text) => {
      return {
        id: uuidv4(),
        text,
      };
    });
  },
  data() {
    return {
      mdiDragVertical,
      mdiDelete,
      selectedItemOptions: {},
      comments: [],
    };
  },
  methods: {
    addComment() {
      const newComment = {
        id: uuidv4(),
        text: "",
      };
      this.comments.push(newComment);
    },
    deleteComment(index) {
      this.comments.splice(index, 1);
    },
    onSubmit() {
      this.selectedItemOptions.question.comments = [
        ...new Set(this.comments.map(({ text }) => text)),
      ];
      this.$emit("input", cloneDeep(this.selectedItemOptions));
    },
  },
};
</script>
