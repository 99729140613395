<template>
  <v-dialog
    :value="showAddImageDialog"
    max-width="500px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar dark color="primary" class="elevation-0">
        <v-toolbar-title>Add Image</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('add-photo-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="py-3">
        <div>Add image by selecting a source</div>

        <input
          type="file"
          accept="image/*;capture=camera"
          style="visibility: hidden"
          ref="cameraInput"
          @change="onFileInputChange"
        />

        <div class="d-flex justify-space-between my-3">
          <v-btn text color="primary" @click="$refs.cameraInput.click()">
            Camera
          </v-btn>
          <v-btn text color="primary" @click="showSelectDocDialog = true">
            Docs
          </v-btn>
          <v-btn text color="primary"> Map </v-btn>
        </div>

        <file-pond
          name="files"
          ref="files"
          label-idle="Drag & Drop or <span class='filepond--label-action'> Browse </span>"
          :allow-multiple="allowMultiple"
          accepted-file-types="image/jpeg, image/png"
          :files="uploadFiles"
          @addfile="onChange"
          allowFileSizeValidation
          maxFileSize="2MB"
          :disabled="readOnly"
        />

        <v-card
          v-for="(image, index) of base64Images"
          :key="image.id"
          class="d-flex flex-column justify-center my-2"
        >
          <v-img :src="image.dataUrl"></v-img>

          <v-card-text class="py-0 my-0">
            <div class="d-flex justify-space-between align-center">
              <v-text-field
                :key="image.id"
                label="Description"
                color="primary"
                :name="image.id"
                v-model="image.description"
                @input="$emit('input', base64Images)"
              >
              </v-text-field>

              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>{{ mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="removePhoto(index, image.id)">
                    <v-list-item-title>
                      <v-icon>{{ mdiDelete }}</v-icon>
                      Delete Image
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-card-text>
        </v-card>

        <SelectDocDialog
          :showSelectDocDialog="showSelectDocDialog"
          @select-doc-dialog-close="showSelectDocDialog = false"
          @image-file-selected="onImageFileSelected"
        />
      </v-card-text>

      <v-card-actions class="d-flex justify-end px-5 py-3">
        <v-btn
          color="primary"
          class="font-weight-regular"
          @click="$emit('add-photo-dialog-close')"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import vueFilePond, { setOptions } from "vue-filepond";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import "filepond/dist/filepond.min.css";
import { v4 as uuidv4 } from "uuid";
import { mdiDotsVertical, mdiDelete } from "@mdi/js";
import { cloneDeep } from "lodash";
import SelectDocDialog from "@/components/forms/form-builder-edit-form/item-preview/photo-input/add-image-dialog/SelectDocDialog";

const Compress = require("compress.js");
const compress = new Compress();
const FilePond = vueFilePond(FilePondPluginFileEncode);

setOptions({
  imageResizeMode: "contain",
  allowImageTransform: true,
});

export default {
  name: "AddImageDialog",
  props: {
    showAddImageDialog: Boolean,
    maxWidthHeight: String,
    readOnly: Boolean,
    allowMultiple: Boolean,
    value: Array,
  },
  components: {
    FilePond,
    SelectDocDialog,
  },
  data() {
    return {
      uploadFiles: undefined,
      base64Images: [],
      showPhotoEditor: {},
      resizedImageWidth: 0,
      resizedImageHeight: 0,
      mdiDotsVertical,
      mdiDelete,
      showSelectDocDialog: false,
      selectedFile: {},
    };
  },
  methods: {
    onImageFileSelected(imageFile) {
      this.base64Images = [...this.base64Images, imageFile];
      this.$emit("input", this.base64Images);
      this.showSelectDocDialog = false;
    },
    async onChange() {
      const files = this.$refs.files.getFiles();
      const base64ImagePromises = files.map(async (f) => {
        let dataUrl = f.getFileEncodeDataURL();
        const { name: fileName, type: fileType } = f.file;
        const result = await compress.compress([f.file], {
          maxWidth: this.maxWidthHeight,
          maxHeight: this.maxWidthHeight,
          resize: true,
        });
        const [{ data, prefix }] = result;
        dataUrl = `${prefix}${data}`;
        return {
          dataUrl,
          description: "",
          fileName,
          fileType,
          id: uuidv4(),
          name: fileName,
          url: dataUrl,
          uploaded: false,
        };
      });

      if (files.length > 0) {
        for (const f of files) {
          this.$refs.files.removeFile(f);
        }
        const base64Images = await Promise.all(base64ImagePromises);
        this.base64Images = [...this.base64Images, ...base64Images];
        this.$emit("input", this.base64Images);
      }
    },
    async removePhoto(index) {
      this.base64Images.splice(index, 1);
      this.$emit("input", this.base64Images);
    },
    onFileInputChange(e) {
      const [file] = e.target.files;
      const fileReader = new FileReader();
      fileReader.onload = this.onFileRead;
      fileReader.readAsDataURL(file);
      this.selectedFile = file;
    },
    onFileRead(e) {
      const dataUrl = e.target.result;
      const { name: fileName, type: fileType } = this.selectedFile;
      const base64Image = {
        dataUrl,
        description: "",
        fileName,
        fileType,
        id: uuidv4(),
        name: fileName,
        url: dataUrl,
        uploaded: false,
      };
      this.base64Images = [...this.base64Images, base64Image];
      this.selectedFile = {};
    },
  },
  beforeMount() {
    this.base64Images = cloneDeep(this.value);
  },
};
</script>
