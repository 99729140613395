<template>
  <v-dialog
    :value="showFormBuilderEditFormCloseDialog"
    max-width="600px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-toolbar dark color="primary">
      <v-toolbar-title> Unsaved Changes </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="$emit('reverse-changes')">
        <v-icon>
          {{ mdiClose }}
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-card class="rounded-0">
      <v-list-item>
        <v-list-item-content>
          You have unsaved changes to your form. Do you want to close the form
          without saving?
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <div class="d-flex justify-end">
            <v-btn
              text
              @click="$emit('form-builder-edit-form-save-and-close')"
              color="primary"
            >
              Save and Close
            </v-btn>
            <v-btn
              @click="$emit('form-builder-edit-form-close')"
              color="primary"
            >
              Close Without Saving
            </v-btn>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from "@mdi/js";
export default {
  name: "UnsavedChangesDialog",
  props: {
    showFormBuilderEditFormCloseDialog: Boolean,
  },
  data() {
    return {
      mdiClose,
    };
  },
};
</script>
