import Table from "@tiptap/extension-table";

const CustomTable = Table.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      display: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.display) {
            return {};
          }

          return {
            style: `display: ${attributes.display}`,
          };
        },
        parseHTML: (element) => {
          return element.style.display.replace(/['"]+/g, "");
        },
      },
      justifyContent: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.justifyContent) {
            return {};
          }

          return {
            style: `justify-content: ${attributes.justifyContent}`,
          };
        },
        parseHTML: (element) => {
          return element.style.justifyContent.replace(/['"]+/g, "");
        },
      },
      justifySelf: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.justifySelf) {
            return {};
          }

          return {
            style: `justify-self: ${attributes.justifySelf}`,
          };
        },
        parseHTML: (element) => {
          return element.style.justifySelf.replace(/['"]+/g, "");
        },
      },
      alignItems: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.alignItems) {
            return {};
          }

          return {
            style: `align-items: ${attributes.alignItems}`,
          };
        },
        parseHTML: (element) => {
          return element.style.alignItems.replace(/['"]+/g, "");
        },
      },
      margin: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.margin) {
            return {};
          }

          return {
            style: `margin: ${attributes.margin}`,
          };
        },
        parseHTML: (element) => {
          return element.style.margin.replace(/['"]+/g, "");
        },
      },
      width: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.width) {
            return {};
          }

          return {
            style: `width: ${attributes.width}`,
          };
        },
        parseHTML: (element) => {
          return element.style.width.replace(/['"]+/g, "");
        },
      },
      textAlign: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.textAlign) {
            return {};
          }

          return {
            style: `text-align: ${attributes.textAlign}`,
          };
        },
        parseHTML: (element) => {
          return element.style.textAlign.replace(/['"]+/g, "");
        },
      },
      class: {
        default: null,
      },
    };
  },
});

export default CustomTable;
