var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showPageSettingsDialog,
        "max-width": "300",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
      },
    },
    [
      _c(
        "v-card",
        [
          _c("validation-observer", {
            ref: "docSettingsForm",
            attrs: { slim: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ valid: isFormValid }) {
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.save.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "v-toolbar",
                          {
                            staticClass: "elevation-0",
                            attrs: { dark: "", color: "primary" },
                          },
                          [
                            _c("v-toolbar-title", [_vm._v(" Page Settings ")]),
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "", dark: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit(
                                      "close-doc-settings-dialog"
                                    )
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v("mdi-close")])],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-card-text",
                          {
                            staticClass:
                              "elevation-0 d-flex justify-space-between",
                          },
                          [
                            _c(
                              "section",
                              [
                                _vm._v(" Page Size (inches) "),
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Width",
                                    rules: {
                                      required: true,
                                      min_value: 0,
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors, valid }) {
                                          return [
                                            _c("v-text-field", {
                                              staticClass:
                                                "page-settings-input",
                                              attrs: {
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                success: valid,
                                                color: "primary",
                                                name: "pageWidth",
                                                label: "Width",
                                                disabled: _vm.disabled,
                                              },
                                              model: {
                                                value:
                                                  _vm.formDefinition.form
                                                    .pageSettings.pageWidth,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formDefinition.form
                                                      .pageSettings,
                                                    "pageWidth",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formDefinition.form.pageSettings.pageWidth",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Height",
                                    rules: {
                                      required: true,
                                      min_value: 0,
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors, valid }) {
                                          return [
                                            _c("v-text-field", {
                                              staticClass:
                                                "page-settings-input",
                                              attrs: {
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                success: valid,
                                                color: "primary",
                                                name: "pageHeight",
                                                label: "Height",
                                                disabled: _vm.disabled,
                                              },
                                              model: {
                                                value:
                                                  _vm.formDefinition.form
                                                    .pageSettings.pageHeight,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formDefinition.form
                                                      .pageSettings,
                                                    "pageHeight",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formDefinition.form.pageSettings.pageHeight",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "section",
                              [
                                _vm._v(" Margins (inches) "),
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Top",
                                    rules: {
                                      required: true,
                                      min_value: 0,
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors, valid }) {
                                          return [
                                            _c("v-text-field", {
                                              staticClass:
                                                "page-settings-input",
                                              attrs: {
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                success: valid,
                                                color: "primary",
                                                name: "marginTop",
                                                label: "Top",
                                                disabled: _vm.disabled,
                                              },
                                              model: {
                                                value:
                                                  _vm.formDefinition.form
                                                    .pageSettings.marginTop,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formDefinition.form
                                                      .pageSettings,
                                                    "marginTop",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formDefinition.form.pageSettings.marginTop",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Bottom",
                                    rules: {
                                      required: true,
                                      min_value: 0,
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors, valid }) {
                                          return [
                                            _c("v-text-field", {
                                              staticClass:
                                                "page-settings-input",
                                              attrs: {
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                success: valid,
                                                color: "primary",
                                                name: "marginBottom",
                                                label: "Bottom",
                                                disabled: _vm.disabled,
                                              },
                                              model: {
                                                value:
                                                  _vm.formDefinition.form
                                                    .pageSettings.marginBottom,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formDefinition.form
                                                      .pageSettings,
                                                    "marginBottom",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formDefinition.form.pageSettings.marginBottom",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Left",
                                    rules: {
                                      required: true,
                                      min_value: 0,
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors, valid }) {
                                          return [
                                            _c("v-text-field", {
                                              staticClass:
                                                "page-settings-input",
                                              attrs: {
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                success: valid,
                                                color: "primary",
                                                name: "marginLeft",
                                                label: "Left",
                                                disabled: _vm.disabled,
                                              },
                                              model: {
                                                value:
                                                  _vm.formDefinition.form
                                                    .pageSettings.marginLeft,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formDefinition.form
                                                      .pageSettings,
                                                    "marginLeft",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formDefinition.form.pageSettings.marginLeft",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Right",
                                    rules: {
                                      required: true,
                                      min_value: 0,
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors, valid }) {
                                          return [
                                            _c("v-text-field", {
                                              staticClass:
                                                "page-settings-input",
                                              attrs: {
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                success: valid,
                                                color: "primary",
                                                name: "marginRight",
                                                label: "Right",
                                                disabled: _vm.disabled,
                                              },
                                              model: {
                                                value:
                                                  _vm.formDefinition.form
                                                    .pageSettings.marginRight,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formDefinition.form
                                                      .pageSettings,
                                                    "marginRight",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formDefinition.form.pageSettings.marginRight",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "v-card-actions",
                          { staticClass: "d-flex justify-end" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  type: "submit",
                                  color: "primary",
                                  disabled: !isFormValid || _vm.disabled,
                                  dark: isFormValid && !_vm.disabled,
                                },
                              },
                              [_vm._v(" Update ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }