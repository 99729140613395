<template>
  <div>
    <v-card>
      <v-card-text>
        <v-data-table
          :headers="headers"
          hide-default-footer
          disable-pagination
          :items="newActivities"
        >
          <template v-slot:top>
            <v-row class="ml-0 mr-0">
              <v-col cols="12" class="d-flex justify-end">
                <v-btn
                  color="primary"
                  @click="showCreateCityworksActivityDialog = true"
                  :disabled="!canManageForms"
                >
                  + Cityworks Activity
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:body="{ items }" v-if="newActivities.length > 0">
            <tbody>
              <tr
                v-for="(item, index) of items"
                :key="index"
                @click="onRowClick(item, index)"
              >
                <td>{{ item.type }}</td>
                <td>{{ item.condition }}</td>
                <td class="text-right px-2">
                  <v-icon
                    class="edit-button mr-1"
                    @click="onRowClick(item, index)"
                    text
                    :disabled="!canManageForms"
                  >
                    {{ mdiPencil }}
                  </v-icon>
                  <v-icon
                    :disabled="!canManageForms"
                    @click.stop="
                      showRemoveDialog = true;
                      itemIndex = index;
                    "
                    text
                    class="delete-button ml-1"
                  >
                    {{ mdiDelete }}
                  </v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="showCreateCityworksActivityDialog"
      max-width="600px"
      persistent
    >
      <NewCityworksActivity
        v-if="showCreateCityworksActivityDialog"
        v-model="formDefinition"
        :itemToEdit="itemToEdit"
        :itemIndex="itemIndex"
        @create-cityworks-activity-close="
          showCreateCityworksActivityDialog = false;
          itemToEdit = {};
          itemIndex = undefined;
        "
        @input="$emit('input', formDefinition)"
      />
    </v-dialog>

    <v-dialog
      v-model="showRemoveDialog"
      max-width="600px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card class="pt-5">
        <v-card-text>
          Are you sure you want to delete this New Cityworks Activity? This
          cannot be undone.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="
              showRemoveDialog = false;
              itemIndex = undefined;
            "
          >
            Cancel
          </v-btn>
          <v-btn color="primary" dark @click="removeItem(itemIndex)">
            Remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import NewCityworksActivity from "@/components/forms/form-builder-edit-form/cityworks-form/NewCityworksActivity";
import { mdiPencil, mdiDelete } from "@mdi/js";
import permissionsMixin from "@/mixins/permissionsMixin";

const headers = [
  {
    text: "Activity Type",
    align: "start",
    sortable: true,
    value: "type",
  },
  {
    text: "Condition",
    align: "start",
    sortable: false,
    value: "condition",
  },
  {
    text: "Actions",
    align: "end",
    sortable: false,
    value: "actions",
  },
];

export default {
  name: "CityworksActivities",
  components: {
    NewCityworksActivity,
  },
  props: {
    value: Object,
  },
  mixins: [permissionsMixin],
  data() {
    return {
      mdiPencil,
      mdiDelete,
      formDefinition: {},
      headers,
      showCreateCityworksActivityDialog: false,
      showRemoveDialog: false,
      itemIndex: undefined,
      itemToEdit: {},
    };
  },
  computed: {
    newActivities() {
      return this.formDefinition.form.city_works_webhook_config
        .on_form_submission.create_new_activities;
    },
  },
  methods: {
    removeItem(itemIndex) {
      this.formDefinition.form.city_works_webhook_config.on_form_submission.create_new_activities.splice(
        itemIndex,
        1
      );

      this.$emit("input", this.formDefinition);

      this.showRemoveDialog = false;
    },
    onRowClick(item, index) {
      this.itemToEdit = item;
      this.itemIndex = index;
      this.showCreateCityworksActivityDialog = true;
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.formDefinition = cloneDeep(val);
      },
    },
  },
};
</script>
