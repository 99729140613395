<template>
  <v-row>
    <v-col cols="12">
      <validation-provider
        slim
        v-slot="{ errors, valid }"
        :name="item.question.label"
        :rules="{
          required: isRequired,
        }"
      >
        <v-menu max-width="100%">
          <template v-slot:activator="{ on }">
            <v-text-field
              class="time-field"
              v-model="timeValue"
              @input="$emit('input', timeValue)"
              v-on="on"
              :label="item.question.label"
              color="primary"
              :error-messages="errors"
              :success="valid"
              :disabled="readOnly"
            ></v-text-field>
          </template>
          <v-time-picker v-model="timeValue"></v-time-picker>
        </v-menu>
      </validation-provider>
    </v-col>
  </v-row>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  name: "GroupTimeInput",
  props: {
    item: Object,
    readOnly: Boolean,
    value: String,
  },
  computed: {
    isRequired() {
      return this.item?.question?.required?.condition === "ALWAYS";
    },
  },
  data() {
    return {
      timeValue: undefined,
    };
  },
  beforeMount() {
    this.timeValue = cloneDeep(this.value);
  },
};
</script>
