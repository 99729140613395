var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    { attrs: { value: _vm.showAdvancedSettingsWarningDialog, width: "500px" } },
    [
      _c(
        "v-card",
        { staticClass: "pt-5 pb-3" },
        [
          _c("v-card-text", [
            _c("h3", [_vm._v("Warning!")]),
            _c("div", { staticClass: "py-3" }, [
              _vm._v(
                " If you continue to the implementation of Advanced Settings, you will break your form in the UtiliSync app found at app.utilisync.com. Contact UtiliSync support if you need help configuring one of these settings on your form. "
              ),
            ]),
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end align-center px-5" },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("cancel")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("continue")
                    },
                  },
                },
                [_vm._v("Continue")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }