var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("validation-observer", { ref: "form" }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "v-card",
          {
            attrs: {
              height: _vm.$vuetify.breakpoint.xsOnly ? "100vh" : "100%",
            },
          },
          [
            _c(
              "v-toolbar",
              {
                staticClass: "elevation-0",
                attrs: { dark: "", color: "primary" },
              },
              [
                _c("v-toolbar-title", [_vm._v("Question Advanced Edit")]),
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "", dark: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("close-advanced-edit-dialog")
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("mdi-close")])],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-card-text",
              { staticClass: "elevation-0 py-0 my-0" },
              [
                _c("validation-provider", {
                  attrs: { name: "JSON", rules: "required|json" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ errors, valid, pristine }) {
                        return [
                          _c("JsonEditor", {
                            attrs: {
                              label: "Question JSON",
                              "error-messages": errors,
                              success: valid,
                              color: "primary",
                              name: "json",
                              outlined: "",
                              height: _vm.$vuetify.breakpoint.xsOnly
                                ? "calc(100vh - 150px)"
                                : "calc(95vh - 200px)",
                            },
                            model: {
                              value: _vm.selectedItemJson,
                              callback: function ($$v) {
                                _vm.selectedItemJson = $$v
                              },
                              expression: "selectedItemJson",
                            },
                          }),
                          !pristine && !valid && Array.isArray(errors)
                            ? _c(
                                "v-alert",
                                {
                                  attrs: {
                                    color: "#EF9A9A",
                                    rounded: "",
                                    elevation: "0",
                                  },
                                },
                                [
                                  _vm._v(
                                    " Error: " + _vm._s(errors.join(". ")) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "v-card-actions",
              { staticClass: "d-flex justify-end pa-3" },
              [
                _c("v-btn", { attrs: { type: "submit", color: "primary" } }, [
                  _vm._v("Save Edits"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }