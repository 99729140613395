var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showSelectPdfPageDialog,
        "max-width": "200px",
        persistent: "",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
      },
    },
    [
      _c(
        "v-card",
        { attrs: { color: "#4F4F4F" } },
        [
          _c(
            "v-toolbar",
            { staticClass: "elevation-0", attrs: { dark: "", color: "black" } },
            [
              _c("v-toolbar-title", [_vm._v("Select Page")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("select-pdf-page-dialog-close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "py-3" },
            [
              _vm.isLoading
                ? _c(
                    "div",
                    { staticClass: "d-flex align-center justify-center" },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "white" },
                      }),
                    ],
                    1
                  )
                : _c("div", [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-center align-center" },
                      [
                        _c(
                          "validation-observer",
                          { ref: "form", attrs: { slim: "" } },
                          [
                            _c(
                              "form",
                              {
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                  },
                                },
                              },
                              [
                                _c(
                                  "validation-provider",
                                  { attrs: { name: "Page", rules: "numeric" } },
                                  [
                                    _c("v-text-field", {
                                      staticClass: "page-input py-0 my-0",
                                      staticStyle: { width: "60px" },
                                      attrs: { dark: "", "hide-details": "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "append-outer",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "6px",
                                                    width: "30px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " of " +
                                                      _vm._s(
                                                        _vm.pdfPageImages.length
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                      model: {
                                        value: _vm.page,
                                        callback: function ($$v) {
                                          _vm.page = _vm._n($$v)
                                        },
                                        expression: "page",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-wrap justify-center" },
                      _vm._l(_vm.pdfPageImages, function (p, index) {
                        return _c("img", {
                          key: p.file_id,
                          staticClass: "my-5 cursor-pointer elevation-5",
                          staticStyle: { width: "80px" },
                          style: {
                            outline:
                              index + 1 === _vm.page
                                ? "6px solid #8AB4F8"
                                : "none",
                            opacity: index + 1 !== _vm.page ? "0.2" : 1,
                          },
                          attrs: { src: require("@/assets/page.png") },
                          on: { click: _vm.onPageClick },
                        })
                      }),
                      0
                    ),
                  ]),
              _vm.showAddPageToFormDialog
                ? _c("AddPageToFormDialog", {
                    attrs: {
                      showAddPageToFormDialog: _vm.showAddPageToFormDialog,
                      selectedPage: _vm.selectedPage,
                      maxWidthHeight: _vm.maxWidthHeight,
                      formResultId: _vm.formResultId,
                    },
                    on: {
                      "add-page-to-form-dialog-close": function ($event) {
                        _vm.showAddPageToFormDialog = false
                      },
                      "confirm-add-page": function ($event) {
                        _vm.$emit("confirm-add-page", $event)
                        _vm.showAddPageToFormDialog = false
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }