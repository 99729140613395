<template>
  <section>
    <v-card v-if="['URL'].includes(item.information.type)" class="elevation-0">
      <p v-if="readOnly">{{ item.information.label }}</p>
      <a :href="item.information.url" v-else target="_blank">
        {{ item.information.label }}
      </a>
    </v-card>

    <v-card
      v-if="['HEADER'].includes(item.information.type)"
      class="elevation-0"
    >
      <h2>
        {{ item.information.label }}
      </h2>
    </v-card>

    <v-card v-if="['TEXT'].includes(item.information.type)" class="elevation-0">
      <v-card-text class="pa-0 ma-0">
        <div v-html="item.information.label"></div>
      </v-card-text>
    </v-card>

    <v-card
      v-if="['IMAGE'].includes(item.information.type)"
      class="elevation-0"
    >
      <div v-if="readOnly">
        <p>{{ item.information.label }}</p>
        <img
          :src="item.information.url"
          :style="{ height: 'auto', 'max-width': '500px' }"
        />
      </div>
      <div v-else>
        <p>{{ item.information.label }}</p>
        <a :href="item.information.openUrl" target="_blank">
          <img
            :src="item.information.url"
            :style="{ height: 'auto', 'max-width': '500px' }"
          />
        </a>
      </div>
    </v-card>
  </section>
</template>

<script>
export default {
  name: "RepeatingGroupInfo",
  props: {
    item: Object,
    readOnly: Boolean,
  },
};
</script>
