<template>
  <v-simple-table v-if="Array.isArray(formDefinition.form.relatedTable.fields)">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">ArcGIS Feature Service Field</th>
          <th class="text-left">UtiliSync Form Field</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="field in fields" :key="field.name">
          <td>{{ field.alias }}</td>
          <td v-if="!noTokenAndNotNoneTokenType">
            <validation-provider
              v-slot="{ errors, valid }"
              name="UtiliSync Form Field"
            >
              <v-select
                label="UtiliSync Form Field"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="utiliSyncFormField"
                :items="filterQuestionChoices(field)"
                item-text="label"
                item-value="value"
                v-model="field.selectedQuestionId"
                @change="onChange"
                :disabled="!canManageForms"
              >
              </v-select>
            </validation-provider>
          </td>
          <td
            v-else-if="utiliSyncFieldName(field.selectedQuestionId)"
            class="font-weight-bold"
          >
            {{ utiliSyncFieldName(field.selectedQuestionId) }}
          </td>
          <td v-else class="font-weight-bold"><i>None</i></td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { cloneDeep } from "lodash";
import questionsMixin from "@/mixins/questionsMixin";
import { mdiInformation } from "@mdi/js";
import permissionsMixin from "@/mixins/permissionsMixin";

export default {
  name: "FeatureServiceFieldsTable",
  props: {
    value: Object,
    fields: Array,
    questionChoices: Array,
    formIsOnLocateLayer: Boolean,
    noTokenAndNotNoneTokenType: Boolean,
  },
  mixins: [questionsMixin, permissionsMixin],
  data() {
    return {
      formDefinition: {},
      mdiInformation,
    };
  },
  computed: {
    esriFieldTypeIntegerQuestionChoices() {
      const { questions } = this;
      const integerQuestions = questions.filter(({ question: { type } }) =>
        ["CALCULATION", "NUMBER", "SINGLE_SELECT"].includes(type)
      );
      return [
        {
          value: null,
          label: "None",
        },
        ...integerQuestions.map(({ id, number, question: { label } = {} }) => ({
          value: id,
          label: `${number} ${label}`,
        })),
      ];
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.formDefinition = cloneDeep(val);
      },
    },
  },
  methods: {
    utiliSyncFieldName(selectedQuestionId) {
      if (!this.formDefinition.form) {
        return {};
      }
      const foundItem = this.formDefinition.form.sections
        .map((section) => section.items || [])
        .flat()
        .find((item) => item.id === selectedQuestionId);

      if (foundItem) {
        return foundItem.question.label;
      }
      return "";
    },
    filterQuestionChoices(field) {
      if (field.type === "esriFieldTypeInteger") {
        return this.esriFieldTypeIntegerQuestionChoices;
      }
      return this.questionChoices;
    },
    onChange() {
      const { fields } = this;
      if (
        this.formDefinition.form.relatedTable !== null &&
        typeof this.formDefinition.form.relatedTable === "object"
      ) {
        this.$set(this.formDefinition.form, "relatedTable", {
          ...this.formDefinition.form.relatedTable,
          fields,
        });
        this.$emit("input", this.formDefinition);
      }
    },
  },
};
</script>

<style scoped>
tr {
  line-height: 52px;
}

th {
  line-height: 16px;
}
</style>
