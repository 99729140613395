<template>
  <v-card>
    <v-card-text class="py-1 my-1">
      <section class="d-flex justify-space-between align-center">
        <div>This UtiliBot will add comments to Cityworks service request</div>

        <v-switch
          v-model="
            formDefinition.form.city_works_webhook_config
              .add_service_request_comment.enabled
          "
          label="Enable Integration"
          @change="$emit('input', formDefinition)"
          :disabled="!canManageForms"
        >
        </v-switch>
      </section>

      <p>Customize the comment to be added on the service request.</p>

      <SimpleFormCommentTemplateEditor
        class="my-3"
        v-model="
          formDefinition.form.city_works_webhook_config
            .add_service_request_comment.template
        "
        @input="onChange"
        :disabled="!canManage811Codes"
        :formDefinition="formDefinition"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { cloneDeep } from "lodash";
import permissionsMixin from "@/mixins/permissionsMixin";
import SimpleFormCommentTemplateEditor from "@/components/shared/SimpleFormCommentTemplateEditor";

export default {
  name: "CityworksServiceRequestCommentsForm",
  props: {
    value: Object,
  },
  mixins: [permissionsMixin],
  components: { SimpleFormCommentTemplateEditor },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.formDefinition = cloneDeep(val);
        if (
          !this.formDefinition.form.city_works_webhook_config
            .add_service_request_comment
        ) {
          this.formDefinition.form.city_works_webhook_config.add_service_request_comment =
            {
              enabled: false,
            };
        }
      },
    },
  },
  methods: {
    onChange() {
      this.$emit("input", cloneDeep(this.formDefinition));
    },
  },
};
</script>
