<template>
  <validation-observer ref="form">
    <form>
      <SectionForm
        v-if="selectedControlType === 'SECTION'"
        :selectedItem="selectedItem"
        @input="onInputChange"
        @form-control-form-submitted="$emit('form-control-form-submitted')"
        :selectedControlType="selectedControlType"
        :formDefinition="value"
      />

      <InformationForm
        v-if="
          selectedItem &&
          selectedItem.type === 'INFORMATION' &&
          ['HEADER', 'URL', 'TEXT', 'IMAGE'].includes(selectedControlType)
        "
        :formDefinition="value"
        :selectedItem="selectedItem"
        @input="onInputChange"
        @form-control-form-submitted="$emit('form-control-form-submitted')"
        :selectedControlType="selectedControlType"
      />

      <TextInputForm
        v-if="
          selectedItem &&
          selectedItem.type === 'QUESTION' &&
          ['TEXT', 'EMAIL', 'NUMBER', 'SIGNATURE'].includes(selectedControlType)
        "
        :formDefinition="value"
        :selectedItem="selectedItem"
        @input="onInputChange"
        @form-control-form-submitted="$emit('form-control-form-submitted')"
        :edit="edit"
        :selectedControlType="selectedControlType"
      />

      <SelectForm
        v-if="['SINGLE_SELECT', 'MULTI_SELECT'].includes(selectedControlType)"
        :formDefinition="value"
        :selectedItem="selectedItem"
        @input="onInputChange"
        @form-control-form-submitted="$emit('form-control-form-submitted')"
        :edit="edit"
        :selectedControlType="selectedControlType"
        :multiSelect="'MULTI_SELECT' === selectedControlType"
      />

      <CalculationForm
        v-if="['CALCULATION'].includes(selectedControlType)"
        :formDefinition="value"
        :selectedItem="selectedItem"
        @input="onInputChange"
        @form-control-form-submitted="$emit('form-control-form-submitted')"
        :selectedControlType="selectedControlType"
      />

      <PhotoFileForm
        v-if="['FILE'].includes(selectedControlType)"
        :formDefinition="value"
        :selectedItem="selectedItem"
        @input="onInputChange"
        @form-control-form-submitted="$emit('form-control-form-submitted')"
        :selectedControlType="selectedControlType"
      />

      <DateForm
        v-if="['DATE'].includes(selectedControlType)"
        :formDefinition="value"
        :selectedItem="selectedItem"
        @input="onInputChange"
        @form-control-form-submitted="$emit('form-control-form-submitted')"
        :edit="edit"
        :selectedControlType="selectedControlType"
      />

      <TimeForm
        v-if="['TIME'].includes(selectedControlType)"
        :formDefinition="value"
        :selectedItem="selectedItem"
        @input="onInputChange"
        @form-control-form-submitted="$emit('form-control-form-submitted')"
        :edit="edit"
        :selectedControlType="selectedControlType"
      />

      <RepeatingGroupForm
        v-if="['GROUP'].includes(selectedControlType)"
        :formDefinition="value"
        :selectedItem="selectedItem"
        @input="onInputChange"
        @form-control-form-submitted="$emit('form-control-form-submitted')"
        :edit="edit"
        :selectedControlType="selectedControlType"
      />

      <ActionItemForm
        v-if="['ACTION_ITEM'].includes(selectedControlType)"
        :formDefinition="value"
        :selectedItem="selectedItem"
        @input="onInputChange"
        @form-control-form-submitted="$emit('form-control-form-submitted')"
        :edit="edit"
        :selectedControlType="selectedControlType"
      />

      <PositiveResponseForm
        v-if="['POSITIVE_RESPONSE'].includes(selectedControlType)"
        :formDefinition="value"
        :selectedItem="selectedItem"
        @input="onInputChange"
        @form-control-form-submitted="$emit('form-control-form-submitted')"
        :edit="edit"
        :selectedControlType="selectedControlType"
      />
    </form>
  </validation-observer>
</template>

<script>
import SectionForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/SectionForm.vue";
import TextInputForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/TextInputForm.vue";
import SelectForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/SelectForm.vue";
import InformationForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/InformationForm.vue";
import CalculationForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/CalculationForm.vue";
import PhotoFileForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/PhotoFileForm.vue";
import DateForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/DateForm.vue";
import TimeForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/TimeForm.vue";
import RepeatingGroupForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/RepeatingGroupForm.vue";
import ActionItemForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/ActionItemForm.vue";
import PositiveResponseForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/PositiveResponseForm.vue";
import { cloneDeep } from "lodash";

export default {
  name: "FormControlEditForm",
  components: {
    SectionForm,
    TextInputForm,
    SelectForm,
    InformationForm,
    CalculationForm,
    PhotoFileForm,
    DateForm,
    TimeForm,
    RepeatingGroupForm,
    ActionItemForm,
    PositiveResponseForm,
  },
  props: {
    value: Object,
    selectedSectionId: Number,
    selectedItemId: Number,
    selectedControlType: String,
    edit: Boolean,
  },
  computed: {
    selectedItem() {
      const formDefinition = { ...this.value };
      const section = formDefinition.form.sections.find(
        (s) => s.id === this.selectedSectionId
      );
      if (this.selectedControlType === "SECTION") {
        return section;
      }

      if (section && Array.isArray(section.items)) {
        const item = section.items.find(
          (item) => +item.id === +this.selectedItemId
        );
        return item;
      }
      return {};
    },
  },
  methods: {
    onInputChange(selectedItem) {
      const formDefinition = cloneDeep(this.value);
      const section = formDefinition.form.sections.find(
        (s) => s.id === this.selectedSectionId
      );
      const sectionIndex = formDefinition.form.sections.findIndex(
        (s) => s.id === this.selectedSectionId
      );
      if (this.selectedControlType === "SECTION") {
        formDefinition.form.sections[sectionIndex] = {
          ...section,
          ...selectedItem,
        };
      } else {
        const item = section.items.find((s) => s.id === selectedItem.id);
        const itemIndex = section.items.findIndex(
          (s) => s.id === selectedItem.id
        );
        formDefinition.form.sections[sectionIndex].items[itemIndex] = {
          ...item,
          ...selectedItem,
        };
      }
      this.$emit("input", formDefinition);
    },
  },
};
</script>
