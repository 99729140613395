var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "pa-0 ma-0", attrs: { elevation: "0" } },
    [
      _c("v-card-text", { staticClass: "pa-0 ma-0" }, [
        _vm._v(" " + _vm._s(_vm.label) + " "),
        _c(
          "section",
          {
            staticClass: "pa-0 ma-0 overflow-y-auto",
            style: { border: _vm.getBorder() },
          },
          [_c("div", { ref: "codeEditor" })]
        ),
        Array.isArray(_vm.errorMessages)
          ? _c("div", [
              _vm._v(" " + _vm._s(_vm.errorMessages.join(". ")) + " "),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }