<template>
  <v-dialog :value="showCityworksLaborFormFieldInfoDialog" max-width="500">
    <v-card class="pt-4">
      <v-card-text>
        <section
          v-if="formFieldInfoDialogFieldName === LABOR_FORM_FIELD.EMPLOYEE"
        >
          The form field selected will be used to populate the 'Employee' on the
          Labor record in the service request. Configure the form field as a
          single-select field, using the Cityworks ID (number) as the value and
          the employee's name as the label. Only single-select fields will
          appear as options for this setting.
        </section>
        <section
          v-else-if="formFieldInfoDialogFieldName === LABOR_FORM_FIELD.HOURS"
        >
          The form field selected will be used to populate the hour on the Labor
          record in the service request. Only number fields will appear as
          options for this setting.
        </section>
        <section
          v-else-if="
            formFieldInfoDialogFieldName === LABOR_FORM_FIELD.TIME_TYPE
          "
        >
          <div>
            The form field selected will be used to set the time type. The value
            needs to match exactly:
          </div>
          <ul>
            <li>Regular</li>
            <li>Overtime</li>
            <li>Holiday</li>
            <li>Benefit</li>
            <li>Standby</li>
            <li>Shift Diff.</li>
            <li>Overhead</li>
            <li>Other</li>
          </ul>
          <div class="mt-4">
            If you want the time to always be classified as 'Regular', for
            example, set the default value of the form field to 'Regular'.
          </div>
        </section>
        <section
          v-else-if="
            formFieldInfoDialogFieldName === LABOR_FORM_FIELD.DESCRIPTION
          "
        >
          The form field selected will be used to populate the description on
          the Labor record in the service request. This is an optional field.
        </section>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pa-3">
        <v-btn color="primary" @click="$emit('close')">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { LABOR_FORM_FIELD } from "@/constants/cityworks";

export default {
  name: "CityworksLaborFormFieldInfoDialog",
  props: {
    formFieldInfoDialogFieldName: String,
    showCityworksLaborFormFieldInfoDialog: Boolean,
  },
  data() {
    return {
      LABOR_FORM_FIELD,
    };
  },
};
</script>
