var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    { staticClass: "pa-0 ma-0" },
    [
      _c(
        "v-list-item",
        { staticClass: "d-flex flex-wrap pa-0 ma-0" },
        [
          _c(
            "v-list-item-content",
            { staticClass: "pa-1 ma-0", staticStyle: { width: "110px" } },
            _vm._l(_vm.borderStyles, function (style) {
              return _c("img", {
                key: style,
                staticClass: "icon pa-0 mx-0 my-1",
                attrs: {
                  src: require(`@/assets/img/borders/border-${style}.svg`),
                },
                on: {
                  click: function ($event) {
                    return _vm.onBorderClick(style)
                  },
                },
              })
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }