var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex justify-space-between" },
    [
      _c("validation-observer", { ref: "selectForm" }, [
        _c(
          "form",
          [
            _vm.selectedRepeatingGroupItemOptions &&
            _vm.selectedRepeatingGroupItemOptions.question
              ? _c(
                  "v-list",
                  { attrs: { dense: "" } },
                  [
                    _c(
                      "v-list-item",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c("validation-provider", {
                              attrs: { name: "Label", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Label",
                                            "hide-details": "auto",
                                            "error-messages": errors,
                                            success: valid,
                                            color: "primary",
                                            name: "name",
                                            disabled: !_vm.canManageForms,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.onSubmit()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm
                                                .selectedRepeatingGroupItemOptions
                                                .question.label,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm
                                                  .selectedRepeatingGroupItemOptions
                                                  .question,
                                                "label",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "selectedRepeatingGroupItemOptions.question.label",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1296071596
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-list-item-content",
                          { staticClass: "mt-0 mb-0 pt-0" },
                          [
                            _c("validation-provider", {
                              attrs: { name: "Hint" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Hint",
                                            "hide-details": "auto",
                                            "error-messages": errors,
                                            success: valid,
                                            color: "primary",
                                            name: "hint",
                                            disabled: !_vm.canManageForms,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.onSubmit()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm
                                                .selectedRepeatingGroupItemOptions
                                                .question.placeholder,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm
                                                  .selectedRepeatingGroupItemOptions
                                                  .question,
                                                "placeholder",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                  selectedRepeatingGroupItemOptions.question.placeholder\n                ",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1861671784
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { staticClass: "mt-0 pt-0 px-0 mx-0" },
                      [
                        _c(
                          "v-list-item-content",
                          {
                            key: _vm.selectedRepeatingGroupItemOptions.question
                              .default.type,
                            staticClass: "mt-0 pt-0",
                          },
                          [
                            _c("validation-provider", {
                              attrs: { name: "Default Value" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            items: _vm.defaultValueOptions,
                                            label: "Default Value",
                                            "error-messages": errors,
                                            success: valid,
                                            "item-text": "label",
                                            "item-value": "value",
                                            "hide-details": "",
                                            disabled: !_vm.canManageForms,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.onSubmit()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm
                                                .selectedRepeatingGroupItemOptions
                                                .question.default.type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm
                                                  .selectedRepeatingGroupItemOptions
                                                  .question.default,
                                                "type",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                  selectedRepeatingGroupItemOptions.question.default.type\n                ",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1949734798
                              ),
                            }),
                            ["LOCATE_DATA"].includes(
                              _vm.selectedRepeatingGroupItemOptions.question
                                .default.type
                            )
                              ? _c(
                                  "v-list",
                                  {
                                    key: _vm.selectedRepeatingGroupItemOptions
                                      .question.default.type,
                                    staticClass: "pa-0 ma-0",
                                  },
                                  [
                                    _c(
                                      "v-list-item",
                                      { staticClass: "pr-0 mr-0" },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          { staticClass: "pt-0 mt-0" },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "811 Ticket",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({
                                                      errors,
                                                      valid,
                                                    }) {
                                                      return [
                                                        _c("v-select", {
                                                          attrs: {
                                                            items:
                                                              _vm.locateRequestDataValues,
                                                            label: "811 Ticket",
                                                            "error-messages":
                                                              errors,
                                                            success: valid,
                                                            "item-text":
                                                              "label",
                                                            "item-value":
                                                              "value",
                                                            "hide-details": "",
                                                            disabled:
                                                              !_vm.canManageForms,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.onSubmit()
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .selectedRepeatingGroupItemOptions
                                                                .question
                                                                .default.value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .selectedRepeatingGroupItemOptions
                                                                  .question
                                                                  .default,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "\n                        selectedRepeatingGroupItemOptions.question.default\n                          .value\n                      ",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                3146068810
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            ["USER"].includes(
                              _vm.selectedRepeatingGroupItemOptions.question
                                .default.type
                            )
                              ? _c(
                                  "v-list",
                                  {
                                    key: _vm.selectedRepeatingGroupItemOptions
                                      .question.default.type,
                                    staticClass: "pa-0 ma-0",
                                  },
                                  [
                                    _c(
                                      "v-list-item",
                                      { staticClass: "pr-0 mr-0" },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "User Field",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({
                                                      errors,
                                                      valid,
                                                    }) {
                                                      return [
                                                        _c("v-select", {
                                                          attrs: {
                                                            items:
                                                              _vm.userDataChoices,
                                                            label: "User Field",
                                                            "error-messages":
                                                              errors,
                                                            success: valid,
                                                            "item-text":
                                                              "label",
                                                            "item-value":
                                                              "value",
                                                            "hide-details": "",
                                                            disabled:
                                                              !_vm.canManageForms,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.onSubmit()
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .selectedRepeatingGroupItemOptions
                                                                .question
                                                                .default.value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .selectedRepeatingGroupItemOptions
                                                                  .question
                                                                  .default,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "\n                        selectedRepeatingGroupItemOptions.question.default\n                          .value\n                      ",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                3255409109
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            ["GIS_DATA"].includes(
                              _vm.selectedRepeatingGroupItemOptions.question
                                .default.type
                            )
                              ? _c(
                                  "v-list",
                                  {
                                    key: _vm.selectedRepeatingGroupItemOptions
                                      .question.default.type,
                                    staticClass: "pa-0 ma-0",
                                  },
                                  [
                                    _c(
                                      "v-list-item",
                                      { staticClass: "pr-0 mr-0" },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "UtiliSync Field",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({
                                                      errors,
                                                      valid,
                                                    }) {
                                                      return [
                                                        _c("v-select", {
                                                          attrs: {
                                                            items:
                                                              _vm.gisDataChoices,
                                                            label:
                                                              "UtiliSync Field",
                                                            "error-messages":
                                                              errors,
                                                            success: valid,
                                                            "item-text":
                                                              "label",
                                                            "item-value":
                                                              "value",
                                                            "hide-details": "",
                                                            disabled:
                                                              !_vm.canManageForms,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.onSubmit()
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .selectedRepeatingGroupItemOptions
                                                                .question
                                                                .default.value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .selectedRepeatingGroupItemOptions
                                                                  .question
                                                                  .default,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "\n                        selectedRepeatingGroupItemOptions.question.default\n                          .value\n                      ",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1771554370
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            ["CUSTOM"].includes(
                              _vm.selectedRepeatingGroupItemOptions.question
                                .default.type
                            )
                              ? _c(
                                  "v-list",
                                  {
                                    key: _vm.selectedRepeatingGroupItemOptions
                                      .question.default.type,
                                  },
                                  [
                                    _c(
                                      "v-list-item",
                                      { staticClass: "pr-0 mr-0" },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          { staticClass: "pa-0 ma-0" },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "Default Value",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({
                                                      errors,
                                                      valid,
                                                    }) {
                                                      return [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            label:
                                                              "Default Value",
                                                            "error-messages":
                                                              errors,
                                                            success: valid,
                                                            disabled:
                                                              !_vm.canManageForms,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.onSubmit()
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .selectedRepeatingGroupItemOptions
                                                                .question
                                                                .default.value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .selectedRepeatingGroupItemOptions
                                                                  .question
                                                                  .default,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "\n                        selectedRepeatingGroupItemOptions.question.default\n                          .value\n                      ",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2278502455
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            ["ORGANIZATION"].includes(
                              _vm.selectedRepeatingGroupItemOptions.question
                                .default.type
                            )
                              ? _c(
                                  "v-list",
                                  {
                                    key: _vm.selectedRepeatingGroupItemOptions
                                      .question.default.type,
                                  },
                                  [
                                    _c(
                                      "v-list-item",
                                      { staticClass: "pr-0 mr-0" },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          { staticClass: "my-0 py-0" },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "Organization Data",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({
                                                      errors,
                                                      valid,
                                                    }) {
                                                      return [
                                                        _c("v-select", {
                                                          attrs: {
                                                            items:
                                                              _vm.orgDataOptions,
                                                            name: "Organization Data",
                                                            "error-messages":
                                                              errors,
                                                            success: valid,
                                                            "item-text":
                                                              "label",
                                                            "item-value":
                                                              "value",
                                                            placeholder:
                                                              "Organization Data",
                                                            disabled:
                                                              !_vm.canManageForms,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.onSubmit()
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .selectedRepeatingGroupItemOptions
                                                                .question
                                                                .default.value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .selectedRepeatingGroupItemOptions
                                                                  .question
                                                                  .default,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "\n                        selectedRepeatingGroupItemOptions.question.default\n                          .value\n                      ",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2314267208
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            ["GIS"].includes(
                              _vm.selectedRepeatingGroupItemOptions.question
                                .default.type
                            )
                              ? _c(
                                  "v-list",
                                  {
                                    key: _vm.selectedRepeatingGroupItemOptions
                                      .question.default.type,
                                  },
                                  [
                                    _c(
                                      "v-list-item",
                                      { staticClass: "mr-0 pr-0" },
                                      [
                                        _vm.fieldChoices.length > 0
                                          ? _c(
                                              "v-list-item-content",
                                              [
                                                _c("validation-provider", {
                                                  attrs: {
                                                    name: "Default Value",
                                                    rules: "required",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          errors,
                                                          valid,
                                                        }) {
                                                          return [
                                                            _c("v-select", {
                                                              attrs: {
                                                                items:
                                                                  _vm.fieldChoices,
                                                                name: "gisField",
                                                                label:
                                                                  "GIS Field",
                                                                "error-messages":
                                                                  errors,
                                                                success: valid,
                                                                "item-text":
                                                                  "label",
                                                                "item-value":
                                                                  "value",
                                                                "hide-details":
                                                                  "",
                                                                disabled:
                                                                  !_vm.canManageForms,
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.onSubmit()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .selectedRepeatingGroupItemOptions
                                                                    .question
                                                                    .default
                                                                    .value,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .selectedRepeatingGroupItemOptions
                                                                        .question
                                                                        .default,
                                                                      "value",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "\n                        selectedRepeatingGroupItemOptions.question.default\n                          .value\n                      ",
                                                              },
                                                            }),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    818206292
                                                  ),
                                                }),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "v-list-item-content",
                                              { staticClass: "ma-0 pa-0" },
                                              [
                                                _c("validation-provider", {
                                                  attrs: {
                                                    name: "Default Value",
                                                    rules: "required",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          errors,
                                                          valid,
                                                        }) {
                                                          return [
                                                            _c("v-text-field", {
                                                              staticClass:
                                                                "ma-0 pa-0",
                                                              attrs: {
                                                                label:
                                                                  "Default Value",
                                                                "error-messages":
                                                                  errors,
                                                                success: valid,
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.onSubmit()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .selectedRepeatingGroupItemOptions
                                                                    .question
                                                                    .default
                                                                    .value,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .selectedRepeatingGroupItemOptions
                                                                        .question
                                                                        .default,
                                                                      "value",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "\n                        selectedRepeatingGroupItemOptions.question.default\n                          .value\n                      ",
                                                              },
                                                            }),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    3962083877
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-list-item-content",
                          { staticClass: "mt-0 mb-0 pt-0" },
                          [
                            _c("validation-provider", {
                              attrs: { name: "Required", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            items: _vm.groupItemRequiredOptions,
                                            label: "Required",
                                            "error-messages": errors,
                                            success: valid,
                                            "item-text": "label",
                                            "item-value": "value",
                                            "hide-details": "",
                                            disabled: !_vm.canManageForms,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.onSubmit()
                                            },
                                          },
                                          model: {
                                            value: _vm.requiredCondition,
                                            callback: function ($$v) {
                                              _vm.requiredCondition = $$v
                                            },
                                            expression: "requiredCondition",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3065418717
                              ),
                            }),
                            ["conditional"].includes(_vm.requiredCondition)
                              ? _c("ComparisonConditionForm", {
                                  attrs: {
                                    formDefinition: _vm.formDefinition,
                                    selectedItem:
                                      _vm.selectedRepeatingGroupItemOptions,
                                    isRepeatingGroupItem: "",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.onSubmit()
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.selectedRepeatingGroupItemOptions
                                        .question.required,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectedRepeatingGroupItemOptions
                                          .question,
                                        "required",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "selectedRepeatingGroupItemOptions.question.required",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-list-item-content",
                          { staticClass: "my-0 pt-0" },
                          [
                            _c("validation-provider", {
                              attrs: { name: "Visible", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            items: _vm.groupItemVisibleOptions,
                                            label: "Visible",
                                            "error-messages": errors,
                                            success: valid,
                                            "item-text": "label",
                                            "item-value": "value",
                                            "hide-details": "",
                                            disabled: !_vm.canManageForms,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.onSubmit()
                                            },
                                          },
                                          model: {
                                            value: _vm.visibleCondition,
                                            callback: function ($$v) {
                                              _vm.visibleCondition = $$v
                                            },
                                            expression: "visibleCondition",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                589881658
                              ),
                            }),
                            ["conditional"].includes(_vm.visibleCondition)
                              ? _c("ComparisonConditionForm", {
                                  attrs: {
                                    formDefinition: _vm.formDefinition,
                                    selectedItem:
                                      _vm.selectedRepeatingGroupItemOptions,
                                    isRepeatingGroupItem: "",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.onSubmit()
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.selectedRepeatingGroupItemOptions
                                        .question.visible,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectedRepeatingGroupItemOptions
                                          .question,
                                        "visible",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "selectedRepeatingGroupItemOptions.question.visible",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-space-between align-center",
                      },
                      [
                        _c("div", { staticClass: "caption" }, [
                          _vm._v("Choices"),
                        ]),
                        _c(
                          "div",
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "px-2",
                                attrs: {
                                  text: "",
                                  color: "primary",
                                  disabled: !_vm.canManageForms,
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.showAddChoiceDialog = true
                                  },
                                },
                              },
                              [_vm._v(" + Choice ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "v-list-item",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c(
                              "draggable",
                              {
                                staticClass: "pa-0 ma-0",
                                attrs: {
                                  list: _vm.selectedRepeatingGroupItemOptions
                                    .question.options,
                                  disabled: !_vm.canManageForms,
                                  handle: ".section-handle",
                                },
                                on: {
                                  end: function ($event) {
                                    return _vm.onSubmit()
                                  },
                                },
                              },
                              _vm._l(
                                _vm.selectedRepeatingGroupItemOptions.question
                                  .options,
                                function (option, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: option.id,
                                      staticClass:
                                        "pa-0 d-flex align-center justify-space-between mt-n8 mb-n3",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "d-flex cursor-pointer",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "mt-6" },
                                            [
                                              _vm.canManageForms
                                                ? _c("v-icon", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.mdiDragVertical
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "ml-n2" },
                                            [
                                              _c(
                                                "v-list",
                                                {
                                                  attrs: {
                                                    dense: "",
                                                    "two-line": "",
                                                    "max-width": "110",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-list-item",
                                                    { staticClass: "mr-n8" },
                                                    [
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _c(
                                                            "v-list-item-subtitle",
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    option.value
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-list-item-title",
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    option.label
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "d-flex" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "pl-0 pr-0 mr-0",
                                              attrs: {
                                                text: "",
                                                disabled: !_vm.canManageForms,
                                                "x-small": "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.showEditChoiceDialog = true
                                                  _vm.selectedOption = option
                                                  _vm.selectedOptionIndex =
                                                    index
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "primary" } },
                                                [_vm._v(_vm._s(_vm.mdiPencil))]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "pl-0 pr-0 mr-0",
                                              attrs: {
                                                text: "",
                                                "x-small": "",
                                                disabled: !_vm.canManageForms,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeChoice(index)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "primary" } },
                                                [_vm._v(_vm._s(_vm.mdiDelete))]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                            _c(
                              "v-dialog",
                              {
                                attrs: {
                                  "max-width": "600px",
                                  persistent: "",
                                  fullscreen: _vm.$vuetify.breakpoint.xsOnly,
                                },
                                model: {
                                  value: _vm.showEditChoiceDialog,
                                  callback: function ($$v) {
                                    _vm.showEditChoiceDialog = $$v
                                  },
                                  expression: "showEditChoiceDialog",
                                },
                              },
                              [
                                _vm.showEditChoiceDialog
                                  ? _c("ChoiceForm", {
                                      attrs: {
                                        option: _vm.selectedOption,
                                        index: _vm.selectedOptionIndex,
                                        selectedItemOptions:
                                          _vm.selectedRepeatingGroupItemOptions,
                                      },
                                      on: {
                                        "choice-submitted":
                                          _vm.onChoiceSubmitted,
                                        "choice-form-close": function ($event) {
                                          _vm.showEditChoiceDialog = false
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "v-dialog",
                              {
                                attrs: {
                                  "max-width": "600px",
                                  fullscreen: _vm.$vuetify.breakpoint.xsOnly,
                                },
                                model: {
                                  value: _vm.showAddChoiceDialog,
                                  callback: function ($$v) {
                                    _vm.showAddChoiceDialog = $$v
                                  },
                                  expression: "showAddChoiceDialog",
                                },
                              },
                              [
                                _vm.showAddChoiceDialog
                                  ? _c("ChoiceForm", {
                                      attrs: {
                                        selectedItemOptions:
                                          _vm.selectedRepeatingGroupItemOptions,
                                      },
                                      on: {
                                        "choice-submitted":
                                          _vm.onChoiceSubmitted,
                                        "choice-form-close": function ($event) {
                                          _vm.showAddChoiceDialog = false
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-list-item-content",
                          { staticClass: "my-0 py-0" },
                          [
                            _c("v-switch", {
                              staticClass: "my-0 py-0",
                              attrs: {
                                label: "Choices are Images",
                                disabled: !_vm.canManageForms,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.onSubmit()
                                },
                              },
                              model: {
                                value:
                                  _vm.selectedRepeatingGroupItemOptions.question
                                    .isImage,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectedRepeatingGroupItemOptions
                                      .question,
                                    "isImage",
                                    $$v
                                  )
                                },
                                expression:
                                  "selectedRepeatingGroupItemOptions.question.isImage",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    !_vm.selectedRepeatingGroupItemOptions.question.isImage
                      ? _c(
                          "v-list-item",
                          { staticClass: "pa-0 ma-0" },
                          [
                            _c(
                              "v-list-item-content",
                              { staticClass: "my-0 py-0" },
                              [
                                _c("v-switch", {
                                  staticClass: "my-0 py-0",
                                  attrs: {
                                    label: "Include 'Other' Option",
                                    disabled: !_vm.canManageForms,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onSubmit()
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.selectedRepeatingGroupItemOptions
                                        .question.hasOther,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectedRepeatingGroupItemOptions
                                          .question,
                                        "hasOther",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "selectedRepeatingGroupItemOptions.question.hasOther",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.selectedRepeatingGroupItemOptions.question.isImage
                      ? _c(
                          "v-list-item",
                          { staticClass: "pa-0 ma-0" },
                          [
                            _c(
                              "v-list-item-content",
                              { staticClass: "my-0 py-0" },
                              [
                                _c("v-switch", {
                                  staticClass: "my-0 py-0",
                                  attrs: {
                                    label: "Show All Choices at Once",
                                    disabled: !_vm.canManageForms,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onSubmit()
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.selectedRepeatingGroupItemOptions
                                        .question.showAllOptions,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectedRepeatingGroupItemOptions
                                          .question,
                                        "showAllOptions",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "\n                selectedRepeatingGroupItemOptions.question.showAllOptions\n              ",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-list-item",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-list-item-content",
                          { staticClass: "my-0 py-0" },
                          [
                            _c("v-switch", {
                              staticClass: "my-0 py-0",
                              attrs: {
                                label: "Apply Default Value",
                                disabled: !_vm.canManageForms,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.onSubmit()
                                },
                              },
                              model: {
                                value:
                                  _vm.selectedRepeatingGroupItemOptions.question
                                    .default.applyDefaultValue,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectedRepeatingGroupItemOptions
                                      .question.default,
                                    "applyDefaultValue",
                                    $$v
                                  )
                                },
                                expression:
                                  "\n                selectedRepeatingGroupItemOptions.question.default\n                  .applyDefaultValue\n              ",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }