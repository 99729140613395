var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "elevation-0 mx-auto", attrs: { "max-width": "600" } },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-0" },
        [
          _c(
            "v-row",
            [
              !_vm.isEsriTokenPresent
                ? _c(
                    "v-col",
                    { staticClass: "card-background", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "alert",
                          attrs: {
                            color: "#FB8C00",
                            rounded: "",
                            elevation: "0",
                          },
                        },
                        [
                          _vm._v(
                            " Sign in using ArcGIS to test secured layers "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                { staticClass: "card-background pl-6", attrs: { cols: "12" } },
                [
                  _c("v-switch", {
                    staticClass: "mb-0 pb-0",
                    attrs: { label: "Enable Save Locate Request UtiliBot" },
                    on: {
                      change: function ($event) {
                        return _vm.$emit("input", _vm.formDefinition)
                      },
                    },
                    model: {
                      value:
                        _vm.formDefinition.form.locate_request_settings
                          .archive_to_service,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formDefinition.form.locate_request_settings,
                          "archive_to_service",
                          $$v
                        )
                      },
                      expression:
                        "\n            formDefinition.form.locate_request_settings.archive_to_service\n          ",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "card-background", attrs: { cols: "12" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "elevation-2" },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "Feature Service URL",
                              rules: {
                                required: true,
                                regex:
                                  /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ errors, valid }) {
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Feature Service URL",
                                        "hide-details": "auto",
                                        "error-messages": errors,
                                        success: valid,
                                        color: "primary",
                                        name: "featureServiceUrl",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.$emit(
                                            "input",
                                            _vm.formDefinition
                                          )
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.formDefinition.form
                                            .locate_request_settings
                                            .feature_service_url,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDefinition.form
                                              .locate_request_settings,
                                            "feature_service_url",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "\n                  formDefinition.form.locate_request_settings\n                    .feature_service_url\n                ",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("v-switch", {
                            staticClass: "mb-0 pb-0",
                            attrs: { label: "Secured Feature Service" },
                            on: {
                              change: function ($event) {
                                return _vm.$emit("input", _vm.formDefinition)
                              },
                            },
                            model: {
                              value:
                                _vm.formDefinition.form.locate_request_settings
                                  .is_secure_service,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formDefinition.form
                                    .locate_request_settings,
                                  "is_secure_service",
                                  $$v
                                )
                              },
                              expression:
                                "\n                formDefinition.form.locate_request_settings.is_secure_service\n              ",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        { staticClass: "pb-5 pr-4" },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { type: "button", color: "primary" },
                              on: { click: _vm.testLayerConnection },
                            },
                            [_vm._v(" Test Connection ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.testSuccess
                ? _c(
                    "v-col",
                    { staticClass: "card-background", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "alert",
                          attrs: {
                            color: "#4CAF50",
                            rounded: "",
                            elevation: "0",
                          },
                        },
                        [_vm._v(" Success! Connected to feature service. ")]
                      ),
                    ],
                    1
                  )
                : _c(
                    "v-col",
                    { staticClass: "card-background", attrs: { cols: "12" } },
                    [
                      _vm.errorDetail
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "error",
                              attrs: {
                                color: "white",
                                rounded: "",
                                elevation: "0",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.errorDetail) + " ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
              Array.isArray(
                _vm.formDefinition.form.locate_request_settings.mappings
              )
                ? _c(
                    "v-col",
                    { staticClass: "mt-n5 mb-n5", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "elevation-2" },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c("FeatureServiceFieldsTable", {
                                attrs: {
                                  questionChoices: _vm.questionChoices,
                                  formIsOnLocateLayer: Boolean(
                                    _vm.formDefinition.form
                                      .locate_request_settings
                                  ),
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$emit(
                                      "input",
                                      _vm.formDefinition
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.formDefinition,
                                  callback: function ($$v) {
                                    _vm.formDefinition = $$v
                                  },
                                  expression: "formDefinition",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }