var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex justify-space-between" },
    [
      _c("validation-observer", { ref: "dateTimeForm" }, [
        _c(
          "form",
          [
            _vm.selectedRepeatingGroupItemOptions &&
            _vm.selectedRepeatingGroupItemOptions.question
              ? _c(
                  "v-list",
                  { staticClass: "pa-0 ma-0", attrs: { dense: "" } },
                  [
                    _c(
                      "v-list-item",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-list-item-content",
                          { staticClass: "mt-0 mb-0 pt-0" },
                          [
                            _c("validation-provider", {
                              attrs: { name: "Label", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Label",
                                            "hide-details": "auto",
                                            "error-messages": errors,
                                            success: valid,
                                            color: "primary",
                                            name: "label",
                                            disabled: !_vm.canManageForms,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.onSubmit()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm
                                                .selectedRepeatingGroupItemOptions
                                                .question.label,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm
                                                  .selectedRepeatingGroupItemOptions
                                                  .question,
                                                "label",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "selectedRepeatingGroupItemOptions.question.label",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1617182925
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-list-item-content",
                          { staticClass: "mt-0 mb-0 pt-0" },
                          [
                            _c("validation-provider", {
                              attrs: { name: "Hint" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Hint",
                                            "hide-details": "auto",
                                            "error-messages": errors,
                                            success: valid,
                                            color: "primary",
                                            name: "hint",
                                            disabled: !_vm.canManageForms,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.onSubmit()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm
                                                .selectedRepeatingGroupItemOptions
                                                .question.placeholder,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm
                                                  .selectedRepeatingGroupItemOptions
                                                  .question,
                                                "placeholder",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                  selectedRepeatingGroupItemOptions.question.placeholder\n                ",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1861671784
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-list-item-content",
                          {
                            key: _vm.selectedRepeatingGroupItemOptions.question
                              .default.type,
                            staticClass: "pt-0 mt-0",
                          },
                          [
                            _c("validation-provider", {
                              attrs: { name: "Default Value" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            autocomplete: "off",
                                            items: _vm.defaultValueOptions,
                                            label: "Default Value",
                                            "error-messages": errors,
                                            success: valid,
                                            "item-text": "label",
                                            "item-value": "value",
                                            "hide-details": "",
                                            disabled: !_vm.canManageForms,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.onSubmit()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm
                                                .selectedRepeatingGroupItemOptions
                                                .question.default.type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm
                                                  .selectedRepeatingGroupItemOptions
                                                  .question.default,
                                                "type",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                  selectedRepeatingGroupItemOptions.question.default.type\n                ",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                4029173329
                              ),
                            }),
                            ["LOCATE_DATA"].includes(
                              _vm.selectedRepeatingGroupItemOptions.question
                                .default.type
                            )
                              ? _c(
                                  "v-list",
                                  {
                                    key: _vm.selectedRepeatingGroupItemOptions
                                      .question.default.type,
                                    staticClass: "pa-0 ma-0",
                                  },
                                  [
                                    _c(
                                      "v-list-item",
                                      { staticClass: "pr-0 mr-0" },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          { staticClass: "pa-0 ma-0" },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "811 Ticket",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({
                                                      errors,
                                                      valid,
                                                    }) {
                                                      return [
                                                        _c("v-select", {
                                                          attrs: {
                                                            autocomplete: "off",
                                                            items:
                                                              _vm.locateRequestDataValues,
                                                            label: "811 Ticket",
                                                            "error-messages":
                                                              errors,
                                                            success: valid,
                                                            "item-text":
                                                              "label",
                                                            "item-value":
                                                              "value",
                                                            "hide-details": "",
                                                            disabled:
                                                              !_vm.canManageForms,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.onSubmit()
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .selectedRepeatingGroupItemOptions
                                                                .question
                                                                .default.value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .selectedRepeatingGroupItemOptions
                                                                  .question
                                                                  .default,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "\n                        selectedRepeatingGroupItemOptions.question.default\n                          .value\n                      ",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2799997717
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            ["USER"].includes(
                              _vm.selectedRepeatingGroupItemOptions.question
                                .default.type
                            )
                              ? _c(
                                  "v-list",
                                  {
                                    key: _vm.selectedRepeatingGroupItemOptions
                                      .question.default.type,
                                    staticClass: "pa-0 ma-0",
                                  },
                                  [
                                    _c(
                                      "v-list-item",
                                      { staticClass: "pr-0 mr-0" },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "User Field",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({
                                                      errors,
                                                      valid,
                                                    }) {
                                                      return [
                                                        _c("v-select", {
                                                          attrs: {
                                                            autocomplete: "off",
                                                            items:
                                                              _vm.userDataChoices,
                                                            label: "User Field",
                                                            "error-messages":
                                                              errors,
                                                            success: valid,
                                                            "item-text":
                                                              "label",
                                                            "item-value":
                                                              "value",
                                                            "hide-details": "",
                                                            disabled:
                                                              !_vm.canManageForms,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.onSubmit()
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .selectedRepeatingGroupItemOptions
                                                                .question
                                                                .default.value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .selectedRepeatingGroupItemOptions
                                                                  .question
                                                                  .default,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "\n                        selectedRepeatingGroupItemOptions.question.default\n                          .value\n                      ",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1498662250
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            ["GIS_DATA"].includes(
                              _vm.selectedRepeatingGroupItemOptions.question
                                .default.type
                            )
                              ? _c(
                                  "v-list",
                                  {
                                    key: _vm.selectedRepeatingGroupItemOptions
                                      .question.default.type,
                                    staticClass: "pa-0 ma-0",
                                  },
                                  [
                                    _c(
                                      "v-list-item",
                                      { staticClass: "pr-0 mr-0" },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "UtiliSync Field",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({
                                                      errors,
                                                      valid,
                                                    }) {
                                                      return [
                                                        _c("v-select", {
                                                          attrs: {
                                                            autocomplete: "off",
                                                            items:
                                                              _vm.gisDataChoices,
                                                            label:
                                                              "UtiliSync Field",
                                                            "error-messages":
                                                              errors,
                                                            success: valid,
                                                            "item-text":
                                                              "label",
                                                            "item-value":
                                                              "value",
                                                            "hide-details": "",
                                                            disabled:
                                                              !_vm.canManageForms,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.onSubmit()
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .selectedRepeatingGroupItemOptions
                                                                .question
                                                                .default.value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .selectedRepeatingGroupItemOptions
                                                                  .question
                                                                  .default,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "\n                        selectedRepeatingGroupItemOptions.question.default\n                          .value\n                      ",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1568210717
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            ["CUSTOM"].includes(
                              _vm.selectedRepeatingGroupItemOptions.question
                                .default.type
                            )
                              ? _c(
                                  "v-list",
                                  {
                                    key: _vm.selectedRepeatingGroupItemOptions
                                      .question.default.type,
                                  },
                                  [
                                    _c(
                                      "v-list-item",
                                      { staticClass: "pr-0 mr-0" },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          { staticClass: "pa-0 ma-0" },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "Default Value",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({
                                                      errors,
                                                      valid,
                                                    }) {
                                                      return [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            label:
                                                              "Default Value",
                                                            "error-messages":
                                                              errors,
                                                            success: valid,
                                                            disabled:
                                                              !_vm.canManageForms,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.onSubmit()
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .selectedRepeatingGroupItemOptions
                                                                .question
                                                                .default.value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .selectedRepeatingGroupItemOptions
                                                                  .question
                                                                  .default,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "\n                        selectedRepeatingGroupItemOptions.question.default\n                          .value\n                      ",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2278502455
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            ["ORGANIZATION"].includes(
                              _vm.selectedRepeatingGroupItemOptions.question
                                .default.type
                            )
                              ? _c(
                                  "v-list",
                                  {
                                    key: _vm.selectedRepeatingGroupItemOptions
                                      .question.default.type,
                                  },
                                  [
                                    _c(
                                      "v-list-item",
                                      { staticClass: "pr-0 mr-0" },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          { staticClass: "my-0 py-0" },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "Organization Data",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({
                                                      errors,
                                                      valid,
                                                    }) {
                                                      return [
                                                        _c("v-select", {
                                                          attrs: {
                                                            autocomplete: "off",
                                                            items:
                                                              _vm.orgDataOptions,
                                                            name: "Organization Data",
                                                            "error-messages":
                                                              errors,
                                                            success: valid,
                                                            "item-text":
                                                              "label",
                                                            "item-value":
                                                              "value",
                                                            placeholder:
                                                              "Organization Data",
                                                            disabled:
                                                              !_vm.canManageForms,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.onSubmit()
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .selectedRepeatingGroupItemOptions
                                                                .question
                                                                .default.value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .selectedRepeatingGroupItemOptions
                                                                  .question
                                                                  .default,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "\n                        selectedRepeatingGroupItemOptions.question.default\n                          .value\n                      ",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                610674775
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            ["GIS"].includes(
                              _vm.selectedRepeatingGroupItemOptions.question
                                .default.type
                            )
                              ? _c(
                                  "v-list",
                                  {
                                    key: _vm.selectedRepeatingGroupItemOptions
                                      .question.default.type,
                                  },
                                  [
                                    _c(
                                      "v-list-item",
                                      [
                                        _vm.fieldChoices.length > 0
                                          ? _c(
                                              "v-list-item-content",
                                              [
                                                _c("validation-provider", {
                                                  attrs: {
                                                    name: "Default Value",
                                                    rules: "required",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          errors,
                                                          valid,
                                                        }) {
                                                          return [
                                                            _c("v-select", {
                                                              attrs: {
                                                                autocomplete:
                                                                  "off",
                                                                items:
                                                                  _vm.fieldChoices,
                                                                name: "gisField",
                                                                label:
                                                                  "GIS Field",
                                                                "error-messages":
                                                                  errors,
                                                                success: valid,
                                                                "item-text":
                                                                  "label",
                                                                "item-value":
                                                                  "value",
                                                                "hide-details":
                                                                  "",
                                                                disabled:
                                                                  !_vm.canManageForms,
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.onSubmit()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .selectedRepeatingGroupItemOptions
                                                                    .question
                                                                    .default
                                                                    .value,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .selectedRepeatingGroupItemOptions
                                                                        .question
                                                                        .default,
                                                                      "value",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "\n                        selectedRepeatingGroupItemOptions.question.default\n                          .value\n                      ",
                                                              },
                                                            }),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    1025719243
                                                  ),
                                                }),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "v-list-item-content",
                                              { staticClass: "mr-0 pr-0" },
                                              [
                                                _c("validation-provider", {
                                                  attrs: {
                                                    name: "Default Value",
                                                    rules: "required",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          errors,
                                                          valid,
                                                        }) {
                                                          return [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                label:
                                                                  "Default Value",
                                                                "error-messages":
                                                                  errors,
                                                                success: valid,
                                                                disabled:
                                                                  !_vm.canManageForms,
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.onSubmit()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .selectedRepeatingGroupItemOptions
                                                                    .question
                                                                    .default
                                                                    .value,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .selectedRepeatingGroupItemOptions
                                                                        .question
                                                                        .default,
                                                                      "value",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "\n                        selectedRepeatingGroupItemOptions.question.default\n                          .value\n                      ",
                                                              },
                                                            }),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2278502455
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-list-item-content",
                          { staticClass: "mt-0 mb-0 pt-0" },
                          [
                            _c("validation-provider", {
                              attrs: { name: "Required", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            autocomplete: "off",
                                            items: _vm.groupItemRequiredOptions,
                                            label: "Required",
                                            "error-messages": errors,
                                            success: valid,
                                            "item-text": "label",
                                            "item-value": "value",
                                            "hide-details": "",
                                            disabled: !_vm.canManageForms,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.onSubmit()
                                            },
                                          },
                                          model: {
                                            value: _vm.requiredCondition,
                                            callback: function ($$v) {
                                              _vm.requiredCondition = $$v
                                            },
                                            expression: "requiredCondition",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                402196066
                              ),
                            }),
                            ["conditional"].includes(_vm.requiredCondition)
                              ? _c("ComparisonConditionForm", {
                                  attrs: {
                                    formDefinition: _vm.formDefinition,
                                    selectedItem:
                                      _vm.selectedRepeatingGroupItemOptions,
                                    isRepeatingGroupItem: "",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.onSubmit()
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.selectedRepeatingGroupItemOptions
                                        .question.required,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectedRepeatingGroupItemOptions
                                          .question,
                                        "required",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "selectedRepeatingGroupItemOptions.question.required",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-list-item-content",
                          { staticClass: "mt-0 mb-0 pt-0" },
                          [
                            _c("validation-provider", {
                              attrs: { name: "Visible", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            autocomplete: "off",
                                            items: _vm.groupItemVisibleOptions,
                                            label: "Visible",
                                            "error-messages": errors,
                                            success: valid,
                                            "item-text": "label",
                                            "item-value": "value",
                                            "hide-details": "",
                                            disabled: !_vm.canManageForms,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.onSubmit()
                                            },
                                          },
                                          model: {
                                            value: _vm.visibleCondition,
                                            callback: function ($$v) {
                                              _vm.visibleCondition = $$v
                                            },
                                            expression: "visibleCondition",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3366523813
                              ),
                            }),
                            ["conditional"].includes(_vm.visibleCondition)
                              ? _c("ComparisonConditionForm", {
                                  attrs: {
                                    formDefinition: _vm.formDefinition,
                                    selectedItem:
                                      _vm.selectedRepeatingGroupItemOptions,
                                    isRepeatingGroupItem: "",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.onSubmit()
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.selectedRepeatingGroupItemOptions
                                        .question.visible,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectedRepeatingGroupItemOptions
                                          .question,
                                        "visible",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "selectedRepeatingGroupItemOptions.question.visible",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-switch", {
                              attrs: {
                                label: "Apply Default Value",
                                disabled: !_vm.canManageForms,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.onSubmit()
                                },
                              },
                              model: {
                                value:
                                  _vm.selectedRepeatingGroupItemOptions.question
                                    .default.applyDefaultValue,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectedRepeatingGroupItemOptions
                                      .question.default,
                                    "applyDefaultValue",
                                    $$v
                                  )
                                },
                                expression:
                                  "\n                selectedRepeatingGroupItemOptions.question.default\n                  .applyDefaultValue\n              ",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }