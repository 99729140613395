<template>
  <validation-observer ref="form">
    <form @submit.prevent="submit">
      <v-card :height="$vuetify.breakpoint.xsOnly ? '100vh' : '100%'">
        <v-toolbar dark color="primary" class="elevation-0">
          <v-toolbar-title>Question Advanced Edit</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$emit('close-advanced-edit-dialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="elevation-0 py-0 my-0">
          <validation-provider
            v-slot="{ errors, valid, pristine }"
            name="JSON"
            rules="required|json"
          >
            <JsonEditor
              v-model="selectedItemJson"
              label="Question JSON"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="json"
              outlined
              :height="
                $vuetify.breakpoint.xsOnly
                  ? 'calc(100vh - 150px)'
                  : 'calc(95vh - 200px)'
              "
            />

            <v-alert
              color="#EF9A9A"
              rounded
              elevation="0"
              v-if="!pristine && !valid && Array.isArray(errors)"
            >
              Error: {{ errors.join(". ") }}
            </v-alert>
          </validation-provider>
        </v-card-text>

        <v-card-actions class="d-flex justify-end pa-3">
          <v-btn type="submit" color="primary">Save Edits</v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </validation-observer>
</template>

<script>
import { cloneDeep } from "lodash";
import JsonEditor from "@/components/shared/JsonEditor";

export default {
  name: "AdvancedEditForm",
  props: {
    value: Object,
    selectedSectionId: Number,
    selectedItemId: Number,
    selectedControlType: String,
    edit: Boolean,
  },
  components: {
    JsonEditor,
  },
  data() {
    return {
      selectedItemJson: JSON.stringify({}, undefined, 2),
      originalSelectedItem: {},
    };
  },
  computed: {
    selectedItem() {
      const formDefinition = cloneDeep(this.value);
      const section = formDefinition.form.sections.find(
        (s) => s.id === this.selectedSectionId
      );
      if (this.selectedControlType === "SECTION") {
        return section;
      }

      if (section && Array.isArray(section.items)) {
        const item = section.items.find(
          (item) => +item.id === +this.selectedItemId
        );
        return item;
      }
      return {};
    },
  },
  watch: {
    selectedItem: {
      deep: true,
      immediate: true,
      handler(val) {
        this.originalSelectedItem = cloneDeep(val);
        this.selectedItemJson = JSON.stringify(val, undefined, 2);
      },
    },
  },
  methods: {
    async submit() {
      const success = await this.$refs.form.validate();
      if (!success) {
        return;
      }
      const formDefinition = cloneDeep(this.value);
      const selectedItem = JSON.parse(this.selectedItemJson);
      const section = formDefinition.form.sections.find(
        (s) => s.id === this.selectedSectionId
      );
      const sectionIndex = formDefinition.form.sections.findIndex(
        (s) => s.id === this.selectedSectionId
      );

      const item = section.items.find((s) => s.id === selectedItem.id);
      const itemIndex = section.items.findIndex(
        (s) => s.id === this.originalSelectedItem.id
      );
      formDefinition.form.sections[sectionIndex].items[itemIndex] = {
        ...item,
        ...selectedItem,
      };
      this.$emit("input", formDefinition);
    },
  },
};
</script>
