var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "elevation-0",
      style: _vm.selected ? "background-color: #fff8e1" : "",
      attrs: { width: "100%" },
    },
    [
      _c(
        "v-card-title",
        {
          staticClass:
            "ma-0 pa-0 d-flex flex-row justify-space-between elevation-0",
        },
        [
          _c("p", { staticClass: "caption" }, [_vm._v(_vm._s(_vm.label))]),
          _c(
            "v-btn",
            {
              staticClass: "mt-n3",
              attrs: { text: "" },
              on: { click: _vm.clearSignature },
            },
            [_vm._v("Clear")]
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "pa-0 mb-2", class: { selected: _vm.selected } },
        [
          _vm.readOnly
            ? _c("div", { attrs: { id: "read-only-box" } })
            : _c("VueSignaturePad", {
                ref: "signaturePad",
                attrs: {
                  width: "100%",
                  height: "200px",
                  options: { onBegin: _vm.onBegin, onEnd: _vm.onEnd },
                  customStyle: { border: "3px black solid" },
                },
              }),
          !_vm.valid
            ? _c("p", { staticClass: "caption" }, [
                _vm._v(_vm._s(_vm.errors && _vm.errors[0])),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }