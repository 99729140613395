var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("validation-provider", {
    attrs: { slim: "", name: _vm.item.question.label },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ errors, valid }) {
          return [
            _c(
              "v-menu",
              {
                attrs: { "close-on-content-click": false },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "v-text-field",
                            _vm._g(
                              {
                                attrs: {
                                  readonly: "",
                                  color: "primary",
                                  "error-messages": errors,
                                  success: valid,
                                  "append-icon": _vm.mdiCalendarBlankOutline,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                  "click:append": on.click,
                                },
                                model: {
                                  value: _vm.date,
                                  callback: function ($$v) {
                                    _vm.date = $$v
                                  },
                                  expression: "date",
                                },
                              },
                              on
                            )
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.showDatePicker,
                  callback: function ($$v) {
                    _vm.showDatePicker = $$v
                  },
                  expression: "showDatePicker",
                },
              },
              [
                _c("v-date-picker", {
                  on: { change: _vm.onInput },
                  model: {
                    value: _vm.date,
                    callback: function ($$v) {
                      _vm.date = $$v
                    },
                    expression: "date",
                  },
                }),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }