var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("node-view-wrapper", {
    staticClass: "pageBreak",
    attrs: { as: "div" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }