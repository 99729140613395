<template>
  <v-dialog
    :value="showPageSettingsDialog"
    max-width="300"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <validation-observer
        ref="docSettingsForm"
        slim
        v-slot="{ valid: isFormValid }"
      >
        <form @submit.prevent="save">
          <v-toolbar dark color="primary" class="elevation-0">
            <v-toolbar-title> Page Settings </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="$emit('close-doc-settings-dialog')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text class="elevation-0 d-flex justify-space-between">
            <section>
              Page Size (inches)

              <validation-provider
                v-slot="{ errors, valid }"
                name="Width"
                :rules="{
                  required: true,
                  min_value: 0,
                }"
              >
                <v-text-field
                  v-model="formDefinition.form.pageSettings.pageWidth"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="pageWidth"
                  class="page-settings-input"
                  label="Width"
                  :disabled="disabled"
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors, valid }"
                name="Height"
                :rules="{
                  required: true,
                  min_value: 0,
                }"
              >
                <v-text-field
                  v-model="formDefinition.form.pageSettings.pageHeight"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="pageHeight"
                  class="page-settings-input"
                  label="Height"
                  :disabled="disabled"
                />
              </validation-provider>
            </section>

            <section>
              Margins (inches)

              <validation-provider
                v-slot="{ errors, valid }"
                name="Top"
                :rules="{
                  required: true,
                  min_value: 0,
                }"
              >
                <v-text-field
                  v-model="formDefinition.form.pageSettings.marginTop"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="marginTop"
                  class="page-settings-input"
                  label="Top"
                  :disabled="disabled"
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors, valid }"
                name="Bottom"
                :rules="{
                  required: true,
                  min_value: 0,
                }"
              >
                <v-text-field
                  v-model="formDefinition.form.pageSettings.marginBottom"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="marginBottom"
                  class="page-settings-input"
                  label="Bottom"
                  :disabled="disabled"
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors, valid }"
                name="Left"
                :rules="{
                  required: true,
                  min_value: 0,
                }"
              >
                <v-text-field
                  v-model="formDefinition.form.pageSettings.marginLeft"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="marginLeft"
                  class="page-settings-input"
                  label="Left"
                  :disabled="disabled"
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors, valid }"
                name="Right"
                :rules="{
                  required: true,
                  min_value: 0,
                }"
              >
                <v-text-field
                  v-model="formDefinition.form.pageSettings.marginRight"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="marginRight"
                  class="page-settings-input"
                  label="Right"
                  :disabled="disabled"
                />
              </validation-provider>
            </section>
          </v-card-text>

          <v-card-actions class="d-flex justify-end">
            <v-btn
              type="submit"
              color="primary"
              :disabled="!isFormValid || disabled"
              :dark="isFormValid && !disabled"
            >
              Update
            </v-btn>
          </v-card-actions>
        </form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  name: "DocSettingsDialog",
  props: {
    value: Object,
    showPageSettingsDialog: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      formDefinition: {},
    };
  },
  methods: {
    async save() {
      const success = await this.$refs.docSettingsForm.validate();
      if (!success) {
        return;
      }
      this.$emit("input", this.formDefinition);
    },
  },
  beforeMount() {
    this.formDefinition = cloneDeep(this.value);
    if (!this.formDefinition?.form?.pageSettings) {
      this.formDefinition.form.pageSettings = {
        pageWidth: 8.5,
        pageHeight: 11,
        marginTop: 1,
        marginBottom: 1,
        marginLeft: 1,
        marginRight: 1,
      };
    }
  },
};
</script>

<style scoped>
.page-settings-input {
  width: 80px;
}
</style>
