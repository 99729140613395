<template>
  <v-dialog :value="showAdvancedFormEditDialog" fullscreen persistent>
    <v-card height="100vh">
      <v-toolbar dark color="primary" class="elevation-0">
        <v-toolbar-title>Form Advanced Edit</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('close-advanced-form-edit-dialog')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="elevation-0">
        <validation-observer ref="form">
          <form @submit.prevent="submit">
            <v-row class="my-0 my-3">
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors, valid, pristine }"
                  name="JSON"
                  rules="required|form_json"
                >
                  <JsonEditor
                    v-model="selectedFormJson"
                    label="Form JSON"
                    :error-messages="errors"
                    :success="valid"
                    color="primary"
                    name="json"
                    outlined
                    height="calc(100vh - 200px)"
                  />

                  <v-alert
                    color="#EF9A9A"
                    rounded
                    elevation="0"
                    v-if="!pristine && !valid && Array.isArray(errors)"
                  >
                    Error: {{ errors.join(". ") }}
                  </v-alert>
                </validation-provider>
              </v-col>

              <v-col cols="12" class="d-flex justify-end py-0 my-0">
                <v-btn
                  type="submit"
                  color="primary"
                  :disabled="!canManageForms"
                >
                  Save Edits
                </v-btn>
              </v-col>
            </v-row>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { cloneDeep } from "lodash";
import JsonEditor from "@/components/shared/JsonEditor";
import permissionsMixin from "@/mixins/permissionsMixin";

export default {
  name: "AdvancedFormEditDialog",
  props: {
    showAdvancedFormEditDialog: Boolean,
    value: Object,
  },
  components: {
    JsonEditor,
  },
  mixins: [permissionsMixin],
  data() {
    return {
      selectedFormJson: JSON.stringify({}, undefined, 2),
    };
  },
  beforeMount() {
    this.selectedFormJson = JSON.stringify(this.value?.form, undefined, 2);
  },
  methods: {
    async submit() {
      const success = await this.$refs.form.validate();
      if (!success) {
        return;
      }
      const { value, selectedFormJson } = this;
      const formDefinition = cloneDeep({
        ...value,
        form: JSON.parse(selectedFormJson),
      });
      this.$emit("input", formDefinition);
    },
  },
};
</script>
