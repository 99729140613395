var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showAddImageDialog,
        "max-width": "500px",
        persistent: "",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", color: "primary" },
            },
            [
              _c("v-toolbar-title", [_vm._v("Add Image")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("add-photo-dialog-close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "py-3" },
            [
              _c("div", [_vm._v("Add image by selecting a source")]),
              _c("input", {
                ref: "cameraInput",
                staticStyle: { visibility: "hidden" },
                attrs: { type: "file", accept: "image/*;capture=camera" },
                on: { change: _vm.onFileInputChange },
              }),
              _c(
                "div",
                { staticClass: "d-flex justify-space-between my-3" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.cameraInput.click()
                        },
                      },
                    },
                    [_vm._v(" Camera ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.showSelectDocDialog = true
                        },
                      },
                    },
                    [_vm._v(" Docs ")]
                  ),
                  _c("v-btn", { attrs: { text: "", color: "primary" } }, [
                    _vm._v(" Map "),
                  ]),
                ],
                1
              ),
              _c("file-pond", {
                ref: "files",
                attrs: {
                  name: "files",
                  "label-idle":
                    "Drag & Drop or <span class='filepond--label-action'> Browse </span>",
                  "allow-multiple": _vm.allowMultiple,
                  "accepted-file-types": "image/jpeg, image/png",
                  files: _vm.uploadFiles,
                  allowFileSizeValidation: "",
                  maxFileSize: "2MB",
                  disabled: _vm.readOnly,
                },
                on: { addfile: _vm.onChange },
              }),
              _vm._l(_vm.base64Images, function (image, index) {
                return _c(
                  "v-card",
                  {
                    key: image.id,
                    staticClass: "d-flex flex-column justify-center my-2",
                  },
                  [
                    _c("v-img", { attrs: { src: image.dataUrl } }),
                    _c("v-card-text", { staticClass: "py-0 my-0" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-space-between align-center",
                        },
                        [
                          _c("v-text-field", {
                            key: image.id,
                            attrs: {
                              label: "Description",
                              color: "primary",
                              name: image.id,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$emit("input", _vm.base64Images)
                              },
                            },
                            model: {
                              value: image.description,
                              callback: function ($$v) {
                                _vm.$set(image, "description", $$v)
                              },
                              expression: "image.description",
                            },
                          }),
                          _c(
                            "v-menu",
                            {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              { attrs: { icon: "" } },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                _vm._s(_vm.mdiDotsVertical)
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "v-list",
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.removePhoto(
                                            index,
                                            image.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-title",
                                        [
                                          _c("v-icon", [
                                            _vm._v(_vm._s(_vm.mdiDelete)),
                                          ]),
                                          _vm._v(" Delete Image "),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                )
              }),
              _c("SelectDocDialog", {
                attrs: { showSelectDocDialog: _vm.showSelectDocDialog },
                on: {
                  "select-doc-dialog-close": function ($event) {
                    _vm.showSelectDocDialog = false
                  },
                  "image-file-selected": _vm.onImageFileSelected,
                },
              }),
            ],
            2
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end px-5 py-3" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "font-weight-regular",
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("add-photo-dialog-close")
                    },
                  },
                },
                [_vm._v(" Close ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }