<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Edit Text</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closeForm">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text class="pt-3">
      <validation-observer ref="textForm">
        <form @submit.prevent="onSubmit">
          <v-row>
            <v-col cols="12">
              <validation-provider
                name="Label"
                rules="required"
                v-slot="{ errors, valid }"
              >
                <QuillEditor
                  v-if="showEditor"
                  v-model="text"
                  :valid="valid"
                  :errors="errors"
                  :disabled="readOnly"
                />
              </validation-provider>
            </v-col>

            <v-col cols="12" class="d-flex justify-end">
              <v-btn color="primary" type="submit">Save Text</v-btn>
            </v-col>
          </v-row>
        </form>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>

<script>
import { tableCellClassList } from "@/constants/tableCellClassList";
import QuillEditor from "@/components/shared/QuillEditor";

export default {
  name: "TextForm",
  props: {
    value: String,
    readOnly: Boolean,
  },
  components: {
    QuillEditor,
  },
  data() {
    return {
      text: "",
      tableCellClassList,
      showEditor: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.text = val;
      },
    },
  },
  methods: {
    closeForm() {
      this.$emit("text-form-close");
    },
    async onSubmit() {
      const success = await this.$refs.textForm.validate();
      if (!success) {
        return;
      }
      this.$emit("input", this.text);
    },
  },
  async mounted() {
    await this.$nextTick();
    this.showEditor = true;
  },
};
</script>
