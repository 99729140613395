import { Node, mergeAttributes } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";
import ParamBlock from "@/components/shared/ParamBlock";
import { escape, unescape } from "lodash";

export default Node.create({
  name: "paramBlock",
  group: "inline",
  inline: true,
  selectable: true,
  draggable: false,
  atom: true,
  parseHTML() {
    return [
      {
        tag: "[data-param-block]",
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(HTMLAttributes),
      `{{${unescape(HTMLAttributes["data-param-block"])}}}`,
    ];
  },
  addAttributes() {
    return {
      paramBlock: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-param-block"),
        renderHTML: (attributes) => ({
          "data-param-block": escape(attributes.paramBlock),
        }),
      },
      class: {
        default: null,
      },
    };
  },
  addNodeView() {
    return VueNodeViewRenderer(ParamBlock);
  },
});
