var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "elevation-2" },
    [
      _c("v-card-text", { staticClass: "py-0 px-0" }, [
        _vm.cwTemplate &&
        _vm.formDefinition.form.city_works_webhook_config.on_form_submission
          .observations.length > 0
          ? _c(
              "div",
              _vm._l(
                _vm.formDefinition.form.city_works_webhook_config
                  .on_form_submission.observations,
                function (item, index) {
                  return _c(
                    "div",
                    { key: item.QuestionId },
                    [
                      _c(
                        "section",
                        {
                          staticClass:
                            "justify-space-between align-center px-5 py-1",
                          class: { "d-flex": _vm.$vuetify.breakpoint.smAndUp },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "py-5",
                              staticStyle: { "font-size": "16px" },
                            },
                            [_vm._v(_vm._s(item.Question))]
                          ),
                          _c(
                            "div",
                            {
                              style: {
                                "max-width": _vm.$vuetify.breakpoint.smAndUp
                                  ? "250px"
                                  : "100%",
                              },
                            },
                            [
                              _c("validation-provider", {
                                attrs: { name: "Observation Mappings" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors, valid }) {
                                        return [
                                          _c("v-autocomplete", {
                                            staticClass: "mt-0 pt-0",
                                            attrs: {
                                              label: "Form Field",
                                              "hide-details": "auto",
                                              "error-messages": errors,
                                              success: valid,
                                              color: "primary",
                                              name: "activityType",
                                              items: _vm.questionChoices,
                                              "item-text": "label",
                                              "item-value": "value",
                                              disabled: !_vm.canManageForms,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.onChange()
                                              },
                                            },
                                            model: {
                                              value: item.utiliSync_question_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "utiliSync_question_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.utiliSync_question_id",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      index <
                      _vm.formDefinition.form.city_works_webhook_config
                        .on_form_submission.observations.length -
                        1
                        ? _c("v-divider")
                        : _vm._e(),
                    ],
                    1
                  )
                }
              ),
              0
            )
          : _c("div", { staticClass: "py-4" }, [
              !_vm.cwTemplate
                ? _c("div", { staticStyle: { "text-align": "center" } }, [
                    _vm._v(" No observations set up on selected template "),
                  ])
                : _c("div", { staticStyle: { "text-align": "center" } }, [
                    _vm._v("No observations available"),
                  ]),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }