var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showAdvancedDefaultSettingsDialog,
        "max-width": "600px",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
        persistent: "",
      },
    },
    [
      _c(
        "v-toolbar",
        { attrs: { dark: "", color: "primary" } },
        [
          _c("v-toolbar-title", [
            _c("div", [_c("b", [_vm._v("Default")])]),
            _c("div", { staticClass: "caption" }, [
              _vm._v("Advanced Settings"),
            ]),
          ]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { icon: "", dark: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("advanced-settings-dialog-close")
                },
              },
            },
            [_c("v-icon", [_vm._v(" " + _vm._s(_vm.mdiClose) + " ")])],
            1
          ),
        ],
        1
      ),
      _c("validation-observer", {
        ref: "advancedSettingsForm",
        attrs: { slim: "" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ invalid }) {
              return [
                _c(
                  "v-card",
                  { staticClass: "rounded-0" },
                  [
                    _c(
                      "v-card-text",
                      { staticClass: "py-3" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "gap align-center",
                            class: {
                              "d-flex": _vm.$vuetify.breakpoint.smAndUp,
                            },
                          },
                          [
                            _c("validation-provider", {
                              staticClass: "select",
                              attrs: { name: "Default Value" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("v-select", {
                                          staticClass: "defaultValue",
                                          attrs: {
                                            autocomplete: "off",
                                            items: _vm.defaultValueOptions,
                                            label: "Default Value",
                                            "error-messages": errors,
                                            success: valid,
                                            "item-text": "label",
                                            "item-value": "value",
                                            "hide-details": "",
                                          },
                                          on: {
                                            change: _vm.onDefaultValueChange,
                                          },
                                          model: {
                                            value:
                                              _vm.selectedItemOptions.question
                                                .default.type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.selectedItemOptions.question
                                                  .default,
                                                "type",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "selectedItemOptions.question.default.type",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm.showDefaultValueDropDown
                              ? [
                                  ["CUSTOM"].includes(
                                    _vm.selectedItemOptions.question.default
                                      .type
                                  )
                                    ? _c("validation-provider", {
                                        attrs: {
                                          slim: "",
                                          name: "Custom Value",
                                          rules: {
                                            required: ![
                                              _vm.FIELD_TYPES.GROUP,
                                            ].includes(
                                              _vm.selectedItemOptions.question
                                                .type
                                            ),
                                            email: [
                                              _vm.FIELD_TYPES.EMAIL,
                                            ].includes(
                                              _vm.selectedItemOptions.question
                                                .type
                                            ),
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors, valid }) {
                                                return [
                                                  [
                                                    _vm.FIELD_TYPES
                                                      .SINGLE_SELECT,
                                                    _vm.FIELD_TYPES
                                                      .MULTI_SELECT,
                                                  ].includes(
                                                    _vm.selectedItemOptions
                                                      .question.type
                                                  )
                                                    ? _c("v-select", {
                                                        attrs: {
                                                          autocomplete: "off",
                                                          items:
                                                            _vm
                                                              .selectedItemOptions
                                                              .question.options,
                                                          "item-text": "label",
                                                          "item-value": "value",
                                                          label: "Custom Value",
                                                          "error-messages":
                                                            errors,
                                                          success: valid,
                                                          multiple:
                                                            _vm.FIELD_TYPES
                                                              .MULTI_SELECT ===
                                                            _vm
                                                              .selectedItemOptions
                                                              .question.type,
                                                          "hide-details": "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .selectedItemOptions
                                                              .question.default
                                                              .value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .selectedItemOptions
                                                                .question
                                                                .default,
                                                              "value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "selectedItemOptions.question.default.value",
                                                        },
                                                      })
                                                    : [
                                                        _vm.FIELD_TYPES.GROUP,
                                                      ].includes(
                                                        _vm.selectedItemOptions
                                                          .question.type
                                                      )
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          staticStyle: {
                                                            "font-size": "16px",
                                                          },
                                                          attrs: {
                                                            text: "",
                                                            color: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.showRepeatingGroupDefaultValuesDialog = true
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.hasDefaultValues
                                                                  ? "Update"
                                                                  : "Add"
                                                              ) +
                                                              " Values "
                                                          ),
                                                        ]
                                                      )
                                                    : _c("v-text-field", {
                                                        attrs: {
                                                          label: "Custom Value",
                                                          "error-messages":
                                                            errors,
                                                          success: valid,
                                                          "hide-details": "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .selectedItemOptions
                                                              .question.default
                                                              .value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .selectedItemOptions
                                                                .question
                                                                .default,
                                                              "value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "selectedItemOptions.question.default.value",
                                                        },
                                                      }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      })
                                    : ["CURRENT"].includes(
                                        _vm.selectedItemOptions.question.default
                                          .type
                                      ) &&
                                      _vm.FIELD_TYPES.TIME ===
                                        _vm.selectedItemOptions.question.type
                                    ? _c("validation-provider", {
                                        attrs: {
                                          name: "Add Minutes",
                                          rules: "min_value:0",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors, valid }) {
                                                return [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Add Minutes",
                                                      "hide-details": "auto",
                                                      "error-messages": errors,
                                                      success: valid,
                                                      color: "primary",
                                                      name: "addMinutes",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.onSubmit()
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedItemOptions
                                                          .question.default
                                                          .value,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm
                                                            .selectedItemOptions
                                                            .question.default,
                                                          "value",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedItemOptions.question.default.value",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      })
                                    : _c("validation-provider", {
                                        key: _vm.fieldPlaceholderText,
                                        attrs: {
                                          name: _vm.fieldPlaceholderText,
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors, valid }) {
                                                return [
                                                  _vm.selectedItemOptions
                                                    .question.default.type ===
                                                    "GIS" &&
                                                  _vm.mapService.token_type !==
                                                    "NONE" &&
                                                  !_vm.isEsriTokenPresent
                                                    ? _c("v-text-field", {
                                                        attrs: {
                                                          label:
                                                            _vm.fieldPlaceholderText,
                                                          "error-messages":
                                                            errors,
                                                          success: valid,
                                                          "item-text": "label",
                                                          "item-value": "value",
                                                          "hide-details": "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .selectedItemOptions
                                                              .question.default
                                                              .value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .selectedItemOptions
                                                                .question
                                                                .default,
                                                              "value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "selectedItemOptions.question.default.value",
                                                        },
                                                      })
                                                    : _c("v-select", {
                                                        attrs: {
                                                          autocomplete: "off",
                                                          items:
                                                            _vm.allDefaultValueOptions,
                                                          label:
                                                            _vm.fieldPlaceholderText,
                                                          "error-messages":
                                                            errors,
                                                          success: valid,
                                                          "item-text": "label",
                                                          "item-value": "value",
                                                          "hide-details": "",
                                                          "menu-props": {
                                                            closeOnClick: true,
                                                            closeOnContentClick: true,
                                                          },
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            _vm.userDataSelected
                                                              ? {
                                                                  key: "prepend-item",
                                                                  fn: function () {
                                                                    return [
                                                                      _c(
                                                                        "v-list-item",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "400px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              disabled:
                                                                                _vm.showNewUserDataInput,
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.stopPropagation()
                                                                                _vm.showNewUserDataInput = true
                                                                                _vm.idForEditing =
                                                                                  undefined
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "font-weight-bold",
                                                                              class:
                                                                                !_vm.showNewUserDataInput
                                                                                  ? "primary--text"
                                                                                  : "",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " + USER DATA "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm.showNewUserDataInput
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "pb-2 pt-2 pl-2 pr-0",
                                                                              staticStyle:
                                                                                {
                                                                                  "background-color":
                                                                                    "rgb(240, 240, 240)",
                                                                                  width:
                                                                                    "400px",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    $event.stopPropagation()
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-flex justify-space-between align-center",
                                                                                  staticStyle:
                                                                                    {
                                                                                      width:
                                                                                        "400px",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    [
                                                                                      _c(
                                                                                        "v-text-field",
                                                                                        {
                                                                                          staticClass:
                                                                                            "pt-4 px-2 pb-2",
                                                                                          staticStyle:
                                                                                            {
                                                                                              "background-color":
                                                                                                "white",
                                                                                              width:
                                                                                                "280px",
                                                                                            },
                                                                                          attrs:
                                                                                            {
                                                                                              label:
                                                                                                "New User Data",
                                                                                              "hide-details":
                                                                                                "auto",
                                                                                              color:
                                                                                                "primary",
                                                                                              name: "newUserData",
                                                                                              autofocus:
                                                                                                "",
                                                                                            },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm
                                                                                                  .newUserData
                                                                                                  .name,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm.newUserData,
                                                                                                    "name",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "newUserData.name",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "d-flex",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              icon: "",
                                                                                              color:
                                                                                                "primary",
                                                                                              disabled:
                                                                                                _vm
                                                                                                  .newUserData
                                                                                                  .name ===
                                                                                                "",
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              _vm.createUserData,
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm.mdiCheck
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          staticClass:
                                                                                            "mr-2",
                                                                                          attrs:
                                                                                            {
                                                                                              icon: "",
                                                                                              color:
                                                                                                "primary",
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                $event.stopPropagation()
                                                                                                _vm.newUserData.name =
                                                                                                  ""
                                                                                                _vm.showNewUserDataInput = false
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm.mdiDelete
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  },
                                                                  proxy: true,
                                                                }
                                                              : null,
                                                            _vm.userDataSelected
                                                              ? {
                                                                  key: "item",
                                                                  fn: function ({
                                                                    item,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-list-item",
                                                                        {
                                                                          class:
                                                                            item.value ===
                                                                            _vm.idForEditing
                                                                              ? "px-0"
                                                                              : "",
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "400px",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                _vm.selectedItemOptions.question.default.value =
                                                                                  item.value
                                                                                _vm.showNewUserDataInput = false
                                                                                _vm.idForEditing =
                                                                                  undefined
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "d-flex justify-space-between align-center",
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "400px",
                                                                                },
                                                                            },
                                                                            [
                                                                              item.value ===
                                                                              _vm.idForEditing
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "d-flex justify-space-between align-center px-4 py-3",
                                                                                      staticStyle:
                                                                                        {
                                                                                          "background-color":
                                                                                            "rgb(240, 240, 240)",
                                                                                          width:
                                                                                            "400px",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        [
                                                                                          _c(
                                                                                            "v-text-field",
                                                                                            {
                                                                                              staticClass:
                                                                                                "pt-4 px-2 pb-2",
                                                                                              staticStyle:
                                                                                                {
                                                                                                  "background-color":
                                                                                                    "white",
                                                                                                  width:
                                                                                                    "280px",
                                                                                                },
                                                                                              attrs:
                                                                                                {
                                                                                                  "hide-details":
                                                                                                    "auto",
                                                                                                  label:
                                                                                                    "Edit User Data",
                                                                                                  color:
                                                                                                    "primary",
                                                                                                  name: "fieldBeingEdited",
                                                                                                  autofocus:
                                                                                                    "",
                                                                                                },
                                                                                              model:
                                                                                                {
                                                                                                  value:
                                                                                                    item.label,
                                                                                                  callback:
                                                                                                    function (
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.$set(
                                                                                                        item,
                                                                                                        "label",
                                                                                                        $$v
                                                                                                      )
                                                                                                    },
                                                                                                  expression:
                                                                                                    "item.label",
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "div",
                                                                                        [
                                                                                          _c(
                                                                                            "v-btn",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  icon: "",
                                                                                                  color:
                                                                                                    "primary",
                                                                                                  disabled:
                                                                                                    item.label ===
                                                                                                    "",
                                                                                                },
                                                                                              on: {
                                                                                                click:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.updateUserData(
                                                                                                      item.value,
                                                                                                      item.label
                                                                                                    )
                                                                                                  },
                                                                                              },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-icon",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.mdiCheck
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _c(
                                                                                    "div",
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            item.label
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                              item.value !==
                                                                                "f_name" &&
                                                                              item.value !==
                                                                                "l_name" &&
                                                                              item.value !==
                                                                                "full_name" &&
                                                                              item.value !==
                                                                                "email" &&
                                                                              item.value !==
                                                                                _vm.idForEditing
                                                                                ? _c(
                                                                                    "div",
                                                                                    [
                                                                                      _c(
                                                                                        "v-menu",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              "offset-y":
                                                                                                "",
                                                                                            },
                                                                                          scopedSlots:
                                                                                            _vm._u(
                                                                                              [
                                                                                                {
                                                                                                  key: "activator",
                                                                                                  fn: function ({
                                                                                                    on,
                                                                                                    attrs,
                                                                                                  }) {
                                                                                                    return [
                                                                                                      _c(
                                                                                                        "v-btn",
                                                                                                        _vm._g(
                                                                                                          _vm._b(
                                                                                                            {
                                                                                                              attrs:
                                                                                                                {
                                                                                                                  icon: "",
                                                                                                                },
                                                                                                            },
                                                                                                            "v-btn",
                                                                                                            attrs,
                                                                                                            false
                                                                                                          ),
                                                                                                          on
                                                                                                        ),
                                                                                                        [
                                                                                                          _c(
                                                                                                            "v-icon",
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                " " +
                                                                                                                  _vm._s(
                                                                                                                    _vm.mdiDotsVertical
                                                                                                                  ) +
                                                                                                                  " "
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                        ],
                                                                                                        1
                                                                                                      ),
                                                                                                    ]
                                                                                                  },
                                                                                                },
                                                                                              ],
                                                                                              null,
                                                                                              true
                                                                                            ),
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-list",
                                                                                            {
                                                                                              staticClass:
                                                                                                "pa-0 ma-0",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-list-item",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "pa-0 ma-0",
                                                                                                  on: {
                                                                                                    click:
                                                                                                      function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        _vm.idForEditing =
                                                                                                          item.value
                                                                                                        _vm.showNewUserDataInput = false
                                                                                                      },
                                                                                                  },
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-btn",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "menu-button",
                                                                                                      attrs:
                                                                                                        {
                                                                                                          text: "",
                                                                                                          color:
                                                                                                            "primary",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-icon",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "mr-1",
                                                                                                          attrs:
                                                                                                            {
                                                                                                              color:
                                                                                                                "primary",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            _vm._s(
                                                                                                              _vm.mdiPencil
                                                                                                            )
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                      _vm._v(
                                                                                                        " Edit "
                                                                                                      ),
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                              _c(
                                                                                                "v-list-item",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "pa-0 ma-0",
                                                                                                  on: {
                                                                                                    click:
                                                                                                      function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        _vm.idForDeletion =
                                                                                                          item.value
                                                                                                        _vm.showDeleteConfirmation = true
                                                                                                      },
                                                                                                  },
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-btn",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "menu-button",
                                                                                                      attrs:
                                                                                                        {
                                                                                                          text: "",
                                                                                                          color:
                                                                                                            "primary",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-icon",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "mr-1",
                                                                                                          attrs:
                                                                                                            {
                                                                                                              color:
                                                                                                                "primary",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            _vm._s(
                                                                                                              _vm.mdiDelete
                                                                                                            )
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                      _vm._v(
                                                                                                        " Delete "
                                                                                                      ),
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                }
                                                              : null,
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm
                                                              .selectedItemOptions
                                                              .question.default
                                                              .value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .selectedItemOptions
                                                                .question
                                                                .default,
                                                              "value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "selectedItemOptions.question.default.value",
                                                        },
                                                      }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                ]
                              : _vm._e(),
                            _c(
                              "v-dialog",
                              {
                                staticStyle: { "z-index": "9999" },
                                attrs: {
                                  "max-width": "600px",
                                  fullscreen: _vm.$vuetify.breakpoint.xsOnly,
                                },
                                model: {
                                  value: _vm.showDeleteConfirmation,
                                  callback: function ($$v) {
                                    _vm.showDeleteConfirmation = $$v
                                  },
                                  expression: "showDeleteConfirmation",
                                },
                              },
                              [
                                _c(
                                  "v-card",
                                  [
                                    _c(
                                      "v-list-item",
                                      [
                                        _c(
                                          "v-list-item-content",
                                          { staticClass: "pl-3" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "text-h5 py-3" },
                                              [
                                                _vm._v(
                                                  "Delete User Data Field?"
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              " Are you sure you want to delete this field? This action cannot be undone. "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item",
                                      [
                                        _c("v-list-item-content", [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "d-flex justify-end",
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    text: "",
                                                    color: "primary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.showDeleteConfirmation = false
                                                      _vm.idForDeletion =
                                                        undefined
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Cancel ")]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: { color: "primary" },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.deleteUserData(
                                                        _vm.idForDeletion
                                                      )
                                                      _vm.showDeleteConfirmation = false
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Delete Field ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.showDefaultConditionDropDown
                              ? _c("validation-provider", {
                                  staticClass: "select",
                                  attrs: {
                                    name: "Condition",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors, valid }) {
                                          return [
                                            _c("v-select", {
                                              attrs: {
                                                autocomplete: "off",
                                                items:
                                                  _vm.DEFAULT_CONDITION_CHOICES,
                                                name: "condition",
                                                label: "Condition",
                                                "error-messages": errors,
                                                success: valid,
                                                "item-text": "label",
                                                "item-value": "value",
                                                "hide-details": "",
                                              },
                                              on: {
                                                change: _vm.onConditionChange,
                                              },
                                              model: {
                                                value:
                                                  _vm.selectedItemOptions
                                                    .question.default
                                                    .applyDefault,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.selectedItemOptions
                                                      .question.default,
                                                    "applyDefault",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "selectedItemOptions.question.default.applyDefault",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm.selectedItemOptions.question.default
                          .applyDefault === _vm.CONDITION_CHOICES.CONDITIONAL
                          ? _c(
                              "section",
                              {
                                key: _vm.selectedItemOptions.question.default
                                  .applyDefault,
                              },
                              [
                                _c("p", { staticClass: "caption py-0 my-0" }, [
                                  _vm._v("Conditional Settings"),
                                ]),
                                _vm.selectedItemOptions.question.default
                                  .applyDefaultConditions.conditions.length > 1
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-end gap py-0 my-0",
                                      },
                                      [
                                        _c("div", [
                                          _c("b", [_vm._v(" Apply if ")]),
                                        ]),
                                        _c("validation-provider", {
                                          staticClass: "select",
                                          attrs: { rules: "required" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({
                                                  errors,
                                                  valid,
                                                }) {
                                                  return [
                                                    _c("v-select", {
                                                      attrs: {
                                                        autocomplete: "off",
                                                        height: "20px",
                                                        items:
                                                          _vm.APPLY_IF_CHOICES,
                                                        name: "applyIf",
                                                        "error-messages":
                                                          errors,
                                                        success: valid,
                                                        "item-text": "label",
                                                        "item-value": "value",
                                                        "hide-details": "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm
                                                            .selectedItemOptions
                                                            .question.default
                                                            .applyDefaultConditions
                                                            .applyIf,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm
                                                              .selectedItemOptions
                                                              .question.default
                                                              .applyDefaultConditions,
                                                            "applyIf",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "\n                  selectedItemOptions.question.default.applyDefaultConditions\n                    .applyIf\n                ",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                        _c("div", [
                                          _c("b", [
                                            _vm._v(
                                              " of the conditions are matched "
                                            ),
                                          ]),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.showConditions
                                  ? _vm._l(
                                      _vm.selectedItemOptions.question.default
                                        .applyDefaultConditions.conditions,
                                      function (condition, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: condition.id,
                                            staticClass:
                                              "align-center justify-space-between",
                                            class: {
                                              "d-flex":
                                                _vm.$vuetify.breakpoint.smAndUp,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "align-center gap",
                                                class: {
                                                  "d-flex":
                                                    _vm.$vuetify.breakpoint
                                                      .smAndUp,
                                                },
                                              },
                                              [
                                                _c("validation-provider", {
                                                  staticClass: "select",
                                                  attrs: {
                                                    name: "Dependant",
                                                    rules: "required",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          errors,
                                                          valid,
                                                        }) {
                                                          return [
                                                            _c("v-select", {
                                                              attrs: {
                                                                autocomplete:
                                                                  "off",
                                                                items:
                                                                  _vm.dependantOptions,
                                                                label:
                                                                  "Dependant",
                                                                "error-messages":
                                                                  errors,
                                                                success: valid,
                                                                "item-text":
                                                                  "label",
                                                                "item-value":
                                                                  "value",
                                                                "hide-details":
                                                                  "",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.onDependantChange()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  condition.type,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      condition,
                                                                      "type",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "condition.type",
                                                              },
                                                            }),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                }),
                                                condition.type
                                                  ? _c("validation-provider", {
                                                      key: condition.type,
                                                      staticStyle: {
                                                        width: "120px",
                                                      },
                                                      attrs: {
                                                        name: "Field",
                                                        rules: "required",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function ({
                                                              errors,
                                                              valid,
                                                            }) {
                                                              return [
                                                                _c("v-select", {
                                                                  key: condition.type,
                                                                  attrs: {
                                                                    autocomplete:
                                                                      "off",
                                                                    items:
                                                                      _vm.getDependantValueOptions(
                                                                        condition
                                                                      ),
                                                                    label:
                                                                      "Field",
                                                                    "error-messages":
                                                                      errors,
                                                                    success:
                                                                      valid,
                                                                    "item-text":
                                                                      "label",
                                                                    "item-value":
                                                                      "value",
                                                                    "hide-details":
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      condition.field,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          condition,
                                                                          "field",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "condition.field",
                                                                  },
                                                                }),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    })
                                                  : _vm._e(),
                                                condition.type
                                                  ? _c("validation-provider", {
                                                      staticClass: "select",
                                                      attrs: {
                                                        name: "Condition",
                                                        rules: "required",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function ({
                                                              errors,
                                                              valid,
                                                            }) {
                                                              return [
                                                                _c("v-select", {
                                                                  attrs: {
                                                                    autocomplete:
                                                                      "off",
                                                                    items:
                                                                      _vm.comparisonTypes,
                                                                    name: "Condition",
                                                                    "error-messages":
                                                                      errors,
                                                                    success:
                                                                      valid,
                                                                    "item-text":
                                                                      "label",
                                                                    "item-value":
                                                                      "value",
                                                                    "hide-details":
                                                                      "",
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.onSubmit()
                                                                      },
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "item",
                                                                          fn: function ({
                                                                            item: {
                                                                              label,
                                                                              value,
                                                                            },
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "div",
                                                                                [
                                                                                  _c(
                                                                                    "MatchIcon",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          value:
                                                                                            value,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        label
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                        {
                                                                          key: "selection",
                                                                          fn: function ({
                                                                            item: {
                                                                              value,
                                                                            },
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "div",
                                                                                [
                                                                                  _c(
                                                                                    "MatchIcon",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          value:
                                                                                            value,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                  model: {
                                                                    value:
                                                                      condition.condition,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          condition,
                                                                          "condition",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "condition.condition",
                                                                  },
                                                                }),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    })
                                                  : _vm._e(),
                                                condition.type
                                                  ? _c("validation-provider", {
                                                      staticStyle: {
                                                        "min-width": "170px",
                                                      },
                                                      attrs: {
                                                        name: "Value",
                                                        rules: "required",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function ({
                                                              errors,
                                                              valid,
                                                            }) {
                                                              return [
                                                                _vm.getGisDataFieldOptionChoices(
                                                                  condition
                                                                ).length > 0
                                                                  ? _c(
                                                                      "v-select",
                                                                      {
                                                                        attrs: {
                                                                          autocomplete:
                                                                            "off",
                                                                          items:
                                                                            _vm.getGisDataFieldOptionChoices(
                                                                              condition
                                                                            ),
                                                                          label:
                                                                            "Value",
                                                                          "error-messages":
                                                                            errors,
                                                                          success:
                                                                            valid,
                                                                          "item-text":
                                                                            "label",
                                                                          "item-value":
                                                                            "value",
                                                                          "hide-details":
                                                                            "",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            condition.value,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                condition,
                                                                                "value",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "condition.value",
                                                                        },
                                                                      }
                                                                    )
                                                                  : _c(
                                                                      "v-text-field",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "Value",
                                                                          "hide-details":
                                                                            "",
                                                                          "error-messages":
                                                                            errors,
                                                                          success:
                                                                            valid,
                                                                          color:
                                                                            "primary",
                                                                          name: "value",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            condition.value,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                condition,
                                                                                "value",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "condition.value",
                                                                        },
                                                                      }
                                                                    ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-btn",
                                              {
                                                class: {
                                                  "ml-3":
                                                    _vm.$vuetify.breakpoint
                                                      .smAndUp,
                                                },
                                                attrs: {
                                                  icon: "",
                                                  color: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeCondition(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.mdiDelete) +
                                                      " "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    )
                                  : _vm._e(),
                                _vm.selectedItemOptions.question.default
                                  .applyDefault ===
                                _vm.CONDITION_CHOICES.CONDITIONAL
                                  ? _c(
                                      "div",
                                      { staticClass: "mx-0 px-0" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "px-0 mx-0",
                                            attrs: {
                                              text: "",
                                              color: "primary",
                                              disabled: invalid,
                                            },
                                            on: { click: _vm.addCondition },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { "x-small": "" } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.mdiPlus) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" Add Condition "),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm.showRepeatingGroupDefaultValuesDialog
                          ? _c("RepeatingGroupDefaultValuesDialog", {
                              attrs: {
                                showRepeatingGroupDefaultValuesDialog:
                                  _vm.showRepeatingGroupDefaultValuesDialog,
                                selectedItemOptions: _vm.selectedItemOptions,
                                formDefinition: _vm.formDefinition,
                              },
                              on: {
                                "repeating-group-default-values-dialog-close":
                                  function ($event) {
                                    _vm.showRepeatingGroupDefaultValuesDialog = false
                                  },
                                addOrDeleteRows: function ($event) {
                                  return _vm.setAddOrDeleteRowsSettings($event)
                                },
                              },
                              model: {
                                value:
                                  _vm.selectedItemOptions.question.default
                                    .value,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectedItemOptions.question.default,
                                    "value",
                                    $$v
                                  )
                                },
                                expression:
                                  "selectedItemOptions.question.default.value",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "d-flex justify-end" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary", disabled: invalid },
                            on: { click: _vm.onSubmit },
                          },
                          [_vm._v(" Save Settings ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }