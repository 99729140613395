var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "caption" }, [
        _vm._v(_vm._s(_vm.item.question.label)),
      ]),
      _c("file-pond", {
        ref: "files",
        attrs: {
          name: "files",
          "label-idle": "Drop files here...",
          "allow-multiple": _vm.item.question.allowMultiple,
          "accepted-file-types": "image/jpeg, image/png",
          files: _vm.uploadFiles,
          allowFileSizeValidation: "",
          maxFileSize: "2MB",
          disabled: _vm.readOnly,
        },
        on: { addfile: _vm.onChange },
      }),
      _vm._l(_vm.files, function (file, index) {
        return _c(
          "v-card",
          { key: file.id },
          [
            _c(
              "v-card-text",
              [
                _c(
                  "v-list-item",
                  [
                    _c(
                      "v-list-item-content",
                      [
                        _c(
                          "v-card",
                          {
                            staticClass:
                              "d-flex flex-row justify-start elevation-0",
                          },
                          [
                            _c(
                              "v-card",
                              {
                                staticClass:
                                  "elevation-0 d-flex flex-column justify-center mr-2",
                              },
                              [_vm._v(" " + _vm._s(file.fileName) + " ")]
                            ),
                            _vm.showDescription
                              ? _c(
                                  "v-card",
                                  {
                                    staticClass: "elevation-0",
                                    attrs: { width: "100%" },
                                  },
                                  [
                                    _c("v-text-field", {
                                      key: file.id,
                                      attrs: {
                                        label: "Description",
                                        color: "primary",
                                        name: file.id,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item-action",
                      [
                        _c(
                          "v-list-item-action-text",
                          [
                            _c(
                              "v-menu",
                              {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                { attrs: { icon: "" } },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  _vm._s(_vm.mdiDotsVertical)
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c(
                                  "v-list",
                                  [
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeFile(
                                              index,
                                              file.id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          [
                                            _c("v-icon", [
                                              _vm._v(_vm._s(_vm.mdiDelete)),
                                            ]),
                                            _vm._v(" Delete "),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }