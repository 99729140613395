<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Edit Formula</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn dark text @click="showPlaceholderDrawer = true">
        Form Placeholders
      </v-btn>
      <v-btn icon dark @click="closeForm">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <validation-observer ref="formulaForm">
        <form @submit.prevent="onSubmit">
          <v-row>
            <v-col cols="12">
              <validation-provider
                name="Calculation"
                rules="required"
                v-slot="{ errors, valid }"
              >
                <v-textarea
                  outlined
                  label="Calculation"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="value"
                  v-model="formula"
                >
                </v-textarea>
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <p class="caption">Readable Formula</p>
              <p>{{ formula | readableQuestion(formDefinition) }}</p>
            </v-col>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn color="primary" type="submit">Save Calculation</v-btn>
            </v-col>
          </v-row>
        </form>

        <v-navigation-drawer
          stateless
          app
          v-model="showPlaceholderDrawer"
          right
          style="z-index: 1002"
          width="300px"
        >
          <v-toolbar color="primary">
            <v-list-item two-line>
              <v-list-item-content>
                <div class="d-flex justify-space-between mr-n6">
                  <v-list-item-title class="white--text text-h6">
                    Form Placeholders
                  </v-list-item-title>
                  <v-btn
                    text
                    color="white"
                    @click="showPlaceholderDrawer = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-toolbar>
          <FormPlaceholdersForm
            :formDefinition="formDefinition"
            type="formula"
            @placeholder-copied="showCopiedSnackbar = true"
          />
        </v-navigation-drawer>
      </validation-observer>

      <v-snackbar v-model="showCopiedSnackbar" style="z-index: 9999">
        Form Placeholder Copied
      </v-snackbar>
    </v-card-text>
  </v-card>
</template>

<script>
import FormPlaceholdersForm from "@/components/forms/form-builder-edit-form/FormPlaceholdersForm.vue";

export default {
  name: "FormulaForm",
  props: {
    label: String,
    formDefinition: Object,
    questionId: Number,
    value: Object,
  },
  components: {
    FormPlaceholdersForm,
  },
  computed: {
    questionChoices() {
      const { sections } = this.formDefinition.form;
      const nestedItems = sections.map(({ items }) => items);
      return nestedItems
        .flat()
        .filter(({ id, question }) => {
          return (
            id !== this.questionId &&
            ["NUMBER", "SINGLE_SELECT"].includes(question?.type)
          );
        })
        .map(({ id, question: { label } }) => {
          return {
            value: `{params.${id}}}`,
            label: `${id}. ${label}`,
          };
        });
    },
  },
  data() {
    return {
      formula: "",
      items: [],
      showPlaceholderDrawer: false,
      showCopiedSnackbar: false,
    };
  },
  methods: {
    closeForm() {
      this.$emit("formula-form-close");
    },
    onOpen() {
      this.items = [...this.questionChoices];
    },
    async onSubmit() {
      const success = await this.$refs.formulaForm.validate();
      if (!success) {
        return;
      }
      this.$emit("formula-form-submitted", this.formula);
    },
  },
  watch: {
    formDefinition: {
      deep: true,
      immediate: true,
      handler() {
        const { sections } = this.formDefinition.form;
        const nestedItems = sections.map(({ items }) => items);
        const items = nestedItems.flat();
        const item = items.find((item) => item.id === this.questionId);
        this.formula = item.question.calculation;
      },
    },
  },
};
</script>

<style>
.tooltip.popover {
  z-index: 999999;
  background-color: white;
  font-family: Roboto, "sans-serif";
  border-radius: 3px;
}

.mention-item {
  border-radius: 4px;
}

.mention-selected {
  background: rgb(192, 250, 153);
  margin: 0px;
}

.mentionable textarea {
  width: 100%;
  height: 100px;
  border: 1px solid gray;
}

.dim {
  padding: 5px;
}
</style>
