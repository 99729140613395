export const comparisonTypes = [
  {
    value: "EQUAL",
    label: "Equal",
  },
  {
    value: "GREATER_THAN",
    label: "Greater Than",
  },
  {
    value: "GREATER_THAN_OR_EQUAL",
    label: "Greater Than or Equal",
  },
  {
    value: "LESS_THAN",
    label: "Less Than",
  },
  {
    value: "LESS_THAN_OR_EQUAL",
    label: "Less Than or Equal",
  },
  {
    value: "NOT_EQUAL",
    label: "Not Equal",
  },
];

export const defaultValueTypes = [
  {
    value: null,
    label: "None",
  },
  {
    value: "ORGANIZATION",
    label: "Organization Data",
  },
  {
    value: "USER",
    label: "User Data",
  },
  {
    value: "CUSTOM",
    label: "Custom Value",
  },
  {
    value: "GIS",
    label: "GIS Field",
  },
  {
    value: "GIS_DATA",
    label: "UtiliSync Field",
  },
];

export const requiredOptions = [
  {
    value: "ALWAYS",
    label: "Always",
  },
  {
    value: "NEVER",
    label: "Never",
  },
  {
    value: "conditional",
    label: "Conditional",
  },
];

export const groupItemRequiredOptions = [
  {
    value: "ALWAYS",
    label: "Always",
  },
  {
    value: "NEVER",
    label: "Never",
  },
  {
    value: "conditional",
    label: "Conditional",
  },
];

export const groupItemVisibleOptions = [
  {
    value: "ALWAYS",
    label: "Always",
  },
  {
    value: "NEVER",
    label: "Never",
  },
  {
    value: "conditional",
    label: "Conditional",
  },
];

export const visibilityOptions = [
  {
    value: "NEVER",
    label: "Never",
  },
  {
    value: "ALWAYS",
    label: "Always",
  },
  {
    value: "conditional",
    label: "Conditional",
  },
];

export const createOptions = [
  {
    value: "ALWAYS",
    label: "Always",
  },
  {
    value: "conditional",
    label: "Conditional",
  },
];

export const orgDataOptions = [
  {
    value: "name",
    label: "Name",
  },
];

export const locateRequestDataValues = [
  {
    value: "assigned_to_name",
    label: "Assigned To Name",
  },
  {
    value: "boring",
    label: "Boring",
  },
  {
    value: "caller",
    label: "Caller",
  },
  {
    value: "caller_email",
    label: "Caller Email",
  },
  {
    value: "caller_phone",
    label: "Caller Phone",
  },
  {
    value: "company",
    label: "Company",
  },
  {
    value: "contact_name",
    label: "Contact Name",
  },
  {
    value: "contact_phone",
    label: "Contact Phone",
  },
  {
    value: "created_date",
    label: "Created Date",
  },
  {
    value: "is_emergency",
    label: "Emergency?",
  },
  {
    value: "is_rush",
    label: "Rush?",
  },
  {
    value: "legal_date",
    label: "Legal Date",
  },
  {
    value: "location",
    label: "Location",
  },
  {
    value: "meet",
    label: "Meet",
  },
  {
    value: "remarks",
    label: "Remarks",
  },
  {
    value: "street_address",
    label: "Street Address",
  },
  {
    value: "ticket",
    label: "Ticket",
  },
  {
    value: "ticket_type",
    label: "Ticket Type",
  },
  {
    value: "work_date",
    label: "Work Date",
  },
  {
    value: "work_type",
    label: "Work Type",
  },
];

export const displayInfoOnlyOptions = [
  {
    value: true,
    label: "True",
  },
  {
    value: false,
    label: "False",
  },
];
