<template>
  <v-dialog :value="showConfirmDeleteRowDialog" persistent max-width="500px">
    <v-card class="rounded-0">
      <v-card-text class="py-3">
        Are you sure you want to delete this row?
      </v-card-text>

      <v-card-actions class="d-flex justify-end px-5 py-3">
        <v-btn text @click="$emit('cancel')">Cancel</v-btn>
        <v-btn color="primary" @click="$emit('delete', selectedRowIndex)">
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDeleteRowDialog",
  props: {
    showConfirmDeleteRowDialog: Boolean,
    selectedRowIndex: Number,
  },
};
</script>
