<template>
  <section
    :style="{ width: `${contentWidth}px`, height: `${contentHeight}px` }"
  >
    <HtmlEditor
      v-model="selectedHtml"
      name="templateHtml"
      @input="submit"
      :height="`${contentHeight}px`"
    />
  </section>
</template>

<script>
import HtmlEditor from "@/components/shared/HtmlEditor";

export default {
  name: "Version1TemplateEditor",
  props: {
    showVersion1TemplateEditorDialog: Boolean,
    value: String,
  },
  data() {
    return {
      selectedHtml: "",
      contentWidth: window.innerWidth - 420,
      contentHeight: window.innerHeight - 56,
    };
  },
  components: { HtmlEditor },
  methods: {
    async submit() {
      this.$emit("input", this.selectedHtml);
    },
    onResize() {
      if (window.innerWidth <= 600) {
        this.contentWidth = window.innerWidth - 59;
      } else {
        this.contentWidth = window.innerWidth - 359;
      }

      if (window.innerWidth <= 600) {
        this.contentHeight = window.innerHeight - 80;
      } else {
        this.contentHeight = window.innerHeight - 92;
      }
    },
  },
  mounted() {
    this.selectedHtml = this.value;
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  watch: {
    value(val) {
      this.selectedHtml = val;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
