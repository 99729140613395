<template>
  <v-dialog
    :value="showPhotoSizeDialog"
    max-width="400"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <validation-observer ref="photoSizeForm" slim>
        <form @submit.prevent="submit">
          <v-toolbar dark color="primary" class="elevation-0">
            <v-toolbar-title> Photo Sizing </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="$emit('close-photo-size-dialog')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="elevation-0">
            <div class="py-3">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Image Width"
                :rules="{
                  required: true,
                  regex: /\d+|auto/,
                }"
              >
                <v-text-field
                  v-model="image.imageWidth"
                  label="Image Width"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="name"
                  class="pt-1"
                  @keydown="onNumberKeyDown"
                  @change="checkImageWidthVal()"
                  @input="onImageWidthInput()"
                />
              </validation-provider>
            </div>

            <div class="py-3">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Image Height"
                :rules="{
                  required: true,
                  regex: /\d+|auto/,
                }"
              >
                <v-text-field
                  v-model="image.imageHeight"
                  label="Image Height"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="name"
                  class="pt-1"
                  @keydown="onNumberKeyDown"
                  @change="checkImageHeightVal()"
                  @input="onImageHeightInput()"
                />
              </validation-provider>
            </div>

            <div class="py-3">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Image Padding"
                :rules="{ required: true, numeric: true, min_value: 0 }"
              >
                <v-text-field
                  v-model.number="image.imagePadding"
                  label="Image Padding"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="name"
                  class="pt-1"
                  type="number"
                  @keydown="onNumberKeyDown"
                />
              </validation-provider>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" type="submit"> Insert </v-btn>
          </v-card-actions>
        </form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PhotoSizeDialog",
  props: {
    showPhotoSizeDialog: Boolean,
    selectedFormField: Object,
  },
  data() {
    return {
      image: {
        inputImageURL: "",
        imageWidth: 0,
        imageHeight: 0,
        imagePadding: 0,
      },
    };
  },
  methods: {
    onImageWidthInput() {
      if (!isNaN(this.image.imageWidth)) {
        this.image.imageHeight = "auto";
      }
    },
    onImageHeightInput() {
      if (!isNaN(this.image.imageHeight)) {
        this.image.imageWidth = "auto";
      }
    },
    checkImageWidthVal() {
      if (!isNaN(this.image.imageWidth)) {
        if (+this.image.imageWidth > 740) {
          this.image.imageWidth = 740;
        } else if (+this.image.imageWidth < 0) {
          this.image.imageWidth = 0;
        }
      }
    },
    checkImageHeightVal() {
      if (!isNaN(this.image.imageHeight)) {
        if (+this.image.imageHeight > 740) {
          this.image.imageHeight = 740;
        } else if (+this.image.imageHeight < 0) {
          this.image.imageHeight = 0;
        }
      }
    },
    onNumberKeyDown(e) {
      if (["e", "+", "-", "*", "/"].includes(e.key.toLowerCase())) {
        e.preventDefault();
      }
    },
    async submit() {
      const success = await this.$refs.photoSizeForm.validate();
      if (!success) {
        return;
      }

      const { selectedFormField: formField } = this;
      this.$emit("insert-image", { ...this.image, formField });
    },
  },
};
</script>
