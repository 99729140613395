<template>
  <section>
    <p
      class="caption"
      :style="{ color: validated && !valid ? '#ff5252' : undefined }"
    >
      {{ label }}
    </p>
    <div :id="`quill-toolbar-${ID}`">
      <select class="ql-size"></select>
      <button class="ql-bold"></button>
      <button class="ql-italic"></button>
      <button class="ql-underline"></button>
      <button class="ql-strike"></button>
      <select class="ql-align"></select>
      <button class="ql-list" value="ordered"></button>
      <button class="ql-list" value="bullet"></button>
      <select class="ql-color"></select>
      <button class="ql-link"></button>
      <button class="ql-indent" value="-1"></button>
      <button class="ql-indent" value="+1"></button>
    </div>

    <div :id="`quill-editor-${ID}`" :style="{ height }">
      {{ inputValue }}
    </div>

    <p class="caption">{{ placeholder }}</p>
    <p class="caption red--text" v-if="Array.isArray(errors)">
      {{ errors[0] }}
    </p>
    <v-icon
      color="red"
      @click="$emit('add-entry')"
      v-if="allowMultiple"
      class="cursor-pointer"
    >
      {{ mdiPlus }}
    </v-icon>
    <v-icon
      color="#093637"
      @click="$emit('remove-entry', index)"
      v-if="multipleItems"
    >
      {{ mdiMinus }}
    </v-icon>
  </section>
</template>

<script>
import { mdiPlus, mdiMinus } from "@mdi/js";
import { v4 as uuidv4 } from "uuid";
import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  name: "QuillEditor",
  props: {
    item: Object,
    errors: Array,
    valid: Boolean,
    validated: Boolean,
    multipleItems: Boolean,
    value: String,
    height: {
      type: String,
      default: "250px",
    },
    index: Number,
    maxChars: Number,
    label: String,
    placeholder: String,
    allowMultiple: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      mdiPlus,
      mdiMinus,
      inputValue: "",
      ID: uuidv4(),
      editor: undefined,
    };
  },
  mounted() {
    this.editor = new Quill(`#quill-editor-${this.ID}`, {
      modules: { toolbar: `#quill-toolbar-${this.ID}` },
      theme: "snow",
    });
    this.editor.on("text-change", () => {
      if (
        !isNaN(this.maxChars) &&
        this.maxChars > 0 &&
        this.editor.getLength() > this.maxChars
      ) {
        this.editor.deleteText(this.maxChars, this.editor.getLength());
      }

      const tmp = document.createElement("div");
      tmp.innerHTML = this.editor.root.innerHTML;
      if (tmp.textContent.length === 0) {
        this.$emit("input", "");
      } else {
        this.$emit("input", this.editor.root.innerHTML);
      }
    });
    this.inputValue = this.value;
    const delta = this.editor.clipboard.convert(this.inputValue);
    this.editor.setContents(delta);
    this.editor?.enable(!this.disabled);
  },
  watch: {
    disabled(val) {
      this.editor?.enable(!val);
    },
  },
};
</script>
