var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "elevation-2" },
        [
          _c(
            "v-card-text",
            { staticClass: "pa-4" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("validation-provider", {
                        attrs: { name: "Activity Type" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-select", {
                                  attrs: {
                                    autocomplete: "off",
                                    label: "Activity Type",
                                    "hide-details": "auto",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "activityType",
                                    items: _vm.activityTypes,
                                    "item-text": "label",
                                    "item-value": "value",
                                    disabled: !_vm.canManageForms,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$emit(
                                        "input",
                                        _vm.copiedFormDefinition
                                      )
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.copiedFormDefinition.form
                                        .city_works_webhook_config.create_task
                                        .type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.copiedFormDefinition.form
                                          .city_works_webhook_config
                                          .create_task,
                                        "type",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "\n                copiedFormDefinition.form.city_works_webhook_config\n                  .create_task.type\n              ",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("validation-provider", {
                        attrs: { name: "Created By" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-select", {
                                  attrs: {
                                    autocomplete: "off",
                                    label: "Created By",
                                    "hide-details": "auto",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "createdBy",
                                    items: _vm.userChoices,
                                    "item-text": "label",
                                    "item-value": "value",
                                    disabled: !_vm.canManageForms,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$emit(
                                        "input",
                                        _vm.copiedFormDefinition
                                      )
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.copiedFormDefinition.form
                                        .city_works_webhook_config.create_task
                                        .created_by,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.copiedFormDefinition.form
                                          .city_works_webhook_config
                                          .create_task,
                                        "created_by",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "\n                copiedFormDefinition.form.city_works_webhook_config\n                  .create_task.created_by\n              ",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("validation-provider", {
                        attrs: { name: "Description" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "Description",
                                    "hide-details": "auto",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "description",
                                    disabled: !_vm.canManageForms,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$emit(
                                        "input",
                                        _vm.copiedFormDefinition
                                      )
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.copiedFormDefinition.form
                                        .city_works_webhook_config.create_task
                                        .description,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.copiedFormDefinition.form
                                          .city_works_webhook_config
                                          .create_task,
                                        "description",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "\n                copiedFormDefinition.form.city_works_webhook_config\n                  .create_task.description\n              ",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("validation-provider", {
                        attrs: { name: "Assign To" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-select", {
                                  attrs: {
                                    autocomplete: "off",
                                    label: "Assign To",
                                    "hide-details": "auto",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "assignTo",
                                    items: _vm.userChoices,
                                    "item-text": "label",
                                    "item-value": "value",
                                    disabled: !_vm.canManageForms,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$emit(
                                        "input",
                                        _vm.copiedFormDefinition
                                      )
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.copiedFormDefinition.form
                                        .city_works_webhook_config.create_task
                                        .assigned_to,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.copiedFormDefinition.form
                                          .city_works_webhook_config
                                          .create_task,
                                        "assigned_to",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "\n                copiedFormDefinition.form.city_works_webhook_config\n                  .create_task.assigned_to\n              ",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-switch", {
                        attrs: {
                          label: "Assignee Closable",
                          disabled: !_vm.canManageForms,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$emit("input", _vm.copiedFormDefinition)
                          },
                        },
                        model: {
                          value:
                            _vm.copiedFormDefinition.form
                              .city_works_webhook_config.create_task
                              .assignee_closable,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.copiedFormDefinition.form
                                .city_works_webhook_config.create_task,
                              "assignee_closable",
                              $$v
                            )
                          },
                          expression:
                            "\n              copiedFormDefinition.form.city_works_webhook_config.create_task\n                .assignee_closable\n            ",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mt-4" },
        [
          _c("v-card-text", [
            _c("div", [
              _vm._v(" Copy this into the "),
              _c("strong", [_vm._v("Template")]),
              _vm._v(
                " field of Action Templates webhook within Cityworks Admin. "
              ),
            ]),
            _c(
              "div",
              { staticClass: "pt-2 d-flex justify-space-between align-center" },
              [
                _c(
                  "v-card",
                  { attrs: { color: "#dddddd" } },
                  [
                    _c(
                      "v-card-text",
                      { staticClass: "pa-1" },
                      [
                        _c(
                          "v-card",
                          { attrs: { flat: "" } },
                          [
                            _c("v-card-text", { staticClass: "pa-2" }, [
                              _vm._v(_vm._s(_vm.templatePayload)),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "ml-2",
                    attrs: { icon: "" },
                    on: { click: _vm.copyPayload },
                  },
                  [
                    _c("v-icon", { attrs: { color: "blue" } }, [
                      _vm._v("mdi-content-copy"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 5000 },
          model: {
            value: _vm.showCopiedPayloadSnack,
            callback: function ($$v) {
              _vm.showCopiedPayloadSnack = $$v
            },
            expression: "showCopiedPayloadSnack",
          },
        },
        [_vm._v(" Copied webhook Template payload! ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }