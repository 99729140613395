<template>
  <div
    style="background-color: #f1f2f1"
    class="mx-0 d-flex flex-column align-center"
  >
    <div
      v-if="formDefinition.html_merge_definition_version === 2"
      class="d-flex flex-grow-1 border-left border-right"
      :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
      :style="{
        height: $vuetify.breakpoint.xsOnly ? undefined : `calc(100vh - 56px)`,
        width: `100%`,
      }"
    >
      <section
        class="white overflow-y-auto border-right"
        style="height: calc(100vh - 56px); width: 300px"
        v-if="!$vuetify.breakpoint.xsOnly"
      >
        <v-expansion-panels
          flat
          multiple
          v-model="openPanels"
          class="flex-grow-0"
          style="width: 300px"
          accordion
        >
          <v-expansion-panel :value="0">
            <v-expansion-panel-header>
              <h2>Configuration</h2>
            </v-expansion-panel-header>

            <v-expansion-panel-content class="border-top">
              <FormConfigurationForm
                v-model="formDefinition"
                :disabled="disabled"
                @input="$emit('input', $event)"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel :value="1" class="border-top border-bottom">
            <v-expansion-panel-header class="header">
              Form Fields
            </v-expansion-panel-header>

            <v-expansion-panel-content class="border-top">
              <FormFieldsForm
                :formDefinition="formDefinition"
                @form-field-selected="onFormFieldSelected"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </section>

      <section
        class="d-flex justify-center pa-0 overflow-hidden flex-grow-1"
        style="height: 100%"
      >
        <validation-provider
          name="Form Template Editor"
          v-slot="{ errors, valid }"
        >
          <TemplateEditor
            v-if="Array.isArray(formDefinition.form.sections)"
            :errors="errors"
            :valid="valid"
            :disabled="disabled"
            :formDefinition="formDefinition"
            v-model="formDefinition.html_merge_definition"
            @input="$emit('input', formDefinition)"
            @input:header="onHeaderInput"
            @input:footer="onFooterInput"
            @input:docSettings="onInputDocSettings"
            @input:version="onVersionInput"
            ref="templateEditor"
          />
        </validation-provider>
      </section>
    </div>
    <div v-else>
      <div
        class="d-flex flex-grow-1 border-left border-right"
        :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
        :style="{
          height: $vuetify.breakpoint.xsOnly ? undefined : `calc(100vh - 56px)`,
          width: `100%`,
        }"
      >
        <section
          class="white overflow-y-auto border-right"
          style="height: calc(100vh - 56px); width: 300px"
          v-if="!$vuetify.breakpoint.xsOnly"
        >
          <v-expansion-panels
            flat
            multiple
            v-model="openPanels"
            class="flex-grow-0"
            style="width: 300px"
            accordion
          >
            <v-expansion-panel :value="0">
              <v-expansion-panel-header>
                <h2>Configuration</h2>
              </v-expansion-panel-header>

              <v-expansion-panel-content class="border-top">
                <FormConfigurationForm
                  v-model="formDefinition"
                  :disabled="disabled"
                  :showPageSettings="false"
                  @input="$emit('input', $event)"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </section>

        <section class="border-left">
          <div class="d-flex justify-end white" v-if="!editV1Code">
            <v-btn icon @click="editV1Code = true">
              <v-icon>
                {{ mdiCodeTags }}
              </v-icon>
            </v-btn>
          </div>
          <div class="d-flex white align-center justify-space-between" v-else>
            <v-btn text @click="editV1Code = false">
              <v-icon size="20">{{ mdiChevronLeft }}</v-icon>
              Doc Builder
            </v-btn>

            <v-btn text @click="showChangeFormDefinitionVersionDialog = true">
              Form Version 2
            </v-btn>
          </div>

          <Version1TemplateEditor
            v-if="editV1Code"
            v-model="formDefinition.html_merge_definition"
            @input="$emit('input', formDefinition)"
          />
          <Version1TemplateView v-else :htmlCode="htmlCode" />
        </section>
      </div>
    </div>

    <ChangeFormDefinitionVersionDialog
      :showChangeFormDefinitionVersionDialog="
        showChangeFormDefinitionVersionDialog
      "
      :versionNumber="1"
      @cancel="showChangeFormDefinitionVersionDialog = false"
      @change-form-version="onVersionInput"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { tableCellClassList } from "@/constants/tableCellClassList";
import { mdiPlus, mdiCodeTags, mdiDotsVertical, mdiChevronLeft } from "@mdi/js";
import TemplateEditor from "@/components/shared/TemplateEditor";
import Version1TemplateView from "@/components/forms/form-builder-edit-form/report-template-form/edit-template/Version1TemplateView";
import FormConfigurationForm from "@/components/forms/form-builder-edit-form/report-template-form/edit-template/FormConfigurationForm";
import FormFieldsForm from "@/components/forms/form-builder-edit-form/report-template-form/edit-template/FormFieldsForm";
import Version1TemplateEditor from "@/components/forms/form-builder-edit-form/report-template-form/edit-template/Version1TemplateEditor";
import ChangeFormDefinitionVersionDialog from "@/components/shared/ChangeFormDefinitionVersionDialog.vue";

export default {
  name: "EditTemplate",
  props: {
    activeTab: String,
    value: Object,
    disabled: Boolean,
    selectedFormDefLayer: Object,
    isLocateRequestLayer: Boolean,
  },
  components: {
    TemplateEditor,
    FormConfigurationForm,
    FormFieldsForm,
    Version1TemplateView,
    Version1TemplateEditor,
    ChangeFormDefinitionVersionDialog,
  },
  mounted() {
    if (!this.formDefinition?.form?.pageSettings) {
      this.formDefinition.form.pageSettings = {
        pageWidth: 8.5,
        pageHeight: 11,
        marginTop: 1,
        marginBottom: 1,
        marginLeft: 1,
        marginRight: 1,
      };
      this.$emit("input", this.formDefinition);
    }
  },
  data() {
    return {
      showCopiedSnackbar: false,
      showPlaceholderDrawer: false,
      formDefinition: {},
      tableCellClassList,
      mdiPlus,
      mdiCodeTags,
      mdiDotsVertical,
      mdiChevronLeft,
      showUnsavedChangesDialog: false,
      hasChanges: false,
      openPanels: [0, 1],
      showVersion1TemplateView: false,
      editV1Code: false,
      showChangeFormDefinitionVersionDialog: false,
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.formDefinition = cloneDeep(val);
      },
    },
    "formDefinition.html_merge_definition"(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.hasChanges = true;
      }
    },
  },
  computed: {
    htmlCode() {
      return this.formDefinition?.html_merge_definition;
    },
  },
  methods: {
    onFormFieldSelected(formFieldId) {
      this.$refs.templateEditor.onFormFieldSelected(formFieldId);
    },
    onVersionInput(versionNumber) {
      this.showChangeFormDefinitionVersionDialog = false;
      this.formDefinition.html_merge_definition_version = versionNumber;
      this.$emit("input", this.formDefinition);
    },
    onInputPageSettings(pageSettings) {
      this.formDefinition.form.pageSettings = pageSettings;
      this.$emit("input", this.formDefinition);
    },
    onInputDocSettings(formDefinition) {
      this.$emit("input", formDefinition);
    },
    onHeaderInput(headerTemplateHtml) {
      this.formDefinition.html_merge_definition_header = headerTemplateHtml;
      this.$emit("input", this.formDefinition);
    },
    onFooterInput(footerTemplateHtml) {
      this.formDefinition.html_merge_definition_footer = footerTemplateHtml;
      this.$emit("input", this.formDefinition);
    },
    onCloseButtonClick() {
      if (!this.hasChanges) {
        this.$emit("edit-template-dialog-close");
      } else {
        this.showUnsavedChangesDialog = true;
      }
    },
  },
};
</script>

<style scoped>
.full-width {
  width: 100%;
}

.list-background {
  background-color: white;
}

.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.header {
  min-height: 0px;
  height: 40px;
}
</style>
