<template>
  <validation-observer ref="cellPaddingForm">
    <form @submit.prevent="save">
      <v-card>
        <v-card-text class="pt-2 pb-3 px-4">
          <validation-provider
            v-slot="{ errors, valid }"
            name="Padding"
            rules="required"
          >
            <v-text-field
              v-model="quantityPadding"
              label="Padding"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="name"
              type="number"
              hint="Max value 50"
              min="0"
              max="50"
              persistent-hint
              @keydown="onNumberKeyDown"
            />
          </validation-provider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="$emit('cancel')"> Cancel </v-btn>
          <v-btn color="primary" type="submit"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </validation-observer>
</template>

<script>
export default {
  name: "CellPaddingForm",
  data() {
    return {
      quantityPadding: 0,
    };
  },
  methods: {
    onNumberKeyDown(e) {
      if (["e", "+", "-", "*", "/"].includes(e.key.toLowerCase())) {
        e.preventDefault();
      }
    },
    async save() {
      const success = await this.$refs.cellPaddingForm.validate();
      if (!success) {
        return;
      }
      this.$emit("saved", this.quantityPadding);
    },
  },
};
</script>
