var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showEditPositiveResponseCommentChoicesDialog,
        "max-width": "1000px",
        persistent: "",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
      },
    },
    [
      [
        _c(
          "v-card",
          [
            _c(
              "v-toolbar",
              { attrs: { dark: "", color: "primary" } },
              [
                _c("v-toolbar-title", [
                  _vm._v(" Edit Positive Response Comments "),
                ]),
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "", dark: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("close-dialog")
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("mdi-close")])],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-card-text",
              {
                staticClass: "pa-4",
                staticStyle: { "background-color": "#f1f2f1" },
              },
              [
                _c(
                  "section",
                  { staticClass: "d-flex justify-end" },
                  [
                    _c(
                      "v-btn",
                      { attrs: { text: "" }, on: { click: _vm.addComment } },
                      [_vm._v("+ Add Comment")]
                    ),
                  ],
                  1
                ),
                _c(
                  "v-simple-table",
                  { attrs: { height: "40vh", "fixed-header": "" } },
                  [
                    _c("thead", [
                      _c("tr", [
                        _c("th"),
                        _c("th", { attrs: { colspan: "2" } }, [
                          _vm._v("Comment (Max 255 Characters)"),
                        ]),
                      ]),
                    ]),
                    _vm.comments.length > 0
                      ? _c(
                          "draggable",
                          {
                            attrs: { list: _vm.comments, tag: "tbody" },
                            on: {
                              end: function ($event) {
                                return _vm.onSubmit()
                              },
                            },
                          },
                          _vm._l(_vm.comments, function (c, index) {
                            return _c("tr", { key: c.id }, [
                              _c(
                                "td",
                                { staticClass: "pl-1 pr-0 mx-0 cursor-grab" },
                                [
                                  _c("v-icon", [
                                    _vm._v(_vm._s(_vm.mdiDragVertical)),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "td",
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "Comment",
                                      rules: "required|max:255",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors, valid }) {
                                            return [
                                              _c("v-text-field", {
                                                attrs: {
                                                  "hide-details": "auto",
                                                  "error-messages": errors,
                                                  success: valid,
                                                  color: "primary",
                                                },
                                                model: {
                                                  value: c.text,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      c,
                                                      "text",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression: "c.text",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c("td", [
                                _c(
                                  "section",
                                  { staticClass: "d-flex justify-end" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { icon: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteComment(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v(
                                            " " + _vm._s(_vm.mdiDelete) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ])
                          }),
                          0
                        )
                      : _c("tbody", [
                          _c("tr", [
                            _c("td", { attrs: { colspan: "3" } }, [
                              _c(
                                "section",
                                { staticClass: "d-flex justify-center" },
                                [_vm._v(" No Comments Added ")]
                              ),
                            ]),
                          ]),
                        ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-card-actions",
              { staticClass: "d-flex pa-5 justify-end" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.onSubmit()
                        _vm.$emit("close-dialog")
                      },
                    },
                  },
                  [_vm._v(" Save Choices ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }