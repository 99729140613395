<template>
  <v-dialog
    :value="showChangeFormDefinitionVersionDialog"
    persistent
    width="500"
  >
    <v-card class="elevation-0">
      <v-card-text class="py-3">
        <h2 class="py-2">Change Form Version</h2>

        <p>
          Are you sure you want to change the form version? This will
          {{ versionNumber }}. Save the Form with the new version number and
          {{ versionNumberToSwitchTo }}. Reload the page. Most of the formatting
          will likely be lost.
        </p>
      </v-card-text>

      <v-card-actions class="d-flex justify-end">
        <v-btn color="primary" @click="$emit('cancel')" text>Cancel</v-btn>

        <v-btn
          dark
          color="primary"
          @click="$emit('change-form-version', versionNumberToSwitchTo)"
        >
          Change Form Version
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ChangeFormDefinitionVersionDialog",
  props: {
    versionNumber: Number,
    showChangeFormDefinitionVersionDialog: Boolean,
  },
  computed: {
    versionNumberToSwitchTo() {
      return this.versionNumber === 1 ? 2 : 1;
    },
  },
};
</script>
