<template>
  <node-view-wrapper as="div" class="image-param" :style="node.attrs.style">
    <node-view-wrapper
      as="img"
      :src="node.attrs.paramSrc"
      class="image-param-image"
      :style="node.attrs.style"
    />
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from "@tiptap/vue-2";

export default {
  components: {
    NodeViewWrapper,
  },
  props: nodeViewProps,
};
</script>

<style>
.image-param {
  background: #e0e0e0;
  border-radius: 16px;
  font-size: 14px;
  padding: 5px 12px;
  display: inline-block;
  background-image: url(~@/assets/image_placeholder_landscape.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.image-param-image {
  visibility: hidden;
}
</style>
