var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex justify-space-between" },
    [
      _c(
        "validation-observer",
        { ref: "fileForm" },
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                { staticClass: "pa-0 ma-0" },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("validation-provider", {
                        attrs: { name: "Label", rules: "required" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "Label",
                                    "hide-details": "auto",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "name",
                                    disabled: !_vm.canManageForms,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onSubmit()
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.selectedRepeatingGroupItemOptions
                                        .question.label,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectedRepeatingGroupItemOptions
                                          .question,
                                        "label",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "selectedRepeatingGroupItemOptions.question.label",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                { staticClass: "pa-0 ma-0" },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("validation-provider", {
                        attrs: { name: "Required", rules: "required" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-select", {
                                  attrs: {
                                    autocomplete: "off",
                                    items: _vm.groupItemRequiredOptions,
                                    label: "Required",
                                    "error-messages": errors,
                                    success: valid,
                                    "item-text": "label",
                                    "item-value": "value",
                                    disabled: !_vm.canManageForms,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onSubmit()
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.selectedRepeatingGroupItemOptions
                                        .question.required.condition,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectedRepeatingGroupItemOptions
                                          .question.required,
                                        "condition",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "\n                selectedRepeatingGroupItemOptions.question.required.condition\n              ",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                { staticClass: "pa-0 ma-0" },
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "mt-0 mb-0 pt-0 pb-0" },
                    [
                      _c("v-switch", {
                        attrs: {
                          label: "Show Description",
                          disabled: !_vm.canManageForms,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onSubmit()
                          },
                        },
                        model: {
                          value:
                            _vm.selectedRepeatingGroupItemOptions.question
                              .showDescription,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.selectedRepeatingGroupItemOptions.question,
                              "showDescription",
                              $$v
                            )
                          },
                          expression:
                            "\n              selectedRepeatingGroupItemOptions.question.showDescription\n            ",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                { staticClass: "pa-0 ma-0" },
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "mt-0 mb-0 pt-0 pb-0" },
                    [
                      _c("v-switch", {
                        attrs: {
                          label: "Allow Multiple",
                          disabled: !_vm.canManageForms,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onSubmit()
                          },
                        },
                        model: {
                          value:
                            _vm.selectedRepeatingGroupItemOptions.allowMultiple,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.selectedRepeatingGroupItemOptions,
                              "allowMultiple",
                              $$v
                            )
                          },
                          expression:
                            "selectedRepeatingGroupItemOptions.allowMultiple",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }