var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "v-card",
        [
          _c("v-card-text", { staticClass: "py-1 my-1" }, [
            _c(
              "section",
              { staticClass: "d-flex justify-space-between align-center" },
              [
                _c("div", [
                  _vm._v(
                    "This UtiliBot will add labor to Cityworks service request."
                  ),
                ]),
                _c("v-switch", {
                  attrs: {
                    label: "Enable Integration",
                    disabled: !_vm.canManageForms,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$emit("input", _vm.formDefinition)
                    },
                  },
                  model: {
                    value:
                      _vm.formDefinition.form.city_works_webhook_config
                        .add_labor_to_cityworks_service_request.enabled,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formDefinition.form.city_works_webhook_config
                          .add_labor_to_cityworks_service_request,
                        "enabled",
                        $$v
                      )
                    },
                    expression:
                      "\n            formDefinition.form.city_works_webhook_config\n              .add_labor_to_cityworks_service_request.enabled\n          ",
                  },
                }),
              ],
              1
            ),
            _c("p", [
              _vm._v(
                " Select a form field to use to create a labor record on the service request. "
              ),
            ]),
            _c(
              "section",
              { staticClass: "py-2", staticStyle: { width: "70%" } },
              [
                _c("validation-provider", {
                  attrs: { name: "Employee", rules: "required" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ errors, valid }) {
                        return [
                          _c("v-select", {
                            attrs: {
                              autocomplete: "off",
                              label: "Employee *",
                              "hide-details": "auto",
                              "error-messages": errors,
                              success: valid,
                              color: "primary",
                              name: "employee",
                              items: _vm.singleSelectQuestionChoices,
                              "item-text": "label",
                              "item-value": "value",
                              disabled: !_vm.canManageForms,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$emit("input", _vm.formDefinition)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "append-outer",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            fab: "",
                                            small: "",
                                            icon: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showFormFieldDialog(
                                                _vm.LABOR_FORM_FIELD.EMPLOYEE
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "primary" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.mdiInformation) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value:
                                _vm.formDefinition.form
                                  .city_works_webhook_config
                                  .add_labor_to_cityworks_service_request
                                  .employeeFieldId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formDefinition.form
                                    .city_works_webhook_config
                                    .add_labor_to_cityworks_service_request,
                                  "employeeFieldId",
                                  $$v
                                )
                              },
                              expression:
                                "\n              formDefinition.form.city_works_webhook_config\n                .add_labor_to_cityworks_service_request.employeeFieldId\n            ",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _c("validation-provider", {
                  attrs: { name: "Hours", rules: "required" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ errors, valid }) {
                        return [
                          _c("v-select", {
                            attrs: {
                              autocomplete: "off",
                              label: "Hours *",
                              "hide-details": "auto",
                              "error-messages": errors,
                              success: valid,
                              color: "primary",
                              name: "hours",
                              items: _vm.numberQuestionChoices,
                              "item-text": "label",
                              "item-value": "value",
                              disabled: !_vm.canManageForms,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$emit("input", _vm.formDefinition)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "append-outer",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            fab: "",
                                            small: "",
                                            icon: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: { color: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showFormFieldDialog(
                                                    _vm.LABOR_FORM_FIELD.HOURS
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.mdiInformation) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value:
                                _vm.formDefinition.form
                                  .city_works_webhook_config
                                  .add_labor_to_cityworks_service_request
                                  .hoursFieldId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formDefinition.form
                                    .city_works_webhook_config
                                    .add_labor_to_cityworks_service_request,
                                  "hoursFieldId",
                                  $$v
                                )
                              },
                              expression:
                                "\n              formDefinition.form.city_works_webhook_config\n                .add_labor_to_cityworks_service_request.hoursFieldId\n            ",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _c("validation-provider", {
                  attrs: { name: "Time Type *", rules: "required" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ errors, valid }) {
                        return [
                          _c("v-select", {
                            attrs: {
                              autocomplete: "off",
                              label: "Time Type *",
                              "hide-details": "auto",
                              "error-messages": errors,
                              success: valid,
                              color: "primary",
                              name: "formField",
                              items: _vm.multiSelectQuestionChoices,
                              "item-text": "label",
                              "item-value": "value",
                              disabled: !_vm.canManageForms,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$emit("input", _vm.formDefinition)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "append-outer",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            fab: "",
                                            small: "",
                                            icon: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: { color: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showFormFieldDialog(
                                                    _vm.LABOR_FORM_FIELD
                                                      .TIME_TYPE
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.mdiInformation) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value:
                                _vm.formDefinition.form
                                  .city_works_webhook_config
                                  .add_labor_to_cityworks_service_request
                                  .timeTypeFieldId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formDefinition.form
                                    .city_works_webhook_config
                                    .add_labor_to_cityworks_service_request,
                                  "timeTypeFieldId",
                                  $$v
                                )
                              },
                              expression:
                                "\n              formDefinition.form.city_works_webhook_config\n                .add_labor_to_cityworks_service_request.timeTypeFieldId\n            ",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _c("validation-provider", {
                  attrs: { name: "Description" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ errors, valid }) {
                        return [
                          _c("v-select", {
                            attrs: {
                              autocomplete: "off",
                              label: "Description",
                              "hide-details": "auto",
                              "error-messages": errors,
                              success: valid,
                              color: "primary",
                              name: "description",
                              items: _vm.textQuestionChoices,
                              "item-text": "label",
                              "item-value": "value",
                              clearable: "",
                              disabled: !_vm.canManageForms,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$emit("input", _vm.formDefinition)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "append-outer",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            fab: "",
                                            small: "",
                                            icon: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: { color: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showFormFieldDialog(
                                                    _vm.LABOR_FORM_FIELD
                                                      .DESCRIPTION
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.mdiInformation) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value:
                                _vm.formDefinition.form
                                  .city_works_webhook_config
                                  .add_labor_to_cityworks_service_request
                                  .descriptionFieldId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formDefinition.form
                                    .city_works_webhook_config
                                    .add_labor_to_cityworks_service_request,
                                  "descriptionFieldId",
                                  $$v
                                )
                              },
                              expression:
                                "\n              formDefinition.form.city_works_webhook_config\n                .add_labor_to_cityworks_service_request.descriptionFieldId\n            ",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("CityworksLaborFormFieldInfoDialog", {
        attrs: {
          formFieldInfoDialogFieldName: _vm.formFieldInfoDialogFieldName,
          showCityworksLaborFormFieldInfoDialog:
            _vm.showCityworksLaborFormFieldInfoDialog,
        },
        on: {
          close: function ($event) {
            _vm.showCityworksLaborFormFieldInfoDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }