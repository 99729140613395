<template>
  <v-card class="elevation-0 mx-auto" max-width="600" color="#e5e5e5">
    <v-card-text class="pa-0 card-background">
      <div class="d-flex justify-end py-4">
        <v-btn
          @click="addRecurrenceSetting"
          color="primary"
          :disabled="!canManageForms"
        >
          + Recurrence Setting
        </v-btn>
      </div>
      <v-expansion-panels v-model="panel" tile>
        <v-expansion-panel
          v-for="(recurrenceSetting, index) in formDefinition.form
            .recurrenceSettings"
          :key="index"
        >
          <v-expansion-panel-header @keyup.space.prevent>
            <div class="d-flex align-center justify-space-between w-100">
              <validation-provider
                v-slot="{ errors }"
                name="Recurrence Label"
                rules="required"
                class="flex-grow-1 mr-2"
              >
                <v-text-field
                  label="Recurrence Label"
                  v-model="recurrenceSetting.label"
                  class="pb-0 pt-2 my-0"
                  hide-details="auto"
                  color="primary"
                  :error-messages="errors"
                  @change="$emit('input', formDefinition)"
                  @click.native.stop
                  :disabled="!canManageForms"
                ></v-text-field>
              </validation-provider>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!canManageForms"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    @click="deleteRecurrenceSetting(index)"
                    :disabled="!canManageForms"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" sm="6">
                    <p class="caption"><b>Configuration</b></p>

                    <section>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Schedule Form On Submission Completion"
                        :class="{
                          'drop-down': $vuetify.breakpoint.smAndUp,
                          'full-width': $vuetify.breakpoint.xsOnly,
                        }"
                      >
                        <v-select
                          autocomplete="off"
                          label="Schedule Form"
                          hide-details="auto"
                          :error-messages="errors"
                          color="primary"
                          name="scheduleForm"
                          :items="schedulerConditions"
                          item-text="label"
                          item-value="value"
                          :value="scheduleFormValue"
                          class="py-0 my-0"
                          @change="scheduleFormChange($event)"
                          :disabled="!canManageForms"
                        >
                        </v-select>
                      </validation-provider>
                    </section>
                  </v-col>
                </v-row>

                <section
                  class="d-flex align-center py-4"
                  v-if="scheduleFormValue === 'CONDITIONAL'"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Form Field"
                    rules="required"
                    class="flex-grow-0 flex-shrink-0"
                    style="width: 50%"
                  >
                    <v-select
                      autocomplete="off"
                      class="py-0 my-0"
                      label="Form Field"
                      hide-details="auto"
                      :error-messages="errors"
                      color="primary"
                      name="formField"
                      :items="questionChoices"
                      item-text="label"
                      item-value="value"
                      v-model="recurrenceSetting.createTask.dependantId"
                      @change="$emit('input', formDefinition)"
                      :disabled="!canManageForms"
                    >
                      <template v-slot:append-outer>
                        <v-tooltip bottom color="rgb(255, 130, 0)">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="rgb(255, 130, 0)"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              class="mr-2"
                            >
                              {{ mdiInformation }}
                            </v-icon>
                          </template>
                          <span
                            >Only single select or number fields are shown
                            here.</span
                          >
                        </v-tooltip>
                      </template>
                    </v-select>
                  </validation-provider>
                  <validation-provider
                    name="Condition"
                    rules="required"
                    v-slot="{ errors }"
                    class="flex-grow-0 flex-shrink-0 mx-2"
                    style="width: 20%"
                  >
                    <v-select
                      autocomplete="off"
                      class="py-0 my-0"
                      label="Condition"
                      hide-details="auto"
                      :error-messages="errors"
                      color="primary"
                      name="questionCondition"
                      :items="conditionOptions"
                      item-text="label"
                      item-value="value"
                      v-model="recurrenceSetting.createTask.condition"
                      @change="$emit('input', formDefinition)"
                      :disabled="!canManageForms"
                    >
                      <template #item="{ item: { label, value } }">
                        <div class="py-0 my-0">
                          <MatchIcon :value="value" />
                          {{ label }}
                        </div>
                      </template>
                      <template #selection="{ item: { value } }">
                        <div class="py-0 my-0">
                          <MatchIcon :value="value" />
                        </div>
                      </template>
                    </v-select>
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Value"
                    :rules="{
                      required: true,
                      numeric: questionType === 'NUMBER',
                    }"
                    class="flex-grow-0 flex-shrink-0"
                    style="width: 30%"
                  >
                    <v-text-field
                      v-if="questionType !== 'SINGLE_SELECT'"
                      class="py-0 my-0"
                      label="Value"
                      :error-messages="errors"
                      :type="questionType === 'NUMBER' ? 'number' : ''"
                      color="primary"
                      name="value"
                      item-text="label"
                      item-value="value"
                      hide-details="auto"
                      v-model="recurrenceSetting.createTask.value"
                      @input="$emit('input', formDefinition)"
                      :disabled="!canManageForms"
                    ></v-text-field>
                    <v-select
                      autocomplete="off"
                      v-else
                      class="py-0 my-0"
                      label="Value"
                      :error-messages="errors"
                      color="primary"
                      name="value"
                      item-text="label"
                      item-value="value"
                      v-model="recurrenceSetting.createTask.value"
                      :items="singleSelectOptions"
                      @change="$emit('input', formDefinition)"
                      :disabled="!canManageForms"
                    ></v-select>
                  </validation-provider>
                </section>
              </v-col>

              <v-col cols="12" v-if="scheduleFormValue !== 'NEVER'">
                <v-row>
                  <v-col cols="12" sm="6">
                    <p class="caption"><b>Schedule</b></p>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Form To Schedule"
                      rules="required"
                    >
                      <v-select
                        autocomplete="off"
                        v-model="recurrenceSetting.scheduledFormDefinitionId"
                        label="Form To Schedule"
                        hide-details="auto"
                        :error-messages="errors"
                        color="primary"
                        name="Schedule Form"
                        :items="computedScheduledFormDefinitions"
                        item-text="label"
                        item-value="value"
                        :loading="isLoadingFormDefinitions"
                        :disabled="isLoadingFormDefinitions || !canManageForms"
                        @change="$emit('input', formDefinition)"
                      >
                        <template v-slot:no-data>
                          <v-list-item v-if="isLoadingFormDefinitions">
                            <v-list-item-title>
                              Loading forms...
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item v-else>
                            <v-list-item-title>
                              No forms available
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-select>
                    </validation-provider>
                  </v-col>
                </v-row>
                <section class="d-flex justify-space-between my-2">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Schedule Form By"
                    :style="{ width: '48%' }"
                    rules="required"
                  >
                    <v-select
                      autocomplete="off"
                      label="Schedule Form By"
                      hide-details="auto"
                      :error-messages="errors"
                      color="primary"
                      name="Schedule Form By"
                      :items="scheduleFormByOptions"
                      item-text="label"
                      item-value="value"
                      v-model="recurrenceSetting.recurrenceDays.source"
                      @change="$emit('input', formDefinition)"
                      :disabled="!canManageForms"
                    >
                    </v-select>
                  </validation-provider>

                  <template
                    v-if="
                      recurrenceSetting.recurrenceDays.source === 'CONSTANT'
                    "
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Value"
                      :rules="{ required: true, numeric: true }"
                      :style="{ width: '48%' }"
                    >
                      <v-text-field
                        label="Number of Days"
                        :error-messages="errors"
                        type="number"
                        color="primary"
                        name="value"
                        v-model="recurrenceSetting.recurrenceDays.value"
                        @input="$emit('input', formDefinition)"
                        hide-details="auto"
                        :disabled="!canManageForms"
                      >
                      </v-text-field>
                    </validation-provider>
                  </template>

                  <template
                    v-else-if="
                      recurrenceSetting.recurrenceDays.source === 'FROM_FIELD'
                    "
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Form Field"
                      rules="required"
                      :style="{ width: '48%' }"
                    >
                      <v-select
                        autocomplete="off"
                        label="Form Field"
                        hide-details="auto"
                        :error-messages="errors"
                        color="primary"
                        name="Form Field"
                        :items="questionChoices"
                        item-text="label"
                        item-value="value"
                        v-model="recurrenceSetting.recurrenceDays.dependantId"
                        @change="$emit('input', formDefinition)"
                        :disabled="!canManageForms"
                      >
                      </v-select>
                    </validation-provider>
                  </template>

                  <template
                    v-else-if="
                      recurrenceSetting.recurrenceDays.source === 'DATE_FIELD'
                    "
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Form Field"
                      rules="required"
                      :style="{ width: '48%' }"
                    >
                      <v-select
                        autocomplete="off"
                        label="Form Field"
                        hide-details="auto"
                        :error-messages="errors"
                        color="primary"
                        name="Form Field"
                        :items="dateFieldChoices"
                        item-text="label"
                        item-value="value"
                        v-model="recurrenceSetting.recurrenceDays.dependantId"
                        @change="$emit('input', formDefinition)"
                        :disabled="!canManageForms"
                      >
                      </v-select>
                    </validation-provider>
                  </template>
                </section>
                <section
                  class="d-flex justify-space-between flex-wrap my-2"
                  style="gap: 10px"
                  v-if="
                    recurrenceSetting.recurrenceDays.source !== 'DATE_FIELD'
                  "
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Recurrence Days From"
                    rules="required"
                  >
                    <v-select
                      autocomplete="off"
                      label="Recurrence Days From"
                      hide-details="auto"
                      :error-messages="errors"
                      color="primary"
                      class="pb-2 mt-2"
                      name="Recurrence Days"
                      :items="recurrenceDaysFromOptions"
                      item-text="label"
                      item-value="value"
                      v-model="recurrenceSetting.recurrenceDaysFrom"
                      @change="$emit('input', formDefinition)"
                      :disabled="!canManageForms"
                    >
                    </v-select>
                  </validation-provider>
                </section>
              </v-col>

              <v-col cols="12" v-if="scheduleFormValue !== 'NEVER'">
                <v-row>
                  <v-col cols="12" sm="6">
                    <p class="caption"><b>Notification Template</b></p>
                    <v-select
                      autocomplete="off"
                      v-model="
                        recurrenceSetting.scheduledTaskNotificationTemplateId
                      "
                      label="Notification Template"
                      hide-details="auto"
                      color="primary"
                      name="taskNotificationTemplate"
                      :items="taskNotificationTemplates"
                      item-text="label"
                      item-value="value"
                      @input="$emit('input', formDefinition)"
                      :disabled="!canManageForms"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiInformation, mdiDotsVertical, mdiDelete } from "@mdi/js";
import { cloneDeep } from "lodash";
import authObjectMixin from "@/mixins/authObjectMixin";
import questionsMixin from "@/mixins/questionsMixin";
import MatchIcon from "@/components/forms/form-builder-edit-form/schedule-form-tab/MatchIcon";
import axios from "axios";
import permissionsMixin from "@/mixins/permissionsMixin";

const API_URL = process.env.VUE_APP_API_URL;

const schedulerConditions = [
  { value: "NEVER", label: "Never" },
  { value: "ALWAYS", label: "Always" },
  { value: "CONDITIONAL", label: "Conditional" },
];

const conditionOptions = [
  { value: "EQUAL", label: "Equal" },
  { value: "NOT_EQUAL", label: "Not Equal" },
  { value: "GREATER_THAN", label: "Greater Than" },
  { value: "LESS_THAN", label: "Less Than" },
  { value: "GREATER_THAN_OR_EQUAL", label: "Greater Than or Equal" },
  { value: "LESS_THAN_OR_EQUAL", label: "Less Than or Equal" },
];

const scheduleFormByOptions = [
  { value: "CONSTANT", label: "Days Out (Constant)" },
  { value: "FROM_FIELD", label: "Days Out (Form Field)" },
  { value: "DATE_FIELD", label: "Due Date" },
];

export default {
  name: "TaskSchedulerForm",
  props: {
    value: Object,
  },
  components: {
    MatchIcon,
  },
  mixins: [authObjectMixin, questionsMixin, permissionsMixin],
  data() {
    return {
      mdiInformation,
      mdiDotsVertical,
      mdiDelete,
      formDefinition: {},
      schedulerConditions,
      conditionOptions,
      taskNotificationTemplates: [],
      panel: 0,
      scheduleFormByOptions,
      scheduledFormDefinitions: [],
      isLoadingFormDefinitions: false,
    };
  },
  computed: {
    scheduleFormValue() {
      if (
        !["ALWAYS", "NEVER"].includes(
          this.formDefinition.form.recurrenceSettings[this.panel].createTask
            .condition
        )
      ) {
        return "CONDITIONAL";
      } else {
        return this.formDefinition.form.recurrenceSettings[this.panel]
          .createTask.condition;
      }
    },
    computedScheduledFormDefinitions() {
      return this.scheduledFormDefinitions;
    },
    // Task Scheduler Conditional Computed Properties
    questionChoices() {
      const { questions } = this;
      return questions
        .filter((question) =>
          ["NUMBER", "SINGLE_SELECT", "CALCULATION"].includes(
            question.question.type
          )
        )
        .map(({ id, question: { label } = {} }) => ({
          value: id,
          label: `${id}. ${label}`,
        }));
    },
    questionType() {
      if (this.panel) {
        const { questions } = this;
        const question = questions.find(
          ({ id }) =>
            +id ===
            +this.formDefinition.form.recurrenceSettings[this.panel].createTask
              .dependantId
        );
        if (question && question.question) {
          return question.question.type;
        }
      }
      return "";
    },
    singleSelectOptions() {
      if (this.panel) {
        const { questions } = this;
        const question = questions.find(
          ({ id }) =>
            +id ===
            +this.formDefinition.form.recurrenceSettings[this.panel].createTask
              .dependantId
        );
        if (question && question.question) {
          return question.question.options;
        }
      }
      return [];
    },
    // Schedule Section Computed Properties
    recurrenceDaysFromOptions() {
      return [
        { value: "previousDue", label: "Previous Due Date" },
        { value: "lastCompleted", label: "Last Completion Date" },
        ...this.dateFieldChoices,
      ];
    },
    dateFieldChoices() {
      const { questions } = this;
      return questions
        .filter((question) => question.question.type === "DATE")
        .map(({ id, question: { label } = {} }) => ({
          value: id,
          label: `${id}. ${label}`,
        }));
    },
  },
  async mounted() {
    await this.getTaskNotificationTemplates();
  },
  methods: {
    scheduleFormChange(value) {
      this.formDefinition.form.recurrenceSettings[
        this.panel
      ].createTask.condition = value;
      this.$emit("input", this.formDefinition);
    },
    addRecurrenceSetting() {
      this.formDefinition.form.recurrenceSettings.push({
        createTask: {
          condition: "ALWAYS",
          dependantId: null,
          value: null,
        },
        recurrenceDays: {
          dependantId: null,
          source: null,
          value: null,
        },
        recurrenceDaysFrom: null,
      });
    },
    deleteRecurrenceSetting(index) {
      this.panel = 0;
      this.formDefinition.form.recurrenceSettings.splice(index, 1);
      this.$emit("input", this.formDefinition);
    },
    // Task Scheduler Notification Template Methods
    async getTaskNotificationTemplates() {
      const {
        data: { results },
      } = await axios.get(`${API_URL}/task_notification_templates`, {});
      this.taskNotificationTemplates = results.map((t) => ({
        value: t.task_notification_template_id,
        label: t.task_notification_template_name,
      }));
    },
    // Schedule Section Methods
    async getScheduledFormDefinitions() {
      if (
        !this.formDefinition.map_service_id ||
        this.hasLoadedFormDefinitions
      ) {
        return;
      }

      this.isLoadingFormDefinitions = true;
      try {
        const {
          data: { results },
        } = await axios.get(
          `${API_URL}/map_services/${this.formDefinition.map_service_id}`
        );
        this.scheduledFormDefinitions = results.forms.map((form) => ({
          value: form.form_definition_id,
          label: form.title,
        }));
        this.hasLoadedFormDefinitions = true;
      } catch (error) {
        console.error("Error fetching scheduled form definitions:", error);
        this.scheduledFormDefinitions = [];
      } finally {
        this.isLoadingFormDefinitions = false;
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.formDefinition = cloneDeep(newVal);
        if (!this.formDefinition.form.recurrenceSettings) {
          this.formDefinition.form.recurrenceSettings = [];
          this.$emit("input", this.formDefinition);
        }

        if (!Array.isArray(this.formDefinition.form.recurrenceSettings)) {
          this.formDefinition.form.recurrenceSettings = [
            this.formDefinition.form.recurrenceSettings,
          ];
          this.$emit("input", this.formDefinition);
        }

        // Call getScheduledFormDefinitions here, it will only load once due to the check inside the method
        this.getScheduledFormDefinitions();
      },
    },
  },
};
</script>

<style>
.card-background {
  background-color: #f1f2f1;
}
</style>

<style scoped>
.v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0px;
  border-top: 1px solid #e0e0e0;
}
</style>
