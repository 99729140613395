var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showAddPageToFormDialog,
        fullscreen: "",
        persistent: "",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { color: "#4F4F4F" } },
        [
          _c(
            "v-toolbar",
            { staticClass: "elevation-0", attrs: { dark: "", color: "black" } },
            [
              _c("v-toolbar-title", [_vm._v("Select Page")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("add-page-to-form-dialog-close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              staticClass: "py-3",
              staticStyle: {
                height: "calc(100vh - 150px)",
                "overflow-y": "scroll",
              },
            },
            [
              _c("p", { staticClass: "white--text" }, [
                _vm._v("Add this page to the form?"),
              ]),
              _c("img", {
                staticStyle: { width: "100%" },
                attrs: { src: require("@/assets/page.png") },
              }),
            ]
          ),
          _c("v-card-actions", [
            _c(
              "div",
              {
                staticClass: "pa-3 d-flex justify-end align-center flex-grow-1",
              },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary", text: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("add-page-to-form-dialog-close")
                      },
                    },
                  },
                  [_vm._v(" Cancel ")]
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary", dark: "" },
                    on: { click: _vm.confirmAddPage },
                  },
                  [_vm._v(" Add Page ")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }