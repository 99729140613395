<template>
  <v-row>
    <v-col cols="12" class="mt-0 mb-0 pt-0 pb-0">
      <template v-if="['TEXT', 'EMAIL'].includes(item.question.type)">
        <validation-provider
          slim
          v-slot="{ errors, valid }"
          :name="item.question.label"
          :rules="{
            required: isRequired,
            email: item.question.type === 'EMAIL',
            max: Boolean(item.question.max_length)
              ? item.question.max_length
              : Infinity,
          }"
        >
          <template v-if="item.question.forInformationOnly">
            <label class="caption">{{ item.question.label }}</label>
            <p>{{ item.question.value }}</p>
          </template>
          <template v-else>
            <v-text-field
              v-model="textValue"
              :label="item.question.label"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              :name="item.id"
              :hint="item.question.placeholder"
              persistent-hint
              @input="onInput"
              :disabled="readOnly"
            >
            </v-text-field>
          </template>
        </validation-provider>
      </template>

      <template v-if="['NUMBER'].includes(item.question.type)">
        <validation-provider
          slim
          v-slot="{ errors, valid }"
          :name="item.question.label"
          :rules="{
            required: isRequired,
            max: Boolean(item.question.max_length)
              ? item.question.max_length
              : Infinity,
          }"
        >
          <v-text-field
            v-model.number="textValue"
            :label="item.question.label"
            hide-details="auto"
            :error-messages="errors"
            :success="valid"
            color="primary"
            :name="item.id"
            :hint="item.question.placeholder"
            persistent-hint
            @input="onInput"
            :disabled="readOnly"
          >
          </v-text-field>
        </validation-provider>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import { cloneDeep } from "lodash";
import dependantValueMixin from "@/mixins/dependantValueMixin";

export default {
  name: "GroupTextInput",
  props: {
    item: Object,
    value: [String, Number],
    readOnly: Boolean,
    formDefinition: Object,
    isRequired: Boolean,
  },
  mixins: [dependantValueMixin],
  data() {
    return {
      textValue: "",
      showEditor: false,
    };
  },
  beforeMount() {
    this.textValue = cloneDeep(this.value);
  },
  watch: {
    "item.question.isLong": {
      immediate: true,
      async handler() {
        this.showEditor = false;
        await this.$nextTick();
        this.showEditor = true;
      },
    },
  },
  methods: {
    onInput() {
      this.$emit("input", this.textValue);
    },
  },
};
</script>
