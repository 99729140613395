import { mergeAttributes, Node } from "@tiptap/core";

const Div = Node.create({
  name: "div",
  priority: 1000,
  group: "block",
  content: "block*",
  parseHTML() {
    return [{ tag: "div" }];
  },
  renderHTML({ HTMLAttributes }) {
    return [
      "div",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
  addAttributes() {
    return {
      style: {
        default: null,
      },
      class: {
        default: null,
      },
    };
  },
});

export default Div;
