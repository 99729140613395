<template>
  <div class="d-flex justify-space-between">
    <validation-observer ref="signatureForm">
      <form>
        <v-list
          v-if="
            selectedRepeatingGroupItemOptions &&
            selectedRepeatingGroupItemOptions.question
          "
        >
          <v-list-item class="pa-0 ma-0">
            <v-list-item-content>
              <validation-provider
                v-slot="{ errors, valid }"
                name="Label"
                rules="required"
              >
                <v-text-field
                  v-model="selectedRepeatingGroupItemOptions.question.label"
                  label="Label"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="name"
                  @change="onSubmit()"
                  :disabled="!canManageForms"
                />
              </validation-provider>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="pa-0 ma-0">
            <v-list-item-content>
              <validation-provider
                v-slot="{ errors, valid }"
                name="Required"
                rules="required"
              >
                <v-select
                  autocomplete="off"
                  v-model="
                    selectedRepeatingGroupItemOptions.question.required
                      .condition
                  "
                  :items="groupItemRequiredOptions"
                  label="Required"
                  :error-messages="errors"
                  :success="valid"
                  item-text="label"
                  item-value="value"
                  @change="onSubmit()"
                  hide-details
                  :disabled="!canManageForms"
                ></v-select>
              </validation-provider>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="pa-0 ma-0">
            <v-list-item-content>
              <v-switch
                v-model="selectedRepeatingGroupItemOptions.allowMultiple"
                label="Allow Multiple"
                @change="onSubmit()"
                class="my-0 py-0"
                :disabled="!canManageForms"
              ></v-switch>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import {
  mdiDragVertical,
  mdiDotsVertical,
  mdiDelete,
  mdiPencil,
} from "@mdi/js";
import { cloneDeep } from "lodash";
import { groupItemRequiredOptions } from "@/constants/choices";
import permissionsMixin from "@/mixins/permissionsMixin";

export default {
  name: "SignatureForm",
  mixins: [permissionsMixin],
  data() {
    return {
      mdiDragVertical,
      mdiDotsVertical,
      mdiDelete,
      mdiPencil,
      selectedRepeatingGroupItemOptions: {},
      groupItemRequiredOptions,
    };
  },
  props: { value: Object },
  beforeMount() {
    this.selectedRepeatingGroupItemOptions = cloneDeep(this.value);
    if (!this.selectedRepeatingGroupItemOptions?.question?.required) {
      this.selectedRepeatingGroupItemOptions.question.required = {
        condition: "NEVER",
      };
    }

    if (!this.selectedRepeatingGroupItemOptions?.question?.default) {
      this.selectedRepeatingGroupItemOptions.question.default = {};
    }
  },
  methods: {
    async onSubmit() {
      const success = await this.$refs.signatureForm.validate();
      if (!success) {
        return;
      }
      this.$emit("input", this.selectedRepeatingGroupItemOptions);
    },
  },
};
</script>
