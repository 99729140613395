var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "elevation-0 mx-auto", attrs: { "max-width": "600" } },
    [
      _c("v-card-text", { staticClass: "pa-0" }, [
        _vm.formDefinition.form.relatedTable
          ? _c("div", [
              _c(
                "div",
                { staticClass: "card-background py-4" },
                [
                  _c(
                    "v-card",
                    { staticClass: "elevation-2" },
                    [
                      !_vm.noTokenAndNotNoneTokenType
                        ? _c("v-card-text", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-space-between align-center",
                              },
                              [
                                _c("validation-provider", {
                                  attrs: { name: "ArcGIS Related Table" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors, valid }) {
                                          return [
                                            _c("v-select", {
                                              attrs: {
                                                label: "ArcGIS Related Table",
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                success: valid,
                                                color: "primary",
                                                name: "arcGisRelatedTable",
                                                items: _vm.arcGisRelatedTables,
                                                disabled:
                                                  _vm.disableTableDropdown ||
                                                  !_vm.canManageForms,
                                                "item-text": "label",
                                                "item-value": "value",
                                              },
                                              on: { change: _vm.onChange },
                                              model: {
                                                value: _vm.relationshipId,
                                                callback: function ($$v) {
                                                  _vm.relationshipId = $$v
                                                },
                                                expression: "relationshipId",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1900218055
                                  ),
                                }),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      text: "",
                                      width: "30px",
                                      "min-width": "30px",
                                      height: "30px",
                                      "min-height": "30px",
                                      disabled: !_vm.canManageForms,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goToSelectedRelatedTable()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "#1976d2" } },
                                      [_vm._v(_vm._s(_vm.mdiOpenInNew))]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ])
                        : _c(
                            "v-card-text",
                            {
                              staticClass: "d-flex justify-center",
                              staticStyle: {
                                "background-color": "rgb(255, 130, 0)",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "white--text font-weight-medium",
                                },
                                [
                                  _vm._v(
                                    " Sign in with an ArcGIS user to view Related Table name. "
                                  ),
                                ]
                              ),
                            ]
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "card-background" },
                [
                  _c(
                    "v-card",
                    { staticClass: "elevation-2" },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("FeatureServiceFieldsTable", {
                            attrs: {
                              fields: _vm.computedFields,
                              questionChoices: _vm.questionChoices,
                              noTokenAndNotNoneTokenType:
                                _vm.noTokenAndNotNoneTokenType,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$emit("input", _vm.formDefinition)
                              },
                            },
                            model: {
                              value: _vm.formDefinition,
                              callback: function ($$v) {
                                _vm.formDefinition = $$v
                              },
                              expression: "formDefinition",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }