<template>
  <v-dialog
    :value="showSelectDocDialog"
    max-width="400px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar dark color="primary" class="elevation-0">
        <v-toolbar-title>Select Doc</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('select-doc-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="py-3">
        <p class="caption py-0 my-0">Folder</p>

        <v-btn
          fab
          x-small
          class="pa-0 ma-0"
          color="#4F4F4F"
          width="20px"
          height="20px"
        >
          <v-icon color="white" size="18px">
            {{ mdiHome }}
          </v-icon>
        </v-btn>

        <v-divider class="my-2"></v-divider>

        <div>
          <div class="d-flex gap align-center py-1">
            <div>
              <v-icon color="primary">
                {{ mdiFolder }}
              </v-icon>
            </div>
            <div :style="{ color: 'primary' }">Attachments</div>
          </div>
          <div class="d-flex gap align-center py-1">
            <div>
              <v-icon color="primary">
                {{ mdiFolder }}
              </v-icon>
            </div>
            <div :style="{ color: 'primary' }">Site Photos</div>
          </div>
          <div
            class="d-flex gap align-center py-1 cursor-pointer"
            @click="onFileClick({ name: 'floorplan.pdf' })"
          >
            <div>
              <v-icon color="#4F4F4F">
                {{ mdiFile }}
              </v-icon>
            </div>
            <div :style="{ color: 'primary' }">Floorplan.pdf</div>
          </div>
          <div
            class="d-flex gap align-center py-1 cursor-pointer"
            @click="onFileClick({ name: 'brokenfence.png' })"
          >
            <div>
              <img src="@/assets/brokenfence.png" class="thumbnail" />
            </div>
            <div :style="{ color: 'primary' }">brokenfence.jpg</div>
          </div>
        </div>

        <SelectPdfPageDialog
          v-if="showSelectPdfPageDialog"
          :showSelectPdfPageDialog="showSelectPdfPageDialog"
          :selectedFile="selectedFile"
          :maxWidthHeight="maxWidthHeight"
          :formResultId="formResultId"
          @select-pdf-page-dialog-close="showSelectPdfPageDialog = false"
          @confirm-add-page="onConfirmAddPage"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiHome, mdiFolder, mdiFile } from "@mdi/js";
import SelectPdfPageDialog from "@/components/forms/form-builder-edit-form/item-preview/photo-input/add-image-dialog/select-doc-dialog/SelectPdfPageDialog";
import { v4 as uuidv4 } from "uuid";
export default {
  name: "SelectDocDialog",
  props: {
    showSelectDocDialog: Boolean,
    formDefinition: Object,
    selectedGisInfo: Object,
    maxWidthHeight: Number,
    formResultId: String,
  },
  data() {
    return {
      mdiHome,
      mdiFolder,
      mdiFile,
      showSelectPdfPageDialog: false,
      selectedPage: {},
    };
  },
  components: {
    SelectPdfPageDialog,
  },
  methods: {
    onConfirmAddPage(file) {
      this.$emit("image-file-selected", file);
      this.showSelectPdfPageDialog = false;
    },
    onFileClick(file) {
      if (
        file.name.endsWith("jpeg") ||
        file.name.endsWith("png") ||
        file.name.endsWith("jpg")
      ) {
        const image = {
          dataUrl: require("@/assets/paint-road.png"),
          url: require("@/assets/paint-road.png"),
          description: "",
          id: uuidv4(),
          ...file,
          uploaded: true,
          isImage: true,
        };
        this.$emit("image-file-selected", image);
      } else if (file.name.endsWith("pdf")) {
        this.showSelectPdfPageDialog = true;
      }
      this.selectedFile = file;
    },
  },
};
</script>

<style scoped>
.gap {
  gap: 5px;
}
</style>
