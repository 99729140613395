import { mergeAttributes, Node } from "@tiptap/core";

const ResizableImage = Node.create({
  name: "ResizableImage",
  addOptions() {
    return {
      inline: false,
      allowBase64: false,
      HTMLAttributes: {},
    };
  },

  inline() {
    return this.options.inline;
  },

  group() {
    return this.options.inline ? "inline" : "block";
  },

  draggable: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      alt: {
        default: null,
      },
      title: {
        default: null,
      },
      width: {
        default: 0,
        parseHTML: (element) => element.getAttribute("data-width"),
        renderHTML: (attributes) => {
          const width = `${attributes.width}px`;
          return {
            "data-width": attributes.width,
            style: `width: ${attributes.width === "auto" ? "auto" : width}`,
          };
        },
      },
      height: {
        default: "auto",
        parseHTML: (element) => element.getAttribute("data-height"),
        renderHTML: (attributes) => {
          const height = `${attributes.height}px`;
          return {
            "data-height": attributes.height,
            style: `height: ${attributes.height === "auto" ? "auto" : height}`,
          };
        },
      },
      class: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: this.options.allowBase64
          ? "img[src]:not([data-param-src]):not([data-signature-src]):not([data-missing-param-src]):not([data-missing-signature-src])"
          : 'img[src]:not([src^="data:"]):not([data-param-src]):not([data-signature-src]):not([data-missing-param-src]):not([data-missing-signature-src])',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "img",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
    ];
  },

  addCommands() {
    return {
      setResizableImage:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
    };
  },
});

export default ResizableImage;
