<template>
  <v-edit-dialog
    save-text="Save"
    cancel-text="Cancel"
    large
    persistent
    @cancel="onCancel"
    @close="onCancel"
    @save="onSave"
  >
    <slot></slot>

    <template v-slot:input>
      <div>
        <validation-observer ref="createConditionForm">
          <validation-provider
            v-slot="{ errors, valid }"
            name="Create"
            rules="required"
          >
            <v-select
              autocomplete="off"
              v-model="createCondition"
              :items="createOptions"
              label="Create"
              :error-messages="errors"
              :success="valid"
              item-text="label"
              item-value="value"
              hide-details
            >
            </v-select>
          </validation-provider>

          <template v-if="createCondition === 'conditional'">
            <validation-provider v-slot="{ errors, valid }" name="Dependent">
              <v-select
                autocomplete="off"
                v-model="createConditionOptions.dependent"
                :items="dependentValueOptions"
                label="Dependent"
                :error-messages="errors"
                :success="valid"
                item-text="label"
                item-value="value"
                hide-details
              ></v-select>
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="User Field"
              rules="required"
              v-if="['USER'].includes(createConditionOptions.dependent)"
            >
              <v-select
                autocomplete="off"
                v-model="createConditionOptions.dependentFieldId"
                :items="userDataChoices"
                label="User Field"
                :error-messages="errors"
                :success="valid"
                item-text="label"
                item-value="value"
                hide-details
              ></v-select>
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="UtiliSync Field"
              rules="required"
              v-else-if="
                ['GIS_DATA'].includes(createConditionOptions.dependent)
              "
            >
              <v-select
                autocomplete="off"
                v-model="createConditionOptions.dependentFieldId"
                :items="gisDataChoices"
                label="UtiliSync Field"
                :error-messages="errors"
                :success="valid"
                item-text="label"
                item-value="value"
                hide-details
              ></v-select>
            </validation-provider>

            <validation-provider
              :key="createConditionOptions.dependent"
              v-slot="{ errors, valid }"
              name="GIS Field"
              rules="required"
              v-else-if="['GIS'].includes(createConditionOptions.dependent)"
            >
              <v-autocomplete
                autocomplete="off"
                v-model="createConditionOptions.dependentFieldId"
                :items="fieldChoices"
                name="gisField"
                label="GIS Field"
                :error-messages="errors"
                :success="valid"
                item-text="label"
                item-value="value"
                hide-details
              ></v-autocomplete>
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Value"
              rules="required"
            >
              <v-select
                autocomplete="off"
                v-if="gisDataFieldOptionChoices.length > 0"
                v-model="createConditionOptions.value"
                :items="gisDataFieldOptionChoices"
                label="Value"
                :error-messages="errors"
                :success="valid"
                item-text="label"
                item-value="value"
                hide-details
              ></v-select>
              <v-text-field
                v-else
                v-model="createConditionOptions.value"
                label="Value"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="value"
              />
            </validation-provider>
          </template>
        </validation-observer>
      </div>
    </template>
  </v-edit-dialog>
</template>

<script>
import { cloneDeep } from "lodash";
import axios from "axios";
import MATCH_CHOICE_VALUES from "@/constants/matchChoiceValues";
import { CONDITION_CHOICES } from "@/constants/advancedSettings";
import { createOptions } from "@/constants/choices";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "CreateConditionDialog",
  props: {
    value: Array,
    formDefinition: Object,
  },
  computed: {
    fieldChoices() {
      const fieldChoices = this.fields?.map(({ name, alias }) => ({
        value: name,
        label: alias || name,
      }));
      return fieldChoices ?? [];
    },
    userDataChoices() {
      const apiChoices = this.userDataFields?.map(
        ({ user_data_field_id: value, name: label }) => ({
          value,
          label,
        })
      );
      return [
        { value: "f_name", label: "First Name" },
        { value: "l_name", label: "Last Name" },
        { value: "full_name", label: "Full Name" },
        { value: "email", label: "Email" },
        ...apiChoices,
      ];
    },
    gisDataChoices() {
      return this.gisDataFields?.map(
        ({ gis_data_field_id: value, name, alias }) => ({
          value,
          label: alias || name,
        })
      );
    },
    dependentValueOptions() {
      return this.defaultValueTypes;
    },
    gisDataFieldOptionChoices() {
      if (this.createConditionOptions.dependent === "GIS_DATA") {
        return (
          this.gisDataFields
            ?.find(
              (g) =>
                g.gis_data_field_id ===
                this.createConditionOptions.dependentFieldId
            )
            ?.gis_data_field_options?.map(({ label, value }) => {
              return {
                label,
                value,
              };
            }) ?? []
        );
      }
      return [];
    },
  },
  data() {
    return {
      gisDataFields: [],
      userDataFields: [],
      defaultValueTypes: [
        {
          value: "USER",
          label: "User Data",
        },
        {
          value: "GIS",
          label: "GIS Field",
        },
        {
          value: "GIS_DATA",
          label: "UtiliSync Field",
        },
      ],
      createConditionOptions: {
        condition: MATCH_CHOICE_VALUES.EQUAL,
      },
      fields: [],
      layer: {},
      createOptions,
      createCondition: "ALWAYS",
    };
  },
  methods: {
    onCancel() {
      this.$emit("input", cloneDeep(this.value));
    },
    async onSave() {
      const success = await this.$refs?.createConditionForm?.validate();
      if (!success) {
        return;
      }

      const newValue = cloneDeep(this.value).map((v) => {
        const { question, information } = v;
        if (question) {
          question.create = {
            ...this.createConditionOptions,
          };
          return {
            ...v,
            question,
          };
        } else if (information) {
          information.create = {
            ...this.createConditionOptions,
          };
          return {
            ...v,
            information,
          };
        }
        return {
          ...v,
        };
      });
      this.$emit("input", newValue);
    },
    async getUserDataFields() {
      const { user_group_id: userGroupId } = JSON.parse(
        localStorage.getItem("auth")
      );

      const {
        data: { results },
      } = await axios.get(`${APIURL}/user_data_fields`, {
        params: {
          user_group_id: userGroupId,
        },
      });
      this.userDataFields = results;
    },
    async getGisDataFields() {
      if (this.formDefinition.map_service_id) {
        const {
          data: { results },
        } = await axios.get(`${APIURL}/gis_data_fields`, {
          params: { map_service_id: this.formDefinition.map_service_id },
        });
        this.gisDataFields = results;
      }
    },
    async getFeatureService() {
      try {
        const { map_service_id: mapServiceId } = this.formDefinition;
        const {
          data: {
            results: { service_url: featureServiceUrl, token_type: tokenType },
          },
        } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
        const token =
          tokenType === "AGOL" ? localStorage.getItem("esri_token") : undefined;
        const params = {
          f: "pjson",
          token,
        };
        const {
          data: { fields },
        } = await axios.get(featureServiceUrl, { params });
        this.fields = fields;
      } catch (error) {
        this.fields = [];
      }
    },
    async getLayer() {
      const { map_service_id: mapServiceId } = this.formDefinition;
      const {
        data: { results },
      } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
      this.layer = results;
    },
  },
  mounted() {
    this.getLayer();
    this.getUserDataFields();
    this.getFeatureService();
    this.getGisDataFields();
  },
  beforeMount() {
    const [item] = cloneDeep(this.value);

    this.createConditionOptions =
      item?.question?.create ?? item?.information?.create ?? {};
    if (item?.question) {
      if (
        item?.question?.create &&
        !["ALWAYS"].includes(item?.question?.create?.condition)
      ) {
        this.createCondition = "conditional";
      } else {
        this.createCondition = item?.question?.create?.condition;
      }
    } else if (item?.information) {
      if (
        item?.information?.create &&
        !["ALWAYS"].includes(item?.information?.create?.condition)
      ) {
        this.createCondition = "conditional";
      } else {
        this.createCondition = item?.information?.create?.condition;
      }
    }
  },
  watch: {
    "createConditionOptions.dependentFieldId"(val) {
      if (val) {
        this.createConditionOptions.condition = "EQUAL";
      }
    },
    createCondition(val) {
      if (val === CONDITION_CHOICES.ALWAYS) {
        this.createConditionOptions = {
          condition: CONDITION_CHOICES.ALWAYS,
        };
      }
    },
  },
};
</script>
