var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("validation-observer", { ref: "form" }, [
    _c(
      "form",
      [
        _vm.selectedControlType === "SECTION"
          ? _c("SectionForm", {
              attrs: {
                selectedItem: _vm.selectedItem,
                selectedControlType: _vm.selectedControlType,
                formDefinition: _vm.value,
              },
              on: {
                input: _vm.onInputChange,
                "form-control-form-submitted": function ($event) {
                  return _vm.$emit("form-control-form-submitted")
                },
              },
            })
          : _vm._e(),
        _vm.selectedItem &&
        _vm.selectedItem.type === "INFORMATION" &&
        ["HEADER", "URL", "TEXT", "IMAGE"].includes(_vm.selectedControlType)
          ? _c("InformationForm", {
              attrs: {
                formDefinition: _vm.value,
                selectedItem: _vm.selectedItem,
                selectedControlType: _vm.selectedControlType,
              },
              on: {
                input: _vm.onInputChange,
                "form-control-form-submitted": function ($event) {
                  return _vm.$emit("form-control-form-submitted")
                },
              },
            })
          : _vm._e(),
        _vm.selectedItem &&
        _vm.selectedItem.type === "QUESTION" &&
        ["TEXT", "EMAIL", "NUMBER", "SIGNATURE"].includes(
          _vm.selectedControlType
        )
          ? _c("TextInputForm", {
              attrs: {
                formDefinition: _vm.value,
                selectedItem: _vm.selectedItem,
                edit: _vm.edit,
                selectedControlType: _vm.selectedControlType,
              },
              on: {
                input: _vm.onInputChange,
                "form-control-form-submitted": function ($event) {
                  return _vm.$emit("form-control-form-submitted")
                },
              },
            })
          : _vm._e(),
        ["SINGLE_SELECT", "MULTI_SELECT"].includes(_vm.selectedControlType)
          ? _c("SelectForm", {
              attrs: {
                formDefinition: _vm.value,
                selectedItem: _vm.selectedItem,
                edit: _vm.edit,
                selectedControlType: _vm.selectedControlType,
                multiSelect: "MULTI_SELECT" === _vm.selectedControlType,
              },
              on: {
                input: _vm.onInputChange,
                "form-control-form-submitted": function ($event) {
                  return _vm.$emit("form-control-form-submitted")
                },
              },
            })
          : _vm._e(),
        ["CALCULATION"].includes(_vm.selectedControlType)
          ? _c("CalculationForm", {
              attrs: {
                formDefinition: _vm.value,
                selectedItem: _vm.selectedItem,
                selectedControlType: _vm.selectedControlType,
              },
              on: {
                input: _vm.onInputChange,
                "form-control-form-submitted": function ($event) {
                  return _vm.$emit("form-control-form-submitted")
                },
              },
            })
          : _vm._e(),
        ["FILE"].includes(_vm.selectedControlType)
          ? _c("PhotoFileForm", {
              attrs: {
                formDefinition: _vm.value,
                selectedItem: _vm.selectedItem,
                selectedControlType: _vm.selectedControlType,
              },
              on: {
                input: _vm.onInputChange,
                "form-control-form-submitted": function ($event) {
                  return _vm.$emit("form-control-form-submitted")
                },
              },
            })
          : _vm._e(),
        ["DATE"].includes(_vm.selectedControlType)
          ? _c("DateForm", {
              attrs: {
                formDefinition: _vm.value,
                selectedItem: _vm.selectedItem,
                edit: _vm.edit,
                selectedControlType: _vm.selectedControlType,
              },
              on: {
                input: _vm.onInputChange,
                "form-control-form-submitted": function ($event) {
                  return _vm.$emit("form-control-form-submitted")
                },
              },
            })
          : _vm._e(),
        ["TIME"].includes(_vm.selectedControlType)
          ? _c("TimeForm", {
              attrs: {
                formDefinition: _vm.value,
                selectedItem: _vm.selectedItem,
                edit: _vm.edit,
                selectedControlType: _vm.selectedControlType,
              },
              on: {
                input: _vm.onInputChange,
                "form-control-form-submitted": function ($event) {
                  return _vm.$emit("form-control-form-submitted")
                },
              },
            })
          : _vm._e(),
        ["GROUP"].includes(_vm.selectedControlType)
          ? _c("RepeatingGroupForm", {
              attrs: {
                formDefinition: _vm.value,
                selectedItem: _vm.selectedItem,
                edit: _vm.edit,
                selectedControlType: _vm.selectedControlType,
              },
              on: {
                input: _vm.onInputChange,
                "form-control-form-submitted": function ($event) {
                  return _vm.$emit("form-control-form-submitted")
                },
              },
            })
          : _vm._e(),
        ["ACTION_ITEM"].includes(_vm.selectedControlType)
          ? _c("ActionItemForm", {
              attrs: {
                formDefinition: _vm.value,
                selectedItem: _vm.selectedItem,
                edit: _vm.edit,
                selectedControlType: _vm.selectedControlType,
              },
              on: {
                input: _vm.onInputChange,
                "form-control-form-submitted": function ($event) {
                  return _vm.$emit("form-control-form-submitted")
                },
              },
            })
          : _vm._e(),
        ["POSITIVE_RESPONSE"].includes(_vm.selectedControlType)
          ? _c("PositiveResponseForm", {
              attrs: {
                formDefinition: _vm.value,
                selectedItem: _vm.selectedItem,
                edit: _vm.edit,
                selectedControlType: _vm.selectedControlType,
              },
              on: {
                input: _vm.onInputChange,
                "form-control-form-submitted": function ($event) {
                  return _vm.$emit("form-control-form-submitted")
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }