import TableCell from "@tiptap/extension-table-cell";

const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      display: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.display) {
            return {};
          }

          return {
            style: `display: ${attributes.display}`,
          };
        },
        parseHTML: (element) => {
          return element.style.display.replace(/['"]+/g, "");
        },
      },
      justifyContent: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.justifyContent) {
            return {};
          }

          return {
            style: `justify-content: ${attributes.justifyContent}`,
          };
        },
        parseHTML: (element) => {
          return element.style.justifyContent.replace(/['"]+/g, "");
        },
      },
      alignItems: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.alignItems) {
            return {};
          }

          return {
            style: `align-items: ${attributes.alignItems}`,
          };
        },
        parseHTML: (element) => {
          return element.style.alignItems.replace(/['"]+/g, "");
        },
      },
      backgroundColor: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.backgroundColor) {
            return {};
          }

          return {
            style: `background-color: ${attributes.backgroundColor}`,
          };
        },
        parseHTML: (element) => {
          return element.style.backgroundColor.replace(/['"]+/g, "");
        },
      },
      padding: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.padding) {
            return {};
          }

          return {
            style: `padding: ${attributes.padding}`,
          };
        },
        parseHTML: (element) => {
          return element.style.padding.replace(/['"]+/g, "");
        },
      },
      borderWidth: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.borderWidth) {
            return {};
          }

          return {
            style: `border-width: ${attributes.borderWidth}`,
          };
        },
        parseHTML: (element) => {
          return element.style.borderWidth.replace(/['"]+/g, "");
        },
      },
      borderLeft: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.borderLeft) {
            return {};
          }

          return {
            style: `border-left: ${attributes.borderLeft}`,
          };
        },
        parseHTML: (element) => {
          return element.style.borderLeft.replace(/['"]+/g, "");
        },
      },
      borderRight: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.borderRight) {
            return {};
          }

          return {
            style: `border-right: ${attributes.borderRight}`,
          };
        },
        parseHTML: (element) => {
          return element.style.borderRight.replace(/['"]+/g, "");
        },
      },
      borderTop: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.borderTop) {
            return {};
          }

          return {
            style: `border-top: ${attributes.borderTop}`,
          };
        },
        parseHTML: (element) => {
          return element.style.borderTop.replace(/['"]+/g, "");
        },
      },
      borderBottom: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.borderBottom) {
            return {};
          }

          return {
            style: `border-bottom: ${attributes.borderBottom}`,
          };
        },
        parseHTML: (element) => {
          return element.style.borderBottom.replace(/['"]+/g, "");
        },
      },
      borderStyle: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.borderStyle) {
            return {};
          }

          return {
            style: `border-style: ${attributes.borderStyle}`,
          };
        },
        parseHTML: (element) => {
          return element.style.borderStyle.replace(/['"]+/g, "");
        },
      },
      verticalAlign: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.verticalAlign) {
            return {};
          }

          return {
            style: `vertical-align: ${attributes.verticalAlign}`,
          };
        },
        parseHTML: (element) => {
          return element.style.verticalAlign.replace(/['"]+/g, "");
        },
      },
      colwidth: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.colwidth) {
            return {};
          }

          let totalWidth = 0;
          let fixedWidth = false;
          if (Array.isArray(attributes.colwidth)) {
            fixedWidth = true;
            totalWidth = attributes.colwidth.reduce((a, b) => a + b, 0);
          }

          if (fixedWidth && totalWidth > 0) {
            return {
              style: `width: ${totalWidth}px`,
              colwidth: totalWidth,
            };
          } else {
            return {
              style: null,
              colwidth: null,
            };
          }
        },
        parseHTML: (element) => {
          const colwidth = element.getAttribute("colwidth");
          const value = colwidth
            ? colwidth.split(",").map((item) => parseInt(item, 10))
            : null;

          return value;
        },
      },
      class: {
        default: null,
      },
    };
  },
});

export default CustomTableCell;
