var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      ["URL"].includes(_vm.item.information.type)
        ? _c("v-card", { staticClass: "elevation-0" }, [
            _vm.readOnly
              ? _c("p", [_vm._v(_vm._s(_vm.item.information.label))])
              : _c(
                  "a",
                  {
                    attrs: { href: _vm.item.information.url, target: "_blank" },
                  },
                  [_vm._v(" " + _vm._s(_vm.item.information.label) + " ")]
                ),
          ])
        : _vm._e(),
      ["HEADER"].includes(_vm.item.information.type)
        ? _c("v-card", { staticClass: "elevation-0" }, [
            _c("h2", [_vm._v(" " + _vm._s(_vm.item.information.label) + " ")]),
          ])
        : _vm._e(),
      ["TEXT"].includes(_vm.item.information.type)
        ? _c(
            "v-card",
            { staticClass: "elevation-0" },
            [
              _c("v-card-text", { staticClass: "pa-0 ma-0" }, [
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.item.information.label) },
                }),
              ]),
            ],
            1
          )
        : _vm._e(),
      ["IMAGE"].includes(_vm.item.information.type)
        ? _c("v-card", { staticClass: "elevation-0" }, [
            _vm.readOnly
              ? _c("div", [
                  _c("p", [_vm._v(_vm._s(_vm.item.information.label))]),
                  _c("img", {
                    style: { height: "auto", "max-width": "500px" },
                    attrs: { src: _vm.item.information.url },
                  }),
                ])
              : _c("div", [
                  _c("p", [_vm._v(_vm._s(_vm.item.information.label))]),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.item.information.openUrl,
                        target: "_blank",
                      },
                    },
                    [
                      _c("img", {
                        style: { height: "auto", "max-width": "500px" },
                        attrs: { src: _vm.item.information.url },
                      }),
                    ]
                  ),
                ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }