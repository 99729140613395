<template>
  <div>
    <div class="d-flex justify-space-between align-center">
      <div class="caption">{{ label }}</div>
      <v-btn
        text
        color="primary"
        @click="showAddImageDialog = true"
        :disabled="readOnly"
      >
        Add
      </v-btn>
    </div>

    <v-simple-table>
      <thead>
        <tr>
          <th>Image</th>
          <th>Description</th>
          <th></th>
        </tr>
      </thead>

      <tbody v-if="base64Images.length > 0">
        <tr v-for="(image, index) of base64Images" :key="image.id">
          <td>
            <img :src="image.url" class="thumbnail" />
          </td>
          <td>
            <v-text-field
              label="Description"
              color="primary"
              v-model="image.description"
              @change="$emit('input', base64Images)"
            >
            </v-text-field>
          </td>
          <td>
            <div class="d-flex justify-end align-center">
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>{{ mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="removePhoto(index, image.id)">
                    <v-list-item-title>
                      <v-icon>{{ mdiDelete }}</v-icon>
                      Delete
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="3">
            <div class="d-flex justify-center">No Images</div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <AddImageDialog
      @add-photo-dialog-close="
        showAddImageDialog = false;
        $emit('input', base64Images);
      "
      :showAddImageDialog="showAddImageDialog"
      :maxWidthHeight="maxWidthHeight"
      :readOnly="readOnly"
      :allowMultiple="allowMultiple"
      v-model="base64Images"
    />
  </div>
</template>

<script>
import AddImageDialog from "@/components/forms/form-builder-edit-form/item-preview/photo-input/AddImageDialog";
import { cloneDeep } from "lodash";
import { mdiDotsVertical, mdiDelete } from "@mdi/js";

export default {
  name: "PhotoInput",
  props: {
    label: String,
    allowMultiple: Boolean,
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    formResultId: String,
    maxWidthHeight: String,
    showDescription: Boolean,
    readOnly: Boolean,
  },
  components: {
    AddImageDialog,
  },
  data() {
    return {
      mdiDotsVertical,
      mdiDelete,
      base64Images: [],
      showAddImageDialog: false,
    };
  },
  methods: {
    async removePhoto(index) {
      this.base64Images.splice(index, 1);
      this.$emit("input", this.base64Images);
    },
  },
  beforeMount() {
    this.base64Images = cloneDeep(this.value);
  },
};
</script>

<style scoped>
.thumbnail {
  width: 70px;
}
</style>
