<template>
  <v-dialog
    :value="showSelectPdfPageDialog"
    max-width="200px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card color="#4F4F4F">
      <v-toolbar dark color="black" class="elevation-0">
        <v-toolbar-title>Select Page</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('select-pdf-page-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="py-3">
        <div class="d-flex align-center justify-center" v-if="isLoading">
          <v-progress-circular indeterminate color="white">
          </v-progress-circular>
        </div>
        <div v-else>
          <div class="d-flex justify-center align-center">
            <validation-observer ref="form" slim>
              <form @submit.prevent>
                <validation-provider name="Page" rules="numeric">
                  <v-text-field
                    v-model.number="page"
                    style="width: 60px"
                    class="page-input py-0 my-0"
                    dark
                    hide-details
                  >
                    <template #append-outer>
                      <div style="margin-top: 6px; width: 30px">
                        of {{ pdfPageImages.length }}
                      </div>
                    </template>
                  </v-text-field>
                </validation-provider>
              </form>
            </validation-observer>
          </div>

          <div class="d-flex flex-wrap justify-center">
            <img
              :style="{
                outline: index + 1 === page ? '6px solid #8AB4F8' : 'none',
                opacity: index + 1 !== page ? '0.2' : 1,
              }"
              v-for="(p, index) of pdfPageImages"
              :key="p.file_id"
              src="@/assets/page.png"
              style="width: 80px"
              class="my-5 cursor-pointer elevation-5"
              @click="onPageClick"
            />
          </div>
        </div>

        <AddPageToFormDialog
          v-if="showAddPageToFormDialog"
          :showAddPageToFormDialog="showAddPageToFormDialog"
          :selectedPage="selectedPage"
          :maxWidthHeight="maxWidthHeight"
          :formResultId="formResultId"
          @add-page-to-form-dialog-close="showAddPageToFormDialog = false"
          @confirm-add-page="
            $emit('confirm-add-page', $event);
            showAddPageToFormDialog = false;
          "
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiChevronDown } from "@mdi/js";
import AddPageToFormDialog from "@/components/forms/form-builder-edit-form/item-preview/photo-input/add-image-dialog/select-doc-dialog/select-pdf-page-dialog/AddPageToFormDialog";

export default {
  name: "SelectPdfPageDialog",
  props: {
    showSelectPdfPageDialog: Boolean,
    selectedFile: Object,
    formResultId: String,
    maxWidthHeight: Number,
  },
  components: {
    AddPageToFormDialog,
  },
  data() {
    return {
      mdiClose,
      mdiChevronDown,
      pdfPageImages: Array(3)
        .fill()
        .map((_, i) => {
          return {
            thumbnail: "@/assets/page.png",
            dataUrl: require("@/assets/page.png"),
            file_id: i + 1,
          };
        }),
      page: 1,
      isLoading: false,
      showAddPageToFormDialog: false,
      selectedPage: {},
    };
  },
  methods: {
    async onPageClick() {
      if (this.pdfPageImages[this.page - 1]) {
        this.selectedPage = this.pdfPageImages[this.page - 1];
        this.showAddPageToFormDialog = true;
      }
    },
  },
};
</script>
