var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "elevation-0 mx-auto",
      attrs: { "max-width": "600", color: "#e5e5e5" },
    },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-0 card-background" },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-end py-4" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", disabled: !_vm.canManageForms },
                  on: { click: _vm.addRecurrenceSetting },
                },
                [_vm._v(" + Recurrence Setting ")]
              ),
            ],
            1
          ),
          _c(
            "v-expansion-panels",
            {
              attrs: { tile: "" },
              model: {
                value: _vm.panel,
                callback: function ($$v) {
                  _vm.panel = $$v
                },
                expression: "panel",
              },
            },
            _vm._l(
              _vm.formDefinition.form.recurrenceSettings,
              function (recurrenceSetting, index) {
                return _c(
                  "v-expansion-panel",
                  { key: index },
                  [
                    _c(
                      "v-expansion-panel-header",
                      {
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "space", 32, $event.key, [
                                " ",
                                "Spacebar",
                              ])
                            )
                              return null
                            $event.preventDefault()
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-center justify-space-between w-100",
                          },
                          [
                            _c("validation-provider", {
                              staticClass: "flex-grow-1 mr-2",
                              attrs: {
                                name: "Recurrence Label",
                                rules: "required",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("v-text-field", {
                                          staticClass: "pb-0 pt-2 my-0",
                                          attrs: {
                                            label: "Recurrence Label",
                                            "hide-details": "auto",
                                            color: "primary",
                                            "error-messages": errors,
                                            disabled: !_vm.canManageForms,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.$emit(
                                                "input",
                                                _vm.formDefinition
                                              )
                                            },
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                            },
                                          },
                                          model: {
                                            value: recurrenceSetting.label,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                recurrenceSetting,
                                                "label",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "recurrenceSetting.label",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c(
                              "v-menu",
                              {
                                attrs: { "offset-y": "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    disabled:
                                                      !_vm.canManageForms,
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-dots-vertical"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c(
                                  "v-list",
                                  [
                                    _c(
                                      "v-list-item",
                                      {
                                        attrs: {
                                          disabled: !_vm.canManageForms,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteRecurrenceSetting(
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-icon",
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-delete"),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c("v-list-item-title", [
                                          _vm._v("Delete"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "v-expansion-panel-content",
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", sm: "6" } },
                                      [
                                        _c("p", { staticClass: "caption" }, [
                                          _c("b", [_vm._v("Configuration")]),
                                        ]),
                                        _c(
                                          "section",
                                          [
                                            _c("validation-provider", {
                                              class: {
                                                "drop-down":
                                                  _vm.$vuetify.breakpoint
                                                    .smAndUp,
                                                "full-width":
                                                  _vm.$vuetify.breakpoint
                                                    .xsOnly,
                                              },
                                              attrs: {
                                                name: "Schedule Form On Submission Completion",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ errors }) {
                                                      return [
                                                        _c("v-select", {
                                                          staticClass:
                                                            "py-0 my-0",
                                                          attrs: {
                                                            autocomplete: "off",
                                                            label:
                                                              "Schedule Form",
                                                            "hide-details":
                                                              "auto",
                                                            "error-messages":
                                                              errors,
                                                            color: "primary",
                                                            name: "scheduleForm",
                                                            items:
                                                              _vm.schedulerConditions,
                                                            "item-text":
                                                              "label",
                                                            "item-value":
                                                              "value",
                                                            value:
                                                              _vm.scheduleFormValue,
                                                            disabled:
                                                              !_vm.canManageForms,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.scheduleFormChange(
                                                                $event
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm.scheduleFormValue === "CONDITIONAL"
                                  ? _c(
                                      "section",
                                      {
                                        staticClass: "d-flex align-center py-4",
                                      },
                                      [
                                        _c("validation-provider", {
                                          staticClass:
                                            "flex-grow-0 flex-shrink-0",
                                          staticStyle: { width: "50%" },
                                          attrs: {
                                            name: "Form Field",
                                            rules: "required",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-select", {
                                                      staticClass: "py-0 my-0",
                                                      attrs: {
                                                        autocomplete: "off",
                                                        label: "Form Field",
                                                        "hide-details": "auto",
                                                        "error-messages":
                                                          errors,
                                                        color: "primary",
                                                        name: "formField",
                                                        items:
                                                          _vm.questionChoices,
                                                        "item-text": "label",
                                                        "item-value": "value",
                                                        disabled:
                                                          !_vm.canManageForms,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.$emit(
                                                            "input",
                                                            _vm.formDefinition
                                                          )
                                                        },
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "append-outer",
                                                            fn: function () {
                                                              return [
                                                                _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      bottom:
                                                                        "",
                                                                      color:
                                                                        "rgb(255, 130, 0)",
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "activator",
                                                                            fn: function ({
                                                                              on,
                                                                              attrs,
                                                                            }) {
                                                                              return [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        staticClass:
                                                                                          "mr-2",
                                                                                        attrs:
                                                                                          {
                                                                                            color:
                                                                                              "rgb(255, 130, 0)",
                                                                                            dark: "",
                                                                                          },
                                                                                      },
                                                                                      "v-icon",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm.mdiInformation
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Only single select or number fields are shown here."
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                            proxy: true,
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                      model: {
                                                        value:
                                                          recurrenceSetting
                                                            .createTask
                                                            .dependantId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            recurrenceSetting.createTask,
                                                            "dependantId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "recurrenceSetting.createTask.dependantId",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                        _c("validation-provider", {
                                          staticClass:
                                            "flex-grow-0 flex-shrink-0 mx-2",
                                          staticStyle: { width: "20%" },
                                          attrs: {
                                            name: "Condition",
                                            rules: "required",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-select", {
                                                      staticClass: "py-0 my-0",
                                                      attrs: {
                                                        autocomplete: "off",
                                                        label: "Condition",
                                                        "hide-details": "auto",
                                                        "error-messages":
                                                          errors,
                                                        color: "primary",
                                                        name: "questionCondition",
                                                        items:
                                                          _vm.conditionOptions,
                                                        "item-text": "label",
                                                        "item-value": "value",
                                                        disabled:
                                                          !_vm.canManageForms,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.$emit(
                                                            "input",
                                                            _vm.formDefinition
                                                          )
                                                        },
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "item",
                                                            fn: function ({
                                                              item: {
                                                                label,
                                                                value,
                                                              },
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "py-0 my-0",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "MatchIcon",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            value,
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          label
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: "selection",
                                                            fn: function ({
                                                              item: { value },
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "py-0 my-0",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "MatchIcon",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            value,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                      model: {
                                                        value:
                                                          recurrenceSetting
                                                            .createTask
                                                            .condition,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            recurrenceSetting.createTask,
                                                            "condition",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "recurrenceSetting.createTask.condition",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                        _c("validation-provider", {
                                          staticClass:
                                            "flex-grow-0 flex-shrink-0",
                                          staticStyle: { width: "30%" },
                                          attrs: {
                                            name: "Value",
                                            rules: {
                                              required: true,
                                              numeric:
                                                _vm.questionType === "NUMBER",
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _vm.questionType !==
                                                    "SINGLE_SELECT"
                                                      ? _c("v-text-field", {
                                                          staticClass:
                                                            "py-0 my-0",
                                                          attrs: {
                                                            label: "Value",
                                                            "error-messages":
                                                              errors,
                                                            type:
                                                              _vm.questionType ===
                                                              "NUMBER"
                                                                ? "number"
                                                                : "",
                                                            color: "primary",
                                                            name: "value",
                                                            "item-text":
                                                              "label",
                                                            "item-value":
                                                              "value",
                                                            "hide-details":
                                                              "auto",
                                                            disabled:
                                                              !_vm.canManageForms,
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.$emit(
                                                                "input",
                                                                _vm.formDefinition
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              recurrenceSetting
                                                                .createTask
                                                                .value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                recurrenceSetting.createTask,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "recurrenceSetting.createTask.value",
                                                          },
                                                        })
                                                      : _c("v-select", {
                                                          staticClass:
                                                            "py-0 my-0",
                                                          attrs: {
                                                            autocomplete: "off",
                                                            label: "Value",
                                                            "error-messages":
                                                              errors,
                                                            color: "primary",
                                                            name: "value",
                                                            "item-text":
                                                              "label",
                                                            "item-value":
                                                              "value",
                                                            items:
                                                              _vm.singleSelectOptions,
                                                            disabled:
                                                              !_vm.canManageForms,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.$emit(
                                                                "input",
                                                                _vm.formDefinition
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              recurrenceSetting
                                                                .createTask
                                                                .value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                recurrenceSetting.createTask,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "recurrenceSetting.createTask.value",
                                                          },
                                                        }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm.scheduleFormValue !== "NEVER"
                              ? _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "6" } },
                                          [
                                            _c(
                                              "p",
                                              { staticClass: "caption" },
                                              [_c("b", [_vm._v("Schedule")])]
                                            ),
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "Form To Schedule",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ errors }) {
                                                      return [
                                                        _c("v-select", {
                                                          attrs: {
                                                            autocomplete: "off",
                                                            label:
                                                              "Form To Schedule",
                                                            "hide-details":
                                                              "auto",
                                                            "error-messages":
                                                              errors,
                                                            color: "primary",
                                                            name: "Schedule Form",
                                                            items:
                                                              _vm.computedScheduledFormDefinitions,
                                                            "item-text":
                                                              "label",
                                                            "item-value":
                                                              "value",
                                                            loading:
                                                              _vm.isLoadingFormDefinitions,
                                                            disabled:
                                                              _vm.isLoadingFormDefinitions ||
                                                              !_vm.canManageForms,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.$emit(
                                                                "input",
                                                                _vm.formDefinition
                                                              )
                                                            },
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "no-data",
                                                                fn: function () {
                                                                  return [
                                                                    _vm.isLoadingFormDefinitions
                                                                      ? _c(
                                                                          "v-list-item",
                                                                          [
                                                                            _c(
                                                                              "v-list-item-title",
                                                                              [
                                                                                _vm._v(
                                                                                  " Loading forms... "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _c(
                                                                          "v-list-item",
                                                                          [
                                                                            _c(
                                                                              "v-list-item-title",
                                                                              [
                                                                                _vm._v(
                                                                                  " No forms available "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                          model: {
                                                            value:
                                                              recurrenceSetting.scheduledFormDefinitionId,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                recurrenceSetting,
                                                                "scheduledFormDefinitionId",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "recurrenceSetting.scheduledFormDefinitionId",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "section",
                                      {
                                        staticClass:
                                          "d-flex justify-space-between my-2",
                                      },
                                      [
                                        _c("validation-provider", {
                                          style: { width: "48%" },
                                          attrs: {
                                            name: "Schedule Form By",
                                            rules: "required",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-select", {
                                                      attrs: {
                                                        autocomplete: "off",
                                                        label:
                                                          "Schedule Form By",
                                                        "hide-details": "auto",
                                                        "error-messages":
                                                          errors,
                                                        color: "primary",
                                                        name: "Schedule Form By",
                                                        items:
                                                          _vm.scheduleFormByOptions,
                                                        "item-text": "label",
                                                        "item-value": "value",
                                                        disabled:
                                                          !_vm.canManageForms,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.$emit(
                                                            "input",
                                                            _vm.formDefinition
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          recurrenceSetting
                                                            .recurrenceDays
                                                            .source,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            recurrenceSetting.recurrenceDays,
                                                            "source",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "recurrenceSetting.recurrenceDays.source",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                        recurrenceSetting.recurrenceDays
                                          .source === "CONSTANT"
                                          ? [
                                              _c("validation-provider", {
                                                style: { width: "48%" },
                                                attrs: {
                                                  name: "Value",
                                                  rules: {
                                                    required: true,
                                                    numeric: true,
                                                  },
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        errors,
                                                      }) {
                                                        return [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              label:
                                                                "Number of Days",
                                                              "error-messages":
                                                                errors,
                                                              type: "number",
                                                              color: "primary",
                                                              name: "value",
                                                              "hide-details":
                                                                "auto",
                                                              disabled:
                                                                !_vm.canManageForms,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                return _vm.$emit(
                                                                  "input",
                                                                  _vm.formDefinition
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                recurrenceSetting
                                                                  .recurrenceDays
                                                                  .value,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    recurrenceSetting.recurrenceDays,
                                                                    "value",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "recurrenceSetting.recurrenceDays.value",
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ]
                                          : recurrenceSetting.recurrenceDays
                                              .source === "FROM_FIELD"
                                          ? [
                                              _c("validation-provider", {
                                                style: { width: "48%" },
                                                attrs: {
                                                  name: "Form Field",
                                                  rules: "required",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        errors,
                                                      }) {
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              autocomplete:
                                                                "off",
                                                              label:
                                                                "Form Field",
                                                              "hide-details":
                                                                "auto",
                                                              "error-messages":
                                                                errors,
                                                              color: "primary",
                                                              name: "Form Field",
                                                              items:
                                                                _vm.questionChoices,
                                                              "item-text":
                                                                "label",
                                                              "item-value":
                                                                "value",
                                                              disabled:
                                                                !_vm.canManageForms,
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.$emit(
                                                                  "input",
                                                                  _vm.formDefinition
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                recurrenceSetting
                                                                  .recurrenceDays
                                                                  .dependantId,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    recurrenceSetting.recurrenceDays,
                                                                    "dependantId",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "recurrenceSetting.recurrenceDays.dependantId",
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ]
                                          : recurrenceSetting.recurrenceDays
                                              .source === "DATE_FIELD"
                                          ? [
                                              _c("validation-provider", {
                                                style: { width: "48%" },
                                                attrs: {
                                                  name: "Form Field",
                                                  rules: "required",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        errors,
                                                      }) {
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              autocomplete:
                                                                "off",
                                                              label:
                                                                "Form Field",
                                                              "hide-details":
                                                                "auto",
                                                              "error-messages":
                                                                errors,
                                                              color: "primary",
                                                              name: "Form Field",
                                                              items:
                                                                _vm.dateFieldChoices,
                                                              "item-text":
                                                                "label",
                                                              "item-value":
                                                                "value",
                                                              disabled:
                                                                !_vm.canManageForms,
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.$emit(
                                                                  "input",
                                                                  _vm.formDefinition
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                recurrenceSetting
                                                                  .recurrenceDays
                                                                  .dependantId,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    recurrenceSetting.recurrenceDays,
                                                                    "dependantId",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "recurrenceSetting.recurrenceDays.dependantId",
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                    recurrenceSetting.recurrenceDays.source !==
                                    "DATE_FIELD"
                                      ? _c(
                                          "section",
                                          {
                                            staticClass:
                                              "d-flex justify-space-between flex-wrap my-2",
                                            staticStyle: { gap: "10px" },
                                          },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "Recurrence Days From",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ errors }) {
                                                      return [
                                                        _c("v-select", {
                                                          staticClass:
                                                            "pb-2 mt-2",
                                                          attrs: {
                                                            autocomplete: "off",
                                                            label:
                                                              "Recurrence Days From",
                                                            "hide-details":
                                                              "auto",
                                                            "error-messages":
                                                              errors,
                                                            color: "primary",
                                                            name: "Recurrence Days",
                                                            items:
                                                              _vm.recurrenceDaysFromOptions,
                                                            "item-text":
                                                              "label",
                                                            "item-value":
                                                              "value",
                                                            disabled:
                                                              !_vm.canManageForms,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.$emit(
                                                                "input",
                                                                _vm.formDefinition
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              recurrenceSetting.recurrenceDaysFrom,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                recurrenceSetting,
                                                                "recurrenceDaysFrom",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "recurrenceSetting.recurrenceDaysFrom",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.scheduleFormValue !== "NEVER"
                              ? _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "6" } },
                                          [
                                            _c(
                                              "p",
                                              { staticClass: "caption" },
                                              [
                                                _c("b", [
                                                  _vm._v(
                                                    "Notification Template"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c("v-select", {
                                              attrs: {
                                                autocomplete: "off",
                                                label: "Notification Template",
                                                "hide-details": "auto",
                                                color: "primary",
                                                name: "taskNotificationTemplate",
                                                items:
                                                  _vm.taskNotificationTemplates,
                                                "item-text": "label",
                                                "item-value": "value",
                                                disabled: !_vm.canManageForms,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.$emit(
                                                    "input",
                                                    _vm.formDefinition
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  recurrenceSetting.scheduledTaskNotificationTemplateId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    recurrenceSetting,
                                                    "scheduledTaskNotificationTemplateId",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                      recurrenceSetting.scheduledTaskNotificationTemplateId\n                    ",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }
            ),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }