<template>
  <v-dialog :value="showAddPageToFormDialog" fullscreen persistent>
    <v-card color="#4F4F4F">
      <v-toolbar dark color="black" class="elevation-0">
        <v-toolbar-title>Select Page</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('add-page-to-form-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        class="py-3"
        style="height: calc(100vh - 150px); overflow-y: scroll"
      >
        <p class="white--text">Add this page to the form?</p>

        <img src="@/assets/page.png" style="width: 100%" />
      </v-card-text>

      <v-card-actions>
        <div class="pa-3 d-flex justify-end align-center flex-grow-1">
          <v-btn
            color="primary"
            text
            @click="$emit('add-page-to-form-dialog-close')"
          >
            Cancel
          </v-btn>
          <v-btn color="primary" dark @click="confirmAddPage"> Add Page </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  name: "AddPageToFormDialog",
  props: {
    showAddPageToFormDialog: Boolean,
    selectedPage: Object,
    maxWidthHeight: Number,
    formResultId: String,
  },
  methods: {
    async confirmAddPage() {
      const { dataUrl } = this.selectedPage;
      const image = {
        dataUrl,
        url: dataUrl,
        description: "",
        fileName: "page.png",
        fileType: "image/png",
        file_id: uuidv4(),
        uploaded: true,
        isImage: true,
      };
      this.$emit("confirm-add-page", image);
    },
  },
};
</script>
