var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("validation-provider", {
            attrs: {
              slim: "",
              name: _vm.item.question.label,
              rules: { required: _vm.isRequired },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors, valid }) {
                  return [
                    _c(
                      "v-menu",
                      {
                        attrs: { "max-width": "100%" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      {
                                        attrs: {
                                          label: _vm.item.question.label,
                                          color: "primary",
                                          "error-messages": errors,
                                          success: valid,
                                          disabled: _vm.readOnly,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.$emit(
                                              "input",
                                              _vm.dateValue
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.dateValue,
                                          callback: function ($$v) {
                                            _vm.dateValue = $$v
                                          },
                                          expression: "dateValue",
                                        },
                                      },
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c("v-date-picker", {
                          on: {
                            input: function ($event) {
                              return _vm.$emit("input", _vm.dateValue)
                            },
                          },
                          model: {
                            value: _vm.dateValue,
                            callback: function ($$v) {
                              _vm.dateValue = $$v
                            },
                            expression: "dateValue",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }